import React, { useEffect, useState } from 'react'
import "./vc-connection-request.sass"
import BannerIMG from '../../../../../assests/images/accelerate/pages/dashboard-bg.svg';
import { Link, useLocation } from 'react-router-dom';
import { getMentorOrVCProjectConnectionRequestData, postRequestMentorApproveAndReject } from '../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const VCConnectionRequest = () => {
    const location = useLocation()
    const pathname = location.pathname

    useEffect(() => {
        getProjectMentorOrVCAllData(pathname)
    }, [pathname])

    const [mentorProjectConnectionRequestData, setMentorProjectConnectionRequestData] = useState<any>()
    const [isLoader, setIsLoader] = useState(false)

    // Get projects vc or mentor user data
    const getProjectMentorOrVCAllData = async (userType: string) => {
        setIsLoader(true)
        await getMentorOrVCProjectConnectionRequestData(userType)
            .then((res) => {
                setIsLoader(false)
                if (res?.status === 200) {
                    let data = res?.data && res?.data?.values || [];
                    setMentorProjectConnectionRequestData(data)
                }
            })
            .catch((err) => {
                setIsLoader(false)
                toast.error(err?.response?.data?.message || 'Error while getting projects vc or mentor data')
            })
    }


    const postRequestMentorApproveAndRejectData = async (requestId: any, oprationType: string, sectionName: string) => {
        try {
            await postRequestMentorApproveAndReject(requestId, oprationType, sectionName)
                .then((res) => {
                    if (res?.status === 200) {
                        getProjectMentorOrVCAllData(pathname)
                        return res
                    } else {
                        toast.success(res?.data?.message)
                    }
                    return {};
                })
                .catch((err: any) => {
                    toast.error(err?.response?.data?.message)
                    return err
                })
        }
        catch (err: any) {
            toast.error(err?.response?.data?.message)
            return err;
        }
    };

    return (
        <>
            {isLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress style={{ color: 'white' }} size={32} />
            </div>}
            {!isLoader && <div className="row">
                { mentorProjectConnectionRequestData?.length > 0 ?
                    <>
                        {mentorProjectConnectionRequestData && mentorProjectConnectionRequestData.map((item: any, index: number) => (
                            <div className="col-lg-6 col-md-6 col-12" key={index}>
                                <div className="vc-card">
                                    <div className="top-button">
                                        <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                            <span className='anim-span'></span>
                                            <span className='anim-span anim-2'></span>
                                            <span className='anim-span anim-3'></span>
                                            <span className='anim-span anim-4'></span>
                                            <span className='anim-span anim-5'></span>
                                        </button>
                                        <button className='new-color-button-small'>DeFi</button>
                                    </div>
                                    <div className="banner-image">
                                        <img src={item?.banner || BannerIMG} className='img-fluid' alt="" />
                                    </div>
                                    <div className="text-content">
                                        <div className="">
                                            <div className="mb-3">
                                                <div className="left-part d-flex align-items-center justify-content-between">
                                                    <div className="user-image">
                                                        <img src={item?.icon || BannerIMG} className='img-fluid' alt="" />
                                                        <h5 className='heading-new-5'>{item.name}</h5>
                                                    </div>
                                                    <div className="card-social mt-0">
                                                        <ul>
                                                            <li>
                                                                <Link to="/"><i className="ri-twitter-fill"></i></Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/"><i className="ri-links-line"></i></Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/"><i className="ri-send-plane-fill"></i></Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="right-part">
                                                    <h6 className='heading-new-6 text-start mb-lg-3 mb-md-3 mb-2'>Message:</h6>
                                                    <p className='paragraph-new-small mb-0'>{item?.message || "-"}</p>
                                                </div>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <div className="respond-reject-btn">
                                                    <button className='respond-btn' onClick={() => postRequestMentorApproveAndRejectData(item?.requestId, "approve", pathname)}>Respond</button>
                                                    <button className='reject-btn' onClick={() => postRequestMentorApproveAndRejectData(item?.requestId, "reject", pathname)}>Reject</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                    : <p className='paragraph-new text-center'>No connection requests</p>
                }
            </div>}
        </>
    )
}

export default VCConnectionRequest