import { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAllFeedsAPI, getFeedByIdAPI } from 'src/pages/accelerate-pages/project-dashboard/API/APICall'
import CustomModal from 'src/shared/components/CustomModal/CustomModal'
import { toast } from 'react-toastify'

export interface IFeed {
  _id: string
  title: string
  description?: string
  createdAt: number
}

export default function Feeds({ hideMore = false }) {

  const nav = useNavigate()
  const location = useLocation()
  const splitPath = location.pathname.split('/')

  const [feed, setFeed] = useState<IFeed | null>(null)
  const [feeds, setFeeds] = useState<IFeed[]>([])
  const [totalFeeds, setTotalFeeds] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalLoader, setModalLoader] = useState(false)

  useEffect(() => {
    onGetAllFeeds()
    if (splitPath && splitPath[2] && splitPath[2] === "feeds" && splitPath[3]) {
      onClickFeed(splitPath[3])
    }
  }, [])

  const onGetAllFeeds = async () => {
    try {
      setIsLoading(true)
      const { values, total } = await getAllFeedsAPI({ filter: { skip: 0, limit: hideMore ? 20 : 4 } })
      setFeeds(values)
      setTotalFeeds(total)
      setIsLoading(false)
    } catch (ex) {
      setIsLoading(false)
    }
  }

  const onClickFeed = async (feedId: string) => {
    // nav(`/project/feed/${feedId}`)
    try {
      setOpenModal(true)
      setModalLoader(true)
      const response = await getFeedByIdAPI(feedId)
      setFeed(response)
      setModalLoader(false)
    } catch (ex) {
      setModalLoader(false)
    }
  }

  const onClickShareFeed = () => {
    navigator.clipboard.writeText(`${window.location.host}/project/feeds/${feed?._id}`)
    toast.success('Link Copied')
  }

  const onCloseModal = () => {
    setOpenModal(prev => !prev)
    const isQueryExists = splitPath && splitPath[2] && splitPath[2] === "feeds" && splitPath[3] || ''
    if (isQueryExists) {
      nav('/project/feeds', { replace: true })
    }
  }

  return (
    <div className={`${hideMore ? 'mt-0' : 'mt-4'}`}>
      <div className=''>
        <h4 className='md:text-2xl heading-new-4 color-primary'>Web3 Industry Insights</h4>
      </div>
      <div className='grid gap-3 mt-3'>
        {isLoading ?
          <div className='bg-card-item rounded-xl py-4 text-white flex justify-center'>
            <CircularProgress size={28} sx={{ color: 'white' }} />
          </div>
          :
          feeds.length ? feeds.map(feed => {
            return <FeedCard
              key={feed._id}
              onClick={() => onClickFeed(feed._id)}
              date={moment(feed.createdAt * 1000).format('DD MMM YYYY')}
              title={feed.title}
            />
          }) : <div className='bg-card-item rounded-xl py-4 text-white flex justify-center'>
            <span className='block font-bold text-sm'>No feed found</span>
          </div>
        }
      </div>
      {
        (!hideMore && totalFeeds > 4) && <div className='flex justify-center mt-3'>
          <button
            className='text-white w-[200px] bg-primary text-sm font-bold rounded-lg py-1'
            onClick={() => nav('/project/feeds')}
          >See More</button>
        </div>
      }

      <CustomModal
        isOpen={openModal}
        toggle={onCloseModal}
        maxWidth={700}
      >
        {
          modalLoader ?
            <div className='h-[200px] w-full flex justify-center items-center'>
              <CircularProgress className='text-white' size={32} />
            </div>
            :
            <div className='flex flex-col w-auto gap-3 justify-start overflow-hidden pb-2'>
              <h3 className='m-0 p-0 text-lg md:text-2xl'>{feed?.title}</h3>
              <span className='block font-bold text-base'>{feed?.createdAt && moment(feed?.createdAt * 1000).format('DD MMM YYYY') || ''}</span>
              <p className='text-sm whitespace-pre-wrap'>{feed?.description}</p>
              <button
                style={{ border: '1px solid #4398FF' }}
                className='text-sm font-bold text-white w-fit rounded-full px-3'
                onClick={onClickShareFeed}
              >
                Share
              </button>
            </div>
        }
      </CustomModal>

    </div>
  )
}

const FeedCard = (props: { date: string, title: string, onClick: () => void }) => {
  return <div
    className='bg-card-item rounded-xl p-[2%] text-white flex flex-col md:flex-row gap-1 md:gap-0 items-start cursor-pointer'
    onClick={props.onClick}
  >
    <span className='block font-bold text-xs w-[160px] mt-1'>{props.date}</span>
    <p className='text-xs md:text-sm'>{props.title}</p>
  </div>
}