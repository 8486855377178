import React, { useState } from 'react'
import "./feature-card.sass"
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';
import viewDiscountIcon from "../../../../../../../assests/icons/view-discount-icon.svg"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model'

export interface FeatureCardData {
    _id: any,
    name: string,
    description: string,
    icon: string,
    linkedInLink: string,
    twitterLink: string,
    telegramId: string,
    companyWebsiteLink: string,
    title: string,
    requestStatus: string,
    setRefreshData: any
    refreshData: boolean
    area: string;
    onClickViewDiscount?: (discountId: string) => void
}

const Featurecard = (props: FeatureCardData) => {
    const { name, area, description, icon, linkedInLink, twitterLink, telegramId, companyWebsiteLink, _id, requestStatus, refreshData, setRefreshData, onClickViewDiscount } = props
    const nav = useNavigate()
    const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
    const [selectedPartnerData, setSelectedPartnerData] = useState<any>()

    const connectionRequestModalToggle = () => {
        setConnectionRequestModal(!connectionRequestModal)
    }
    const location = useLocation();
    const { pathname } = location;
    const isPartnerDiscountPage = pathname.includes("/partner-discounts")

    const twitterLinkid = twitterLink ? `https://twitter.com/${twitterLink}` : ''
    const telegramLink = telegramId ? `https://t.me/${telegramId}` : ''
    const websiteLink = companyWebsiteLink || ''
    const linkedinLinkId = linkedInLink || ''

    return (
        <>
            <div className="slide-content feature-card mb-lg-0 mb-md-0 mb-2">
                <div className="content" onClick={() => isPartnerDiscountPage ? null : nav(`/public-view/vc/${_id}?user-type=project`)} role='button'>
                    <div className="row">
                        <div className="col-12">
                            <div className='profile-text'>
                                <div className='profiles-name d-flex align-items-center'>
                                    <div className='user-image-name'>
                                        <img src={icon || NoImage} alt="" className='img-fluid parter-image' />
                                        <h5 className='heading-new-5 ms-lg-3 ms-2 mb-0'>{name || "-"}</h5>
                                    </div>
                                    <p className='partner-area paragraph-new fw-bold mb-lg-3 mb-2 mt-lg-3 mt-2'>{area || "-"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className='profile-discription mt-4'>
                                <div className='deshboard-social d-flex gap-3'>
                                    <ul className='social-link-table'>
                                        {twitterLinkid && <li><a href={twitterLinkid} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                                        {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                                        {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                                        {linkedinLinkId && <li><a href={linkedinLinkId} target='_blank'><i className="ri-linkedin-fill"></i></a></li>}
                                    </ul>
                                </div>
                                <div>
                                    {isPartnerDiscountPage ? <button className='new-color-button view-discount-btn' onClick={() => onClickViewDiscount!(_id)}>
                                        <img src={viewDiscountIcon} width={28} />
                                        View Discounts
                                    </button> :
                                        <p className='partner-area paragraph-new fw-bold'>{description || "-"}</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-lg-4 mt-3 text-center'>
                    {requestStatus === "none" &&
                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' onClick={() => { setSelectedPartnerData(props); connectionRequestModalToggle() }}>Connect to {isPartnerDiscountPage ? "partner" : "VC"}</button>
                    }
                    {requestStatus === "requested" &&
                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "transparent", color: "#FFB800", border: "1px solid #FFB800" }}>Requested</button>
                    }
                    {requestStatus === "rejected" &&
                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "transparent", color: "#FF0000", border: "1px solid #FF0000" }}>Not interested</button>
                    }
                    {requestStatus === "connected" &&
                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "#4398FF", color: "#FFFFFF", border: "1px solid #4398FF" }}>Connected</button>
                    }
                    {/* <button className='new-primary-button' onClick={() => { setSelectedPartnerData(props); connectionRequestModalToggle() }}>Connect to {isPartnerDiscountPage ? "partner" : "VC"}</button> */}
                </div>
            </div>
            <ConnectionRequestModel
                isOpen={connectionRequestModal}
                toggle={connectionRequestModalToggle}
                name={name}
                id={_id}
                icon={icon}
                userType={isPartnerDiscountPage ? "partner" : "vc"}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
            />
        </>
    )
}

export default Featurecard