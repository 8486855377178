import React, { useEffect, useState } from 'react'
import "../../../project-dashboard/project-dashboard.sass"
import '../../vc-dashboard.sass'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getMentorOrVCUserByIdData } from '../../API/APICall';
import { VCHeroSection } from '../vc-hero-section/vc-hero-section';
import VcPublicProfile from '../vc-public-profile/vc-public-profile';
import { DashbordTopSection } from '../../../project-dashboard/components/dashbord-top-section/dashbord-top-section';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { getProjectsUserData } from '../../../project-dashboard/API/APICall';

const VCData = [
    {
        name: "Dashboard",
        route: "/vc-dashboard/dashboard"
    },
    {
        name: "My Projects",
        route: "/vc-dashboard/my-project"
    },
    {
        name: "All Projects",
        route: "/vc-dashboard/all-project"
    },
    {
        name: "Connection request",
        route: "/vc-dashboard/connection-request"
    },
]
const MentorData = [
    {
        name: "Dashboard",
        route: "/mentor-dashboard/dashboard"
    },
    {
        name: "My Projects",
        route: "/mentor-dashboard/my-project"
    },
    {
        name: "All Projects",
        route: "/mentor-dashboard/all-project"
    },
    {
        name: "Connection request",
        route: "/mentor-dashboard/connection-request"
    },
]

const ProjectData = [
    {
        name: "Dashboard",
        route: "/project/dashboard"
    },
    {
        name: "Progress",
        route: "/project/progress"
    },
    {
        name: "Mentor Network",
        route: "/project/mentor-network"
    },
    {
        name: "Partner Network",
        route: "/project/partner-network"
    },
    {
        name: "VC Network",
        route: "/project/vc-network"
    },
    {
        name: "Launch Network",
        route: "/project/launch-network"
    },
    {
        name: "Events",
        route: "/project/events"
    },
    {
        name: "Partner Discounts",
        route: "/project/partner-discounts"
    },
    {
        name: "Tools",
        route: "/project/Tools"
    },
]

const VCOrMentorProfile = () => {
    const nav = useNavigate()
    const location = useLocation();
    const { pathname } = location;
    const [mobileResponsiveMenu, setMobileResponsiveMenu] = useState(false);
    const [userData, setUserData] = useState<any>();
    const isVcPage = pathname.includes("/public-view/vc");
    const isMentorPage = pathname.includes("/public-view/mentor");
    const [sideMenuRoute, setSideMenuRoute] = useState<any>()
    const [projectUserData, setProjectUserData] = useState<any>()
    const userType = localStorage.getItem("userTypeVal") || ""
    const params = useParams<{ id: any }>();
    const queryParams = new URLSearchParams(window.location.search);
    const user_Type = queryParams.get("user-type")

    // Get vc or mentor user data
    const getMentorOrVCUserByIdAllData = async (userId: string, userType: string) => {
        await getMentorOrVCUserByIdData(userId, userType)
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Error while getting vc user data')
            })
    }

    useEffect(() => {
        if (params) {
            getMentorOrVCUserByIdAllData(params && params.id, isVcPage ? "vc-dashboard" : isMentorPage ? "mentor-dashboard" : 'partner-dashboard')
        }
    }, [params])

    useEffect(() => {
        if (user_Type && user_Type !== null) {
            setSideMenuRoute(
                user_Type === "vc" ? VCData :
                    user_Type === "mentor" ? MentorData :
                        ProjectData
            )
        }
    }, [user_Type])

    const getProjectsUserAllData = async () => {
        await getProjectsUserData()
            .then((res) => {
                if (res?.status === 200) {
                    setProjectUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }
    useEffect(() => {
        console.log("userTypeuserTypeuserType", userType.toString(), ">>", user_Type)
        if (user_Type === "project" && userType && userType === JSON.stringify("project")) {
            getProjectsUserAllData()
        }
    }, [user_Type, userType])


    return (
        <>
            <div className="dashbord-main-page">
                <div className='position-relative'>
                    <VCHeroSection userData={userData} />
                    <div className='container container-maxwidth profil-text'>
                        <div className='dashboard-tabs-section with-mobile-view mt-lg-5 mt-md-3 mt-2'>
                            <div className='profile-name mobile-view mb-lg-0 mb-3 '>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <button className='new-color-button' onClick={() => nav("/forms/project-view/profile")}>Your Profile</button>
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <div className='percentage-slider text-center m-auto d-flex'>
                                                <CircularProgressbar value={projectUserData?.profileCompleted || 0} text={`${Number(projectUserData?.profileCompleted || 0).toFixed(0)}%`} background
                                                    backgroundPadding={0}
                                                    styles={buildStyles({
                                                        backgroundColor: "transparent",
                                                        textColor: "#fff",
                                                        pathColor: "#FFB800",
                                                        trailColor: "white"
                                                    })} />
                                                <p className='paragraph-new-small my-4 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile-tab-btn">
                                    <div className='mobile-icon' role='button' onClick={() => { setMobileResponsiveMenu(true) }}>
                                        <i className="ri-menu-3-line"></i>
                                    </div>
                                </div>
                            </div>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <div className={mobileResponsiveMenu ? 'mobile-responsive-tab active' : 'mobile-responsive-tab'}>
                                    <div className='profile-name'>
                                        <h3 className='heading-new-3 color-white-new text-start fw-light first-letter-capital'>{projectUserData?.name}</h3>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <button className='new-color-button' onClick={() => nav("/forms/project-view/profile")}>Your Profile</button>
                                            </div>
                                            <div className="">
                                                <div className="">
                                                    <div className='percentage-slider text-center m-auto d-flex'>
                                                        <CircularProgressbar value={projectUserData?.profileCompleted || 0} text={`${Number(projectUserData?.profileCompleted || 0).toFixed(0)}%`} background
                                                            backgroundPadding={0}
                                                            styles={buildStyles({
                                                                backgroundColor: "transparent",
                                                                textColor: "#fff",
                                                                pathColor: "#FFB800",
                                                                trailColor: "white"
                                                            })} />
                                                        <p className='paragraph-new-small my-4 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-close-icon" role='button' onClick={() => { setMobileResponsiveMenu(false) }}>
                                        <i className="ri-close-line"></i>
                                    </div>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={""}
                                        onClick={() => { setMobileResponsiveMenu(false) }}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        {sideMenuRoute && sideMenuRoute.map((item: any, i: number) => (
                                            <Tab key={i} label={item?.name} onClick={() => { nav(item?.route) }} />
                                        ))}
                                    </Tabs>
                                </div>
                                <div className="container mb-lg-0 mb-4">
                                    <VcPublicProfile userData={userData} />
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="vc-dashbord-main-page">
                <div className='position-relative'>
                    <VCHeroSection userData={userData} />
                    <div className='container container-maxwidth profil-text'>
                        <div className='dashboard-tabs-section mt-lg-5 mt-md-3 mt-2'>
                            <div className='d-flex justify-content-between align-items-center mb-lg-0 mb-md-0 mb-3'>
                                <div><h4 className='heading-new-4 mb-0'>My projects</h4></div>
                                <div className="mobile-tab-btn">
                                    <div className='mobile-icon' role='button' onClick={() => { setMobileResponsiveMenu(true) }}>
                                        <i className="ri-menu-3-line"></i>
                                    </div>
                                </div>
                            </div>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <div className={mobileResponsiveMenu ? 'mobile-responsive-tab active' : 'mobile-responsive-tab'}>
                                    <div className="tab-close-icon" role='button' onClick={() => { setMobileResponsiveMenu(false) }}>
                                        <i className="ri-close-line"></i>
                                    </div>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        {sideMenuRoute && sideMenuRoute.map((item: string, i: number) => (
                                            <Tab key={i} label={item} />
                                        ))}
                                    </Tabs>
                                </div>
                                <div className="form-section w-100">
                                    <div className="container">
                                        <VcPublicProfile userData={userData} />
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default VCOrMentorProfile;