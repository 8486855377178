import React from 'react'
import "./footer.sass"
import { Link, useLocation, useNavigate } from 'react-router-dom'


const Footer = () => {
    const nav = useNavigate();
    const location = useLocation()

    if (location.pathname === "/") return null

    return (
        <>
            <div className='mt-lg-5 mt-4 m-lg-0 m-md-0 m-3 mb-0'>
                <div className="container">
                    <div className='row footer-sec justify-content-between'>
                        <div className='col-lg-7 Symbiote '>
                            <div className="d-lg-none d-md-none d-block text-center">
                                <button className='new-primary-button mb-3' style={{ lineHeight: '16px' }} onClick={() => { nav(`/contact-form/project-ahead`) }}>Accelerate with Symbiote, Apply to Launch!</button>
                            </div>
                            <h4 className='mb-3 heading-new-2'>Symbiote</h4>
                            <p className='mb-3 line-height-p-140'>* Past performances do not indicate future success.</p>
                            <p className='line-height-p-140 mb-3'>This web page and any other contents published on this website shall not constitute investment advice, financial advice, trading advice, or any other kind of advice, and you should not treat any of the website’s content as such. You alone assume the sole responsibility of evaluating the merits and risks associated with using any information or other content on this website before making any decisions based on such information. You understand that the crypto market is characterised by high volatility, and you should be aware of the concrete possibility of losing the entirety of the funds you allocated in the crypto market. You should refrain from using funds you can’t afford to lose when purchasing cryptocurrencies and other digital tokens.</p>
                        </div>
                        <div className="col-lg-5">
                            <div className="d-lg-block d-md-block d-none">
                                <button className='new-primary-button mb-5' onClick={() => { nav(`/contact-form/project-ahead`) }}>Accelerate with Symbiote, Apply to Launch!</button>
                            </div>
                            <div className="footer-links">
                                {/* <div className='Product'>
                                    <p>Product</p>
                                    <ul>
                                        <li><Link to="">Launchpad</Link></li>
                                        <li><Link to="">Staking</Link></li>
                                        <li><Link to="">VoteDAO</Link></li>
                                    </ul>
                                </div> */}
                                {/* <div className='Product'>
                                    <p>Resources</p>
                                    <ul>
                                        <li><Link to="">Documentation</Link></li>
                                        <li><Link to="">About Us</Link></li>
                                        <li><Link to="">CoinGecko link</Link></li>
                                        <li><Link to="">CMC Link</Link></li>
                                        <li><Link to="">Buy GS</Link></li>
                                    </ul>
                                </div> */}
                                <div className='Product'>
                                    {/* <p className='mb-lg-4 mb-md-3 mb-3'>Ecosystem</p> */}
                                    <p>Explore More!</p>
                                    <ul>
                                        <li><Link to="https://twitter.com/SymbioteSync" target='_blank'>Twitter</Link></li>
                                        <li><Link to="https://t.me/+iWT-OhLgyOc4ODVl" target='_blank'>Telegram</Link> </li>
                                        <li><Link to="mailto:contact@symbiote.gg">Email</Link></li>
                                    </ul>
                                </div>
                                <div className='d-lg-none d-md-none d-block' style={{ width: "100px" }}>
                                    <p className="line-height-p-140 color-white-new mb-0">2023 (C) | All Rights Reserved</p>
                                </div>
                            </div>
                        </div>
                        <p className="d-lg-block d-md-block d-none mt-5 line-height-p-140 color-white-new col-12 text-center mb-0">2023 (C) | All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer

