import { Id as ToastId, toast } from 'react-toastify'

export const isLoggedIn = (): boolean => {
  const token = localStorage.getItem('authorization')
  if (!token) return false
  else return true
}

export const getloggedInUserData = (): {
  _id: string
  name: string
  email: string
  accessToken: string
} => {
  let user = localStorage.getItem('userdata') as any
  if (user) {
    user = JSON.parse(user)
  }
  return user || {}
}

export const loggedInAs = (): { mentor: boolean, vc: boolean, project: boolean, partner: boolean } => {
  let mentor = false
  let vc = false
  let project = false
  let partner = false
  if (!isLoggedIn()) return { mentor, vc, project, partner }
  let userLoginType = localStorage.getItem('userTypeVal')

  if (userLoginType) {
    userLoginType = JSON.parse(userLoginType)
  }

  if (userLoginType === 'mentor') mentor = true
  if (userLoginType === 'project') project = true
  if (userLoginType === 'vc') vc = true
  if (userLoginType === 'partner') partner = true
  return { mentor, vc, project, partner }
}


const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

export const requestBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`
  };
}

export function sideScroll(element: any, direction: 'left' | 'right', speed: number, distance: number, step: number) {
  let scrollAmount = 0;
  let slideTimer = setInterval(function () {
    if (direction == 'left') {
      element.scrollLeft -= step;
    } else {
      element.scrollLeft += step;
    }
    scrollAmount += step;
    if (scrollAmount >= distance) {
      window.clearInterval(slideTimer);
    }
  }, speed);
}

interface IToastUpdater {
  id: ToastId
  type: 'success' | 'error',
  message: string
}

export function toastIdUpdater({ id, type, message }: IToastUpdater) {
  toast.update(id, {
    render: message || type === 'success' ? 'Success' : 'Error',
    type: type,
    isLoading: false,
    hideProgressBar: false,
    autoClose: 3000,
    closeButton: true,
  })
}