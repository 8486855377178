import React, { useEffect, useState } from 'react'
import { Grid, CircularProgress } from "@mui/material"
import { useParams } from 'react-router-dom'
import { getBlogBySlugAPI } from './apis'
import './blogs.scss'

function Blog() {
  const { slug } = useParams<{ slug: string }>()

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    if (slug) {
      onFetchBlog(slug)
    }
  }, [slug])

  const onFetchBlog = async (slug: string) => {
    setLoader(true)
    window.scrollTo(0, 0)
    const response = await getBlogBySlugAPI(slug)
    setLoader(false)
    const elm = document.getElementById("single-post-id")
    if (elm) {
      elm.innerHTML = response.content || ''
    }
  }

  if (window) {
    (window as any).twttr!.widgets.load()
  }

  return (
    <Grid container className='blog-container'>
      {loader && <div className='container-loader'>
        <CircularProgress size={32} />
      </div>}
      <div id="news-post-container">
        <div id="single-post-id" />
      </div>

    </Grid>
  )
}

export default Blog