import React, { useState, useEffect } from 'react'
import "./accelerate-onboard.sass"
import CelebrateBGIcon from '../../../assests/images/accelerate/pages/celebrate-bg-icon.svg';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';
import { WelcomingSection } from '../project-dashboard/components/welcoming-section/welcoming-section';
import { getAllVideosData } from '../project-dashboard/API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import YouTube from 'react-youtube';
import MediaSlider from '../../../shared/components/media-slider-comp/mediaSlider';

let settings = {
    dots: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2.5,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1.8,
            }
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1.3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1.2,
            }
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 0.5,
            }
        }

    ]
};

const Accelerateonboard = () => {
    const [courcesData, setCourcesData] = useState<any>()
    const [courseLoader, setCourseLoader] = useState(false)

    const nav = useNavigate()

    const getAllCoursesData = async () => {
        setCourseLoader(true)
        await getAllVideosData(true)
            .then((res) => {
                setCourseLoader(false)
                if (res?.status === 200) {
                    setCourcesData(res?.data?.values && res?.data?.values)
                }
            })
            .catch((err) => {
                setCourseLoader(false)
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        getAllCoursesData()
    }, [])

    return (
        <>
            <img className='cele-bg' src={CelebrateBGIcon} alt="" />
            <div className='onbord-section'>
                <div className="container container-maxwidth">
                    <WelcomingSection />
                    <div className='thank-text-section mt-lg-5 mt-md-4 mt-3'>
                        <div>
                            <h3 className='heading-new-3 start-heading color-white-new'>Good Start!</h3>
                            <p className='paragraph-new fw-light my-lg-4 my-md-4 my-3'>We think your next course of action should be to get some mentors onboard who can help you grow your team and <br /> select the right people to #BUIDL your project and take it to the moon!</p>
                            <p className='paragraph-new fw-bold mt-0 mb-lg-4 mb-md-4 mb-3'>Check out some of our mentors who can guide you specifically with this</p>
                            <MediaSlider videoData={courcesData} />
                            <div>
                                <button className='new-black-button mt-lg-5 mt-md-3 mt-2' onClick={() => nav('/project/resources')}>Check out all the Tutorials</button>
                            </div>
                        </div>
                    </div>
                    <div className='text-center mt-lg-5 mt-md-3 mt-2'>
                        <button className='new-color-button' onClick={() => nav('/project/dashboard')}>Go to App <i className="ri-external-link-line ms-2"></i></button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accelerateonboard