import React, { useEffect, useState } from 'react'
import "./vc-all-projects.sass"
import Filterlist from '../filter-list/filter-list'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import { VCCard } from '../vc-card/vc-card';
import BannerIMG from '../../../../../assests/images/banner-placeholder.png';
import Roket from '../../../../../assests/images/homepage/roket.svg'
import Game from '../../../../../assests/images/homepage/game.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RadioDropdown } from '../radio-dropdown/radio-dropdown';
import { getProjectMentorOrVCData, getProjectVcTagWiseData, setProjectInterestAPI } from '../../API/APICall';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

const collections = [
    {
        id: 1,
    },
    {
        id: 2,
        read: true
    },
    {
        id: 3
    },
    {
        id: 4
    },
    {
        id: 5
    },
]

const cardData = [
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
]
const projectslistData = [
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
        name: "DUELIST KING",
        title: "Ut enim ad minim veniam, quis nostrud ",
        description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    }
]

const dropdownData = [
    {
        btnName: "Connect Status",
        dropdown: [
            "Connected",
            "Requested",
            "Not Connected",
        ]
    },
]

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

let settings = {
    dots: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 1.8,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 1.45,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                rows: 2,
                arrows: false,
            }
        }
    ]
};

const VcAllProjects = () => {
    const [selectedMenu, setselectedMenu] = useState("Projects Overview");
    const [projectMenu, setProjectMenu] = useState(["Projects Overview"]);
    const location = useLocation();
    const pathname = location.pathname;
    const isVCDashboard = pathname.includes("/vc-dashboard");
    const isMentorDashboard = pathname.includes("/mentor-dashboard");
    const [mentorProjectData, setMentorProjectData] = useState<any>();
    const [projectVcJustInData, setProjectVcJustInData] = useState<any>();
    const [projectVcEarlyStarData, setProjectVcEarlyStarData] = useState<any>();
    const [projectVcGamingData, setProjectVcGamingData] = useState<any>();
    const [allProjectLoader, setAllProjectLoader] = useState(false)
    const nav = useNavigate()

    useEffect(() => {
        getProjectMentorAllData()
    }, [])

    useEffect(() => {
        if (projectMenu.length < 2) {
            setProjectMenu([...projectMenu, "All Projects List"])
        }
        if (isVCDashboard && selectedMenu === "Projects Overview") {
            getProjectVcTagWiseAllData("just-in")
            getProjectVcTagWiseAllData("early-star")
            getProjectVcTagWiseAllData("gaming")
        }
    }, [selectedMenu])

    const getProjectMentorAllData = async (disableLoader?: boolean) => {
        if (!disableLoader) setAllProjectLoader(true)
        await getProjectMentorOrVCData()
            .then((res) => {
                setAllProjectLoader(false)
                if (res?.status === 200) {
                    let data = res?.data && res?.data?.values || [];
                    setMentorProjectData(data)
                }
            })
            .catch((err) => {
                setAllProjectLoader(false)
                toast.error(err?.response?.data?.message || 'Error while getting projects mentors data data')
            })
    }

    const getProjectVcTagWiseAllData = async (tag: string) => {
        await getProjectVcTagWiseData(tag)
            .then((res) => {
                if (res?.status === 200) {
                    if (tag === "just-in") {
                        setProjectVcJustInData(res?.data && res?.data?.values || [])
                    } else if (tag === "early-star") {
                        setProjectVcEarlyStarData(res?.data && res?.data?.values[0] || [])
                    } else if (tag === "gaming") {
                        setProjectVcGamingData(res?.data && res?.data?.values || [])
                    }
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Error while getting projects mentors data data')
            })
    }

    const onClickExpressInterest = async (projectId: string) => {
        await setProjectInterestAPI(projectId, isMentorDashboard ? 'mentor' : isVCDashboard ? 'vc' : 'partner')
        if (isVCDashboard) {
            if (selectedMenu === "Projects Overview") {
                getProjectVcTagWiseAllData("gaming")
            } else {
                getProjectMentorAllData(true)
            }
        } else {
            getProjectMentorAllData(true)
        }
    }

    return (
        <>
            {allProjectLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress style={{ color: 'white' }} size={32} />
            </div>}
            {isVCDashboard ?
                <>
                    <div className="event-button text-center my-3 mt-0">
                        {projectMenu.map((menu: any, id: number) => (
                            <button key={id}
                                className={selectedMenu === menu ? "selectedMenu menuItem " : "menuItem "}
                                onClick={() => setselectedMenu(menu)}
                            >
                                {menu}
                            </button>
                        ))}
                    </div>
                    {selectedMenu === "Projects Overview" &&
                        <div>
                            <div className='just-in mb-lg-5 mb-md-5 mb-4'>
                                <h1 className='mb-lg-0 mb-md-0 mb-3'>Just in</h1>
                                {/* <Filterlist /> */}
                            </div>
                            <div className='all-project-slider mb-lg-5 mb-md-5 mb-0'>
                                {projectVcJustInData && projectVcJustInData?.length > 0 ?
                                    <Slider {...settings}>
                                        {projectVcJustInData?.map((item: any, i: number) => (
                                            <div className='vc-slider-card' key={i}>
                                                <div className="vc-card">
                                                    <div className={item?.isAccelerateProject === true ? "top-button justify-content-between" : "top-button justify-content-end"} >
                                                        {item?.isAccelerateProject === true &&
                                                            <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                                <span className='anim-span'></span>
                                                                <span className='anim-span anim-2'></span>
                                                                <span className='anim-span anim-3'></span>
                                                                <span className='anim-span anim-4'></span>
                                                                <span className='anim-span anim-5'></span>
                                                            </button>
                                                        }
                                                        {/* <button className='new-color-button-small'>DeFi</button> */}
                                                    </div>
                                                    <div className="banner-image">
                                                        <img src={item?.banner || BannerIMG} className='img-fluid' alt="" />
                                                    </div>
                                                    <div className="text-content">
                                                        <div className="row ">
                                                            <div className="col-6">
                                                                <div className="left-part">
                                                                    <div className="user-image">
                                                                        <img src={item?.icon || BannerIMG} className='img-fluid' alt="" />
                                                                        <h5 className='heading-new-5'>{item?.companyName || "No data available"}</h5>
                                                                    </div>
                                                                    <div className="card-social">
                                                                        <ul className="justify-content-center">
                                                                            {item?.twitterLink && item?.twitterLink?.length > 0 &&
                                                                                <li>
                                                                                    <Link to={item?.twitterLink}><i className="ri-twitter-fill"></i></Link>
                                                                                </li>
                                                                            }
                                                                            {item?.telegramLink && item?.telegramLink?.length > 0 &&
                                                                                <li>
                                                                                    <Link to={item?.telegramLink}><i className="ri-links-line"></i></Link>
                                                                                </li>
                                                                            }
                                                                            {item?.linkedInProfileLink && item?.linkedInProfileLink?.length > 0 &&
                                                                                <li>
                                                                                    <Link to={item?.linkedInProfileLink}><i className="ri-linkedin-fill"></i></Link>
                                                                                </li>
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <div className="right-part">
                                                                    <h6 className='heading-new-6 text-start mb-lg-3 mb-md-3 mb-2'>{item?.companyName || "No title available"}</h6>
                                                                    <p className='paragraph-new-small mb-0'>{item?.companyDescription || "No description available"}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 mt-lg-4 mt-md-3 mt-2 text-center project-card-actions">
                                                                <button onClick={() => nav(`/profile/public-profile-vc/${item?._id}`)} className='card-button'>View Project Page</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider> : <p className='paragraph-new text-center mb-0'>No data available</p>
                                }
                            </div>

                            <div className='my-lg-5 my-md-5 my-4'>
                                <img src={Roket} className='img-fluid mb-3 rocket-all-project' alt="" />
                                <h4 className='heading-new-4 mb-lg-5 mb-md-5 mb-0 pb-lg-0 pb-md-0 pb-3'>Early Star</h4>
                                <div>
                                    {projectVcEarlyStarData ?
                                        <div className="vc-card">
                                            <div className={projectVcEarlyStarData?.isAccelerateProject === true ? "top-button justify-content-between" : "top-button justify-content-end"} >
                                                {projectVcEarlyStarData?.isAccelerateProject === true &&
                                                    <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                        <span className='anim-span'></span>
                                                        <span className='anim-span anim-2'></span>
                                                        <span className='anim-span anim-3'></span>
                                                        <span className='anim-span anim-4'></span>
                                                        <span className='anim-span anim-5'></span>
                                                    </button>
                                                }
                                                {/* <button className='new-color-button-small'>DeFi</button> */}
                                            </div>
                                            <div className="banner-image">
                                                <img src={projectVcEarlyStarData?.banner || BannerIMG} className='img-fluid' alt="" />
                                            </div>
                                            <div className="text-content">
                                                <div className="row ">
                                                    <div className="col-lg-6">
                                                        <div className="left-part d-flex justify-content-between align-items-center">
                                                            <div className="user-image">
                                                                <img src={projectVcEarlyStarData?.icon || BannerIMG} className='img-fluid' alt="" />
                                                                <h5 className='heading-new-5'>{projectVcEarlyStarData?.name || "No name available"}</h5>
                                                            </div>
                                                            <div className="card-social mt-0 me-5">
                                                                <ul>
                                                                    {projectVcEarlyStarData?.twitterLink && projectVcEarlyStarData?.twitterLink?.length > 0 &&
                                                                        <li>
                                                                            <Link to={projectVcEarlyStarData?.twitterLink}><i className="ri-twitter-fill"></i></Link>
                                                                        </li>
                                                                    }
                                                                    {projectVcEarlyStarData?.telegramLink && projectVcEarlyStarData?.telegramLink?.length > 0 &&
                                                                        <li>
                                                                            <Link to={projectVcEarlyStarData?.telegramLink}><i className="ri-links-line"></i></Link>
                                                                        </li>
                                                                    }
                                                                    {projectVcEarlyStarData?.linkedInProfileLink && projectVcEarlyStarData?.linkedInProfileLink?.length > 0 &&
                                                                        <li>
                                                                            <Link to={projectVcEarlyStarData?.linkedInProfileLink}><i className="ri-linkedin-fill"></i></Link>
                                                                        </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="right-part">
                                                            <h6 className='heading-new-6 text-start mb-lg-3 mb-md-3 mb-2'>{projectVcEarlyStarData?.companyName || "No title available"}</h6>
                                                            <p className='paragraph-new-small mb-0'>{projectVcEarlyStarData?.companyDescription || "No description available"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 mt-4 text-center project-card-actions">
                                                        <button className='card-button' onClick={() => nav(`/profile/public-profile-vc/${projectVcEarlyStarData?._id}`)}>View Project Page</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <p className='paragraph-new text-center mb-0'>No data available</p>
                                    }
                                </div>
                            </div>

                            <div>
                                <div className="mb-lg-5 mb-md-5 mb-3">
                                    <img src={Game} className='img-fluid mb-3 rocket-all-project' alt="" />
                                    <div className='d-lg-flex d-md-flex d-block align-items-center gap-5'>
                                        <h5 className='heading-new-5 mb-lg-0 mb-md-0 mb-3'>New in Gaming</h5>
                                        {/* <div className="table-filter-content mt-0">
                                            <div className="heading-table">
                                                <p className='paragraph-new fw-bold mb-0'>Filter List by</p>
                                                {dropdownData && dropdownData.map((item: any, index: number) => (
                                                    <RadioDropdown
                                                        key={index}
                                                        BtnName={item.btnName}
                                                        dropdownValues={item.dropdown}
                                                        name={undefined}
                                                    />
                                                ))}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {projectVcGamingData && projectVcGamingData?.length > 0 ?
                                        projectVcGamingData.map((item: any, i: number) => (
                                            <VCCard
                                                key={i}
                                                name={item.name}
                                                icon={item?.icon}
                                                banner={item?.banner}
                                                _id={item?._id}
                                                isInterested={item?.isInterested}
                                                companyName={item?.companyName}
                                                projectAvgScore={item?.projectAvgScore}
                                                companyDescription={item?.companyDescription}
                                                linkedInProfileLink={item?.linkedInProfileLink}
                                                twitterLink={item?.twitterLink}
                                                telegramLink={item?.telegramLink}
                                                websiteLink={item?.websiteLink}
                                                isAccelerateProject={item?.isAccelerateProject}
                                                indexValue={undefined}
                                                onClickExpressInterest={onClickExpressInterest}
                                            />
                                        )) : <p className='paragraph-new text-center mb-0'>No data available</p>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {selectedMenu === "All Projects List" &&
                        <div className='all-project-list-tab'>
                            {/* <div className='mb-lg-5 mb-md-4 mb-4 d-flex justify-content-center'>
                                <Filterlist />
                            </div> */}
                            <div>
                                <div className="row projects-scroll">
                                    {mentorProjectData && mentorProjectData.map((item: any, index: number) => (
                                        <VCCard
                                            key={index}
                                            name={item?.name}
                                            icon={item?.icon}
                                            banner={item?.banner}
                                            _id={item?._id}
                                            isInterested={item?.isInterested}
                                            projectAvgScore={item?.projectAvgScore}
                                            companyName={item?.companyName}
                                            companyDescription={item?.companyDescription}
                                            linkedInProfileLink={item?.linkedInProfileLink}
                                            isAccelerateProject={item?.isAccelerateProject}
                                            twitterLink={item?.twitterLink}
                                            telegramLink={item?.telegramLink}
                                            websiteLink={item?.websiteLink}
                                            indexValue={undefined}
                                            onClickExpressInterest={onClickExpressInterest}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </> :
                <div className="row mb-5">
                    {mentorProjectData && mentorProjectData.map((item: any, index: number) => (
                        <VCCard
                            key={index}
                            name={item?.name}
                            icon={item?.icon}
                            banner={item?.banner}
                            _id={item?._id}
                            isInterested={item?.isInterested}
                            projectAvgScore={item?.projectAvgScore}
                            companyName={item?.companyName}
                            companyDescription={item?.companyDescription}
                            isAccelerateProject={item?.isAccelerateProject}
                            linkedInProfileLink={item?.linkedInProfileLink}
                            twitterLink={item?.twitterLink}
                            telegramLink={item?.telegramLink}
                            websiteLink={item?.websiteLink}
                            indexValue={undefined}
                            onClickExpressInterest={onClickExpressInterest}
                        />
                    ))}
                </div>
            }
        </>
    )
}

export default VcAllProjects