import { useEffect, useState } from 'react'
import { addRemoveEventReminderAPI, getEventByIdAPI, getEventsData } from '../accelerate-pages/project-dashboard/components/calendar-events/API/APICall'
import moment from "moment"
import { CircularProgress } from '@mui/material';
import CustomModal from 'src/shared/components/CustomModal/CustomModal';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IEvent {
  _id: string;
  title: string;
  startDate: number;
  endDate?: number;
  refLink?: string;
  author: string;
  featuredBanner?: string;
  banner: string;
  guests: string;
  meetLink: string;
  isReminder: boolean;
  description?: string;
}

const initialEventRegister = {
  name: '',
  email: '',
  telegramId: '',
  twitterId: ''
}

export default function Events() {

  const nav = useNavigate()

  const [event, setEvent] = useState<IEvent | null>(null)
  const [pastEvents, setPastEvents] = useState<IEvent[]>([])
  const [events, setEvents] = useState<IEvent[]>([])
  const [featuredEvent, setFeaturedEvent] = useState<IEvent | null>(null)
  const [featuredLoader, setFeaturedLoader] = useState(false)
  const [eventLoader, setEventLoader] = useState(false)
  const [eventId, setEventId] = useState('')
  const [modalLoader, setModalLoader] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [eventRegister, setEventRegister] = useState({ ...initialEventRegister })

  const { slug } = useParams<{ slug: string }>()

  useEffect(() => {
    onGetEvents()
    onGetPastEvents()
    onGetFeaturedEvents()
    if (slug) {
      setEventId(slug)
      onGetEventById(slug)
    }
  }, [])

  const onGetEvents = async () => {
    setEventLoader(true)
    await getEventsData()
      .then((res) => {
        setEventLoader(false)
        if (res?.status === 200) {
          setEvents(res.data && res?.data?.values || []);
        }
      })
      .catch((err) => {
        setEventLoader(false)
      })
  }

  const onGetPastEvents = async () => {
    await getEventsData('past')
      .then((res) => {
        if (res?.status === 200) {
          setPastEvents(res.data && res?.data?.values || []);
        }
      })
  }

  const onGetFeaturedEvents = async () => {
    setFeaturedLoader(true)
    await getEventsData('', true)
      .then((res) => {
        setFeaturedLoader(false)
        if (res?.status === 200) {
          setFeaturedEvent(res.data && res?.data?.values && res?.data?.values[0] || null)
        }
      })
      .catch((err) => {
        setFeaturedLoader(false)
      })
  }

  const onGetEventById = async (eventId: string) => {
    setModalLoader(true)
    getEventByIdAPI(eventId)
      .then(res => {
        setModalLoader(false)
        setEvent(res?.data || {})
      })
      .catch(ex => {
        setModalLoader(false)
      })
  }

  const onCloseEventModal = () => {
    setEventId('')
    setEvent(null)
    setEventRegister({ ...initialEventRegister })
    nav('/events')
  }

  const onClickEventCard = (eventId: string) => {
    nav(`/events/${eventId}`)
    setEventId(eventId)
    onGetEventById(eventId)
  }

  const onSaveReminder = async () => {
    try {
      setModalLoader(true)
      setConfirmModal(false)
      const response = await addRemoveEventReminderAPI(eventId, eventRegister)
      setModalLoader(false)
      onCloseEventModal()
      toast.success(response?.data?.message || 'Event registration completed')
    } catch (ex: any) {
      toast.error(ex?.response?.data.message || 'Something went wrong')
      setModalLoader(false)
    }
  }

  return (
    <div className='min-h-[400px] text-white'>
      <h1 className='capitalize text-center tracking-[.25em] font-bold text-3xl md:text-5xl mt-2'>EVENTS</h1>
      <div style={{ backgroundImage: `url(${featuredEvent?.featuredBanner})` }} className="bg-blend-soft-light bg-no-repeat bg-center bg-cover bg-[#25272a] aspect-[16/4] mt-4 flex justify-center items-center py-[4%]">
        {featuredLoader ?
          <div className='h-[200px] w-full flex justify-center items-center'>
            <CircularProgress className='text-white' size={32} />
          </div>
          :
          featuredEvent ? <div className='max-w-[1400px] px-3 w-full m-auto items-center justify-start'>
            <span className='text-base md:text-2xl'>{featuredEvent?.startDate && moment(featuredEvent?.startDate * 1000).format('Do MMMM YYYY - HH:mm UTC') || '-'}</span>
            <h2 className='text-xl md:text-5xl font-bold max-w-[800px] m-0 mt-3'>{featuredEvent?.title}</h2>
            <button className='w-fit bg-[#2479DF] text-white px-4 py-2 text-sm md:text-base rounded-2xl mt-3' onClick={() => onClickEventCard(featuredEvent?._id!)}>Register</button>
          </div> : <div>No Featured Event Found</div>
        }
      </div>

      <div className='py-4 mt-4 max-w-[1400px] m-auto'>
        <h2 className='mb-4'>Symbiote Upcoming Events</h2>
        {eventLoader ? <div className='h-[200px] w-full flex justify-center items-center'>
          <CircularProgress className='text-white' size={32} />
        </div>
          :
          events.length ? <div className='px-3 w-full grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:px-2 lg:px-0'>
            {events.map((event, index) => {
              const isRegistered = event.isReminder
              return <div
                className={`text-center rounded-2xl cursor-pointer`}
                style={{ background: isRegistered ? '#0f540f' : '#173760' }}
                key={index}
                onClick={() => onClickEventCard(event._id)}
              >
                <img className='object-contain min-h-[100px] w-full bg-[#232323] aspect-[16/9] rounded-2xl' src={event.banner} alt="banner" />
                <button className='text-center py-2 font-bold '>{isRegistered ? 'Registered' : 'Register now'}</button>
              </div>
            })}
          </div> : <div className='py-6 text-center'>No Event Found</div>}
      </div>

      <div className='py-4 mt-4 max-w-[1400px] m-auto'>
        <h2 className='mb-4'>Symbiote Past Events</h2>
        {eventLoader ? <div className='h-[200px] w-full flex justify-center items-center'>
          <CircularProgress className='text-white' size={32} />
        </div>
          :
          pastEvents.length ? <div className='max-w-[1400px] px-3 w-full m-auto grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:px-2 lg:px-0'>
            {
              pastEvents.map((event, index) => {
                const isRegistered = event.isReminder
                return <div
                  className={`text-center rounded-2xl cursor-pointer`}
                  style={{ background: isRegistered ? '#0f540f' : '#173760' }}
                  key={index}
                  onClick={() => onClickEventCard(event._id)}
                >
                  <img className='object-contain min-h-[100px] w-full bg-[#232323] aspect-[16/9] rounded-2xl' src={event.banner} alt="banner" />
                </div>
              })}
          </div> : <div className='py-6 text-center'>No Past Event Found</div>}
      </div>

      <CustomModal
        isOpen={!!eventId}
        toggle={onCloseEventModal}
        maxWidth={700}
        hideClose
      >
        {
          modalLoader ?
            <div className='h-[200px] w-full flex justify-center items-center'>
              <CircularProgress className='text-white' size={32} />
            </div>
            :
            <div className='flex flex-col w-auto gap-3 justify-start overflow-hidden pb-2'>
              <img className='aspect-[16/9] bg-[#232323] rounded-lg object-cover' src={event?.banner} alt="" />
              <h2 className='m-0 text-xl md:text-3xl font-bold'>{event?.title}</h2>
              <div className='flex flex-col sm:flex-row gap-1 items-center'>
                <span className='text-base md:text-lg m-0'>{event?.startDate && moment(event?.startDate * 1000).format('Do MMMM YYYY HH:mm UTC') || '-'}</span>
                {event?.endDate && <div className='flex flex-col sm:flex-row gap-1 items-center'><span>-- </span> <span className='text-base md:text-lg m-0'>{event?.endDate && moment(event?.endDate * 1000).format('Do MMMM YYYY HH:mm UTC') || '-'}</span></div>}
              </div>
              <p className='text-base md:text-lg m-0'>Guests: <span className='font-bold'>{event?.guests}</span></p>
              {!!event?.refLink! && <a href={event?.refLink} className='bg-blue-500 text-white w-fit px-3 py-1 no-underline rounded-lg'>Website Link</a>}
              {event?.description && <p className='whitespace-pre-wrap m-0 text-xs md:text-sm'>{event?.description}</p>}
              {event?.startDate! > moment().unix() && <div className='flex gap-2'>
                <button className='w-fit m-0 bg-[#64985e] px-4 py-2 rounded-2xl font-bold' onClick={() => setConfirmModal(true)}>Register Now</button>
                <button className='w-fit m-0 bg-transparent border px-4 py-2 rounded-2xl font-bold' onClick={onCloseEventModal}>Close</button>
              </div>}
            </div>
        }
      </CustomModal>

      <CustomModal
        isOpen={confirmModal}
        toggle={() => setConfirmModal(false)}
        title='RSVP for the Event'
        maxWidth={400}
      >
        <div>
          <div className='grid gap-3 grid-cols-1 my-3'>
            <input
              className='bg-transparent rounded-md w-full h-10 px-2 outline-none text-sm md:text-base'
              name='name'
              style={{ border: '1px solid gray' }}
              value={eventRegister.name}
              onChange={({ target: { value, name } }) => setEventRegister(prev => ({ ...prev, [name]: value }))}
              placeholder='Enter Your Name'
            />
            <input
              className='bg-transparent rounded-md w-full h-10 px-2 outline-none text-sm md:text-base'
              name='email'
              style={{ border: '1px solid gray' }}
              value={eventRegister.email}
              onChange={({ target: { value, name } }) => setEventRegister(prev => ({ ...prev, [name]: value }))}
              placeholder='Enter Your Email Address'
            />
            <input
              className='bg-transparent rounded-md w-full h-10 px-2 outline-none text-sm md:text-base'
              name='telegramId'
              style={{ border: '1px solid gray' }}
              value={eventRegister.telegramId}
              onChange={({ target: { value, name } }) => setEventRegister(prev => ({ ...prev, [name]: value }))}
              placeholder='Enter Your Telegram ID (ex: @johndoe)'
            />
            <input
              className='bg-transparent rounded-md w-full h-10 px-2 outline-none text-sm md:text-base'
              name='twitterId'
              style={{ border: '1px solid gray' }}
              value={eventRegister.twitterId}
              onChange={({ target: { value, name } }) => setEventRegister(prev => ({ ...prev, [name]: value }))}
              placeholder='Enter Your Twitter Id (ex: @johndoe)'
            />
          </div>
          <button className="new-color-button py-2" onClick={onSaveReminder}>
            Register
          </button>
        </div>
      </CustomModal>

    </div >
  )
}
