import React, { useEffect, useState } from 'react'
import "./first-time-login.sass"
import { useNavigate, useLocation } from 'react-router-dom';
import { WelcomingSection } from '../welcoming-section/welcoming-section';
import CelebrateBGIcon from '../../../../../assests/images/accelerate/pages/celebrate-bg-icon.svg';
import { DashbordTopSection } from '../dashbord-top-section/dashbord-top-section';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import { getProjectsUserData } from '../../API/APICall';
import { UserUpdateForm } from '../../../user-update-form/user-update-form';

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

var _ = require("lodash");

const FirstTimeLogin = () => {
    const [projectUserData, setProjectUserData] = useState<any>();
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const isProjectProfile = pathname.includes("/project-view/profile")
    const nav = useNavigate()
    const isProjectFirstTimeLogin = pathname.includes("/forms/first-time-login")

    const getProjectsUserAllData = async () => {
        await getProjectsUserData()
            .then((res) => {
                if (res?.status === 200) {
                    setProjectUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        getProjectsUserAllData()
    }, [])

    return (
        <>
            <div className='position-relative'>
                {isProjectFirstTimeLogin &&
                    <>
                        <img className='cele-bg' src={CelebrateBGIcon} alt="" />
                        <WelcomingSection />
                    </>
                }
                {isProjectProfile &&
                    <DashbordTopSection userData={projectUserData} />
                }
                <div className='form-section mb-5'>
                    <div className='container container-maxwidth'>
                        <div className="projectDetails-top background-primary mt-lg-5 mt-md-3 mt-2">
                            <div className="projectDetails">
                                <UserUpdateForm />
                            </div>
                        </div>
                        {isProjectProfile &&
                            <div className='mt-lg-5 mt-3 align-items-center d-flex justify-content-center'>
                                <button className='new-primary-button' onClick={() => { nav(-1) }}>Complete the Form later</button>
                            </div>
                        }
                    </div>
                </div >
            </div >
        </>
    )
}

export default FirstTimeLogin