import gsap from 'gsap'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'

const sponsorsLogo = (): string[] => {
  let list = [] as string[]
  let totalLogos = 24
  for (let i = 1; i <= totalLogos; i++) {
    list.push(`/img/sponsors/${i}.svg`)
  }
  return list
}

export default function OurNetwork() {

  const [startTicker, setStartTicker] = useState(false)
  const tickerRef = useRef(null)

  useLayoutEffect(() => {
    setTimeout(() => {
      setStartTicker(true)
    }, 600)

    gsap.from(tickerRef.current, {
      scrollTrigger: {
        trigger: tickerRef.current,
        start: '-200px 80%',
        end: 'bottom 80%',
        scrub: false,
        toggleActions: "play none reverse none",
      },
      x: '-40%',
      duration: 2
    })

  }, [])


  return (
    <div className='text-center pb-12'>
      <h3 className='bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-xl md:text-3xl'>OUR NETWORK</h3>
      <div ref={tickerRef} className='h-[60px] md:h-[80px] w-full bg-[#000000b9] overflow-hidden flex mt-4 md:mt-8 gap-4 md:gap-8'>
        <div className={`flex justify-start items-center gap-4 md:gap-8 h-full ${startTicker ? 'animate-marquee' : ''}`}>
          {sponsorsLogo().map((logo, index) => {
            return <img key={index} src={logo} alt="" className='w-fit max-w-[200px] h-[40%]' />
          })}
        </div>
        <div className={`flex justify-start items-center gap-4 md:gap-8 h-full ${startTicker ? 'animate-marquee' : ''}`}>
          {sponsorsLogo().map((logo, index) => {
            return <img key={index} src={logo} alt="" className='w-fit max-w-[200px] h-[40%]' />
          })}
        </div>
      </div>
    </div>
  )
}
