import React, { useEffect, useState, useMemo } from 'react'
import "./vc-network.sass"
import Latestbets from './components/latest-bets/latest-bets'
import VcLevelTable from './components/vc-level-table/vc-level-table'
import VcCondensedTable from './components/vc-condensed-table/vc-condensed-table'
import VcListTable from './components/vc-list-table/vc-list-table'
import ConnectionStatusTable from '../partner-network/components/connection-status-table/connection-status-table'
import Slider from 'react-slick'
import Featurecard from './components/feature-card/feature-card'
import ProgressTable from '../progress/components/progress-table/progress-table'
import { ToastContainer, toast } from 'react-toastify'
import PartnerListTable from '../../../../home/components/partner-list-table/partner-list-table'
import { getAllVCData, getProjectsAssignedVcData } from './API/APICall'
import CustomPagination from '../../../../../shared/components/pagination/Pagination'
import VcFeatureCard from './components/vc-feature-card/VcFeatureCard'
import SearchBox from '../dashbord-top-section/search-box/search-box'
import { CircularProgress } from '@mui/material'

export interface TableFilterDAta {
    value: any,
}
export interface MentorCardData {
    value: any,
}

interface AssignedVCColumn {
    id: string;
    label: string;
    minWidth: number;
    maxWidth?: number
}
const columns: AssignedVCColumn[] = [
    { id: 'image', label: '', minWidth: 20, maxWidth: 20 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'sectorsLikeToInvest', label: 'Area', minWidth: 170 },
    { id: 'stageOfInvests', label: 'Investment stage', minWidth: 170 },
    { id: 'socials', label: 'Socials', minWidth: 120 },
    { id: 'status', label: 'Status', minWidth: 130 },
];

const sliderSettings = {
    dots: false,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 2.1,
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 1.9,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1.8,
            }
        },
        {
            breakpoint: 600,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 300,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        }

    ]
};

const Vcnetwork = (props: MentorCardData) => {
    const { value } = props
    const [viewTable, setViewTable] = useState("smallView");
    const [assignedProjectsVC, setAssignedProjectsVC] = useState<any>([]);
    const [assignedProjectsLoader, setAssignedProjectsLoader] = useState(false);
    const [vCFeaturedData, setVCFeaturedData] = useState<any>([]);
    const [featuredLoader, setFeaturedLoader] = useState(false);
    const [vCAllData, setVCAllData] = useState<any>([]);
    const [refreshData, setRefreshData] = useState(false)
    const [allVcLoader, setAllVcLoader] = useState(false)
    const [filter, setFilter] = useState({
        page: 1,
        skip: 0,
        limit: 12,
    })
    const [totalCount, setTotalCount] = useState<number>(0)

    useEffect(() => {
        getProjectsAssignedVcAllData()
        getVCAllData(true)
    }, [refreshData])

    useEffect(() => {
        getVCAllData(false)
    }, [refreshData, filter])

    const onChangePage = (page: number) => {
        let skip = (page - 1) * filter.limit
        setFilter(prev => ({ ...prev, page, skip }))
    }

    // get assigned vc api
    const getProjectsAssignedVcAllData = async () => {
        setAssignedProjectsLoader(true)
        await getProjectsAssignedVcData()
            .then((res) => {
                setAssignedProjectsLoader(false)
                if (res?.status === 200) {
                    setAssignedProjectsVC(res?.data?.values && res?.data?.values)
                }
            })
            .catch((err) => {
                setAssignedProjectsLoader(false)
            })
    }

    // get assigned vc api
    const getVCAllData = async (isFeatured: boolean) => {
        const loaderFunction = isFeatured ? setFeaturedLoader : setAllVcLoader
        loaderFunction(true)
        await getAllVCData(isFeatured, filter)
            .then((res) => {
                loaderFunction(false)
                if (res?.status === 200) {
                    if (isFeatured) {
                        setVCFeaturedData(res?.data && res?.data?.values || [])
                    } else {
                        setVCAllData(res?.data && res?.data?.values || [])
                        setTotalCount(res?.data && res?.data?.total || 0)
                    }
                }
            })
            .catch((err) => {
                loaderFunction(false)
            })
    }

    const onClickSearch = async (value: string) => {
        setAllVcLoader(true)
        await getAllVCData(false, { skip: 0, limit: 12, search: value })
            .then((res) => {
                setAllVcLoader(false)
                if (res?.status === 200) {
                    setVCAllData(res?.data && res?.data?.values || [])
                    setTotalCount(res?.data && res?.data?.total || 0)
                }
            })
            .catch((err) => {
                setAllVcLoader(false)
            })
    }

    const totalPages = useMemo(() => Math.ceil(totalCount / filter.limit), [totalCount, filter])

    return (
        <>
            <div className=''>
                <div className='feature-vc-section'>
                    <h5 className='heading-new-5 color-primary'>Featured VCs</h5>
                    {featuredLoader && <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress style={{ color: 'white' }} size={32} />
                    </div>}
                    <div className='mt-4 pb-2 flex overflow-auto gap-3 justify-center'>
                        {!featuredLoader ? vCFeaturedData?.length > 0 ? vCFeaturedData?.map((item: any, i: number) => (
                            <VcFeatureCard
                                key={i}
                                name={item?.name}
                                _id={item?._id}
                                sectorsToInvest={item?.sectorsLikeToInvest && item?.sectorsLikeToInvest.join(', ')}
                                stageOfInvests={item?.stageOfInvests || "-"}
                                icon={item?.icon}
                                linkedInLink={item?.linkedInProfileLink}
                                twitterLink={item?.twitterLink}
                                telegramId={item?.telegramId}
                                companyWebsiteLink={item?.companyWebsiteLink}
                                requestStatus={item?.requestStatus}
                                setRefreshData={setRefreshData}
                                refreshData={refreshData}
                            />
                        ))
                            : <p className='paragraph-new text-center'>No data available</p>
                            : null}
                    </div>
                </div>
                <div className='progress-table mt-5'>
                    <div className="table-heading d-flex align-items-center justify-content-between">
                        <h5 className='heading-new-5 color-primary mb-0'>Currently Connected to</h5>
                        {/* <a className='paragraph-new-medium' href="#">Raise a concern</a> */}
                    </div>
                    <PartnerListTable isLoading={assignedProjectsLoader} tabIndex={value} setPartnerStatus={undefined} currentPartnerData={assignedProjectsVC} tableColumns={columns} userType={"vc"} />
                </div>
                {/* ?Latest Bets by in VC Network */}
                {/* <div className='latest-bets-section mt-lg-5 mt-4'>
                    <h5 className='heading-new-5'>Latest Bets by in VC Network</h5>
                    <div className='mt-lg-4 mt-3'>
                        <Latestbets />
                    </div>
                </div> */}
                <div className='explore-section mt-lg-5 mt-md-3 mt-2'>
                    <div className='d-lg-flex d-md-flex d-block align-items-center justify-content-between'>
                        <div>
                            <h4 className='heading-new-4 color-primary'>Explore Our Network</h4>
                        </div>
                        <div className='d-flex text-center align-items-center mt-lg-0 mt-md-0 mt-3 justify-content-center'>
                            <p className='paragraph-new-small mb-0 me-4'>View</p>
                            <i className="ri-zoom-out-line color-primary-new me-3"></i>
                            <div className='form-check'>
                                <input className="form-check-input me-5" type="radio" name="exampleRadios" id="flexRadioDefault1" value="option1" defaultChecked onChange={() => setViewTable("smallView")} />
                            </div>
                            {/* <div className='form-check'>
                                <input className="form-check-input me-5" type="radio" name="exampleRadios" id="flexRadioDefault2" value="option1" onChange={() => setViewTable("mediumView")} />
                            </div> */}
                            <div className='form-check'>
                                <input className="form-check-input me-3" type="radio" name="exampleRadios" id="flexRadioDefault3" value="option1" onChange={() => setViewTable("largeView")} />
                            </div>
                            <i className="ri-zoom-in-line color-primary-new"></i>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="mt-3 flex justify-end">
                            <SearchBox onSearch={onClickSearch} />
                        </div>
                        {viewTable === "smallView" &&
                            <ConnectionStatusTable
                                partnerData={vCAllData}
                                tabValue={value}
                                userType={"vc"}
                                isLoading={allVcLoader}
                                tableColumns={columns}
                                bigData={"mediumView"}
                                setRefreshData={setRefreshData}
                                refreshData={refreshData}
                            />
                        }
                        {viewTable === "mediumView" &&
                            <ConnectionStatusTable
                                partnerData={vCAllData}
                                tabValue={value}
                                userType={"vc"}
                                tableColumns={columns}
                                setRefreshData={setRefreshData}
                                refreshData={refreshData}
                            />
                        }
                        {viewTable === "largeView" &&
                            <VcLevelTable
                                vCData={vCAllData}
                                setRefreshData={setRefreshData}
                                refreshData={refreshData}
                            />
                        }
                    </div>
                    {totalPages > 1 ? <CustomPagination
                        total={totalPages}
                        page={filter.page}
                        onChange={onChangePage}
                    /> : null}
                </div>
            </div>
        </>
    )
}

export default Vcnetwork