import { useState, useMemo, useEffect } from 'react'
import { Box, CircularProgress, Grid } from "@mui/material"
import './index.sass'
import JobCard from './JobCard'
import JobDetailCard from './JobDetailCard';
import Slider from '@mui/material/Slider';
import { getAllJobsAPI, getJobByIdAPI, getJobIndustriesAPI, getJobRolesAPI } from './apis';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomPagination from '../../../../../shared/components/pagination/Pagination';
import JobAnimation from './JobAnimation';
import Switch from '../../../../../shared/components/CustomSwitch/Switch';

export interface IJob {
  _id: string;

  icon: string;
  companyName: string;
  shortDescription: string;
  description?: string;

  role: string;
  location: string;
  isRemote: boolean;
  minSalary: number;
  maxSalary: number;
  isFullTime: boolean;
  currency: string;

  twitterLink: string;
  telegramLink: string;
  websiteLink: string;
  applyLink: string;

  createdAt: string;
}

const minDistance = 10;
interface IFilter {
  page: number;
  skip: number;
  limit: number;
  roles: string[];
  industries: string[];
  search: string;
  isRemote: boolean;
}

export interface ICombineFilters extends IFilter {
  salaryRange: number[]
}

const initialFilters = {
  page: 1,
  skip: 0,
  limit: 6,
  roles: [],
  industries: [],
  search: '',
  isRemote: false
}

function JobBoard() {

  const [salaryRange, setSalaryRange] = useState<number[]>([10, 100]);
  const [jobs, setJobs] = useState<IJob[]>([])
  const [job, setJob] = useState<IJob | null>(null)
  const [roles, setRoles] = useState<string[]>([])
  const [filters, setFilters] = useState<IFilter>({ ...initialFilters })
  const [industries, setIndustries] = useState<string[]>([])
  const [jobsLoader, setJobsLoader] = useState<boolean>(false)
  const [jobLoader, setJobLoader] = useState<boolean>(false)
  const [totalJobs, setTotalJobs] = useState<number>(0)
  const [moreOption, setMoreOptions] = useState<any>({
    rolesIsSelected: false,
    industriesIsSelected: false
  })

  const location = useLocation()
  const history = useNavigate()
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  let intervalId = null as any

  useEffect(() => {
    getRoles()
    getIndustries()
    const paramsId = splitLocation && splitLocation[2] || ''
    if (paramsId) {
      getJobById(paramsId)
    }
  }, [])

  useEffect(() => {
    getAllJobs({ ...filters, salaryRange })
    return () => {
      clearInterval(intervalId)
    }
  }, [filters, salaryRange])

  const getAllJobs = (pfilters: ICombineFilters) => {
    const isFilterChanged = JSON.stringify(pfilters) !== JSON.stringify({ ...initialFilters, salaryRange })
    intervalId = setTimeout(() => {
      setJobsLoader(true)
      if (isFilterChanged) {
        setJob(null)
      }
      getAllJobsAPI(pfilters).then((data) => {
        setJobsLoader(false)
        setJobs(data.values)
        setTotalJobs(data.total)
      }).catch(err => {
        setJobsLoader(false)
      })
    }, 500)
  }

  const getRoles = () => {
    getJobRolesAPI().then((data) => setRoles(data)).catch(err => { })
  }
  const getIndustries = () => {
    getJobIndustriesAPI().then((data) => setIndustries(data)).catch(err => { })
  }

  const getJobById = (id: string) => {
    setJobLoader(true)
    setJob(null)
    getJobByIdAPI(id).then(data => {
      setJobLoader(false)
      setJob(data)
    }).catch((err) => {
      setJobLoader(false)
    })
  }

  const onClickJob = (id: string) => {
    getJobById(id)
    history(`/jobs/${id}`)
  }

  const onChangePage = (page: number) => {
    if (filters.page === page) return
    let skip = (page - 1) * filters.limit
    setFilters(prev => ({ ...prev, page, skip }))
  }

  const onSelectFilters = (type: 'roles' | 'industries', value: string) => {
    if (type === 'roles') {
      const updatedValues = [...filters.roles] as string[]
      const valueIndex = updatedValues.findIndex(item => item === value)
      if (valueIndex > -1) updatedValues.splice(valueIndex, 1)
      else updatedValues.push(value)
      setFilters((prev: any) => ({ ...prev, roles: updatedValues }))
    } else {
      const updatedValues = [...filters.industries] as string[]
      const valueIndex = updatedValues.findIndex(item => item === value)
      if (valueIndex > -1) updatedValues.splice(valueIndex, 1)
      else updatedValues.push(value)
      setFilters((prev: any) => ({ ...prev, industries: updatedValues }))
    }
  }

  const handleChangeSalaryRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance);
        setSalaryRange([clamped, clamped + minDistance]);
      } else {
        const clamped = Math.max(newValue[1], minDistance);
        setSalaryRange([clamped - minDistance, clamped]);
      }
    } else {
      setSalaryRange(newValue as number[]);
    }
  };

  const onChangeMoreLessOption = (type: 'roles' | 'industries') => {
    const keyName = type === 'roles' ? 'rolesIsSelected' : 'industriesIsSelected'
    setMoreOptions((prev: any) => {
      return {
        ...prev,
        [keyName]: !prev[keyName]
      }
    })
  }

  const totalPages = useMemo(() => Math.ceil(totalJobs / filters.limit), [totalJobs, filters])

  return (
    <Grid container className="job-board-container">

      {/* {jobsLoader ? <div className="container-loader" >
        <CircularProgress sx={{ color: '#dcdcdc' }} />
      </div> : null} */}
      <JobAnimation />

      <Grid item xs={12}>
        <h3 className='heading-new-6 text-start mt-3 mb-4'>Browse blockchain jobs in web3 with our partners. Filter the best remote crypto jobs by salary, location, and skills.</h3>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} className="filters-container">
          <Grid item xs={12} sm={6} md={4}>
            <div className='filter-item'>
              <div>
                <p className='paragraph-new fw-bold text-center color-primary mb-3'>Salary</p>
                <div className='slider-container'>
                  <Slider
                    value={salaryRange}
                    onChange={handleChangeSalaryRange}
                    valueLabelDisplay="auto"
                    disableSwap
                    min={10}
                    max={100}
                    sx={{
                      '& .MuiSlider-track': {
                        height: 3,
                        background: '#4398FF'
                      },
                      '& .MuiSlider-rail': {
                        background: '#ffffff',
                        opacity: 1,
                        height: '1px',
                      },
                    }}
                  />
                </div>
                <div className='salary-range'>
                  <span className='sup-new-small fw-lighter m-0'>$10K</span>
                  <span className='sup-new-small fw-lighter m-0'>$100K+</span>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className='filter-item'>
              <div>

                <p className='paragraph-new fw-bold text-center color-primary mb-3'>Roles</p>
                <div className='roles-container' id='role-container-id'>
                  {roles.filter((item, index) => index <= (moreOption.rolesIsSelected ? roles.length - 1 : 1)).map(item => {
                    const isSelected = filters.roles.find(value => value === item)
                    return <div
                      key={item}
                      className={`role-item ${isSelected ? 'active' : ''}`}
                    >
                      <span
                        onClick={() => onSelectFilters('roles', item)}>{item}
                      </span></div>
                  })}
                </div>
                {roles.length > 2 && <div
                  className="paragraph-new-small text-center mt-lg-3 mt-4"
                  role='button'
                  onClick={() => onChangeMoreLessOption('roles')}
                >
                  {moreOption.rolesIsSelected ? 'Less options' : 'More options'}
                  {moreOption.rolesIsSelected ? <i className="ri-arrow-up-s-line color-primary ml-1"></i> : <i className="ri-arrow-down-s-line color-primary"></i>}
                </div>}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className='filter-item'>
              <div>
                <p className='paragraph-new fw-bold text-center color-primary mb-3'>Industry</p>
                <div className='roles-container' id='industry-container-id'>
                  {industries.filter((item, index) => index <= (moreOption.industriesIsSelected ? industries.length - 1 : 1)).map(item => {
                    const isSelected = filters.industries.find(value => value === item)
                    return <div
                      key={item}
                      className={`role-item ${isSelected ? 'active' : ''}`}
                    >
                      <span
                        onClick={() => onSelectFilters('industries', item)}>{item}
                      </span>
                    </div>
                  })}
                </div>
                {industries.length > 2 && <div
                  className="paragraph-new-small text-center mt-lg-3 mt-4"
                  role='button'
                  onClick={() => onChangeMoreLessOption('industries')}
                >
                  {moreOption.industriesIsSelected ? 'Less options' : 'More options'}
                  {moreOption.industriesIsSelected ? <i className="ri-arrow-up-s-line color-primary ml-1"></i> : <i className="ri-arrow-down-s-line color-primary"></i>}
                </div>}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className='search-remote-container'>
          <Grid item xs={12} md={6}>
            <div className='remote-sort-item'>
              <span>Show only remote jobs</span>
              <Switch
                value={filters.isRemote}
                onChange={(e: any) => setFilters(prev => ({ ...prev, isRemote: e.target.checked }))}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='search-sort-item'>
              <span>Search</span>
              <input
                value={filters.search}
                onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
                placeholder='Search by Company/Role name'
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>

            {jobsLoader ? <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress style={{ color: 'white' }} size={32} />
            </div> : jobs.length ? jobs.map((jobItem, index) => {
              return <JobCard
                key={index}
                item={jobItem}
                colorIndex={index + 1}
                selectedItem={job!}
                onClickJob={onClickJob}
              />
            }) : <div className='h-[200px] flex justify-center items-center'>
              <span>No jobs found</span>
            </div>
            }
            {totalPages > 1 ? <CustomPagination
              total={totalPages}
              page={filters.page}
              onChange={onChangePage}
            /> : null}
          </Grid>

          <Grid item xs={12} sm={12} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>

            {jobLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress style={{ color: 'white' }} size={32} />
            </div>}
            {
              !jobLoader && job && <JobDetailCard selectedItem={job} />
            }
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  )
}

export default JobBoard