import React, { useEffect, useState } from 'react';
import './login.sass'
import { Box, FormControl, FormHelperText, OutlinedInput, Tab, Tabs, Radio } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { postForotPasswordData, postLoginData, postResetOrForgotPasswordData } from './API/APICall';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export const Login = () => {
    const [value, setValue] = useState(0);
    const [isPassword, setIsPassword] = useState<boolean>(true);
    const nav = useNavigate()
    const [loginFormOpen, setLoginFormOpen] = useState<boolean>(false)
    const [isPasswordMatch, setIsPasswordMatch] = useState<any>("")
    const [resetPasswordCompleted, setResetPasswordCompleted] = useState<boolean>(false)
    const [forgotPasswordRequested, setForgotPasswordRequested] = useState<boolean>(false)
    const [invalidUrl, setInvalidUrl] = useState<boolean>(false)
    const [selectedForm, setSelectedForm] = useState("")
    const loginModalToggle = () => {
        // setLoginFormOpen(!loginFormOpen)
    }
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    // const [pageType, setPageType] = useState("Login")
    const isLogin = pathname.includes("/login")
    const isResetPassword = pathname.includes("/reset-password")
    const isForgetPassword = pathname.includes("/forgot-password")
    const isSignUp = pathname.includes("/sign-up")
    const queryParams = new URLSearchParams(window.location.search);
    const user_token: any = queryParams.get("userToken")
    const user_type: any = queryParams.get("userType")
    // const authorization = localStorage.getItem("authorization") || ""
    console.log("user_token", user_token, user_type, resetPasswordCompleted)

    useEffect(() => {
        if (isResetPassword && (user_type === "" || user_type === null) && (user_token === "" || user_token === null)) {
            setInvalidUrl(true)
        }
    }, [isResetPassword])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email: any = data.get('email')
        const password: any = data.get('password')
        const confirmPassword: any = data.get('confirmPassword')
        let checkErrorFlag = false
        if (isLogin) {
            if (email?.length <= 0) {
                checkErrorFlag = true
                toast.error("Email is required.");
            }
            else if (email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email && email)) {
                checkErrorFlag = true
                toast.error("Please enter valid email.");
            }
            else if (password && password?.length <= 0) {
                checkErrorFlag = true
                toast.error("Password is required.");
            }
        }
        else if (isForgetPassword && (user_type === "" || user_type === null) && (user_token === "" || user_token === null)) {
            if (email?.length <= 0) {
                checkErrorFlag = true
                toast.error("Email is required.");
            }
            else if (email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email && email)) {
                checkErrorFlag = true
                toast.error("Please enter valid email.");
            }
        }
        else if (isResetPassword || isForgetPassword) {
            if (password?.length <= 0) {
                checkErrorFlag = true
                toast.error("Password is required.");
            }
            else if (confirmPassword?.length <= 0) {
                checkErrorFlag = true
                toast.error("Confirm Password is required.");
            } else if (password !== confirmPassword) {
                checkErrorFlag = true
                setIsPasswordMatch(false)
                toast.error("Your password and confirmation password do not match.");
            } else if (user_token?.length === 0) {
                checkErrorFlag = true
                toast.error("User type and user token must be required.");
            }
        }

        if (isLogin && !checkErrorFlag) {
            try {
                await postLoginData(email, password, value === 0 ? "project" : value === 1 ? "mentor" : value === 2 ? "vc" : "partner")
                    .then((res: any) => {
                        if (res?.status === 200) {
                            localStorage.setItem('authorization', JSON.stringify(res && res?.data?.accessToken));
                            localStorage.setItem('userdata', JSON.stringify(res && res?.data));
                            localStorage.setItem('userTypeVal', value === 0 ? JSON.stringify("project") : value === 1 ? JSON.stringify("mentor") : value === 2 ? JSON.stringify("vc") : JSON.stringify("partner"));
                            if (value === 0) {
                                nav("/project/dashboard")
                            } else if (value === 1) {
                                nav("/mentor-dashboard")
                            } else if (value === 2) {
                                nav("/vc-dashboard")
                            } else {
                                nav("/partner-dashboard")
                            }
                            return res
                        }
                        return {};
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }
        else if (isForgetPassword && !checkErrorFlag && (user_type === "" || user_type === null) && (user_token === "" || user_token === null)) {
            try {
                await postForotPasswordData(email, value === 0 ? "project" : value === 1 ? "mentor" : value === 2 ? "vc" : "partner")
                    .then((res: any) => {
                        if (res?.status === 200) {
                            setForgotPasswordRequested(true)
                        }
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }
        else if ((isResetPassword || isForgetPassword) && !checkErrorFlag) {
            setIsPasswordMatch(true)
            try {
                await postResetOrForgotPasswordData(user_token, password, value === 0 ? "project" : value === 1 ? "mentor" : value === 2 ? "vc" : "partner")
                    .then((res: any) => {
                        if (res?.status === 200) {
                            // if (value === 0) {
                            //     nav(isForgetPassword ? "/forms/first-time-login" : "/project-dashboard")
                            // } else if (value === 1) {
                            //     nav("/mentor-dashboard")
                            // } else if (value === 2) {
                            //     nav("/vc-dashboard")
                            // }
                            setResetPasswordCompleted(true)
                        }
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }
    };
    useEffect(() => {
        setValue(
            user_type?.toLowerCase() === "project" ? 0 :
                user_type?.toString().toLowerCase() === "mentor" ? 1 :
                    user_type?.toLowerCase() === "vc" ? 2 :
                        user_type?.toLowerCase() === "partner" ? 3 : 0)
    }, [user_type])


    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue)
    };

    const onChangeFormSelection = (e: any) => {
        const { name = "" } = e.target
        setSelectedForm(name)
    }

    const onSelectedFormContinue = () => {
        switch (selectedForm) {
            case 'project': return nav('/contact-form/project-ahead')
            case 'mentor': return nav('/contact-form/mentor-projects')
            case 'vc': return nav('/contact-form/invest')
            case 'partner': return nav('/contact-form/become-partner')
            default: return
        }
    }

    return (
        <>
            <div className='login-page'>
                <button className='new-color-button rounded-5 px-5' onClick={loginModalToggle} type="submit">Login</button>

                <Modal className='connection-request-model login-model' centered fade={false} isOpen={isSignUp || isResetPassword || isForgetPassword || isLogin} toggle={loginModalToggle}>
                    <div className="animation-part">
                        <div className="login-form">
                            <ModalBody>
                                {
                                    invalidUrl && isResetPassword ? <p className='paragraph-new-medium success-form-data py-5 fw-bold mb-0'>Invalid Url OR Url is expired.</p> :
                                        <>
                                            <h4 className='heading-new-3 mb-4 fw-bolder'>{isResetPassword ? "Reset Password" : isForgetPassword ? "Forgot Password" : isLogin ? "Login" : "Sign Up"}</h4>
                                            {!isSignUp && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="login-tab-container">
                                                    <Tab label="Project" />
                                                    <Tab label="Mentor" />
                                                    <Tab label="VC" />
                                                    <Tab label="Partner" />
                                                </Tabs>
                                            </Box>}
                                            {
                                                isSignUp &&
                                                <Box
                                                    sx={{
                                                        marginTop: 3,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div className="sign-up-container">
                                                        <h2 className="heading-new-4">Ready to get Started?</h2>
                                                        <span className='join-symbiote'>Join Symbiote now!</span>
                                                        <p className="fill-form">Fill out this form to begin your journey.</p>

                                                        <div className='form-selection-container'>
                                                            <span className="what-are-you">What are you?</span>
                                                            <div className='radio-selection-item'>
                                                                <div className='radio-selection'>
                                                                    <label>Project</label>
                                                                    <Radio
                                                                        checked={selectedForm === "project"}
                                                                        name="project"
                                                                        onChange={onChangeFormSelection}
                                                                        sx={{
                                                                            color: 'white',
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='radio-selection'>
                                                                    <label>Mentor</label>
                                                                    <Radio
                                                                        checked={selectedForm === "mentor"}
                                                                        name="mentor"
                                                                        onChange={onChangeFormSelection}
                                                                        sx={{
                                                                            color: 'white',
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='radio-selection'>
                                                                    <label>Partner</label>
                                                                    <Radio
                                                                        checked={selectedForm === "partner"}
                                                                        name="partner"
                                                                        onChange={onChangeFormSelection}
                                                                        sx={{
                                                                            color: 'white',
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='radio-selection'>
                                                                    <label>VC</label>
                                                                    <Radio
                                                                        checked={selectedForm === "vc"}
                                                                        name="vc"
                                                                        onChange={onChangeFormSelection}
                                                                        sx={{
                                                                            color: 'white',
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: 28,
                                                                            },
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button className='new-color-button rounded-5 mt-4' onClick={onSelectedFormContinue}>Continue</button>
                                                    </div>
                                                </Box>
                                            }
                                            {isLogin &&
                                                <Box
                                                    sx={{
                                                        marginTop: 3,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                        <FormControl variant="outlined">
                                                            <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Email ID</FormHelperText>
                                                            <OutlinedInput
                                                                // required
                                                                fullWidth
                                                                id="email"
                                                                name="email"
                                                                autoComplete="email"
                                                                autoFocus
                                                                type="text"
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'Email ID',
                                                                }}
                                                            />
                                                        </FormControl>
                                                        <FormControl variant="outlined" className='position-relative'>
                                                            <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Password</FormHelperText>
                                                            <OutlinedInput
                                                                // required
                                                                fullWidth
                                                                name="password"
                                                                type={isPassword ? "password" : "text"}
                                                                id="password"
                                                                autoComplete="password"
                                                                autoFocus
                                                                aria-describedby="outlined-weight-helper-text"
                                                                inputProps={{
                                                                    'aria-label': 'Password',
                                                                }}
                                                            />
                                                            <div className="password-visible">
                                                                <i onClick={() => setIsPassword(!isPassword)} className={isPassword ? "ri-eye-fill" : "ri-eye-off-fill"}></i>
                                                            </div>
                                                        </FormControl>
                                                        <div className="w-100 mt-4">
                                                            <button className='new-color-button rounded-5 px-5' type="submit">Login</button>
                                                        </div>
                                                        <div className="sign-up-redirect" onClick={() => nav('/sign-up')}>
                                                            <p>Don't have an account? <span>SIGN UP NOW</span></p>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            }
                                            {isResetPassword &&
                                                <Box
                                                    sx={{
                                                        marginTop: 3,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >

                                                    {invalidUrl === true ?
                                                        <p className='paragraph-new-medium success-form-data py-5 fw-bold'>Invalid Url OR Url is expired.</p> :
                                                        !resetPasswordCompleted ?
                                                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                                <p className='paragraph-new text-center'>Your account is verified</p>
                                                                <FormControl variant="outlined" className='position-relative'>
                                                                    <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Enter a new password for your account</FormHelperText>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        name="password"
                                                                        type={isPassword ? "password" : "text"}
                                                                        id="password"
                                                                        autoComplete="password"
                                                                        autoFocus
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                        inputProps={{
                                                                            'aria-label': 'Password',
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                <FormControl variant="outlined" className='position-relative'>
                                                                    <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Re-Enter Password</FormHelperText>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        name="confirmPassword"
                                                                        type={isPassword ? "password" : "text"}
                                                                        id="confirmPassword"
                                                                        autoComplete="confirmPassword"
                                                                        autoFocus
                                                                        aria-describedby="outlined-weight-helper-text"
                                                                        inputProps={{
                                                                            'aria-label': 'ConfirmPassword',
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                                {isPasswordMatch === "" ? null :
                                                                    isPasswordMatch === true ?
                                                                        <p className='paragraph-new-small' style={{ color: "#2BEB33" }} >Passwords Match</p> :
                                                                        isPasswordMatch === false ?
                                                                            <p className='paragraph-new-small' style={{ color: "#FF1717" }} >Passwords don’t match</p> : null
                                                                }
                                                                <div className="w-100 mt-4">
                                                                    <button className='new-color-button rounded-5 px-5' type="submit">Submit</button>
                                                                </div>
                                                            </Box> :
                                                            <>
                                                                <p className='paragraph-new-medium success-form-data py-5 fw-bold'>Password changed successfully.</p>
                                                                <button className='new-color-button rounded-5 px-5' onClick={() => { nav("/login") }} type="submit">Proceed to login</button>
                                                            </>
                                                    }
                                                </Box>
                                            }
                                            {isForgetPassword && (user_type === "" || user_type === null) && (user_token === "" || user_token === null) &&
                                                <Box
                                                    sx={{
                                                        marginTop: 3,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {user_type} {user_token}
                                                    {!forgotPasswordRequested ?
                                                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                            <p className='paragraph-new text-center'>Enter the Email ID your account is connected to</p>
                                                            <FormControl variant="outlined">
                                                                <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Email ID</FormHelperText>
                                                                <OutlinedInput
                                                                    // required
                                                                    fullWidth
                                                                    id="email"
                                                                    name="email"
                                                                    autoComplete="email"
                                                                    autoFocus
                                                                    type="text"
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'Email ID',
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            <div className="w-100 mt-4">
                                                                <button className='new-color-button rounded-5 px-5' type="submit">Submit</button>
                                                            </div>
                                                        </Box>
                                                        :
                                                        <div>
                                                            <p className='paragraph-new-medium'>Password reset instructions have been sent to your email. Please check your email inbox or spam folder.</p>
                                                            <button className='new-color-button rounded-5 px-5 mt-4' onClick={() => { window.open("https://mail.google.com", "_blank") }} type="submit">Go to mail box</button>
                                                        </div>
                                                    }
                                                </Box>
                                            }
                                            {isForgetPassword && user_type?.length > 0 && user_token?.length > 0 &&
                                                <Box
                                                    sx={{
                                                        marginTop: 3,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        // minHeight: 200
                                                    }}
                                                >
                                                    {!resetPasswordCompleted ?
                                                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                            <p className='paragraph-new text-center'>Your account is verified</p>
                                                            <FormControl variant="outlined" className='position-relative'>
                                                                <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Enter a new password for your account</FormHelperText>
                                                                <OutlinedInput
                                                                    // required
                                                                    fullWidth
                                                                    name="password"
                                                                    type={isPassword ? "password" : "text"}
                                                                    id="password"
                                                                    autoComplete="password"
                                                                    autoFocus
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'Password',
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            <FormControl variant="outlined" className='position-relative'>
                                                                <FormHelperText className='paragraph-new text-center' id="outlined-weight-helper-text">Re-Enter Password</FormHelperText>
                                                                <OutlinedInput
                                                                    // required
                                                                    fullWidth
                                                                    name="confirmPassword"
                                                                    type={isPassword ? "password" : "text"}
                                                                    id="confirmPassword"
                                                                    autoComplete="confirmPassword"
                                                                    autoFocus
                                                                    aria-describedby="outlined-weight-helper-text"
                                                                    inputProps={{
                                                                        'aria-label': 'ConfirmPassword',
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            {isPasswordMatch === "" ? null :
                                                                isPasswordMatch === true ?
                                                                    <p className='paragraph-new-small' style={{ color: "#2BEB33" }} >Passwords Match</p> :
                                                                    isPasswordMatch === false ?
                                                                        <p className='paragraph-new-small' style={{ color: "#FF1717" }} >Passwords don’t match</p> : null
                                                            }
                                                            <div className="w-100 mt-4">
                                                                <button className='new-color-button rounded-5 px-5' type="submit">Submit</button>
                                                            </div>
                                                        </Box> :
                                                        <>
                                                            <p className='paragraph-new-medium success-form-data py-5 fw-bold'>Password changed successfully.</p>
                                                            <button className='new-color-button rounded-5 px-5' onClick={() => { nav("/login") }} type="submit">Proceed to login</button>
                                                        </>
                                                    }
                                                </Box>
                                            }
                                        </>
                                }
                            </ModalBody>
                        </div>
                    </div>
                    {isLogin ?
                        <a type="button" onClick={() => { nav("/forgot-password") }} className='paragraph-new mt-4 text-center'>Forgot Password (?)</a>
                        : isForgetPassword ? <a type="button" onClick={() => { nav("/login") }} className='paragraph-new mt-4 text-center'>Back to Login</a> : null}
                </Modal>
            </div>
        </>
    )
}
