import React, { useEffect, useState } from 'react';
import './vc-dashboard-comp.sass';
import { VCCard } from '../vc-card/vc-card';
import { InfoCard } from '../info-card/info-card';
import Updatessymbioversetable from '../../../project-dashboard/components/dashboard/components/updates-symbioverse-table/updates-symbioverse-table';
import ReactApexChart from 'react-apexcharts';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import Profile from '../../../../../assests/images/accelerate/pages/profile-img.svg';
import { getProjectMentorOrVCData, setProjectInterestAPI } from '../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from "@mui/material/CircularProgress"

let Mentorsettings = {
    dots: false,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    arrows: true,
    infinite: false,
    rows: 1,
    responsive: [
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1.5,
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1.1,
            }
        },
        {
            breakpoint: 300,
            settings: {
                slidesToShow: 1.1,
            }
        }

    ]
};

const initialChartData = {
    series: [{
        data: [24000, 15000, 400]
    }],
    options: {
        chart: {
            type: 'bar',
            height: 300
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -0,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            },
            formatter: (val: any) => {
                return val / 1000
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        xaxis: {
            categories: ['$24m', '$15m', '$400k'],
        },
    },
}

export interface VCUserData {
    userData: any
}

export const VCDashboardComp = (props: VCUserData) => {
    const { userData } = props
    const location = useLocation();
    const pathname = location.pathname;
    const isVCDashboard = pathname.includes("/vc-dashboard")
    const isMentorDashboard = pathname.includes("/mentor-dashboard")

    const [mentorProjectData, setMentorProjectData] = useState<any>()
    const [CharteData, setCharteData] = useState<any>({ ...initialChartData })
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [projectLoader, setProjectLoader] = useState(false)

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    useEffect(() => {
        getProjectMentorOrVCAllData()
    }, [])

    const getProjectMentorOrVCAllData = async (disableLoader?: boolean) => {
        if (!disableLoader) setProjectLoader(true)
        await getProjectMentorOrVCData()
            .then((res) => {
                setProjectLoader(false)
                if (res?.status === 200) {
                    let data = res?.data && res?.data?.values || [];
                    const convertedData = data.slice(0, 4)
                    setMentorProjectData(convertedData)
                }
            })
            .catch((err) => {
                setProjectLoader(false)
                toast.error(err?.response?.data?.message || 'Error while getting project mentor and vc data')
            })
    }

    const onClickExpressInterest = async (projectId: string) => {
        await setProjectInterestAPI(projectId, isVCDashboard ? 'vc' : isMentorDashboard ? 'mentor' : 'partner')
        getProjectMentorOrVCAllData(true)
    }

    const InfocardData = [
        {
            title: isVCDashboard ? "Projects Invested" : isMentorDashboard ? "Projects Mentoring" : "Partner Projects",
            number: isVCDashboard ? userData?.totalVcProjects : isMentorDashboard ? userData?.totalProjectsMentoring : userData?.totalPartnerProjects
        },
        {
            title: "Projects Interested",
            number: userData?.totalProjectsInterested
        },
    ]

    return (
        <div className='vc-dashboard-section'>
            <div className="row">
                {InfocardData && InfocardData.map((item: any, i: number) => (
                    <InfoCard key={i} title={item.title} number={item.number} />
                ))}
            </div>
            {/* {isVCDashboard &&
                <>
                    <h5 className='heading-new-5 mb-4 mt-lg-5 mt-md-4 mt-3'>Investment Overview </h5>
                    <div className="investment-charts">
                        <div className="investment-charts-inner">
                            <div className='row'>
                                <div className='col-3'>
                                    <div className='duelist-profile'>
                                        <div className=' d-flex gap-2 align-items-center'>
                                            <img src={Profile} alt="" className='img-fluid' />
                                            <h6 className='heading-new-6 fw-light'>Duelist King</h6>
                                        </div>
                                        <div className=' d-flex gap-2 align-items-center '>
                                            <img src={Profile} alt="" className='img-fluid' />
                                            <h6 className='heading-new-6 fw-light'>Duelist King</h6>
                                        </div>
                                        <div className=' d-flex gap-2 align-items-center '>
                                            <img src={Profile} alt="" className='img-fluid' />
                                            <h6 className='heading-new-6 fw-light'>Duelist King</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-9'>
                                    <div id="chart" className='w-100'>
                                        <ReactApexChart options={CharteData && CharteData.options} series={CharteData.series} type="bar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            } */}
            <div className='mt-lg-5 mt-md-4 mt-3'>
                <Updatessymbioversetable />
            </div>

            <div className="mt-lg-5 mt-4 mb-lg-0 mb-md-0 mb-3">
                <h5 className='heading-new-5'>Latest Projects in the Platform</h5>
            </div>

            {projectLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress style={{ color: 'white' }} size={32} />
            </div>}

            {!projectLoader && <div className="row justify-content-lg-between justify-content-md-start mt-lg-5 mt-md-4 mt-3">
                {mentorProjectData && mentorProjectData.map((item: any, index: number) => (
                    <VCCard
                        key={index}
                        name={item?.name}
                        icon={item?.icon}
                        banner={item?.banner}
                        _id={item?._id}
                        isInterested={item?.isInterested}
                        companyName={item?.companyName}
                        projectAvgScore={item?.projectAvgScore}
                        companyDescription={item?.companyDescription}
                        linkedInProfileLink={item?.linkedInProfileLink}
                        twitterLink={item?.twitterLink}
                        telegramLink={item?.telegramLink}
                        isAccelerateProject={item?.isAccelerateProject}
                        websiteLink={item?.websiteLink}
                        indexValue={undefined}
                        onClickExpressInterest={onClickExpressInterest}
                    />
                ))}
            </div>}
            {/* //     <Slider {...Mentorsettings}>
                //         {mentorProjectData && mentorProjectData.map((item: any, index: number) => (
                //             <VCCard
                //                 key={index}
                //                 name={item?.name}
                //                 icon={item?.icon}
                //                 banner={item?.banner}
                //                 _id={item?._id}
                //                 isInterested={item?.isInterested}
                //                 companyName={item?.companyName}
                //                 projectAvgScore={item?.projectAvgScore}
                //                 companyDescription={item?.companyDescription}
                //                 linkedInProfileLink={item?.linkedInProfileLink}
                //                 twitterLink={item?.twitterLink}
                //                 telegramLink={item?.telegramLink}
                //                 websiteLink={item?.websiteLink}
                //                 indexValue={undefined}
                //                 onClickExpressInterest={onClickExpressInterest}
                //                 isAccelerateProject={item?.isAccelerateProject}
                //             />
                //         ))}
                //     </Slider>
                // </div> */}

        </div>
    )
}
