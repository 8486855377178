import axios from "axios";
import { GET_EVENTS_DATA, GET_LAUNCHNETWORK_ASSIGEND_DATA, GET_LAUNCHNETWORK_CATEGORY_DATA, GET_PROJECT_MENTOR_NETWORK_DATA, POST_ADD_EVENT_REMINDER, POST_MENTOR_SUBMIT_DATA, POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
  };
}

const getEventsDataURL = GET_EVENTS_DATA

const getEventsData = async (sortBy?: 'latest' | 'upcoming' | 'past' | '', isFeatured = false) => {
  let queryParams = {}
  if (sortBy) {
    queryParams = { ...queryParams, sortBy }
  }
  if (isFeatured) {
    queryParams = { ...queryParams, is_featured: true }
  }

  const res: any = await axios.get(getEventsDataURL, { headers: postBodyHeaders(), params: queryParams })
  return res;
};

const getEventByIdAPI = async (eventId: string) => {
  const res: any = await axios.get(getEventsDataURL.concat('/' + eventId), { headers: postBodyHeaders() })
  return res;
}

const addRemoveEventReminderAPI = async (eventId: string, requestBody: { emails?: string[], name?: string, email?: string, twitterId?: string, telegramId?: string }) => {
  const url = POST_ADD_EVENT_REMINDER.concat("/" + eventId)
  const res: any = await axios.post(url, requestBody, { headers: postBodyHeaders() })
  return res;
}

export {
  getEventByIdAPI,
  getEventsData,
  addRemoveEventReminderAPI
};