import React, { useEffect, useMemo, useState } from 'react'
import "./public-profile-view.sass"
import { DashbordTopSection } from '../dashbord-top-section/dashbord-top-section'
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom'
import NoImage from '../../../../../assests/images/homepage/no-image-available.jpg';
import CourseImg from '../../../../../assests/images/accelerate/pages/course-img.svg';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressTable from '../progress/components/progress-table/progress-table';
import { VCHeroSection } from '../../../vc-dashboard/components/vc-hero-section/vc-hero-section';
import { ProgressRangeSlider } from '../dashboard/components/progress-slider/progress-slider';
import { getProjectFeedbackData, getProjectProgressData, getProjectsByIDData, getProjectsUserData } from '../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import { getMentorOrVCUserData } from '../../../vc-dashboard/API/APICall';
import { isLoggedIn, loggedInAs } from '../../../../../constants/utils';
import CustomPagination from '../../../../../shared/components/pagination/Pagination';

interface PastProgressColumn {
    id: 'date' | 'area' | 'update' | 'docs';
    label: string;
    minWidth?: number;
}

interface Column {
    id: 'date' | 'mentorName' | 'feedback';
    label: string;
    minWidth?: number;
}

const pastProgressTableColumns: readonly PastProgressColumn[] = [
    { id: 'date', label: 'Date', minWidth: 110 },
    { id: 'area', label: 'Area', minWidth: 100 },
    { id: 'update', label: 'Update', minWidth: 170 },
    { id: 'docs', label: 'Docs', minWidth: 170 },
];

const mentorFeedbackTableColumns: readonly Column[] = [
    { id: 'date', label: 'Date', minWidth: 80 },
    { id: 'mentorName', label: 'Mentor Name', minWidth: 80 },
    { id: 'feedback', label: 'Feedback', minWidth: 200 },
];

export interface MentorCardData {
    value: any,
}

const initialFilter = {
    page: 1,
    skip: 0,
    limit: 8,
}

const PublicProfileView = (props: MentorCardData) => {
    const { value } = props
    const nav = useNavigate()
    const [progressData, setProgressData] = useState<any>([]);
    const [totalProgress, setTotalProgress] = useState<number>(1);
    const [scoreFeedback, setScoreFeedback] = useState({
        score: 0,
        feedback: ''
    });
    const [progressFilter, setProgressFilter] = useState({ ...initialFilter })
    const [feedbackFilter, setFeedbackFilter] = useState({ ...initialFilter })
    const [feedbackData, setFeedbackData] = useState([])
    const [totalfeedbacks, setTotalfeedbacks] = useState<number>(1);
    const location = useLocation();
    const [userData, setUserData] = useState<any>({});
    const [vcMentor, setVcMentor] = useState<any>({});
    const [projectDetailsData, setProjectDetailsData] = useState<any>();
    const { pathname } = location;
    const isVCProfile = pathname.includes("/profile/public-profile-vc")
    const isMentorProfile = pathname.includes("/profile/public-profile-mentor")
    const isPartnerProfile = pathname.includes("/profile/public-profile-partner")
    const isProjectPublicProfile = pathname.includes("/profile/public-profile-project")
    const params = useParams<{ id: any }>();
    // console.log("paramsparamsparams", params, projectDetailsData, userData)
    // console.log("paramsparamsparams", isLoggedIn(), loggedInAs())

    const isUserLoggedin = isLoggedIn()
    useEffect(() => {
        if (isUserLoggedin) {
            const { mentor, vc, project, partner } = loggedInAs()
            if (project) {
                getProjectsUserAllData()
                getProjectProgressAllData('', { ...initialFilter })
                getProjectFeedbackAllData('', { ...initialFilter })
            }
            if (mentor || vc || partner) {
                getMentorOrVCUserAllData(mentor ? "mentor-dashboard" : vc ? "vc-dashboard" : 'partner-dashboard')
                getProjectsByIDAllData()
                getProjectProgressAllData(params.id, { ...initialFilter })
                getProjectFeedbackAllData(params.id, { ...initialFilter })
            }
        } else {
            if (isVCProfile || isMentorProfile || isPartnerProfile) {
                getMentorOrVCUserAllData(isMentorProfile ? "mentor-dashboard" : isVCProfile ? "vc-dashboard" : "partner-dashboard")
            }
            getProjectsByIDAllData()
            getProjectProgressAllData(params.id, { ...initialFilter })
            getProjectFeedbackAllData(params.id, { ...initialFilter })
        }
    }, [params.id])

    // get project data for logged in users
    const getProjectsUserAllData = async () => {
        await getProjectsUserData()
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data || {})
                    setProjectDetailsData(res.data || {})
                }
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.message)
            })
    }

    // get project data for public users
    const getProjectsByIDAllData = async () => {
        await getProjectsByIDData(params && params?.id)
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data || {})
                    setScoreFeedback({
                        score: res.data && res.data.mentorfeedback && res.data.mentorfeedback[0] && res.data.mentorfeedback[0].score || 0,
                        feedback: res.data && res.data.mentorfeedback && res.data.mentorfeedback[0] && res.data.mentorfeedback[0].feedback || ''
                    })
                    setProjectDetailsData(res.data || {})
                }
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.message)
            })
    }

    // pass progress api
    const getProjectProgressAllData = async (projectId?: string, filter?: { skip: number, limit: number }) => {
        await getProjectProgressData(projectId, filter)
            .then((res) => {
                if (res?.status === 200) {
                    setProgressData(res?.data?.values || [])
                    setTotalProgress(res?.data?.total || 0)
                }
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.message)
            })
    }

    // feedback data api
    const getProjectFeedbackAllData = async (projectId?: string, filter?: { skip: number, limit: number }) => {
        await getProjectFeedbackData(projectId, filter)
            .then((res) => {
                if (res?.status === 200) {
                    setFeedbackData(res?.data?.values || [])
                    setTotalfeedbacks(res?.data?.total || 0)
                }
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.message)
            })
    }

    // Get vc or mentor user data
    const getMentorOrVCUserAllData = async (userType: string) => {
        await getMentorOrVCUserData(userType)
            .then((res) => {
                if (res?.status === 200) {
                    setVcMentor(res.data || {})
                }
            })
            .catch((err) => {
                // toast.error(err?.response?.data?.message || 'Error while getting vc user data')
            })
    }

    const onClickBackToDashboard = () => {
        const { mentor, vc, partner } = loggedInAs()
        if (mentor) return nav('/mentor-dashboard')
        else if (vc) return nav('/vc-dashboard')
        else if (partner) return nav('/partner-dashboard')
        else return nav('/project/dashboard')
    }

    const onChangeProgressPage = (page: number) => {
        if (progressFilter.page === page) return
        let skip = (page - 1) * progressFilter.limit
        const filter = { ...progressFilter, page, skip }
        setProgressFilter(filter)
        getProjectProgressAllData(params.id, filter)
    }
    const onChangeFeedbackPage = (page: number) => {
        if (feedbackFilter.page === page) return
        let skip = (page - 1) * feedbackFilter.limit
        const filter = { ...feedbackFilter, page, skip }
        setFeedbackFilter(filter)
        getProjectFeedbackAllData(params.id, filter)
    }

    const totalProgressPages = useMemo(() => Math.ceil(totalProgress / progressFilter.limit), [totalProgress, progressFilter])
    const totalfeedbackPages = useMemo(() => Math.ceil(totalfeedbacks / feedbackFilter.limit), [totalfeedbacks, feedbackFilter])

    if (projectDetailsData?.notionLink) {
        return <div style={{ width: '100%', background: 'white', height: '100%' }}>
            <iframe src={projectDetailsData?.notionLink} width={window.innerWidth} style={{ margin: 'auto', minHeight: '800px', border: 'none' }}></iframe>
        </div>
    }

    return (
        <>
            <div className="project-profile-view-container">
                {(isVCProfile || isMentorProfile) &&
                    <VCHeroSection userData={vcMentor} />
                }
                <DashbordTopSection scoreFeedback={scoreFeedback} setScoreFeedback={setScoreFeedback} userData={userData} />

                <div className='container container-maxwidth mt-lg-5 mt-md-3 mt-2 public-view-section'>
                    {isProjectPublicProfile &&
                        <>
                            <div className='d-flex align-items-center gap-3 mt-3 d-lg-none d-md-none justify-content-center'>
                                <div className='d-lg-none d-md-none d-block'>
                                    <button className='new-color-button'>Your Profile</button>
                                </div>
                                <div className='form-slider '>
                                    <div className='percentage-slider text-center gap-2 align-items-center m-auto d-flex'>
                                        <CircularProgressbar value={projectDetailsData?.profileCompleted || 0} text={`${Number(projectDetailsData?.profileCompleted || 0).toFixed(0)}%`} background
                                            backgroundPadding={0}
                                            styles={buildStyles({
                                                backgroundColor: "transparent",
                                                textColor: "#fff",
                                                pathColor: "#FFB800",
                                                trailColor: "white"
                                            })} />
                                        <p className='paragraph-new-small mb-0 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                    </div>
                                </div>
                            </div>
                            {isUserLoggedin && <div className='profile-section d-flex align-items-center justify-content-between'>
                                <div className='back-btn d-lg-block d-md-block d-none'>
                                    <p className='paragraph-new-medium mb-0' role='button' onClick={onClickBackToDashboard}>Back to Dashboard</p>
                                </div>
                                <div className='your-profile d-lg-block d-md-block d-none'>
                                    <h2 className='heading-new-2'>Your Profile</h2>
                                </div>
                                <div className='public-btn m-lg-0 m-md-0 m-auto mt-lg-0 mt-md-0 mt-3'>
                                    {isProjectPublicProfile &&
                                        <button className='new-primary-button'><i className="ri-eye-fill me-2"></i>Viewing Public Profile</button>
                                    }
                                    {!isProjectPublicProfile &&
                                        <button className='new-primary-button' onClick={() => nav('/profile/public-profile')}><i className="ri-eye-fill me-2"></i>View as Public Profile</button>
                                    }
                                </div>
                            </div>}
                            {isUserLoggedin && <div className='text-center edit-form mt-lg-5 mt-md-3 mt-2 d-lg-block d-md-block d-none'>
                                <p className='paragraph-new-medium mb-0' role='button' onClick={() => nav('/forms/project-view/profile')}>Edit the form</p>
                            </div>}
                        </>
                    }
                    <div className='profile-details mt-lg-5 mt-md-4 mt-3'>
                        <div className='d-lg-flex d-md-flex d-block justify-content-center align-items-center' style={{ gap: projectDetailsData?.isAccelerateProject === true ? "0" : "50px" }}>
                            {/* If Project a part of Accelerate */}
                            {isProjectPublicProfile ?
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='duelist-img'><img className='img-fluid' src={projectDetailsData?.icon || NoImage} alt="" /></div>
                                    <div className='duelist-name'>
                                        <h3 className=''>{projectDetailsData?.companyName || "No name available"}</h3>
                                        {projectDetailsData?.isAccelerateProject &&
                                            <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                <span className='anim-span'></span>
                                                <span className='anim-span anim-2'></span>
                                                <span className='anim-span anim-3'></span>
                                                <span className='anim-span anim-4'></span>
                                                <span className='anim-span anim-5'></span>
                                            </button>
                                        }
                                    </div>
                                </div> :
                                <>
                                    {projectDetailsData?.isAccelerateProject &&
                                        <div className='text-center mb-lg-0 mb-md-0 mb-2' style={{ marginRight: '14px' }}>
                                            <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                <span className='anim-span'></span>
                                                <span className='anim-span anim-2'></span>
                                                <span className='anim-span anim-3'></span>
                                                <span className='anim-span anim-4'></span>
                                                <span className='anim-span anim-5'></span>
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                            <div className='d-flex gap-lg-5 gap-md-3 gap-2 justify-content-center score-profile-container'>
                                <div className='form-slider'>
                                    <div className='percentage-slider align-items-center text-center gap-2 m-auto d-flex'>
                                        <CircularProgressbar value={projectDetailsData?.projectAvgScore || 0} text={`${Number(projectDetailsData?.projectAvgScore || 0).toFixed(0)}`} background
                                            backgroundPadding={0}
                                            styles={buildStyles({
                                                backgroundColor: "transparent",
                                                textColor: "#fff",
                                                pathColor: "#65DB6A",
                                                trailColor: "white"
                                            })} />
                                        <p className='paragraph-new-small mb-0 text-center fw-bold'>SymbioteScore</p>
                                    </div>
                                </div>
                                <div className='form-slider'>
                                    <div className='percentage-slider align-items-center text-center gap-2 m-auto d-flex'>
                                        <CircularProgressbar value={projectDetailsData?.profileCompleted || 0} text={`${Number(projectDetailsData?.profileCompleted || 0).toFixed(0)}%`} background
                                            backgroundPadding={0}
                                            styles={buildStyles({
                                                backgroundColor: "transparent",
                                                textColor: "#fff",
                                                pathColor: "#FFB800",
                                                trailColor: "white"
                                            })} />
                                        <p className='paragraph-new-small text-center mb-0 fw-bold' style={{ color: "#FFB800" }}>Profile Completion</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='view-btn-section d-lg-flex d-md-flex d-block gap-4 justify-content-center mt-lg-5 mt-md-3 mt-2'>
                            <div className='d-flex gap-lg-4 gap-md-4 gap-3 align-items-center justify-content-center'>
                                <div>
                                    <button className='new-primary-button'>View Whitepaper</button>
                                </div>
                                <div>
                                    <button className='new-primary-button'>View Pitchdeck</button>
                                </div>
                            </div>
                            <div className='deshboard-social d-flex justify-content-center mt-lg-0 mt-md-0 mt-3'>
                                <ul>
                                    {projectDetailsData?.linkedInProfileLink && projectDetailsData?.linkedInProfileLink?.length > 0 &&
                                        <li>
                                            <Link to={projectDetailsData?.linkedInProfileLink} target='_blank'>
                                                <i className="ri-linkedin-fill"></i>
                                            </Link>
                                        </li>
                                    }
                                    {projectDetailsData?.twitterLink && projectDetailsData?.twitterLink?.length > 0 &&
                                        <li>
                                            <Link to={projectDetailsData?.twitterLink} target='_blank'>
                                                <i className="ri-twitter-fill"></i>
                                            </Link>
                                        </li>
                                    }
                                    {projectDetailsData?.telegramLink && projectDetailsData?.telegramLink?.length > 0 &&
                                        <li>
                                            <Link to={projectDetailsData?.telegramLink} target='_blank'>
                                                <i className="ri-send-plane-fill"></i>
                                            </Link>
                                        </li>
                                    }
                                    {projectDetailsData?.websiteLink && projectDetailsData?.websiteLink?.length > 0 &&
                                        <li>
                                            <Link to={projectDetailsData?.websiteLink} target='_blank'>
                                                <i className="ri-link"></i>
                                            </Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        {projectDetailsData?.companyDescription && <div className='mt-lg-5 mt-md-3 mt-2'>
                            <p className='paragraph-new-medium mb-0'>{projectDetailsData?.companyDescription}</p>
                        </div>}
                        {projectDetailsData?.keyTeamHighlights &&
                            <div className='mt-lg-5 mt-md-3 mt-2'>
                                <h4 className='heading-new-4'>Key Team Highlights</h4>
                                <ul className='mt-4'>
                                    <li className='paragraph-new-medium mb-3' style={{ whiteSpace: 'pre-wrap' }}>{projectDetailsData?.keyTeamHighlights}</li>
                                </ul>
                            </div>
                        }
                        <div className='mt-lg-5 mt-md-3 mt-2'>
                            <div className="row">
                                {projectDetailsData?.keyPartnershipHighlights &&
                                    <div className={projectDetailsData?.keyInvestmentHighlights ? "col-lg-6" : "col-lg-12"}>
                                        <h4 className='heading-new-4'>Key Partnership Highlights</h4>
                                        <ul className='mt-4'>
                                            <li className='paragraph-new-medium mb-3' style={{ whiteSpace: 'pre-wrap' }}>{projectDetailsData?.keyPartnershipHighlights}</li>
                                        </ul>
                                    </div>
                                }
                                {projectDetailsData?.keyInvestmentHighlights &&
                                    <div className={projectDetailsData?.keyPartnershipHighlights ? "col-lg-6" : "col-lg-12"}>
                                        <h4 className='heading-new-4'>Key Investment Highlights</h4>
                                        <ul className='mt-4'>
                                            <li className='paragraph-new-medium mb-3' style={{ whiteSpace: 'pre-wrap' }}>{projectDetailsData?.keyInvestmentHighlights}</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* <div className='mt-lg-5 mt-md-3 mt-2'>
                            <p className='paragraph-new mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                        </div> */}
                        <div className='mt-lg-5 mt-md-3 mt-3'>
                            <div className='counter-section '>
                                <div className="row">
                                    <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='counters-card mb-lg-0 mb-md-0 mb-2'>
                                            <h4 className='heading-new-4'>Partner Connects</h4>
                                            <h2 className='heading-new-2 mt-3'>{projectDetailsData?.partnerConnects || 0}</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='counters-card mb-lg-0 mb-md-0 mb-2'>
                                            <h4 className='heading-new-4'>Mentor Connects</h4>
                                            <h2 className='heading-new-2 mt-3'>{projectDetailsData?.mentorConnects || 0}</h2>
                                        </div>
                                    </div>
                                    {/* <div className='col-lg-4 col-md-4 col-12'>
                                        <div className='counters-card mb-lg-0 mb-md-0 mb-2'>
                                            <h4 className='heading-new-4'>Courses Completed</h4>
                                            <h2 className='heading-new-2 mt-3'>{projectDetailsData?.coursesCompleted || 0}</h2>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {projectDetailsData &&
                            <div className='mt-lg-5 mt-md-4 mt-3'>
                                <ProgressRangeSlider tabValue={value} setValue={undefined} symbioteScalePostion={projectDetailsData?.symbioteScalePostion || 0} />
                            </div>
                        }
                        <div className='mt-lg-5 mt-4'>
                            <h4 className='heading-new-4'>Past Progress</h4>
                            <ProgressTable
                                tableData={progressData}
                                tableColumn={pastProgressTableColumns}
                            />
                            {totalProgressPages > 1 ? <CustomPagination
                                total={totalProgressPages}
                                page={progressFilter.page}
                                onChange={onChangeProgressPage}
                            /> : null}
                        </div>
                        <div className='mt-lg-5 mt-4'>
                            <h4 className='heading-new-4'>Mentor Feedback</h4>
                            <ProgressTable
                                tableData={feedbackData}
                                tableColumn={mentorFeedbackTableColumns}
                            />
                            {totalProgressPages > 1 ? <CustomPagination
                                total={totalfeedbackPages}
                                page={feedbackFilter.page}
                                onChange={onChangeFeedbackPage}
                            /> : null}
                        </div>
                        {/* <div className='event-section mt-lg-5 mt-md-4 mt-2'>
                            <div className="row">
                                <div className="col-lg-6">
                                    <h4 className='heading-new-4 mt-lg-0 mt-md-0 mt-3'>The Project’s Upcoming Events</h4>
                                    <div className='event-bg mt-3'>
                                        <div className='event-card'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h4 className='heading-new-4'>15 May 2023</h4>
                                                <p className='paragraph-new fw-light mb-0'>3 Days to go</p>
                                            </div>
                                            <h4 className='heading-new-4 fw-light mt-3'>Understanding Tokenomics</h4>
                                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                                <h4 className='paragraph-new fw-light'>with Gregory Peck</h4>
                                                <i className="ri-notification-3-line" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                        <div className='event-card mt-3'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h4 className='heading-new-4'>15 May 2023</h4>
                                                <p className='paragraph-new fw-light mb-0'>3 Days to go</p>
                                            </div>
                                            <h4 className='heading-new-4 fw-light mt-3'>Understanding Tokenomics</h4>
                                            <div className='d-flex justify-content-between align-items-center mt-3'>
                                                <h4 className='paragraph-new fw-light'>with Gregory Peck</h4>
                                                <i className="ri-notification-3-line" style={{ color: "#fff" }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <h4 className='heading-new-4 mt-lg-0 mt-md-4 mt-3'>Study Courses Completed</h4>
                                    <div className='event-bg mt-3'>
                                        {CourseData && CourseData?.map((item: any, i: any) => (
                                            <div className='courses-section d-flex gap-4 mb-lg-5 mb-md-4 mb-3' key={i}>
                                                <div><img src={item.image} alt="" /></div>
                                                <div>
                                                    <h5 className='heading-new-5'>{item.name}</h5>
                                                    <p className='paragraph-new mb-0'>{item.details}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicProfileView