import React from 'react';
import './page-not-found.css'
import { Link } from 'react-router-dom';

export const PageNotFound = () => {
    return (
        <div className='page-not-found'>
            <section className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-12 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>
                                <div className="contant_box_404">
                                    <h3 className="h2">
                                    404 Page not found
                                    </h3>
                                    <p>You are not authorised to access this page</p>
                                    <Link to="/" className="link_404">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
