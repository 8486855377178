import axios from "axios";
import { GET_ALL_PROJECT_DISCOUNT_DATA, GET_CATEGORY_DATA, GET_PROJECT_MENTOR_NETWORK_DATA, POST_MENTOR_SUBMIT_DATA, symbioteApiBaseUrl } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`
  };
}

const getProjectDiscoutData = GET_ALL_PROJECT_DISCOUNT_DATA

const getProjectPartnerDiscountData = async (isFeatured?: boolean) => {
  let APIurl = "";
  if (isFeatured) {
    APIurl = getProjectDiscoutData + "?isFeatured=" + isFeatured
  } else {
    APIurl = getProjectDiscoutData
  }

  const res: any = await axios.get(APIurl, { headers: postBodyHeaders() })
  return res;
};

const getPartnerDiscountByIdAPI = (discountId: string): Promise<any> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/partner/discount/" + discountId, { headers: postBodyHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({})
    })
  })
}

export {
  getPartnerDiscountByIdAPI,
  getProjectPartnerDiscountData
};