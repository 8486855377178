import React, { useEffect, useState } from 'react'
import Slider from 'rc-slider'
import './progress-slider.sass'
import roketIcon from '../../../../../../../assests/images/accelerate/roket-icon.svg';
import { useLocation } from 'react-router-dom';
import { putUpdateProjectData } from '../../../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';

export interface MentorCardData {
  tabValue: any,
  setValue: any,
  symbioteScalePostion: any,
  value?: any,
  userId?: any
}

export const ProgressRangeSlider = (props: MentorCardData) => {
  const { tabValue, setValue, symbioteScalePostion, value, userId } = props
  const [showSliderMsg, setShowSliderMsg] = useState("Great start! As you refine your idea, consider conducting market research to validate your concept further. Symbiote can connect you with industry experts who can provide valuable insights and guidance.")
  const location = useLocation()
  const pathname = location.pathname;
  const [symboiteValue, setSymboiteValue] = useState<any>(0)

  const putUpdateProjectAllData = async (userIdVal: any, symboiteVal: any) => {
    await putUpdateProjectData(userIdVal, symboiteVal)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(res.data && res.data?.message)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  return (
    <div>
      <div className='symbioteScale-section range-slider'>
        <div className='symbioteScale-box-section'>
          <h3 className='symbioteScale-heading text-center mb-lg-5 mb-4 color-white-new heading-new-4 fw-light color-primary'>Position on the <span className='fw-bold'>SymbioteScale</span><span>&trade;</span></h3>
          <div className="d-lg-flex pt-4 d-md-flex d-block align-items-center gap-lg-5 gap-md-4 symbiotescale-slider">
            <div className="w-100">
              <div className="symbioteScale-slider-main">
                <div className='symbioteScale-slider'>
                  <Slider
                    defaultValue={
                      symbioteScalePostion === 0 ? 0 :
                        symbioteScalePostion === 1 ? 10 :
                          symbioteScalePostion === 2 ? 21 :
                            symbioteScalePostion === 3 ? 35 :
                              symbioteScalePostion === 4 ? 52 :
                                symbioteScalePostion === 5 ? 68 :
                                  symbioteScalePostion === 6 ? 85 :
                                    symbioteScalePostion === 7 ? 100 : 100
                    }
                    step={20}
                    disabled={(pathname === "/" || value === 1) ? false : true}
                    onChange={(nextValues) => {
                      if (nextValues == 0) {
                        setShowSliderMsg("Great start! As you refine your idea, consider conducting market research to validate your concept further. Symbiote can connect you with industry experts who can provide valuable insights and guidance.")
                      } else if (nextValues == 10) {
                        setShowSliderMsg("Congratulations on reaching the MVP stage! Now it's essential to gather user feedback and iterate on your product. Symbiote can connect you with early adopters and beta testers to help refine your offering.")
                      } else if (nextValues == 21) {
                        setShowSliderMsg("You're on the right track! Focus on customer acquisition and retention strategies to achieve product-market fit. Symbiote can provide growth-hacking expertise and connect you with potential early adopters.")
                      } else if (nextValues == 35) {
                        setShowSliderMsg("As you structure your company, it's crucial to consider legal and compliance aspects. Symbiote can connect you with legal advisors experienced in the Web3 space to ensure your company is on the right path.")
                      } else if (nextValues == 52) {
                        setShowSliderMsg("Building a strong team is key to success. Symbiote can help you connect with talented individuals with relevant expertise and share your vision.")
                      } else if (nextValues == 68) {
                        setShowSliderMsg("Expanding your network is vital! Symbiote can introduce you to potential partners, collaborators, and industry experts who can amplify your growth and support your project's success.")
                      } else if (nextValues == 85) {
                        setShowSliderMsg("You're almost there! In addition to connecting with venture capitalists (VCs), it's important to leverage the power of influencers and thought leaders. Symbiote can facilitate introductions to influential KOLs who can help you expand your community.")
                      } else if (nextValues == 100) {
                        setShowSliderMsg("Marketing plays a crucial role in driving user adoption. Symbiote can help you strategize your marketing efforts and connect you with relevant influencers who can promote your product to their audiences.")
                      }
                      setSymboiteValue(
                        nextValues === 0 ? 0 :
                          nextValues === 10 ? 1 :
                            nextValues === 21 ? 2 :
                              nextValues === 35 ? 3 :
                                nextValues === 52 ? 4 :
                                  nextValues === 68 ? 5 :
                                    nextValues === 85 ? 6 :
                                      nextValues === 100 ? 7 : null
                      )
                    }}
                    marks={{ 0: "Idea Clarity", 10: "MVP Build", 21: "Product Market Fit", 35: "Company Restructuring", 52: "Team & Management", 68: "Networking with Partners", 85: "VC Networks", 100: "Marketing & KOL Connects" }}
                    trackStyle={{ backgroundColor: '#65DB6A' }}
                    railStyle={{ backgroundColor: '#4398FF' }}
                    handleStyle={{
                      borderColor: '#FFFFFF',
                      backgroundColor: '#FFFFFF',
                    }}
                    dotStyle={{
                      background: "#000000",
                      border: "1.1761px solid #FFFFFF",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-fit-content launch-heading">
              <div className="text-center">
                <img src={roketIcon} alt="" className='img-fluid' />
              </div>
              <p className='paragraph-new mb-0 mt-lg-3 mt-md-3 mt-0 fw-bold launch-text'>LAUNCH<br />TO MARKET</p>
            </div>
          </div>
          {pathname === "/" &&
            <div>
              <p className='paragraph-new mb-0 mt-4 text-center fw-lighter mb-3' style={{ color: "#515151" }}>Drag the slider to where you think you are in your journey</p>
              <p className='paragraph-new mb-0 text-center crucial-text fw-lighter'>{showSliderMsg}</p>
            </div>
          }
          {tabValue === 1 && tabValue !== undefined &&
            <div className='mt-lg-4 mt-md-3 mt-2'>
              <div className='text-center'>
                <button className='new-black-button' onClick={() => { putUpdateProjectAllData(userId, symboiteValue) }}>Update</button>
              </div>
              {/* <div className='text-lg-end text-md-end text-center mt-lg-0 mt-md-0 mt-3'>
                <a className='paragraph-new-small' href="#">Raise a concern</a>
              </div> */}
            </div>
          }
        </div>
      </div>
    </div>
  )
}
