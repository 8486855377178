import axios from "axios";
import { GET_ALL_ASSIGNED_VC, GET_PROJECTS_ASSIGNED_VC, GET_PROJECT_MENTOR_NETWORK_DATA } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "multipart/form-data"
  };
}

const getProjectsAssignedVCDataURL = GET_PROJECTS_ASSIGNED_VC
const getAllVCDataURL = GET_ALL_ASSIGNED_VC

const getProjectsAssignedVcData = async () => {
  const res: any = await axios.get(getProjectsAssignedVCDataURL, { headers: postBodyHeaders() })
  return res;
};

const getAllVCData = async (featured: boolean, filter?: { skip: number, limit: number, search?: string }) => {

  let queryParams = { featured } as any
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }

  const res: any = await axios.get(getAllVCDataURL, { headers: postBodyHeaders(), params: queryParams })
  return res;
};

export {
  getProjectsAssignedVcData,
  getAllVCData
};