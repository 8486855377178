import axios from "axios";
import { symbioteApiBaseUrl } from "../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const requestHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
  };
}

export const getAllBlogsAPI = (filters: { skip: number, limit: number, search_term?: string, tag?: string, isTrending?: boolean }):
  Promise<{ values: any[], total: number }> => {
  let queryParams = {}
  if (filters) {
    queryParams = { ...filters }
  }
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/blogs", { headers: requestHeaders(), params: queryParams }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({ values: [], total: 0 })
    })
  })
}

export const getBlogTagsAPI = (): Promise<string[]> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/blogs/tags", { headers: requestHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve([])
    })
  })
}

export const getBlogBySlugAPI = (slug: string): Promise<any> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/blogs/" + slug, { headers: requestHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({})
    })
  })
}