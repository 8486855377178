import { CircularProgress } from '@mui/material'
import React from 'react'
import mainLoader from "src/assests/icons/main-loader.svg"

interface ILayout {
    children: React.ReactNode,
    isLoading?: boolean
    loaderMsg?: string
}

export default function LoaderLayout({ children, isLoading, loaderMsg }: ILayout) {
    return (
        <div>
            {
                isLoading &&
                <div className='fixed top-0 z-[999999] bg-[#000000b5] w-full h-screen flex justify-center items-center'>
                    <div className='flex flex-col items-center gap-2 md:gap-4'>
                        <img src={mainLoader} width={100} className='w-[80px] md:w-[100px]' />
                        {loaderMsg && <span className='text-sm md:text-base text-[#d2d2d2] bg-[#272727] rounded-lg px-2 font-semibold'>{loaderMsg}</span>}
                    </div>
                </div>
            }
            {children}
        </div>
    )
}
