import React, { useState } from 'react';
import './vc-hero-section.sass';
import Accelerate from '../../../../../assests/images/accelerate/accelerate.svg';
import imagePlaceholder from '../../../../../assests/images/image-placeholder.png';

import { useLocation, useNavigate } from 'react-router-dom';
import { fileUploadAPI } from 'src/apis/common';
import { updateUserPhotoAPI } from 'src/pages/accelerate-pages/all-form/API/APICall';
import { toast } from 'react-toastify';
import { isLoggedIn } from 'src/constants/utils';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LoaderLayout from 'src/shared/layouts/loader-layout/LoaderLayout';

export interface VCUserData {
    userData: any
}

export const VCHeroSection = (props: VCUserData) => {
    const { userData } = props;
    const [iconPreview, setIconPreview] = useState<string | null>(null)
    const [profileLoader, setProfileLoader] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState('')

    const nav = useNavigate()
    const location = useLocation();
    const isUserLoggedIn = isLoggedIn()
    const pathname = location.pathname
    const isMentorPage = pathname.includes("/mentor-dashboard");
    const isPartnerPage = pathname.includes("/partner-dashboard");
    const isVCPage = pathname.includes("/vc-dashboard");
    const isPublicViewPage = pathname.includes("/public-view/");
    const isProfileView = pathname.includes("/profile-view");


    const onChangeFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files![0] || null
        const key = e.target.name as 'icon'
        if (!file) return
        const objectUrl = URL.createObjectURL(file)

        setIconPreview(objectUrl)
        onFileUpload(file, key)
    }

    const onFileUpload = async (file: any, imgType: 'icon') => {
        try {
            setProfileLoader(true)
            setLoaderMsg('Uploading image please wait...')
            const { link } = await fileUploadAPI(file)
            setLoaderMsg('Updating profile...')
            await updateUserPhotoAPI({ imageLocation: link, imgType })
            toast.success('Profile Updated Successfully')
            setProfileLoader(false)
            setLoaderMsg('')
        } catch (ex: any) {
            setProfileLoader(false)
            setLoaderMsg('')
            toast.error(ex.message)
        }
    }

    const showProfileIconEdit = isUserLoggedIn && !isPublicViewPage && isProfileView

    return (
        <LoaderLayout isLoading={profileLoader} loaderMsg={loaderMsg}>
            <div className='vc-profile-section py-lg-5 py-4 position-relative overflow-hidden'>
                <div className='container container-maxwidth profil-text'>
                    <div className='d-lg-flex d-md-flex d-block justify-content-between text-center align-items-center'>
                        <img className='dashboard-title img-fluid' src={Accelerate} alt="" />
                        <div className='d-flex justify-content-center align-items-center gap-3 mt-lg-0 mt-md-0 mt-4'>
                            <div className='user-profile-image'>
                                <img src={iconPreview || userData?.icon || userData?.profilePic || imagePlaceholder} className='img-fluid' alt="" />
                                {showProfileIconEdit && <div className='absolute top-0 left-0 w-full h-full bg-[#00000094] flex justify-center items-center z-0'>
                                    <input type="file" id="banner-file-upload" name="banner" onChange={onChangeFileHandler} className='hidden' />
                                    <label htmlFor='banner-file-upload'>
                                        <div className='p-[14px] bg-[#00000091] rounded-full cursor-pointer'>
                                            <CameraAltIcon sx={{ color: 'white' }} />
                                        </div>
                                    </label>
                                </div>}
                            </div>
                            <div className='text-start'>
                                <h4 className='heading-new-4 color-white-new mb-2 fw-light'>{userData && userData?.name}</h4>
                                {(!isPublicViewPage) &&
                                    <button className='new-color-button-small' onClick={() => nav(isVCPage ? '/vc-dashboard/profile-view' : isMentorPage ? '/mentor-dashboard/profile-view' : '/partner-dashboard/profile-view')}>Your Profile</button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <span className='blue-line' />
                <span className='blue-line blue-line-2' />
                <span className='blue-line blue-line-3' />
                <span className='blue-line blue-line-4' />
                <span className='blue-line blue-line-5' />
                <span className='blue-line blue-line-6' />
                <span className='blue-line blue-line-7' />
                <span className='blue-line blue-line-16' />
                <span className='blue-line blue-line-17' />
                <span className='blue-line blue-line-18' />
                <span className='blue-line blue-line-8' />
                <span className='blue-line blue-line-9' />
                <span className='blue-line blue-line-10' />
                <span className='blue-line blue-line-11' />
                <span className='blue-line blue-line-12' />
                <span className='blue-line blue-line-13' />
                <span className='blue-line blue-line-14' />
                <span className='blue-line blue-line-15' />
            </div>
        </LoaderLayout>
    )
}
