import React, { useState } from 'react'
import "./mentor-card.sass"
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';
import { ToastContainer, toast } from 'react-toastify';
import { postRequestMentorToConnect } from '../../../../API/APICall';
import { useNavigate } from 'react-router-dom';
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model';
import infoIcon from "../../../../../../../assests/icons/info-icon.svg"

export interface MentorCardData {
    _id: string,
    name: string,
    profilePic: string,
    title: string,
    requestStatus: string
    setRefreshData?: any
    refreshData?: boolean
    mentorScore?: any
    onUpdateScore?: (mentorId: string, score: any) => void
    disableScore?: Boolean;
    showUpdate?: Boolean
}

const MentorCard = (props: MentorCardData) => {
    const { _id, name, profilePic, title, requestStatus, setRefreshData, refreshData, mentorScore, showUpdate, onUpdateScore, disableScore } = props;
    const nav = useNavigate();
    const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
    const [showInfo, setShowInfo] = useState(false)
    const connectionRequestModalToggle = () => {
        setConnectionRequestModal(!connectionRequestModal)
    }

    const toggleScoreDiv = (e: any) => {
        const { type = '' } = e
        setShowInfo(type === 'mouseenter')
    }

    return (
        <>
            <div className='all-mentor-section text-center'>
                <div onClick={() => nav(`/public-view/mentor/${_id}?user-type=project`)} role='button'>
                    <img className='img-fluid m-auto' src={profilePic || NoImage} alt="" />
                    <p className={`paragraph-new mentor-name hover-primary`} title={name}>{name || "No name available"}</p>
                    <p className={`paragraph-new-medium mb-lg-3 mb-md-3 mb-2 mentor-title`} title={title}>{title || "No title available"}</p>
                </div>
                {
                    showUpdate && <div className="update-score-action-item">
                        <button
                            disabled={!!disableScore}
                            className={`new-primary-button ${disableScore ? 'disable-score' : ''}`}
                            style={{ background: "#4398FF", color: "#FFFFFF", border: "1px solid #4398FF" }}
                            onClick={() => onUpdateScore!(_id, mentorScore)}
                        >
                            Update Score
                        </button>
                        {disableScore && <img src={infoIcon} onMouseEnter={toggleScoreDiv} onMouseLeave={toggleScoreDiv} />}
                        {showInfo && <div className='info-score-div' onMouseEnter={toggleScoreDiv} onMouseLeave={toggleScoreDiv}>
                            <span>Scores can be updated only
                                <br />
                                once a week on every Monday
                            </span>
                        </div>}
                    </div>
                }
                {requestStatus === "none" &&
                    <button className='new-primary-button' onClick={() => { connectionRequestModalToggle() }}>Connect</button>
                }
                {requestStatus === "requested" &&
                    <button className='new-primary-button' style={{ background: "transparent", color: "#FFB800", border: "1px solid #FFB800" }}>Requested</button>
                }
                {requestStatus === "rejected" &&
                    <button className='new-primary-button' style={{ background: "transparent", color: "#FF0000", border: "1px solid #FF0000" }}>Not interested</button>
                }
                {requestStatus === "connected" &&
                    <button className='new-primary-button' style={{ background: "#4398FF", color: "#FFFFFF", border: "1px solid #4398FF" }}>Connected</button>
                }
            </div>
            <ConnectionRequestModel
                isOpen={connectionRequestModal}
                toggle={connectionRequestModalToggle}
                name={name}
                id={_id}
                icon={profilePic}
                userType={"mentor"}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
            />
        </>
    )
}

export default MentorCard