import { useMemo } from 'react'
import { IJob } from './JobBoard'
import { Link } from 'react-router-dom'
import JobDetailCard from './JobDetailCard'
import useMediaQuery from '@mui/material/useMediaQuery';

const DESCRIPTION_MAX_LENGTH = 105
interface IJobCard {
  item: IJob,
  selectedItem: IJob,
  colorIndex: number,
  onClickJob: (id: string) => void
}

const cardBgColors: any = {
  "1": "rgba(244, 69, 69, 0.25)",
  "2": "rgba(101, 219, 106, 0.15)",
  "3": "rgba(240, 152, 24, 0.25)",
  "4": "rgba(68, 68, 68, 0.20)",
  "5": "rgba(227, 14, 53, 0.20)",
  "6": "rgba(167, 96, 96, 0.20)",
}

function JobCard({ item, colorIndex, selectedItem, onClickJob }: IJobCard) {
  const { _id, icon, companyName, isFullTime, location, maxSalary, minSalary, role, shortDescription, telegramLink, websiteLink, twitterLink, currency } = item

  const shortCompanyDescription = useMemo(() => {
    if (shortDescription) {
      return shortDescription.length > DESCRIPTION_MAX_LENGTH ? shortDescription.substring(0, DESCRIPTION_MAX_LENGTH) + '...' : shortDescription
    } return "-"
  }, [shortDescription])

  const salary = currency + minSalary + "k" + " - " + currency + maxSalary + "k"

  const isMobileView = useMediaQuery('(max-width:900px)');

  const onClickJobCard = (id: string) => {
    onClickJob(id)
    setTimeout(() => {
      if (isMobileView) {
        const elem = document.getElementById(`job-card-${id}`)
        if (elem) elem.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  return (
    <div
      className={`job-card-container ${selectedItem?._id === _id ? "active" : ""}`}
      onClick={() => onClickJobCard(_id)}
      id={`job-card-${_id}`}
    >
      <div>
        <div className='job-card-inner' style={{ background: (isMobileView && selectedItem?._id === _id) ? '' : cardBgColors[colorIndex] }}>

          <div className="section-1">
            <div className='icon-and-name'>
              <div className='image-section'>
                <img src={icon} alt="" />
              </div>
              <h5 className='project-name'>{companyName}</h5>
            </div>
            <div className='social-icons'>
              {twitterLink &&
                <Link to={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></Link>
              }
              {telegramLink &&
                <Link to={telegramLink} target='_blank'><i className="ri-send-plane-fill"></i></Link>
              }
              {websiteLink &&
                <Link to={websiteLink} target='_blank'><i className="ri-links-line"></i></Link>
              }
            </div>

            <div>
              <p className='paragraph-new fw-bold m-0'>{isFullTime ? salary : 'Contractual'}</p>
            </div>

          </div>

          <div className="section-2">
            <p className='job-role paragraph-new fw-bold m-0' title={role}>{role}</p>
            <span className='sup-new-small fw-lighter short-desc m-0'>{shortCompanyDescription}</span>
            <p className='paragraph-new fw-bold uppercase m-0'>{location}</p>
          </div>

        </div>

        {(isMobileView && selectedItem?._id === _id) && <>
          <div className='apply-btn-container'>
            <button className='new-color-button apply-button' onClick={() => { }}>Apply</button>
          </div>
          <JobDetailCard selectedItem={selectedItem} />
        </>
        }

      </div>
    </div>
  )
}

export default JobCard