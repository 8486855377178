import React, { useEffect, useState } from 'react'
import "../project-dashboard/project-dashboard.sass"
import "./vc-dashboard.sass"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { VCHeroSection } from './components/vc-hero-section/vc-hero-section';
import { VCDashboardComp } from './components/vc-dashboard-comp/vc-dashboard-comp';
import VcProjects from './components/vc-projects/vc-projects';
import VcAllProjects from './components/vc-all-projects/vc-all-projects';
import VCConnectionRequest from './components/vc-connection-request/vc-connection-request';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import ProfileForm from './components/profile-form/profile-form';
import VcPublicProfile from './components/vc-public-profile/vc-public-profile';
import { getMentorOrVCProjectConnectionRequestData, getMentorOrVCUserData } from './API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import CalendarEvents from '../project-dashboard/components/calendar-events/calendar-events';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    formValue: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const VCDashboard = () => {
    const [value, setValue] = useState<any>(0);
    const nav = useNavigate()
    const location = useLocation();
    const { pathname } = location;
    const isProfileInfo = pathname.includes("/profile-view");

    const [userForm, setUserForm] = useState<boolean>(false);
    const [mobileResponsiveMenu, setMobileResponsiveMenu] = useState(false);
    const [userData, setUserData] = useState<any>();

    const isVcPage = pathname.includes("/vc-dashboard");
    const isVcProfileInfo = pathname.includes("/vc-dashboard/profile-view");

    const isMentorPage = pathname.includes("/mentor-dashboard");
    const isMentorProfileInfo = pathname.includes("/mentor-dashboard/profile-view");

    const isPartnerPage = pathname.includes("/partner-dashboard");
    const isPartnerProfileInfo = pathname.includes("/partner-dashboard/profile-view");

    const [mentorProjectConnectionRequestData, setMentorProjectConnectionRequestData] = useState<any>()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        if (isVcProfileInfo) {
            nav('/vc-dashboard')
        } else if (isMentorProfileInfo) {
            nav('/mentor-dashboard')
        } else if (isPartnerProfileInfo) {
            nav('/partner-dashboard')
        }
        setMobileResponsiveMenu(false)
    };

    useEffect(() => {
        if (isProfileInfo) {
            setValue(null)
        }
    }, [isProfileInfo])


    // Get vc or mentor user data
    const getMentorOrVCUserAllData = async (userType: string) => {
        await getMentorOrVCUserData(userType)
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Error while getting vc user data')
            })
    }

    // Get projects vc or mentor user data
    const getProjectMentorOrVCAllData = async (userType: string) => {
        await getMentorOrVCProjectConnectionRequestData(userType)
            .then((res) => {
                if (res?.status === 200) {
                    let data = res?.data && res?.data?.values || [];
                    setMentorProjectConnectionRequestData(data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Error while getting projects vc or mentor data')
            })
    }

    useEffect(() => {
        if (pathname === "/vc-dashboard" || pathname === "/mentor-dashboard" || pathname === '/partner-dashboard') {
            getProjectMentorOrVCAllData(pathname)
        }
    }, [])

    useEffect(() => {
        getMentorOrVCUserAllData(isVcPage ? "vc-dashboard" : isMentorPage ? "mentor-dashboard" : "partner-dashboard")
    }, [isVcPage, isMentorPage, isPartnerPage])

    return (
        <>
            <div className="vc-dashbord-main-page">
                <div className='position-relative'>
                    <VCHeroSection userData={userData} />
                    <div className='container container-maxwidth profil-text'>
                        <div className='dashboard-tabs-section mt-lg-5 mt-md-3 mt-2' id="scroll-start-element">
                            <div className='d-flex justify-content-end align-items-center mb-lg-0 mb-md-0 mb-3'>
                                {/* <div><h4 className='heading-new-4 mb-0'>My projects</h4></div> */}
                                <div className="mobile-tab-btn">
                                    <div className='mobile-icon mb-md-4 mb-1' role='button' onClick={() => { setMobileResponsiveMenu(true) }}>
                                        <i className="ri-menu-3-line"></i>
                                    </div>
                                </div>
                            </div>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <div className={mobileResponsiveMenu ? 'mobile-responsive-tab active' : 'mobile-responsive-tab'}>
                                    <div className="tab-close-icon" role='button' onClick={() => { setMobileResponsiveMenu(false) }}>
                                        <i className="ri-close-line"></i>
                                    </div>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        className='drawer-tab-container'
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='tab-items' label="Dashboard" {...a11yProps(0)} />
                                        <Tab className='tab-items' label="Events" {...a11yProps(1)} />
                                        <Tab className='tab-items' label="My Projects" {...a11yProps(2)} />
                                        <Tab className='tab-items' label="All Projects" {...a11yProps(3)} />
                                        <Tab
                                            className='tab-items'
                                            label={
                                                <div className='connection-count'>
                                                    <span className='fw-bold'>Connection Requests</span>
                                                    {mentorProjectConnectionRequestData && mentorProjectConnectionRequestData?.length > 0
                                                        && <span className='border-round-count fw-bold ms-2'>{mentorProjectConnectionRequestData && mentorProjectConnectionRequestData?.length || 0}</span>
                                                    } </div>}
                                            {...a11yProps(4)}
                                        />
                                    </Tabs>
                                </div>
                                {!isProfileInfo &&
                                    <div className="main-tab-panel-container container mb-lg-0 mb-4">
                                        <TabPanel value={value} index={0} formValue={0}>
                                            <VCDashboardComp userData={userData} />
                                        </TabPanel>

                                        <TabPanel value={value} index={1} formValue={0}>
                                            <CalendarEvents />
                                        </TabPanel>
                                        
                                        <TabPanel value={value} index={2} formValue={0}>
                                            <VcProjects value={value} />
                                        </TabPanel>

                                        <TabPanel value={value} index={3} formValue={0}>
                                            <VcAllProjects />
                                        </TabPanel>

                                        <TabPanel value={value} index={4} formValue={0}>
                                            <VCConnectionRequest />
                                        </TabPanel>
                                    </div>
                                }
                                {isProfileInfo &&
                                    <div className="form-section w-100">
                                        <div className="container">
                                            <div className='d-flex justify-content-between align-items-center mt-lg-3 mt-md-3 mt-0'>
                                                <div>
                                                    <h5 className='heading-new-5'>Your Profile</h5>
                                                </div>
                                                <div className='public-btn'>
                                                    {userForm &&
                                                        <button className='public-viewing-btn' onClick={() => { setUserForm(false) }}><i className="ri-eye-line"></i>Viewing Public Profile</button>}
                                                    {!userForm &&
                                                        <button className='new-primary-button' onClick={() => { setUserForm(true) }}><i className="ri-eye-line"></i>View as Public Profile</button>}
                                                </div>
                                            </div>
                                            {userForm &&
                                                <VcPublicProfile userData={userData} />
                                            }
                                            {!userForm &&
                                                <ProfileForm />
                                            }
                                        </div>
                                    </div>
                                }
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VCDashboard;