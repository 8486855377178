import React, { useState } from 'react'
import SectionHeading from '../common/SectionHeading'
import { communitySignupAPI } from 'src/apis/common'
import { toast } from 'react-toastify'

const forms = [
  {
    label: 'Founder',
    value: 'founder'
  },
  {
    label: 'Investor',
    value: 'investor'
  },
  {
    label: 'Media',
    value: 'media'
  },
  {
    label: 'Community',
    value: 'community'
  },
  {
    label: 'Others',
    value: 'others'
  },
]

export default function StayConnected() {

  const [selectedForm, setSelectedForm] = useState('')
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onClickSignup = async () => {
    try {
      if (!selectedForm) return toast.error('Please select type')
      if (!email) return toast.error('Enter email')
      setIsLoading(true)
      await communitySignupAPI({ type: selectedForm, email })
      setIsLoading(false)
      toast.success('Registered successfully')
      setEmail('')
      setSelectedForm('')
    } catch (ex: any) {
      setIsLoading(false)
      toast.error(ex.message)
    }
  }

  return (
    <div className='container pt-14 pb-16'>
      <div className='flex flex-col md:flex-row gap-4'>
        <div className='md:w-[30%] flex justify-center items-center overflow-hidden'>
          <img src="/img/home/loop-img.png" alt="" className='loop-animation min-h-[260px] md:min-h-[300px] w-[300px] min-w-[260px] md:min-w-[300px]' />
        </div>
        <div className='flex flex-col justify-between md:w-[70%] max-w-[640px] px-2 md:px-0'>
          <div className='max-w-[380px] grid gap-2 mt-4 md:mt-0'>
            <SectionHeading heading='Stay in the loop' />
          </div>
          <div className='mt-9'>
            <p>
              Join our community to receive the latest updates, news, and exclusive insights straight to your inbox
            </p>
          </div>

          <div className='flex flex-wrap md:flex-wrap gap-6 mt-4 justify-start'>
            {
              forms.map((form, key) => {
                return <div key={key} className='flex items-center gap-3 cursor-pointer' onClick={() => setSelectedForm(form.value)}>
                  <input type='radio' className='scale-150 accent-[#4498fd] cursor-pointer' checked={selectedForm === form.value} readOnly />
                  <span>{form.label}</span>
                </div>
              })
            }
          </div>

          <div className='flex flex-col w-full justify-center items-center md:flex-row gap-4 md:gap-10 mt-12'>
            <input
              className='h-[34px] bg-[#1A1A1A] w-full rounded-2xl px-6 outline-none border-none'
              placeholder='Your email ID'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <button
              disabled={isLoading}
              className='home-apply-btn w-[180px]'
              onClick={onClickSignup}
            >
              <span>{isLoading ? 'Please wait..' : 'Sign up'}</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}
