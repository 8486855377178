import React, { useEffect, useState } from 'react'
import "./study-programs.sass"
import "../../../../../shared/components/media-slider-comp/mediaSlider.sass"
import NoImage from '../../../../../assests/images/homepage/no-image-available.jpg';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import MediaSlider from '../../../../../shared/components/media-slider-comp/mediaSlider';
import { getAllExternalVideosLinkData, getAllVideoCategorys, getAllVideosData, getSectionVideoData, getVideosDataByID, postRequestMentorToConnect, setVideoAsViewedAPI } from '../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import YouTube from 'react-youtube';
import Checkbox from "@mui/material/Checkbox"
import { CircularProgress } from '@mui/material';

const StudyPrograms = () => {
    const location = useLocation();
    const nav = useNavigate();
    const { pathname } = location;
    const [ExternalvideoData, setExternalVideoData] = useState<any>([])
    const [externalLoader, setExternalLoader] = useState(false)

    const [singleVideoData, setSingleVideoData] = useState<any>()
    const [markedViewed, setMarkedViewed] = useState<boolean>(false)

    const isVideoDetailsPage = pathname.includes("/video-details")
    const params = useParams<{ id: any }>();
    const [allCategorySectionsData, setAllCategorySectionsData] = useState<any>()
    const [allCoursesLoader, setAllCoursesLoader] = useState(false)

    useEffect(() => {
        getAllCoursesData()
        getAllCategorysData()
        if (isVideoDetailsPage) {
            getVideoByID()
        }
    }, [isVideoDetailsPage])

    const getAllCoursesData = async () => {
        setExternalLoader(true)
        await getAllExternalVideosLinkData()
            .then((res) => {
                setExternalLoader(false)
                if (res?.status === 200) {
                    setExternalVideoData(res?.data?.values && res?.data?.values)
                }
            })
            .catch((err) => {
                setExternalLoader(false)
                toast.error(err?.response?.data?.message)
            })
    }

    const getVideoByID = async () => {
        await getVideosDataByID(params?.id)
            .then((res) => {
                if (res?.status === 200) {
                    setSingleVideoData(res?.data || {})
                    setMarkedViewed(res?.data && res?.data?.isMarked || false)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const getAllCategorysData = async () => {
        setAllCoursesLoader(true)
        try {
            await getAllVideoCategorys()
                .then(async (res: any) => {
                    if (res?.status === 200) {
                        const data = res?.data?.values || []
                        let responseResult = data.reduce(function (r: any, a: any) {
                            console.log("asaa", a, r);
                            r[a?.category] = r[a?.category] || [];
                            r[a?.category].push(a);
                            return r;
                        }, Object.create(null));

                        const getAllSectionsAPIResponseData = responseResult && responseResult?.undefined.map(async (item: any, index: any) => {
                            let responseDataFromResearchAPI: any = []
                            let responseDataFromResearchAPIArray = await getSectionVideoData(item?._id)
                            responseDataFromResearchAPI = responseDataFromResearchAPIArray || []
                            return {
                                "keyName": item?.slug,
                                "fullName": item?.name,
                                "data": responseDataFromResearchAPI?.data?.values || []
                            };
                        }) || []
                        const fetchSectionDataArray = await Promise.all(getAllSectionsAPIResponseData)
                        console.log("fetchSectionDataArray", fetchSectionDataArray)
                        setAllCategorySectionsData(fetchSectionDataArray || {})
                    }
                    setAllCoursesLoader(false)
                }).catch((err: any) => {
                    setAllCoursesLoader(false)
                    toast.error(err?.response?.data?.error?.message)
                })
        }
        catch (err: any) {
            setAllCoursesLoader(false)
            toast.error(err?.response?.data?.error?.message)
            return { error: err?.response?.data };
        }
    };

    const postRequestMentorToConnectData = async (userId: any) => {
        console.log("userIduserIduserId", userId)
        try {
            postRequestMentorToConnect(userId)
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success(res?.data?.message)
                    }
                })
                .catch((err: any) => {
                    toast.error(err?.response?.data?.message)
                    return err
                })
        }
        catch (err: any) {
            toast.error(err?.response?.data?.message)
            return err;
        }
    };

    const markAsViewed = async () => {
        setMarkedViewed(prev => !prev)
        await setVideoAsViewedAPI(singleVideoData?._id)
        getVideoByID()
    }

    return (
        <>
            {isVideoDetailsPage ?
                <div className='study-program-container'>
                    <div className="">
                        <div className="top-header-container">
                            <a className='paragraph-new d-lg-block d-md-block d-none' href='#' onClick={() => nav(-1)}>Back to Resources</a>
                            <div className="mark-viewed-item" onClick={e => { e.preventDefault(); markAsViewed() }}>
                                <Checkbox
                                    checked={markedViewed}
                                    sx={{
                                        color: '#73ed79', '&.Mui-checked': {
                                            color: '#73ed79',
                                        }
                                    }}
                                    onChange={() => markAsViewed()}
                                />
                                <span>Mark as Viewed</span>
                            </div>
                        </div>
                        <h4 className='heading-new-4 fw-light text-start mt-lg-3 mt-md-3 mb-lg-0 mb-md-0 mb-4 managing-finances-color'>Managing Finances</h4>
                        <div className="fs-82 my-4 d-lg-block d-md-block d-none">{singleVideoData && singleVideoData?.title || "No title available"}</div>
                        <div className="video-details">
                            <YouTube videoId={singleVideoData && singleVideoData?.videoId} />
                            <div className='mt-3'>
                                {singleVideoData?.shortDescription &&
                                    <h4 className='heading-new-4 color-white-new'>{singleVideoData && singleVideoData?.shortDescription || "No short description available"}</h4>
                                }
                                <p className='paragraph-new mt-lg-3 mt-md-3 mt-2'>{singleVideoData && singleVideoData?.description || "No description available"}</p>
                            </div>
                        </div>
                    </div>
                    <div className='connection-section mt-lg-5 mt-4'>
                        <h4 className='heading-new-4 mb-4 text-lg-start text-md-start text-center'>Mentors in the video</h4>
                        <div className="row">
                            {singleVideoData?.mentors && singleVideoData?.mentors.map((item: any, i: number) => (
                                <div className="col-lg-2 col-md-3" key={i} onClick={() => nav(`/public-view/mentor/${item._id}?user-type=project`)}>
                                    <div className='all-mentor-section text-center m-auto'>
                                        <img className='img-fluid' src={item?.profilePic || NoImage} alt="" />
                                        <p className='paragraph-new mt-3 mb-lg-3 mb-md-3 mb-2 hover-primary cursor-pointer'>{item?.name || "No name available"}</p>
                                        {/* <p className='paragraph-new-medium mt-0 mb-lg-3 mb-md-3 mb-2'>{item?.title || "No title available"}</p> */}
                                        <button className='new-primary-button' onClick={() => { postRequestMentorToConnectData(item?._id) }}>Connect</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                :
                <div className='study-programing-page'>
                    {allCoursesLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress style={{ color: 'white' }} size={32} />
                    </div>}
                    {!allCoursesLoader && allCategorySectionsData && allCategorySectionsData.map((item: any, i: number) => (
                        <div className='courses-section mb-lg-5' key={i}>
                            <h4 className='heading-new-4 media-slider-heading mb-3 color-primary'>{item?.fullName}</h4>
                            <MediaSlider videoData={item?.data} />
                        </div >
                    ))}

                    <div className='courses-section'>
                        <div className='external-content-heading'>
                            <i className="ri-external-link-line external-icon color-primary"></i>
                            <h4 className='heading-new-4 mb-3 color-primary'>Curated External Content for you</h4>
                        </div>
                        {externalLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress style={{ color: 'white' }} size={32} />
                        </div>}
                        {!externalLoader && <MediaSlider videoData={ExternalvideoData} isexternalContent />}
                    </div>
                </div >
            }
        </>
    )
}

export default StudyPrograms