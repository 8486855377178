import axios from "axios";
import { GET_LAUNCHNETWORK_ASSIGEND_DATA, GET_LAUNCHNETWORK_CATEGORY_DATA, GET_PROJECT_MENTOR_NETWORK_DATA, POST_MENTOR_SUBMIT_DATA, POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION, symbioteApiBaseUrl } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "multipart/form-data"
  };
}

const postBodyHeadersJson = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "application/json"
  };
}

const getLauchnetworkData = GET_LAUNCHNETWORK_CATEGORY_DATA
const getAssignedLauchnetworkData = GET_LAUNCHNETWORK_ASSIGEND_DATA
const postRequestLaunchNetworkToConnectURL = POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION

const getAllLauchnetworkCategoryData = async () => {
  const res: any = await axios.get(getLauchnetworkData + "/category", { headers: postBodyHeaders() })
  return res;
};


const getAllAndCategoryWiseAssignedLaunchnetworkData = async (category?: string) => {

  let queryParams = { is_launch: true } as any
  if (category) {
    queryParams = { ...queryParams, operations: category }
  }

  const res: any = await axios.get(`${symbioteApiBaseUrl}/api/v1/projects/partners`, { params: queryParams, headers: postBodyHeaders() })
  return res;
};

const getAllAndCategoryWiseLaunchnetworkData = async (category?: string, filters?: { skip: number, limit: number, search?: string }) => {
  let queryParams = { is_launch: true } as any
  if (category) {
    queryParams = { ...queryParams, operations: category }
  }
  if(filters) {
    queryParams = { ...queryParams, ...filters }
  }

  const res: any = await axios.get(`${symbioteApiBaseUrl}/api/v1/partner`, { params: queryParams, headers: postBodyHeaders() })
  return res;
};

const postRequestLaunchNetworkToConnect = async (networkId: any) => {
  const bodyParams = {
    "networkId": networkId
  }
  const res: any = await axios.post(`${symbioteApiBaseUrl}/api/v1/partner/request/connect`, bodyParams, { headers: postBodyHeadersJson() })
  return res;
};


export {
  getAllLauchnetworkCategoryData,
  getAllAndCategoryWiseLaunchnetworkData,
  getAllAndCategoryWiseAssignedLaunchnetworkData,
  postRequestLaunchNetworkToConnect
};