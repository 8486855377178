import React from 'react';
import {
  Outlet
} from 'react-router-dom';
import ScrollToTop from './shared/components/scroll-to-top/scroll-to-top';
import Header from './shared/components/header/header';
import Footer from './shared/components/footer/footer';
function RouteLayout() {
  return (
    <div>
      {/* <ScrollToTop /> */}
      <Header />
      <Outlet />
      <Footer />
    </div >
  );
}

export default RouteLayout;
