import React, { useEffect, useState } from 'react'
import "./calendar-events.sass"
import { ReminderDetailsCardData } from './details-card/details-card';
import { addRemoveEventReminderAPI, getEventsData } from './API/APICall';
import CircularProgress from "@mui/material/CircularProgress"
import CustomModal from 'src/shared/components/CustomModal/CustomModal';
import { toast } from 'react-toastify';


const CalendarEvents = () => {
  const [selectedMenu, setselectedMenu] = useState("Listicle View");
  const [projectMenu, setProjectMenu] = useState(["Listicle View"]);
  const [partnerStatus, setPartnerStatus] = useState<any>();
  const [eventEmails, setEventEmails] = useState<string[]>([''])
  const [modalEventId, setModalEventId] = useState("")

  const [symbioteEvents, setSymbioteEvents] = useState("Listicle View");
  const [symbioteEventsMenu, setSymbioteEventsMenu] = useState(["Listicle View"]);
  const [eventsData, setEventsData] = useState<any>([]);
  const [pastEventsData, setPastEventsData] = useState<any>([]);
  const [eventLoader, setEventLoader] = useState(false)

  useEffect(() => {
    if (projectMenu.length < 2) {
      setProjectMenu([...projectMenu, "Calendar View"])
    }
  }, [selectedMenu])

  useEffect(() => {
    if (symbioteEventsMenu.length < 2) {
      setSymbioteEventsMenu([...projectMenu, "Calendar View"])
    }
  }, [symbioteEvents])

  useEffect(() => {
    getAllEventsData()
    getAllPastEventsData()
  }, [])

  // get events data data
  const getAllEventsData = async () => {
    setEventLoader(true)
    await getEventsData(partnerStatus)
      .then((res) => {
        setEventLoader(false)
        if (res?.status === 200) {
          setEventsData(res && res?.data?.values);
        }
      })
      .catch((err) => {
        setEventLoader(false)
        console.log(err, "Error")
      })
  }

  const getAllPastEventsData = async () => {
    setEventLoader(true)
    await getEventsData('past')
      .then((res) => {
        setEventLoader(false)
        if (res?.status === 200) {
          setPastEventsData(res && res?.data?.values);
        }
      })
      .catch((err) => {
        setEventLoader(false)
      })
  }

  const addEmailToReminder = () => {
    setEventEmails(prev => ([...prev, ""]))
  }

  const removeEmailToReminder = (index: number) => {
    setEventEmails(prev => prev.filter((e, i) => index !== i))
  }

  const onSaveReminder = async () => {
    try {
      const validEmails = eventEmails.filter(item => !!item)
      if (!validEmails.length) {
        return toast.error('Emails are required')
      }
      if (eventLoader) return
      setEventLoader(true)
      setModalEventId("")
      setEventEmails([''])
      await addRemoveEventReminderAPI(modalEventId, { emails: eventEmails })
      toast.success('Emails added to the event')
      getAllEventsData()
      setEventLoader(false)
    } catch (ex) {
      setEventLoader(false)
    }
  }

  return (
    <>
      <div>


        <div className="event-text-card-section">
          <div className="event-header-content">
            <div className="d-flex align-items-center gap-4 justify-content-lg-start justify-content-md-start justify-content-between mb-lg-0 mb-md-0 mb-3">
              <h4 className='heading-new-4 mb-0 color-primary'>Your Events</h4>
            </div>
          </div>


          {eventLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: 'white' }} size={32} />
          </div>}
          {!eventLoader && <>
            {eventsData && eventsData?.length > 0 ?
              <div className="row">
                {eventsData && eventsData.map((item: any, i: number) => (
                  <ReminderDetailsCardData
                    key={i}
                    id={item._id}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    author={item.author}
                    banner={item.banner}
                    title={item.title}
                    guests={item.guests}
                    meetLink={item.meetLink}
                    isRemindered={item.isReminder}
                    onClickReminder={(id) => setModalEventId(id)}
                  />
                ))}
              </div> : <p className='paragraph-new text-center mt-lg-5 mt-3'>No Events</p>
            }
          </>}
        </div>

        <div className="event-text-card-section mt-10">
          <div className="event-header-content">
            <div className="d-flex align-items-center gap-4 justify-content-lg-start justify-content-md-start justify-content-between mb-lg-0 mb-md-0 mb-3">
              <h4 className='heading-new-4 mb-0 color-primary'>Past Events</h4>
            </div>
          </div>


          {eventLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: 'white' }} size={32} />
          </div>}
          {!eventLoader && <>
            {pastEventsData && pastEventsData?.length > 0 ?
              <div className="row">
                {pastEventsData && pastEventsData.map((item: any, i: number) => (
                  <ReminderDetailsCardData
                    key={i}
                    id={item._id}
                    startDate={item.startDate}
                    endDate={item.endDate}
                    author={item.author}
                    banner={item.banner}
                    title={item.title}
                    guests={item.guests}
                    meetLink={item.meetLink}
                    isRemindered={item.isReminder}
                    onClickReminder={(id) => setModalEventId(id)}
                    isPast
                  />
                ))}
              </div> : <p className='paragraph-new text-center mt-lg-5 mt-3'>No Past Events</p>
            }
          </>}
        </div>

        <CustomModal
          isOpen={!!modalEventId}
          toggle={() => setModalEventId("")}
          title='RSVP for the Event'
        >
          <div className='text-left max-w-[400px] mx-auto'>
            <h3 className='text-sm md:text-base mt-3 mb-0'>Add event attendee email IDs</h3>
            <div>
              {
                eventEmails.map((item, index: any) => {
                  return <div className='flex items-center gap-2 w-full my-3'>
                    <input
                      className='bg-transparent rounded-md w-full max-w-[300px] h-10 px-2 outline-none text-sm md:text-base'
                      style={{ border: '1px solid gray' }}
                      value={item}
                      onChange={(event) => setEventEmails(prev => prev.map((item, i) => i === index ? event.target.value : item))}
                      placeholder='Enter email address'
                    />
                    {(eventEmails.length - 1 === index) ? <span
                      className='flex justify-center items-center bg-green-600 hover:bg-green-500 min-w-[2rem] w-8 h-8 rounded-full text-white cursor-pointer text-3xl'
                      onClick={() => addEmailToReminder()}>+</span>
                      :
                      <span
                        className='flex justify-center items-end bg-red-600 hover:bg-red-500 min-w-[2rem] w-8 h-8 rounded-full text-white cursor-pointer text-3xl'
                        onClick={() => removeEmailToReminder(index)}>-</span>
                    }
                  </div>
                })
              }
            </div>
            <button className="new-color-button py-2" onClick={onSaveReminder}>
              Save
            </button>
          </div>
        </CustomModal>
      </div>
    </>
  )
}

export default CalendarEvents