import React, { useEffect, useState } from 'react'
import "./index.scss"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { getGrantPartnerByIdAPI, getPartnerByIdAPI } from '../../API/APICall'
import CircularProgress from "@mui/material/CircularProgress"
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';

interface detailsProps {
  isOpen: boolean,
  toggle: () => void,
  descPartner: { partner: string, isGrant: boolean, showGrantDesc: boolean }
}

const DescriptionModal = ({ isOpen, toggle, descPartner }: detailsProps) => {

  const [descLoader, setDescLoader] = useState(true)
  const [partner, setPartner] = useState<any>({})

  const { partner: partnerId, isGrant, showGrantDesc } = descPartner

  useEffect(() => {
    if (partnerId) {
      onClickReadMore(partnerId)
    }
  }, [partnerId])

  const onClickReadMore = async (partnerId: string) => {
    setDescLoader(true)
    let response = {}
    if (isGrant) {
      response = await getGrantPartnerByIdAPI(partnerId)
    } else {
      response = await getPartnerByIdAPI(partnerId)

    }
    setPartner(response)
    setDescLoader(false)
  }

  return (
    <>
      <Modal className='connection-request-model description-modal' centered fade={false} isOpen={isOpen} toggle={toggle}>
        <div className="modal-wrapper">
          <ModalHeader toggle={toggle} className='justify-content-center'>
            <div className="close-btn" onClick={toggle}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </ModalHeader>
        </div>
        <ModalBody>
          <div className="description-modal-container">
            {descLoader ? <div className='modal-loader'>
              <CircularProgress size={32} sx={{ color: 'white' }} />
            </div>
              :
              <>
                <div className="icon-and-name">
                  {!showGrantDesc && <img src={partner?.icon || NoImage} alt="" />}
                  <span>{isGrant ? showGrantDesc ? 'Grant Name: ' + partner?.grantName : partner?.name : partner?.companyName}</span>
                </div>
                <p className="parter-desc">{isGrant ? showGrantDesc ? partner?.grantDescription : partner?.description : partner?.companyDescription}</p>
              </>
            }
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default DescriptionModal