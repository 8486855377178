import axios from "axios"
import { symbioteApiBaseUrl } from "src/constants"
import { getloggedInUserData, loggedInAs, requestBodyHeaders } from "src/constants/utils"

export const fileUploadAPI = (file: any): Promise<{ key: string, link: string }> => {

    const { partner, project, mentor, vc } = loggedInAs()
    const { _id: uploaderId } = getloggedInUserData()

    return new Promise(async (resolve, reject) => {
        let dirName = ''
        if (project) dirName = `/projects-${uploaderId}`
        else if (partner) dirName = `/partners-${uploaderId}`
        else if (mentor) dirName = `/mentors-${uploaderId}`
        else if (vc) dirName = `/vcs-${uploaderId}`

        const formData = new FormData()
        formData.append('file', file)
        axios.post(symbioteApiBaseUrl.concat(`/api/v1/uploads${dirName}`), formData, { headers: requestBodyHeaders() })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject((error?.response && error?.response?.data) || { message: 'Something went wrong' })
            })
    })
}

export const communitySignupAPI = (data: { type: string, email: string }): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        axios.post(symbioteApiBaseUrl.concat(`/api/v1/community/register`), data, { headers: requestBodyHeaders() })
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject((error?.response && error?.response?.data) || { message: 'Something went wrong' })
            })
    })
}