import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import AddServiceRequest from './new-request/AddServiceRequest';
import ServiceRequests from './show-all-requests/ServiceRequests';

export default function SeriveRequestsMain() {

    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const [isAddView, setIsAddView] = useState(false)

    useEffect(() => {
        if (splitLocation && splitLocation[3]) {
            setIsAddView(true)
        } else {
            setIsAddView(false)
        }
    }, [splitLocation])

    if (isAddView) {
        return <AddServiceRequest />
    }
    return <ServiceRequests />
}
