import React from 'react'
import "./vc-public-profile.sass"
import NoImage from '../../../../../assests/images/homepage/no-image-available.jpg';
import { InfoCard } from '../info-card/info-card';
import { Link, useLocation } from 'react-router-dom';

export interface UserDataProps {
    userData: any,
}

const VcPublicProfile = (props: UserDataProps) => {
    const { userData } = props
    const location = useLocation();
    const { pathname } = location;
    const isVcProfileInfo = pathname.includes("/vc-dashboard/profile-view");
    const isMentorProfileInfo = pathname.includes("/mentor-dashboard/profile-view")
    const isPartnerProfileInfo = pathname.includes("/partner-dashboard/profile-view");
    const isPublicView = pathname.includes('/public-view/')
    const isPublicViewMentor = pathname.includes('/public-view/mentor')
    const isPublicViewVC = pathname.includes('/public-view/vc')
    const isPublicViewParter = pathname.includes('/public-view/partner')

    return (
        <>
            {(isPartnerProfileInfo || isPublicViewParter) &&
                <div className={isPublicViewParter ? 'vc-public-profile mb-lg-5 mb-4' : 'vc-public-profile mt-lg-5 mt-4'}>
                    <div className='vc-public-profile-section d-lg-flex d-md-flex d-block justify-content-between align-items-center'>
                        <div className='d-flex align-items-center gap-lg-4 gap-3 flex-lg-nowrap flex-md-nowrap flex-wrap justify-lg-content-between justify-md-content-between justify-content-center'>
                            <div className='vc-profile-img'><img src={userData?.icon || NoImage} alt="" className='img-fluid' /></div>
                            <h4 className='heading-new-4 m-0'>{userData?.companyName || "No name available"}</h4>
                            {userData?.companyWebsiteLink &&
                                <Link to={userData?.companyWebsiteLink} target='_blank' className='paragraph-new-small m-0' >Visit Website</Link>
                            }
                        </div>
                        <div className='d-flex gap-3 vc-public-icons mt-lg-0 mt-md-0 mt-3 justify-lg-content-start justify-md-content-start justify-content-center'>
                            {userData?.twitterLink &&
                                <Link to={userData?.twitterLink} target='_blank'><i className="ri-twitter-fill"></i></Link>
                            }
                            {userData?.telegramId &&
                                <Link to={userData?.telegramId} target='_blank'><i className="ri-send-plane-fill"></i></Link>
                            }
                        </div>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <p className='paragraph-new mb-0'>{userData?.companyDescription || "-"}</p>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        {/* {InfocardData && InfocardData.map((item: any, index: number) => ( */}
                        <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Partner Projects</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.totalPartnerProjects || 0}</h2>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Projects Interested</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.totalProjectsInterested || 0}</h2>
                            </div>
                        </div>
                    </div>

                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Company Operations:</h5>
                        <div className='d-flex gap-lg-4 gap-3 mt-4 flex-wrap'>
                            {userData?.companyOperations && userData?.companyOperations.map((item: any, i: number) => (
                                <button className='new-white-button' style={{ pointerEvents: "none" }} role='cell' key={i}>{item || "-"}</button>
                            ))}
                        </div>
                    </div>

                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Industries Of Expertise:</h5>
                        <div className='d-flex gap-lg-4 gap-3 mt-4 flex-wrap'>
                            {userData?.industriesOfExpertise && userData?.industriesOfExpertise.map((item: any, i: number) => (
                                <button className='new-white-button' style={{ pointerEvents: "none" }} role='cell' key={i}>{item || "-"}</button>
                            ))}
                        </div>
                    </div>

                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Partner Connection Type</h5>
                        <p className='paragraph-new mb-0 mt-4'>{userData?.partnerConnectionType || "-"}</p>
                    </div>
                </div>}
            {(isPublicViewMentor || isMentorProfileInfo) &&
                <div className={isPublicViewMentor ? 'vc-public-profile mb-lg-5 mb-4' : 'vc-public-profile mt-lg-5 mt-4'}>
                    <div className='vc-public-profile-section d-lg-flex d-md-flex d-block justify-content-between align-items-center'>
                        <div className='d-flex align-items-center gap-lg-4 gap-3 flex-lg-nowrap flex-md-nowrap flex-wrap justify-lg-content-between justify-md-content-between justify-content-center'>
                            <div className='vc-profile-img'><img src={userData?.profilePic || NoImage} alt="" className='img-fluid' /></div>
                            <h4 className='heading-new-4'>{userData?.name || "No name available"}</h4>
                        </div>
                        <div className='d-flex gap-3 vc-public-icons mt-lg-0 mt-md-0 mt-3 justify-lg-content-start justify-md-content-start justify-content-center'>
                            {userData?.twitterLink &&
                                <Link to={userData?.twitterLink} target='_blank'><i className="ri-mail-fill"></i></Link>
                            }
                            {userData?.telegramId &&
                                <Link to={userData?.telegramId} target='_blank'><i className="ri-send-plane-fill"></i></Link>
                            }
                            {userData?.linkedInProfileLink &&
                                <Link to={userData?.linkedInProfileLink} target='_blank'><i className="ri-linkedin-fill"></i></Link>
                            }
                        </div>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <p className='paragraph-new mb-0'>{userData?.about || "-"}</p>
                    </div>
                    <div className="row mt-lg-5 mt-4">
                        {/* {InfocardData && InfocardData.map((item: any, index: number) => ( */}
                        <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Years of Experience Mentoring</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.yearsOfExp || 0}</h2>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Projects Mentoring</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.projectMentor || 0}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <div className='mt-lg-5 mt-4'>
                            <h5 className='heading-new-5'>Sectors of Mentoring:</h5>
                            <div className='d-flex gap-lg-4 gap-3 mt-4 flex-wrap'>
                                {userData?.areasOfExpertise && userData?.areasOfExpertise.map((item: any, i: number) => (
                                    <button className='new-white-button' style={{ pointerEvents: "none" }} role='cell' key={i}>{item || "-"}</button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {(isPublicViewVC || isVcProfileInfo) &&
                <div className={isPublicViewVC ? 'vc-public-profile mb-lg-5 mb-4' : 'vc-public-profile mt-lg-5 mt-4'}>
                    <div className='vc-public-profile-section d-lg-flex d-md-flex d-block justify-content-between align-items-center'>
                        <div className='d-flex align-items-center gap-lg-4 gap-3 flex-lg-nowrap flex-md-nowrap flex-wrap justify-lg-content-between justify-md-content-between justify-content-center'>
                            <div className='vc-profile-img'><img src={userData?.icon || NoImage} alt="" className='img-fluid' /></div>
                            <h4 className='heading-new-4'>{userData?.name || "No name available"}</h4>
                            {userData?.websiteLink &&
                                <Link to={userData?.websiteLink} target='_blank' className='paragraph-new-small m-0' >Visit Website</Link>
                            }
                            {userData?.portfolioLink &&
                                <Link to={userData?.portfolioLink} target='_blank' className='paragraph-new-small m-0'>See Portfolio</Link>
                            }
                        </div>
                        <div className='d-flex gap-3 vc-public-icons mt-lg-0 mt-md-0 mt-3 justify-lg-content-start justify-md-content-start justify-content-center'>
                            {userData?.twitterLink &&
                                <Link to={userData?.twitterLink} target='_blank'><i className="ri-mail-fill"></i></Link>
                            }
                            {userData?.telegramId &&
                                <Link to={userData?.telegramId} target='_blank'><i className="ri-send-plane-fill"></i></Link>
                            }
                            {userData?.linkedInProfileLink &&
                                <Link to={userData?.linkedInProfileLink} target='_blank'><i className="ri-linkedin-fill"></i></Link>
                            }
                        </div>
                    </div>
                    {/* <div className='mt-lg-5 mt-4'>
                        <p className='paragraph-new mb-0'>{userData?.description || "No description available"}</p>
                    </div> */}
                    <div className="row mt-lg-5 mt-4">
                        {/* {InfocardData && InfocardData.map((item: any, index: number) => ( */}
                        <div className="col-lg-5 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Investment Ticket Size</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.averageInvestmentTicket || 0}</h2>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Investments Completed</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.investmentCompleted || 0}</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-lg-0 mb-md-0 mb-3">
                            <div className="info-card">
                                <h5 className='heading-new-5 mb-3'>Total Amount Invested</h5>
                                <h2 className='heading-new-2 mb-0'>{userData?.totalAmountInvested || 0}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <div>
                            <h5 className='heading-new-5'>Investment Asset Type:</h5>
                            <div className='d-flex gap-lg-4 gap-3 mt-4 flex-wrap'>
                                {
                                    userData?.investmentAssets === 'Both' ? ['Equity', 'Tokens'].map(item => {
                                        return <button className='new-white-button' style={{ pointerEvents: "none" }} key={item}>{item || "-"}</button>
                                    }) : userData?.investmentAssets
                                }
                            </div>
                        </div>
                        <div className='mt-lg-5 mt-4'>
                            <h5 className='heading-new-5'>Sectors of Investment:</h5>
                            <div className='d-flex gap-lg-4 gap-3 mt-4 flex-wrap'>
                                {userData?.sectorsLikeToInvest && userData?.sectorsLikeToInvest.map((item: any, i: number) => (
                                    <button className='new-white-button' style={{ pointerEvents: "none" }} key={i}>{item || "-"}</button>
                                ))}
                            </div>
                        </div>
                        <div className='mt-lg-5 mt-4'>
                            <h5 className='heading-new-5'>Investment Stage:</h5>
                            <button className='new-white-button mt-4' style={{ pointerEvents: "none" }}>{userData?.stageOfInvests || "-"}</button>
                        </div>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Top things we look for in a startup</h5>
                        <p className='paragraph-new mb-0 mt-4'>{userData?.startupTopThings || "-"}</p>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Value Adds we can offer to a startup</h5>
                        <p className='paragraph-new mb-0 mt-4'>{userData?.startupValueAdds || "-"}</p>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Our Partnership Deals with other Web3 comapanies</h5>
                        <p className='paragraph-new mb-0 mt-4'>{userData?.detailsOfPartnershipWithOthers || "-"}</p>
                    </div>
                    <div className='mt-lg-5 mt-4'>
                        <h5 className='heading-new-5'>Our investment thesis and industry experience</h5>
                        <p className='paragraph-new mb-0 mt-4'>{userData?.investmentThesisOrExpertise || "-"}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default VcPublicProfile