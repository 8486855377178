import { useState, useEffect, useRef } from 'react'
import SearchIcon from "../../../../../../assests/icons/search-icon.svg"

export default function SearchBox({ onSearch }: { onSearch: (value: string) => void }) {

  const [input, setInput] = useState('')

  const interval = useRef(null) as any

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearTimeout(interval.current)
      }
    }
  }, [])


  const onChangeHandler = (value: string) => {
    setInput(value)

    if (interval.current) {
      clearTimeout(interval.current)
    }
      interval.current = setTimeout(() => {
        onSearch(value)
      }, 500)
  }

  return (
    <div className="max-w-[400px] w-full h-[38px]">
      <div
        className={`w-full h-full flex justify-between items-center bg-[#161616] px-1 py-2 rounded-3xl overflow-hidden relative`}
      >
        <input
          className="md:min-w-[300px] pl-3 bg-transparent outline-none border-none text-white"
          type="text"
          value={input}
          placeholder='Search'
          onChange={(e) => onChangeHandler(e.target.value)}

        />
        <div className='bg-black p-2 rounded-full cursor-pointer'>
          <img src={SearchIcon} alt="search" className='min-w-[16px] w-16px' />
        </div>
      </div>
    </div>
  )
}
