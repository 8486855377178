import { useEffect, useState } from 'react'
import "./updates-symbioverse-table.sass"
import { getUpdateData } from '../../../../API/APICall'
import moment from "moment"
import { CircularProgress } from '@mui/material'

const Updatessymbioversetable = () => {
    const [updateData, setUpdateData] = useState<any>([])
    const [updateLoader, setUpdateLoader] = useState(true)

    useEffect(() => {
        getAllUpdateData()
    }, [])

    // update data
    const getAllUpdateData = async () => {
        setUpdateLoader(true)
        await getUpdateData()
            .then((res) => {
                setUpdateLoader(false)
                if (res?.status === 200) {
                    setUpdateData(res && res?.data?.values);
                }
            })
            .catch((err) => {
                setUpdateLoader(false)
                console.log(err, "Error")
            })
    }

    return (
        <>
            <div className='update-section mt-lg-5 mt-4'>
                <h4 className='heading-new-4 color-primary'>Updates from Symbioverse</h4>
                <div className='update-table scrolled mt-4'>
                    {
                        updateLoader ?
                            <div className="loader-section">
                                <CircularProgress style={{ color: 'white' }} size={32} />
                            </div>
                            :
                            <>
                                {updateData && updateData.length ? updateData?.map((item: any, i: number) => (
                                    <div className='align-items-center update-table-field' key={i}>
                                        <p className='paragraph-new mb-0 ms-3'>{item?.title || "No title available"}</p>
                                        <div className='update-date-item'>
                                            <span >{item.createdAt ? moment(item.createdAt).format("DD-MMM-YYYY hh:mm A") : "-"}</span>
                                        </div>
                                    </div>
                                )) : <div className='align-items-center update-table-field'>
                                    <p className='paragraph-new mb-0 ms-3 text-center'>No Updates</p>
                                </div>}
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default Updatessymbioversetable