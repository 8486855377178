import { Pagination, PaginationItem } from '@mui/material';
import "./index.sass"

interface ICustomPagination {
  onChange: (page: number) => void;
  total: number;
  page: number;
}

export default function CustomPagination(props: ICustomPagination) {
  const { onChange, total, page } = props

  const isMobileView = window.innerWidth < 756

  return (
    <div className="pagination-container">
      <Pagination
        count={total}
        page={page}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            size={isMobileView ? 'small' : 'medium'}
          />
        )}
        onChange={(e, page) => onChange(page)}
        variant="outlined"
        shape="rounded"
      />
    </div>
  )
}
