
export const symbioteApiBaseUrl = process.env.REACT_APP_SYMBIOTE_API_BASE_URL || ''
// export const symbioteApiBaseUrl = 'http://localhost:3011'

// Accelerate Contact form NODEJS APIs
export const POST_CONTACT_ASSETS_UPDATE_DATA = `${symbioteApiBaseUrl}/api/v1/uploads/projects`
export const POST_PROJECT_SUBMIT_DATA = `${symbioteApiBaseUrl}/api/v1/projects`
export const POST_MENTOR_SUBMIT_DATA = `${symbioteApiBaseUrl}/api/v1/mentor`
export const POST_VC_SUBMIT_DATA = `${symbioteApiBaseUrl}/api/v1/vc`
export const POST_PARTNER_SUBMIT_DATA = `${symbioteApiBaseUrl}/api/v1/partner`
export const GET_PROJECT_GET_USER_DATA = `${symbioteApiBaseUrl}/api/v1/projects/get/me`
export const GET_VIDEO_DATA = `${symbioteApiBaseUrl}/api/v1/video`
export const GET_LINKS_DATA = `${symbioteApiBaseUrl}/api/v1/links`
export const GET_VC_USER_DATA = `${symbioteApiBaseUrl}/api/v1/vc/get/me`
export const GET_MENTOR_USER_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/get/me`
export const GET_PROJECT_PROGRESS_DATA = `${symbioteApiBaseUrl}/api/v1/projects/progress`
export const GET_PROJECT_FEEDBACK_DATA = `${symbioteApiBaseUrl}/api/v1/projects/feedback`
export const GET_PROJECT_MENTOR_NETWORK_DATA = `${symbioteApiBaseUrl}/api/v1/projects/mentors`
export const GET_CATEGORY_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/category`
export const GET_ALL_MENTORS_DATA = `${symbioteApiBaseUrl}/api/v1/mentor`
export const GET_ALL_MENTORS_PROJECT_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/projects`
export const GET_MENTORS_PROJECT_CONNECTION_REQUEST_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/request/pending`
export const POST_REQUEST_MENTOR_TO_CONNECTION = `${symbioteApiBaseUrl}/api/v1/mentor/request/connect`
export const POST_APPROVE_MENTOR_CONNECTION_REQUEST = `${symbioteApiBaseUrl}/api/v1/mentor/request/approve/`
export const POST_REJECT_MENTOR_CONNECTION_REQUEST = `${symbioteApiBaseUrl}/api/v1/mentor/request/reject/`
export const POST_MENTOR_ADD_FEEDBACK = `${symbioteApiBaseUrl}/api/v1/mentor/feedback/project/`

export const GET_PROJECT_PARTNER = `${symbioteApiBaseUrl}/api/v1/projects/partners`
export const GET_PARTNER = `${symbioteApiBaseUrl}/api/v1/partner`
export const POST_REQUEST_PARTNER_TO_CONNECTION = `${symbioteApiBaseUrl}/api/v1/partner/request/connect`
export const POST_REQUEST_VC_TO_CONNECTION = `${symbioteApiBaseUrl}/api/v1/vc/request/connect`
export const GET_PROJECTS_ASSIGNED_VC = `${symbioteApiBaseUrl}/api/v1/projects/vc`
export const GET_ALL_ASSIGNED_VC = `${symbioteApiBaseUrl}/api/v1/vc`
export const GET_LAUNCHNETWORK_CATEGORY_DATA = `${symbioteApiBaseUrl}/api/v1/launchnetwork`
export const GET_LAUNCHNETWORK_ASSIGEND_DATA = `${symbioteApiBaseUrl}/api/v1/projects/launchnetwork`
export const POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION = `${symbioteApiBaseUrl}/api/v1/launchnetwork/request/connect`
export const GET_EVENTS_DATA = `${symbioteApiBaseUrl}/api/v1/events`
export const POST_ADD_EVENT_REMINDER = `${symbioteApiBaseUrl}/api/v1/events/reminder`
export const GET_UPDATES_DATA = `${symbioteApiBaseUrl}/api/v1/update`
export const GET_VIDEO_CATEGORY_DATA = `${symbioteApiBaseUrl}/api/v1/video/category`
export const GET_PARTNER_CATEGORIES_DATA = `${symbioteApiBaseUrl}/api/v1/partner/category`
export const POST_VIDEO_AS_VIEWED = `${symbioteApiBaseUrl}/api/v1/video/mark`

export const GET_VC_PROJECT_CONNECTION_REQUEST_DATA = `${symbioteApiBaseUrl}/api/v1/vc/request/pending`
export const POST_APPROVE_VC_CONNECTION_REQUEST = `${symbioteApiBaseUrl}/api/v1/vc/request/approve/`
export const POST_REJECT_VC_CONNECTION_REQUEST = `${symbioteApiBaseUrl}/api/v1/vc/request/reject/`
export const GET_ALL_VC_PROJECT_DATA = `${symbioteApiBaseUrl}/api/v1/vc/projects`

export const GET_ALL_PROJECT_DISCOUNT_DATA = `${symbioteApiBaseUrl}/api/v1/partner/discount`

// login api
export const POST_PROJRCT_LOGIN_DATA = `${symbioteApiBaseUrl}/api/v1/projects/login`
export const POST_MENTOR_LOGIN_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/login`
export const POST_VC_LOGIN_DATA = `${symbioteApiBaseUrl}/api/v1/vc/login`
export const POST_PARTNER_LOGIN_DATA = `${symbioteApiBaseUrl}/api/v1/partner/login`

// Reset password api
export const POST_PROJRCT_RESET_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/projects/reset`
export const POST_MENTOR_RESET_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/reset`
export const POST_VC_RESET_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/vc/reset`
export const POST_PARTNER_RESET_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/partner/reset`

// Forget password api
export const POST_PROJRCT_FORGOT_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/projects/forgot`
export const POST_MENTOR_FORGOT_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/mentor/forgot`
export const POST_VC_FORGOT_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/vc/forgot`
export const POST_PARTNER_FORGOT_PASSWORD_DATA = `${symbioteApiBaseUrl}/api/v1/partner/forgot`

export const symbioteGraduateContractAddress = '0x0eBed424FF74Cc07D981d6c2F1bB5B881B8399C5'