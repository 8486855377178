import axios from "axios";
import { POST_MENTOR_FORGOT_PASSWORD_DATA, POST_MENTOR_LOGIN_DATA, POST_MENTOR_RESET_PASSWORD_DATA, POST_PARTNER_FORGOT_PASSWORD_DATA, POST_PARTNER_LOGIN_DATA, POST_PARTNER_RESET_PASSWORD_DATA, POST_PROJRCT_FORGOT_PASSWORD_DATA, POST_PROJRCT_LOGIN_DATA, POST_PROJRCT_RESET_PASSWORD_DATA, POST_VC_FORGOT_PASSWORD_DATA, POST_VC_LOGIN_DATA, POST_VC_RESET_PASSWORD_DATA } from "../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization.access_token) {
    return authorization.access_token
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "multipart/form-data"
  };
}
const postBodyHeadersJson = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "application/json"
  };
}

const postProjectLoginURL = POST_PROJRCT_LOGIN_DATA
const postMentorLoginURL = POST_MENTOR_LOGIN_DATA
const postVCLoginURL = POST_VC_LOGIN_DATA
const postPartnerLoginURL = POST_PARTNER_LOGIN_DATA

const postProjectResetPasswordURL = POST_PROJRCT_RESET_PASSWORD_DATA
const postMentorResetPasswordURL = POST_MENTOR_RESET_PASSWORD_DATA
const postVCResetPasswordURL = POST_VC_RESET_PASSWORD_DATA
const postPartnerResetPasswordURL = POST_PARTNER_RESET_PASSWORD_DATA

const postProjectForgotPasswordURL = POST_PROJRCT_FORGOT_PASSWORD_DATA
const postMentorForgotPasswordURL = POST_MENTOR_FORGOT_PASSWORD_DATA
const postVCForgotPasswordURL = POST_VC_FORGOT_PASSWORD_DATA
const postPartnerForgotPasswordURL = POST_PARTNER_FORGOT_PASSWORD_DATA

const postLoginData = async (email: string, password: string, loginTo: string) => {
  const bodyParams = {
    "email": email,
    "password": password,
  }
  let apiURL = "";
  if (loginTo === "project") {
    apiURL = postProjectLoginURL;
  } else if (loginTo === "mentor") {
    apiURL = postMentorLoginURL;
  } else if (loginTo === "vc") {
    apiURL = postVCLoginURL;
  } else {
    apiURL = postPartnerLoginURL;
  }

  const res: any = await axios.post(apiURL, bodyParams)
  console.log('postLoginData', res);
  return res;
};

const postResetOrForgotPasswordData = async (token: string, password: string, loginTo: string) => {
  const bodyParams = {
    "token": token,
    "password": password,
  }
  console.log("bodyParams", bodyParams)
  let apiURL = "";

  if (loginTo === "project") {
    apiURL = postProjectResetPasswordURL;
  } else if (loginTo === "mentor") {
    apiURL = postMentorResetPasswordURL;
  } else if (loginTo === "vc") {
    apiURL = postVCResetPasswordURL;
  } else {
    apiURL = postPartnerResetPasswordURL;
  }

  const res: any = await axios.post(apiURL, bodyParams)
  console.log('postResetOrForgotPasswordData', res);
  return res;
};

const postForotPasswordData = async (email: string, loginTo: string) => {
  const bodyParams = {
    "email": email,
  }
  console.log("bodyParams", bodyParams)
  let apiURL = "";

  if (loginTo === "project") {
    apiURL = postProjectForgotPasswordURL;
  } else if (loginTo === "mentor") {
    apiURL = postMentorForgotPasswordURL;
  } else if (loginTo === "vc") {
    apiURL = postVCForgotPasswordURL;
  } else {
    apiURL = postPartnerForgotPasswordURL;
  }

  const res: any = await axios.post(apiURL, bodyParams, {})
  console.log('postForotPasswordData', res);
  return res;
};

export {
  postLoginData,
  postResetOrForgotPasswordData,
  postForotPasswordData
};