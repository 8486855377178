import React, { useEffect, useRef, useState } from 'react'
import "./vc-level-table.sass"
import tableIcon from "../../../../../../../assests/images/homepage/table-icon.svg"
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model'

const cardData = [
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },
  {
    name: "Partner X",
    image: tableIcon,
    category: "Gaming",
  },

]

export interface TableData {
  vCData: any,
  setRefreshData: any
  refreshData: boolean
}

const VcLevelTable = (props: TableData) => {
  const { vCData, setRefreshData, refreshData } = props
  const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
  const [selectedUserData, setSelectedUserData] = useState<any>()
  const connectionRequestModalToggle = () => setConnectionRequestModal(!connectionRequestModal)
  const [height, setHeight] = useState(0)
  const ref = useRef<any>(null)

  useEffect(() => {
    if (vCData && ref?.current?.clientHeight) {
      setHeight(ref?.current?.clientHeight)
    }
  }, [ref, vCData])

  return (
    <>
      <div className={height > 1300 ? 'list-table-section scrolled' : 'list-table-section'}>
        <div className="row">
          {vCData && vCData?.map((item: any, i: any) => {

            const twitterLink = item?.twitterLink ? `https://twitter.com/${item?.twitterLink}` : ''
            const telegramLink = item?.telegramId ? `https://t.me/${item?.telegramId}` : ''
            const websiteLink = item?.companyWebsiteLink || ''
            const linkedinLink = item?.linkedInProfileLink || ''
            return (
              <div className="col-lg-3 col-md-4 col-6 mb-3" key={i}>
                <div className='vc-list-card text-center'>
                  <img className="m-auto" src={item?.icon || tableIcon} alt="" />
                  <h5 className='heading-new-5 mt-lg-3 mt-md-3 mt-2 vc-name'>{item?.name || "No name available"}</h5>
                  <p className='mb-0 paragraph-new-small mt-lg-3 mt-md-3 mt-2 vc-areas'>{item?.sectorsLikeToInvest && item?.sectorsLikeToInvest.join(', ') || "-"}</p>
                  <ul className='social-link-table mt-lg-3 mt-md-3 mt-2'>
                    {twitterLink && <li><a href={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                    {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                    {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                    {linkedinLink && <li><a href={linkedinLink} target='_blank'><i className="ri-linkedin-fill"></i></a></li>}
                    {/* <li>
                      <a href="#"><i className="ri-twitter-fill"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="ri-links-line"></i></a>
                    </li>
                    <li>
                      <a href="#"><i className="ri-send-plane-fill"></i></a>
                    </li> */}
                  </ul>
                  <div className='text-center'>
                    {item?.requestStatus === "none" &&
                      <button className='request-to-onnect-btn mt-3 request-btn' onClick={() => { setSelectedUserData(item); connectionRequestModalToggle() }}>Request to Connect</button>
                    }
                    {item?.requestStatus === "requested" &&
                      <button className='request-to-onnect-btn mt-3 yellow-border'>Requested</button>
                    }
                    {item?.requestStatus === "rejected" &&
                      <button className='request-to-onnect-btn mt-3 red-border'>Not interested</button>
                    }
                    {item?.requestStatus === "connected" &&
                      <button className='request-to-onnect-btn mt-3 green-border'>Connected</button>
                    }

                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <ConnectionRequestModel
        isOpen={connectionRequestModal}
        toggle={connectionRequestModalToggle}
        name={selectedUserData?.name}
        id={selectedUserData?._id}
        icon={selectedUserData?.icon}
        userType={"vc"}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
    </>
  )
}

export default VcLevelTable