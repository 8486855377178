import axios from "axios";
import { symbioteApiBaseUrl } from "src/constants";
import { requestBodyHeaders } from "src/constants/utils";

export const getAllServiceRequestAPI = (status?: 'ongoing' | 'pending' | 'completed' | 'unresolved'): Promise<{ values: any[], total: number }> => {
  return new Promise((resolve, reject) => {

    let queryParams = {}
    if (status) {
      queryParams = { ...queryParams, status }
    }
    axios.get(symbioteApiBaseUrl + "/api/v1/request", { headers: requestBodyHeaders(), params: queryParams }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

interface ICreateRequest {
  type: string;
  title: string;
  details: string;
  attachments: string[]
}

export const createServiceRequestAPI = (reqBody: ICreateRequest): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.post(symbioteApiBaseUrl + "/api/v1/request", reqBody, { headers: requestBodyHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};