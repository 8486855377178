import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='py-10 container'>
      <div className='project-stats-card relative rounded-2xl text-left md:px-8 pt-8 pb-12'>
        <div className='absolute -z-10 w-full h-full -bottom-1 -right-1 rounded-2xl overflow-hidden'>
          <img src="/img/home/stats-bg.jpg" alt="" className='object-cover w-full h-full' />
        </div>
        <div className='md:px-8 md:h-[240px] flex items-center'>
          <div className='w-full flex flex-col md:flex-row justify-between items-center gap-6 md:gap-0'>
            <div className='flex gap-2 h-[82px] overflow-hidden'>
              <img src="/img/home/symbiote-logo.svg" alt="" className='h-[70px] md:h-full' />
              <div className='grid'>
                <span className='text-4xl md:text-[56px] font-semibold leading-[56px] bg-gradient-to-r from-[#2FBAFF] to-[#2479DF] inline-block text-transparent bg-clip-text'>Symbiote</span>
                <span className='text-xs font-bold -mt-4 md:mt-1'>SUPERCHARGING WEB3 STARTUPS</span>
              </div>
            </div>
            <div className='flex flex-col gap-2 md:gap-3'>
              <Link to="/blogs" className='text-white no-underline hover-primary'>Blogs</Link>
              <Link to="/events" className='text-white no-underline hover-primary'>Events</Link>
              <a className='text-white no-underline hover-primary' href="mailto:contact@symbiote.gg">Contact Us</a>
              <Link to="/login" className='text-white no-underline hover-primary'>Login</Link>
            </div>
            <div className='flex justify-between overflow-hidden w-[160px] h-[30px] mr-4 mt-4 md:mt-0'>
              <a href="https://x.com/SymbioteSync" target="_blank"><img src="/img/social/twitter-logo.svg" alt="" className='h-full' /></a>
              <a href="https://t.me/+iWT-OhLgyOc4ODVl" target="_blank"><img src="/img/social/telegram-logo.svg" alt="" className='h-full'/></a>
              <a href="mailto:contact@symbiote.gg" target="_blank"><img src="/img/social/mail-logo.svg" alt="" className='h-full'/></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
