import React, { useEffect, useState } from 'react'
import "./project-dashboard.sass"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Dashboard from './components/dashboard/dashboard';
import Progress from './components/progress/progress';
import Mentornetwork from './components/mentor-network/mentor-network';
import Vcnetwork from './components/vc-network/vc-network';
import PartnersNetwork from './components/partner-network/partner-network';
import Launchnetwork from './components/launch-network/launch-network';
import StudyPrograms from './components/study-programs/study-programs';
import Partnerdiscounts from './components/partner-discounts/partner-discounts';
import Tools from './components/tools/tools';
import CalendarEvents from './components/calendar-events/calendar-events';
import { DashbordTopSection } from './components/dashbord-top-section/dashbord-top-section';
import { getProjectsUserData } from './API/APICall';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import JobBoard from './components/job-board/JobBoard';
import MintNFT from './components/mint-nft/MintNFT';
import ServiceRequestsMain from './components/service-requests';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Projectdashboard = () => {
    const [value, setValue] = useState<any>(null);
    const [mobileResponsiveMenu, setMobileResponsiveMenu] = useState(false);
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const [projectUserData, setProjectUserData] = useState<any>();

    const nav = useNavigate();

    useEffect(() => {
        if (splitLocation) {
            setValue(
                splitLocation[2] === "dashboard" ? 0 :
                    splitLocation[2] === "progress" ? 1 :
                        splitLocation[2] === "mentor-network" ? 2 :
                            splitLocation[2] === "partner-network" ? 3 :
                                splitLocation[2] === "vc-network" ? 4 :
                                    splitLocation[2] === "launch-network" ? 5 :
                                        splitLocation[2] === "events" ? 6 :
                                            splitLocation[2] === "resources" ? 7 :
                                                splitLocation[2] === "partner-discounts" ? 8 :
                                                    splitLocation[2] === "requests" ? 9 :
                                                        splitLocation[2] === "mint" ? 10 : 0
            );
        }
    }, [splitLocation])

    const getProjectsUserAllData = async () => {
        await getProjectsUserData()
            .then((res) => {
                if (res?.status === 200) {
                    setProjectUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }
    useEffect(() => {
        getProjectsUserAllData()
    }, [])

    const onClickTabSelection = (path: string) => {
        nav(path)
        const elm = document.getElementById('scroll-start-element')
        if (elm) {
            elm.scrollIntoView()
        }
    }

    return (
        <>
            <div className="dashbord-main-page">
                <div className='position-relative'>
                    <DashbordTopSection
                        userData={projectUserData}
                    />
                    <div className='container container-maxwidth profil-text'>
                        <div className='dashboard-tabs-section with-mobile-view' id="scroll-start-element">
                            <div className='profile-name mobile-view mb-lg-0 mb-3 '>
                                <div className='d-flex align-items-center'>
                                    <div>
                                        <button className='new-color-button' onClick={() => nav("/forms/project-view/profile")}>Your Profile</button>
                                    </div>
                                    <div className="">
                                        <div className="">
                                            <div className='percentage-slider text-center m-auto d-flex'>
                                                <CircularProgressbar value={projectUserData?.profileCompleted} text={`${Number(projectUserData?.profileCompleted).toFixed(0)}%`} background
                                                    backgroundPadding={0}
                                                    styles={buildStyles({
                                                        backgroundColor: "transparent",
                                                        textColor: "#fff",
                                                        pathColor: "#FFB800",
                                                        trailColor: "white"
                                                    })} />
                                                <p className='paragraph-new-small my-4 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mobile-tab-btn">
                                    <div className='mobile-icon' role='button' onClick={() => { setMobileResponsiveMenu(true) }}>
                                        <i className="ri-menu-3-line"></i>
                                    </div>
                                </div>
                            </div>
                            <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                <div className={`mobile-responsive-tab ${mobileResponsiveMenu ? 'active' : ''}`}>
                                    <div className='profile-name overflow-hidden'>
                                        <h3 className='drawer-company-name heading-new-3 color-white-new text-start fw-light first-letter-capital'>{projectUserData && projectUserData?.companyName || "-"}</h3>
                                        <div className='d-flex align-items-center'>
                                            <div>
                                                <button className='new-color-button' onClick={() => nav("/forms/project-view/profile")}>Your Profile</button>
                                            </div>
                                            <div className="">
                                                <div className="">
                                                    <div className='percentage-slider text-center m-auto d-flex'>
                                                        <CircularProgressbar value={projectUserData?.profileCompleted} text={`${Number(projectUserData?.profileCompleted).toFixed(0)}%`} background
                                                            backgroundPadding={0}
                                                            styles={buildStyles({
                                                                backgroundColor: "transparent",
                                                                textColor: "#fff",
                                                                pathColor: "#FFB800",
                                                                trailColor: "white"
                                                            })} />
                                                        <p className='paragraph-new-small my-4 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-close-icon" role='button' onClick={() => { setMobileResponsiveMenu(false) }}>
                                        <i className="ri-close-line"></i>
                                    </div>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        className='drawer-tab-container'
                                        // onChange={handleChange}
                                        onClick={() => { setMobileResponsiveMenu(false) }}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab className='tab-items' label="Dashboard" {...a11yProps(0)} onClick={() => onClickTabSelection('/project/dashboard')} />
                                        <Tab className='tab-items' label="Progress" {...a11yProps(1)} onClick={() => onClickTabSelection('/project/progress')} />
                                        <Tab className='tab-items' label="Mentor Network" {...a11yProps(2)} onClick={() => onClickTabSelection('/project/mentor-network')} />
                                        <Tab className='tab-items' label="Partner Network" {...a11yProps(3)} onClick={() => onClickTabSelection('/project/partner-network')} />
                                        <Tab className='tab-items' label="VC Network" {...a11yProps(4)} onClick={() => onClickTabSelection('/project/vc-network')} />
                                        <Tab className='tab-items' label="Launch Network" {...a11yProps(5)} onClick={() => onClickTabSelection('/project/launch-network')} />
                                        <Tab className='tab-items' label="Events" {...a11yProps(6)} onClick={() => onClickTabSelection('/project/events')} />
                                        <Tab className='tab-items' label="Resources" {...a11yProps(7)} onClick={() => onClickTabSelection('/project/resources')} />
                                        <Tab className='tab-items' label="Partner Discounts" {...a11yProps(8)} onClick={() => onClickTabSelection('/project/partner-discounts')} />
                                        {/* <Tab className='tab-items' label="Job Board" {...a11yProps(9)} onClick={() => onClickTabSelection('/project/job-board')} /> */}
                                        <Tab className='tab-items' label="Service Requests" {...a11yProps(9)} onClick={() => onClickTabSelection('/project/requests')} />
                                        <Tab className='tab-items' label="Mint Your NFT" {...a11yProps(10)} onClick={() => onClickTabSelection('/project/mint')} />
                                        {/* <Tab label="Tools" {...a11yProps(9)} onClick={() => onClickTabSelection('/project/tools')} /> */}
                                    </Tabs>
                                </div>
                                <div className="main-tab-panel-container container mb-lg-0 mb-4">
                                    <div className="">
                                        <TabPanel value={value} index={0}>
                                            <Dashboard value={value} setValue={setValue} projectUserData={projectUserData && projectUserData} />
                                        </TabPanel>

                                        <TabPanel value={value} index={1}>
                                            <Progress value={value} projectUserData={projectUserData && projectUserData} />
                                        </TabPanel>

                                        <TabPanel value={value} index={2}>
                                            <Mentornetwork />
                                        </TabPanel>

                                        <TabPanel value={value} index={3}>
                                            <PartnersNetwork value={value} />
                                        </TabPanel>

                                        <TabPanel value={value} index={4}>
                                            <Vcnetwork value={value} />
                                        </TabPanel>

                                        <TabPanel value={value} index={5}>
                                            <Launchnetwork />
                                        </TabPanel>

                                        <TabPanel value={value} index={6}>
                                            <CalendarEvents />
                                        </TabPanel>

                                        <TabPanel value={value} index={7}>
                                            <StudyPrograms />
                                        </TabPanel>

                                        <TabPanel value={value} index={8}>
                                            <Partnerdiscounts value={value} />
                                        </TabPanel>

                                        <TabPanel value={value} index={9}>
                                            <ServiceRequestsMain />
                                        </TabPanel>
                                        <TabPanel value={value} index={10}>
                                            <MintNFT />
                                        </TabPanel>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projectdashboard