import React, { useEffect, useState } from 'react'
import "./home.sass"
import accelerate from '../../assests/images/accelerate/accelerate-name-image.svg';
import comprehensiveIcon from '../../assests/images/accelerate/comprehensive-icon.svg';
import investIcon from '../../assests/images/accelerate/invest-icon.svg';
import mentorIcon from '../../assests/images/accelerate/mentor-icon.svg';
import Raised from "../../assests/images/homepage/raised.svg"
import ProjectLaunch from "../../assests/images/homepage/project-launch.svg"
import Partners from "../../assests/images/homepage/partners.svg"
import GlobalCommunity from "../../assests/images/homepage/global-community.svg"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import exelerateProjectImg from '../../assests/images/homepage/exelerate-project-img.svg';
import partnerImg from '../../assests/images/homepage/partner-img.svg';
import { useNavigate } from 'react-router-dom';
import { ProgressRangeSlider } from '../accelerate-pages/project-dashboard/components/dashboard/components/progress-slider/progress-slider';
import { ComprehensiveOfferings } from './components/comprehensive-offerings/comprehensive-offerings';
import CountUp from 'react-countup';
import { loggedInAs } from 'src/constants/utils';
import NewHome from './NewHome';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component='div'>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Home = () => {
    const [value, setValue] = useState(0);
    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        const { project, mentor, vc, partner } = loggedInAs()
        if (project) {
            navigate("/project/dashboard")
        } else if (mentor) {
            navigate("/mentor-dashboard")
        } else if (vc) {
            navigate("/vc-dashboard")
        } else if (partner) {
            navigate("/partner-dashboard")
        }
    }, [])

    return <NewHome />

    return (
        <>
            <div>
                <div className='accelerate-hero-section left-part-background position-relative'>
                    <div className='container position-relative'>
                        <div className='d-flex justify-content-center mt-lg-3 mt-3'>
                            <img src={accelerate} className='img-fluid accelerate-main-img' alt="" />
                        </div>
                        <div className='help-text'>
                            <p className='paragraph-new fw-lighter mb-0 mt-lg-4 mt-md-4 mt-3 fs-12'>Supercharging Web3 startups for breakthrough success</p>
                        </div>
                        <div className='program-button mt-lg-4 mt-md-3 mt-3'>
                            <button className='new-color-button' onClick={() => { navigate(`/contact-form/project-ahead`) }}>Apply for the Program</button>
                        </div>
                        <div className="row mt-lg-5 mt-md-3 mt-2">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="counter-card">
                                            <div className="counter-img">
                                                <img src={ProjectLaunch} className='img-fluid' alt="" />
                                            </div>
                                            <h2 className='heading-new-2'><CountUp className='fw-bold' start={10} end={50} duration={5} />+</h2>
                                            <p className='paragraph-new-medium'>Project Launches</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="counter-card">
                                            <div className="counter-img">
                                                <img src={Raised} className='img-fluid' alt="" />
                                            </div>
                                            <h2 className='heading-new-2'>$<CountUp className='fw-bold' start={100} end={250} duration={5} />M+</h2>
                                            <p className='paragraph-new-medium'>Access to Capital</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="counter-card">
                                            <div className="counter-img">
                                                <img src={Partners} className='img-fluid' alt="" />
                                            </div>
                                            <h2 className='heading-new-2'><CountUp className='fw-bold' start={200} end={450} duration={5} />+</h2>
                                            <p className='paragraph-new-medium'>Partners</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <div className="counter-card">
                                            <div className="counter-img">
                                                <img src={GlobalCommunity} className='img-fluid' alt="" />
                                            </div>
                                            <h2 className='heading-new-2'><CountUp className='fw-bold' start={20} end={110} duration={5} />k+</h2>
                                            <p className='paragraph-new-medium'>Global Community</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className='blue-line' />
                    <span className='blue-line blue-line-2' />
                    <span className='blue-line blue-line-3' />
                    <span className='blue-line blue-line-4' />
                    <span className='blue-line blue-line-5' />
                    <span className='blue-line blue-line-6' />
                    <span className='blue-line blue-line-7' />
                    <span className='blue-line blue-line-8' />
                    <span className='blue-line blue-line-9' />
                    <span className='blue-line blue-line-10' />
                </div>

                <div className='accelerate-program-section position-relative padding-top-80'>
                    <div className="container">
                        <h5 className='heading-new-5 fw-normal text-center'>Understand the <span className='heading-new-5 fw-700'>Accelerate </span> program</h5>
                        <div className="projectDetails-top background-primary mt-lg-5 mt-md-3 mt-3">
                            <div className="projectDetails">
                                <Box sx={{ flexGrow: 1, display: 'flex' }}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="What do you get by joining us?" {...a11yProps(0)} />
                                        <Tab label="Where will the program be run?" {...a11yProps(1)} />
                                        <Tab label="Why are we the best fit for you?" {...a11yProps(2)} />
                                        <Tab label="What happens after the program?" {...a11yProps(3)} />
                                    </Tabs>
                                    <div className='tab-data-alignment'>
                                        <TabPanel value={value} index={0}>
                                            <p className='paragraph-new mb-0'>If you are looking to turn your game-changing ideas into a viable business, we’ll provide you:</p>
                                            <ul>
                                                <li className='paragraph-new'>3-month fully remote accelerator program.</li>
                                                <li className='paragraph-new'>Operational, legal, commercial, marketing, and tech support.</li>
                                                <li className='paragraph-new'>Token engineering support and business model testing to pinpoint product-market fit.</li>
                                                <li className='paragraph-new'>50+ mentors and active VC network featuring known names in Web3.</li>
                                                <li className='paragraph-new'>Access to one of the largest web3 ecosystems operating since 2016.</li>
                                            </ul>
                                        </TabPanel>
                                    </div>
                                    <div className='tab-data-alignment'>
                                        <TabPanel value={value} index={1}>
                                            <p className='paragraph-new mb-0'>We are currently planning to be a fully remote and virtual program. There may be the opportunity for in-person events in major cities. We have so far incubated companies from all over the globe.</p>
                                        </TabPanel>
                                    </div>
                                    <div className='tab-data-alignment'>
                                        <TabPanel value={value} index={2}>
                                            <p className='paragraph-new mb-0'>Symbiote Accelerate is designed to support decentralized projects to design, build, and scale at every stage of their development.</p>
                                            <p className='paragraph-new mb-0'>We provide support via relationships, introductions, and access to specialists from across the venture platform. We have incubated and supported leading Web 3.0 teams and can bring a powerhouse of support to ventures building Web 3.0 infrastructure.</p>
                                        </TabPanel>
                                    </div>
                                    <div className='tab-data-alignment'>
                                        <TabPanel value={value} index={3}>
                                            <p className='paragraph-new mb-0'>Once we help you with your public launch and listing, expect to establish a long-term relationship with our founders and team. We will be there at every step of your success and continue providing value.</p>
                                        </TabPanel>
                                    </div>
                                </Box>
                            </div>

                        </div>
                    </div>
                </div >

                <div className='plunge-section padding-top-80 right-part-background' >
                    <div className="container position-relative">
                        <h2 className='heading-new-2 text-center'>Ready to take the plunge?</h2>
                        <button className='new-anim-btn my-lg-5 my-md-3 my-2' onClick={() => { navigate(`/contact-form/project-ahead`) }}>Get Accelerated
                            <span className='anim-span'></span>
                            <span className='anim-span anim-2'></span>
                            <span className='anim-span anim-3'></span>
                            <span className='anim-span anim-4'></span>
                            <span className='anim-span anim-5'></span>
                        </button>
                        <ProgressRangeSlider tabValue={undefined} setValue={undefined} symbioteScalePostion={undefined} />
                    </div>
                </div>

                <div className="">
                    <ComprehensiveOfferings />
                </div>

                <div className='sounds-good-section mt-lg-5 mt-md-3 mt-2 right-part-background'>
                    <div className="container position-relative">
                        {/* <h2 className='heading-new-2 mb-lg-5 mb-4'>Sounds good? Then see you soon!</h2> */}
                        <h2 className='heading-new-2 mb-lg-5 mb-4'>Ready To Get Accelerated? Join Our Ecosystem Today!</h2>
                        <div className="row justify-content-center mt-lg-3 mt-md-2 mt-2">
                            <div className="col-lg-3 col-md-4 mb-lg-0 mb-md-0 mb-3 text-center">
                                <img src={accelerate} className='img-fluid mb-4' alt="" />
                                <div className="service-card-border" role='button' onClick={() => { navigate(`/contact-form/project-ahead`) }} >
                                    <div className="service-card-inner">
                                        <div className="service-card mb-0 left-card">
                                            <div className="card-img m-auto">
                                                <img src={exelerateProjectImg} className="img-fluid m-auto" alt="" />
                                            </div>
                                            <h4 className="heading-new-4 mt-4 mb-0">Take your<br />project ahead</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <p className='heading-new-5 mb-3'>Support | Be a part of the Symbiote Ecosystem</p>
                                <div className="service-card-border">
                                    <div className="service-card-inner">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6">
                                                <div className="service-card" role='button' onClick={() => { navigate(`/contact-form/become-partner`) }}>
                                                    <div className="card-img">
                                                        <img src={partnerImg} className="img-fluid m-auto" alt="" />
                                                    </div>
                                                    <h4 className="heading-new-4 mt-4 mb-0">Become<br />a Partner</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6" role='button' onClick={() => { navigate(`/contact-form/invest`) }}>
                                                <div className="service-card">
                                                    <div className="card-img">
                                                        <img src={investIcon} className="img-fluid m-auto" alt="" />
                                                    </div>
                                                    <h4 className="heading-new-4 mt-4 mb-0">Invest<br />with us</h4>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6" role='button' onClick={() => { navigate(`/contact-form/mentor-projects`) }}>
                                                <div className="service-card mb-0">
                                                    <div className="card-img">
                                                        <img src={mentorIcon} className="img-fluid m-auto" alt="" />
                                                    </div>
                                                    <h4 className="heading-new-4 mt-4 mb-0">Mentor<br />our Projects</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Home