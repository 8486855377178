import React, { useState } from 'react'
import "./connection-request-model.sass";
import "../../../calendar-events/event-remider-modal/event-remider-modal.sass";
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import ConnectionuccessModel from '../connection-success-model/connection-success-model';
import { postRequestPartnerToConnectData } from '../../API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';


interface detailsProps {
    isOpen: boolean,
    toggle: () => void,
    name: string,
    id: string,
    icon: string,
    userType: string,
    setRefreshData?: any
    refreshData?: boolean
}

const ConnectionRequestModel = ({ isOpen, toggle, name, id, icon, userType, setRefreshData, refreshData }: detailsProps) => {
    const [connectionuccessModel, setConnectionuccessModel] = useState<boolean>(false)
    const [messageValue, setMessageValue] = useState<any>()
    const connectionuccessModelToggle = () => setConnectionuccessModel(!connectionuccessModel)

    const postAllRequestPartnerToConnectData = async (userType: string, userId: any, message: string) => {
        try {
            return await postRequestPartnerToConnectData(userType, userId, message)
                .then((res) => {
                    if (res?.status === 200) {
                        toast.success(res?.data?.message)
                        connectionuccessModelToggle(); toggle()
                        setRefreshData(!refreshData)
                        return res
                    } else {
                        toast.success(res?.data?.message)
                    }
                    return {};
                })
                .catch((err: any) => {
                    toast.error(err?.response?.data?.message)
                    return err
                })
        }
        catch (err: any) {
            toast.error(err?.response?.data?.message)
            return err;
        }
    };

    return (
        <>
            <Modal className='connection-request-model' centered fade={false} isOpen={isOpen} toggle={toggle}>
                <div className="modal-wrapper">
                    <ModalHeader toggle={toggle} className='justify-content-center'>
                        <div className="close-btn" onClick={toggle}>
                            <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className='connection-request-details'>
                        <h3 className='heading-new-3 mb-lg-4 mb-3 text-center color-white-new'>Connection Request</h3>
                        <div className="partner-details d-flex justify-content-center align-items-center gap-3 mb-3">
                            <img src={icon || NoImage} className='img-fluid' alt="" />
                            <h5 className='heading-new-5 fw-lighter'>{name || "No name available"}</h5>
                        </div>
                        <div className="paragraph-new mb-0 text-center fw-lighter">Write Message</div>
                        <div className="mt-lg-4 mt-3 connection-request-textarea">
                            <textarea className="form-control" rows={6} onChange={(e: any) => { setMessageValue(e?.target?.value) }}></textarea>
                        </div>
                        <div className="text-center mt-lg-4 mt-3">
                            <button className='new-primary-button' onClick={() => { postAllRequestPartnerToConnectData(userType, id, messageValue) }}>Connect</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ConnectionuccessModel connectionuccessModelOpen={connectionuccessModel} connectionuccessModelToggle={connectionuccessModelToggle} />
        </>
    );
}

export default ConnectionRequestModel