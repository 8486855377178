import React, { useState } from 'react';
import './profile-form.sass'
import { useLocation } from 'react-router-dom';
import { UserUpdateForm } from '../../../user-update-form/user-update-form';

const ProfileForm = () => {

    return (
        <>
            <div className="projectDetails-top profile-view-tabs background-primary mt-lg-5 mt-md-3 mt-3">
                <div className="projectDetails">
                    <UserUpdateForm />
                </div>
            </div>
        </>
    )
}


export default ProfileForm
