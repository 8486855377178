import { useEffect } from 'react'
import centerBall from "./center-ball.png"
import hexagon from "./hexagon.svg"
import "./index.scss"

function JobAnimation() {

  useEffect(() => {
    const rippleContainer = document.getElementById('job-ripple-container') as any
    let isSlowMo = false
    let fastInterval = null as any
    let slowInterval = null as any
    let isSlowIntervalStarted = false
    function createRipple() {
      const ripple = document.createElement('div');
      ripple.classList.add('ripple');

      ripple.style.animation = `ripple-animation ${isSlowMo ? '6' : '2'}s linear`
      if (rippleContainer) {
        rippleContainer.appendChild(ripple);
      }

      ripple.addEventListener('animationend', () => {
        ripple.remove();
      });
      if (isSlowMo && !isSlowIntervalStarted) {
        clearInterval(fastInterval)
        isSlowIntervalStarted = true
        slowInterval = setInterval(createRipple, 1100)
      }
    }

    fastInterval = setInterval(createRipple, 300);
    setTimeout(() => { isSlowMo = true }, 1200)
    return () => {
      clearInterval(fastInterval)
      clearInterval(slowInterval)
    }
  }, [])

  return (
    <div className='job-board-animation-container'>

      <h1 className="heading">Job Board</h1>

      <div id="job-ripple-container" />
      <img className='center-ball-container' src={centerBall} />
      <img className='hexagon one' src={hexagon} />
      <img className='hexagon two' src={hexagon} />
      <img className='hexagon three' src={hexagon} />
      <img className='hexagon four' src={hexagon} />
      <img className='hexagon five' src={hexagon} />
      <img className='hexagon six' src={hexagon} />

      <div className='subscribe-item'>
        <input />
        <button className='new-color-button apply-button' onClick={() => { }}>Subscribe</button>
      </div>

    </div>
  )
}

export default JobAnimation