import { useState } from 'react'
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model'
import imgPlaceholder from '../../../../../../../assests/images/image-placeholder.png';
import "./index.scss"

export interface VcFeatureCardData {
  _id: any,
  icon: string,
  name: string,
  stageOfInvests: string,
  sectorsToInvest: string,
  linkedInLink: string,
  twitterLink: string,
  telegramId: string,
  companyWebsiteLink: string,
  requestStatus: string,
  setRefreshData: any
  refreshData: boolean
}

export default function VcFeatureCard(props: VcFeatureCardData) {

  const { _id, icon, name, stageOfInvests, sectorsToInvest, linkedInLink, twitterLink, telegramId, companyWebsiteLink, requestStatus, setRefreshData, refreshData } = props

  const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
  const [selectedPartnerData, setSelectedPartnerData] = useState<any>()

  const connectionRequestModalToggle = () => {
    setConnectionRequestModal(!connectionRequestModal)
  }

  const twitterLinkid = twitterLink ? `https://twitter.com/${twitterLink}` : ''
  const telegramLink = telegramId ? `https://t.me/${telegramId}` : ''
  const websiteLink = companyWebsiteLink || ''
  const linkedinLinkId = linkedInLink || ''

  return (
    <div className='vc-featured-card'>
      <div className="w-[320px] md:w-[340px] pb-4 py-4 px-1 box-border bg-[#161616] flex flex-col gap-4 rounded-xl overflow-hidden text-white">

        <div className="flex justify-between items-start">
          <div className="w-1/2 flex justify-center">
            <img
              src={icon || imgPlaceholder}
              alt=""
              className='rounded-full w-[80px] h-[80px]'
            />

          </div>
          <div className="w-1/2">
            <span className="line-clamp-1 text-lg font-semibold" title={name}>{name}</span>
            <p className="line-clamp-2 text-sm mt-2" title={sectorsToInvest}>{sectorsToInvest}</p>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <ul className='w-1/2 flex gap-3 items-center justify-center m-0 p-0'>
            {twitterLinkid && <li><a href={twitterLinkid} target='_blank' className='flex items-center justify-center min-w-[24px] no-underline bg-primary text-black rounded-full'>
              <i className="ri-twitter-fill"></i>
            </a></li>}
            {websiteLink && <li><a href={websiteLink} target="_blank" className='flex items-center justify-center min-w-[24px] no-underline bg-primary text-black rounded-full'>
              <i className="ri-links-line"></i>
            </a></li>}
            {telegramLink && <li><a href={telegramLink} target="_blank" className='flex items-center justify-center min-w-[24px] no-underline bg-primary text-black rounded-full'>
              <i className="ri-send-plane-fill"></i>
            </a></li>}
            {linkedinLinkId && <li><a href={linkedinLinkId} target='_blank' className='flex items-center justify-center min-w-[24px] no-underline bg-primary text-black rounded-full'>
              <i className="ri-linkedin-fill"></i>
            </a></li>}
          </ul>
          <div className='w-1/2 border-l-2 border-primary'>
            <p className='text-base m-0 pl-3 uppercase font-semibold'>{stageOfInvests}</p>
          </div>
        </div>

        <div className='flex justify-center'>
          {requestStatus === "none" &&
            <button className="border-1 border-primary rounded-2xl py-1 px-4 font-semibold" onClick={() => { setSelectedPartnerData(props); connectionRequestModalToggle() }}>Connect To Vc</button>
          }
          {requestStatus === "requested" &&
            <button className="border-1 border-[#FFB800] text-[#FFB800] rounded-2xl py-1 px-4 font-semibold">Requested</button>
          }
          {requestStatus === "rejected" &&
            <button className="border-1 border-[#FF0000] text-[#FF0000] rounded-2xl py-1 px-4 font-semibold">Not interested</button>
          }
          {requestStatus === "connected" &&
            <button className="border-1 border-primary bg-primary rounded-2xl py-1 px-4 font-semibold">Connected</button>
          }

        </div>

      </div>
      <ConnectionRequestModel
        isOpen={connectionRequestModal}
        toggle={connectionRequestModalToggle}
        name={name}
        id={_id}
        icon={icon}
        userType={'vc'}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
    </div>
  )
}
