import React, { useEffect, useLayoutEffect, useRef } from 'react'
import gsap from "gsap"

export default function SectionHeading({ heading }: { heading: string }) {

  const elementRef = useRef(null)

  useLayoutEffect(() => {
    gsap.from(elementRef.current, {
      scrollTrigger: {
        trigger: elementRef.current,
        start: 'top 80%',
        end: 'bottom 80%',
        markers: false,
        scrub: false,
        toggleActions: "play none reverse none",
      },
      x: -100,
      opacity: 0,
      duration: 1
    })
  }, [])

  return (
    <div ref={elementRef} className='w-fit'>
      <h3 className='bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-2xl md:text-3xl'>{heading}</h3>
      <div className='bg-gradient-to-r from-[#000000] to-[#2479DF] h-[2px] w-[110%] mx-auto' />
    </div>
  )
}
