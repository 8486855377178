import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { fileUploadAPI } from 'src/apis/common';
import uploadIcon from "src/assests/icons/upload-icon.svg"
import LoaderLayout from 'src/shared/layouts/loader-layout/LoaderLayout';
import { createServiceRequestAPI } from '../api';

const requestTypes = [
  "Select...",
  "Partnership Requests",
  "Marketing and Promotion",
  "Technical Support and Development",
  "Investor Introductions",
  "Legal and Compliance",
  "Community Building and Engagement",
  "Product Feedback and Improvement",
  "Research and Market Insights",
  "Other Requests",

]

interface IRequest {
  type: string;
  title: string;
  details: string;
  attachments: string[];
}


const intitalRequest = {
  type: "",
  title: "",
  details: "",
  attachments: []
}

export default function AddServiceRequest() {
  const nav = useNavigate()

  const [request, setRequest] = useState<IRequest>(JSON.parse(JSON.stringify(intitalRequest)))
  const [attachmentsPreview, setAttachmentsPreview] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [loaderMsg, setLoaderMsg] = useState("")
  const [isAddedSuccessfully, setIsAddedSuccessfully] = useState(false)

  const onFileHandler = async (file: File) => {
    try {
      if (!file) return
      setIsLoading(true)
      setLoaderMsg('Uploading attachment please wait')
      setAttachmentsPreview(file)
      const { link } = await fileUploadAPI(file)
      setRequest(prev => ({ ...prev, attachments: [link] }))
      setIsLoading(false)
      setLoaderMsg('')
      toast.success('Attachment uploaded successfully')
    } catch (ex) {
      setIsLoading(false)
      setLoaderMsg('')
      toast.error('Something went wrong')
    }

  }

  const onChangeHandler = (key: string, value: string) => {
    setRequest(prev => ({ ...prev, [key]: value }))
  }

  const onSubmitRequest = async () => {
    try {
      setIsLoading(true)
      await createServiceRequestAPI(request)
      setIsAddedSuccessfully(true)
      setIsLoading(false)
    } catch (ex) {
      setIsLoading(false)
    }
  }

  return (
    <LoaderLayout isLoading={isLoading} loaderMsg={loaderMsg}>
      <div className='text-white'>

        <div className='flex justify-between items-center'>
          <h3 className='text-3xl color-primary font-semibold'>Service Requests</h3>
          <button
            className='text-white px-4 py-1 rounded-xl font-semibold outline-none border-none'
            style={{ border: '1px solid #4398FF' }}
            onClick={() => nav('/project/requests')}
          >Back to Service Requests</button>
        </div>

        {isAddedSuccessfully ?
          <div className='flex justify-center items-center h-[500px] w-full text-white'>
            <div className='text-center grid gap-3'>
              <h2 className='m-0 p-0 text-2xl'>Thank you for <br />submitting your request</h2>
              <span className='text-primary font-semibold'>We will be in touch shortly.</span>
            </div>
          </div>
          :
          <div className='grid gap-5 mt-4 max-w-[700px]'>

            <h3 className='m-0 p-0 text-2xl'>New Request</h3>

            <div className='flex gap-3 items-start'>
              <label className='min-w-[140px]'>Type of Request</label>
              <select
                className='bg-primary text-white font-semibold border-none outline-none px-3 py-1 rounded-lg'
                value={request?.type || ''}
                onChange={({ target: { value } }) => onChangeHandler('type', value)}
              >
                {
                  requestTypes.map((item, index) => {
                    return <option key={index} className='font-semibold' >{item}</option>
                  })
                }
              </select>
            </div>

            <div className='flex gap-3 items-start'>
              <label className='min-w-[140px]'>Title</label>
              <input
                className='w-full bg-transparent rounded-lg px-2 py-1 outline-none'
                style={{ border: '1px solid gray' }}
                placeholder='Please enter title'
                value={request?.title || ''}
                onChange={({ target: { value } }) => onChangeHandler('title', value)}
              />
            </div>

            <div className='flex gap-3 items-start w-full'>
              <label className='min-w-[140px]'>Details</label>
              <textarea
                className='w-full bg-transparent rounded-lg px-2 py-1 outline-none'
                placeholder='Please enter Details'
                style={{ border: '1px solid gray' }}
                rows={7}
                value={request?.details}
                onChange={({ target: { value } }) => onChangeHandler('details', value)}
              />
            </div>

            <div className='flex gap-3 items-start w-full'>
              <label className='min-w-[140px]'>Attachments<br />(optional)</label>
              <input id="service-request-file-upload" className='hidden' type="file" onChange={(e) => onFileHandler(e.target.files![0])} />
              <label
                htmlFor='service-request-file-upload'
                className='flex justify-end min-w-[300px] px-2 py-1 rounded-lg cursor-pointer'
                style={{ border: '1px solid #4398FF' }}
              >
                {attachmentsPreview ? <div className='w-full flex justify-start'>
                  <span className='text-sm py-1 text-primary'>{attachmentsPreview?.name || ''}</span>
                </div> : <img src={uploadIcon} alt="" />}
              </label>
            </div>

            <div>
              <button
                className='w-full px-2 py-1 rounded-lg'
                style={{ border: '1px solid #4398FF' }}
                onClick={onSubmitRequest}
              >
                Submit
              </button>
            </div>

          </div>}

      </div>
    </LoaderLayout>
  )
}
