import React, { useEffect, useState } from 'react'
import "./progress.sass"
import Slider from 'rc-slider'
import { RangeSlider } from './components/range-slider/range-slider'
import ProgressTable from './components/progress-table/progress-table'
import { ProgressRangeSlider } from '../dashboard/components/progress-slider/progress-slider'
import { getProjectFeedbackData, getProjectProgressData, postProjectProgressData } from '../../API/APICall'
import { Id as ToastId, toast } from 'react-toastify'
import { postContactAssetsUpdatedData } from '../../../all-form/API/APICall'
import { toastIdUpdater } from 'src/constants/utils'

interface PastProgressColumn {
  id: 'date' | 'area' | 'update' | 'docs';
  label: string;
  minWidth?: number;
}
interface Column {
  id: 'date' | 'mentorName' | 'feedback';
  label: string;
  minWidth?: number;
}

const pastProgressTableColumns: readonly PastProgressColumn[] = [
  { id: 'date', label: 'Date', minWidth: 100 },
  { id: 'area', label: 'Area', minWidth: 100 },
  { id: 'update', label: 'Update', minWidth: 270 },
  { id: 'docs', label: 'Docs', minWidth: 100 },
];

const mentorFeedbackTableColumns: readonly Column[] = [
  { id: 'date', label: 'Date', minWidth: 80 },
  { id: 'mentorName', label: 'Mentor Name', minWidth: 80 },
  { id: 'feedback', label: 'Feedback', minWidth: 200 },
];

export interface MentorCardData {
  value: any,
  projectUserData: any,
}

const Progress = (props: MentorCardData) => {
  const { value, projectUserData } = props

  const [progressData, setProgressData] = useState()
  const [feedbackData, setFeedbackData] = useState()
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [updateProgressfileData, setUpdateProgressfileData] = useState<any>()
  const [updateProgressData, setUpdateProgressData] = useState({
    area: "Community",
    description: "",
  });

  // pass progress api
  const getProjectProgressAllData = async () => {
    await getProjectProgressData()
      .then((res) => {
        if (res?.status === 200) {
          setProgressData(res?.data?.values && res?.data?.values)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  // feedback data api
  const getProjectFeedbackAllData = async () => {
    await getProjectFeedbackData()
      .then((res) => {
        if (res?.status === 200) {
          setFeedbackData(res?.data?.values && res?.data?.values)
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
      })
  }

  const handleSubmitData = async (e: any) => {
    let loaderId = null as ToastId | null
    try {
      if (!updateProgressData.area) return toast.error('Area is required')
      if (!updateProgressData.description) return toast.error('Description is required')
      let attachmentLink = ''
      loaderId = toast.loading('Adding progress please wait...')
      if (updateProgressfileData !== undefined) {
        let response = await postImageUploade(updateProgressfileData);
        if (response?.data?.link) {
          attachmentLink = response?.data?.link
        }
      }
      const responseData = await submitDataForProfileCreation(attachmentLink)
      toastIdUpdater({ id: loaderId, message: responseData?.message, type: 'success' })
    } catch (err: any) {
      toastIdUpdater({ id: loaderId!, message: err?.message, type: 'error' })
    }
  }

  const submitDataForProfileCreation = (uplodeLink: string): Promise<{ message: string }> => {
    return new Promise((resolve, reject) => {
      postProjectProgressData(updateProgressData?.area, updateProgressData?.description, uplodeLink)
        .then((res: any) => {
          setRefreshData(!refreshData)
          resolve(res.data)
        })
        .catch(err => {
          reject(err?.response?.data || err?.message)
        })
    })
  }

  // upload image api
  const postImageUploade = async (fileObj: any) => {
    try {
      return await postContactAssetsUpdatedData(fileObj, "project-ahead")
        .then((res) => {
          if (res?.status === 200) {
            console.log("res of image api ", res)
            return res
          } else {
            toast.success(res?.data?.message)
          }
          return {};
        })
        .catch((err: any) => {
          toast.error(err?.response?.data?.message)
          return err
        })
    }
    catch (err: any) {
      toast.error(err?.response?.data?.message)
      return err;
    }
  };

  const handelFileUpload = (e: any) => {
    setUpdateProgressfileData(e || {})
  };

  const handleChange = (event: any) => {
    setUpdateProgressData({
      ...updateProgressData,
      [event.target.name]: event?.target?.value
    });
  }

  useEffect(() => {
    getProjectProgressAllData()
  }, [refreshData])

  useEffect(() => {
    getProjectFeedbackAllData()
  }, [])

  return (
    <>
      <div className="project-progress">
        <div className="">
          {projectUserData &&
            <div className=''>
              <ProgressRangeSlider value={value} tabValue={value} setValue={undefined} symbioteScalePostion={projectUserData?.symbioteScalePostion || 0} userId={projectUserData && projectUserData?._id} />
            </div>
          }

          <div className="update-progres">
            <div className="">
              <h5 className='heading-new-5 mb-4 color-primary'>Update your progress</h5>
              <form action="">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-6">
                    <p className='paragraph-new'>Area</p>
                    <select className="form-select" aria-label="Default select example" name='area' onChange={handleChange} >
                      <option value="Community">Community</option>
                      <option value="VC">VC</option>
                      <option value="Mentor">Mentor</option>
                      <option value="Fundraise">Fundraise</option>
                      <option value="Growth">Growth</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 col-6 d-lg-none d-block">
                    <p className='paragraph-new'>Any supporting Documents</p>
                    <div className="file-uploader position-relative">
                      <input type="file" className="form-control" onChange={(e: any) => { handelFileUpload(e?.target?.files[0]) }} />
                      <i className="ri-upload-2-line"></i>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 mt-lg-0 mt-3">
                    <p className='paragraph-new'>Update</p>
                    <div className="">
                      <textarea className="form-control" id="exampleFormControlTextarea1" rows={4} name='description' onChange={handleChange}></textarea>
                    </div>
                  </div>
                  <div className="col-lg-3 d-lg-block d-none">
                    <p className='paragraph-new'>Any supporting Documents</p>
                    <div className="file-uploader position-relative">
                      <input type="file" className="form-control" onChange={(e: any) => { handelFileUpload(e?.target?.files[0]) }} />
                      <i className="ri-upload-2-line"></i>
                    </div>
                  </div>
                </div>
              </form>
              <div className="text-center mt-4">
                <button className='new-color-button' type='submit' onClick={(e: any) => handleSubmitData(e)}>Update</button>
              </div>
            </div>
          </div>

          <div className='progress-table mt-5'>
            <div className="table-heading d-flex align-items-center justify-content-between">
              <h5 className='heading-new-5 color-white-new color-primary'>Past Progress</h5>
              {/* <a className='paragraph-new-medium' href="#">Raise a concern</a> */}
            </div>
            <ProgressTable tableData={progressData} tableColumn={pastProgressTableColumns} />
          </div>

          <div className='progress-table mt-5'>
            <div className="table-heading d-flex align-items-center justify-content-between">
              <h5 className='heading-new-5 color-white-new color-primary'>Mentor Feedback</h5>
              {/* <a className='paragraph-new-medium' href="#">Raise a concern</a> */}
            </div>
            <ProgressTable tableData={feedbackData} tableColumn={mentorFeedbackTableColumns} />
          </div>
        </div>
      </div >
    </>
  )
}

export default Progress