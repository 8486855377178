import { useEffect, useRef, useState, useMemo } from 'react'
import "./mentor-network.sass"
import MentorCard from '../dashboard/components/mentor-card/mentor-card';
import Slider from 'react-slick';
import { getAllCategoryData, getAllCategoryWiseMentorsData, getProjectsMentorNetworData } from './API/APICall';
import { toast } from 'react-toastify';
import CustomPagination from '../../../../../shared/components/pagination/Pagination';
import CircularProgress from "@mui/material/CircularProgress"
import MentorScoreModal from './MentorScoreModal/MentorScoreModal';
import SearchBox from '../dashbord-top-section/search-box/search-box';

const initialFilters = {
    page: 1,
    skip: 0,
    limit: 12,
    search: '',
    categories: ''
}

interface IFilters {
    page: number;
    skip: number;
    limit: number;
    search: string,
    categories: string
}

const Mentornetwork = () => {
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [mentorNetworkLeadData, setMentorNetworkLeadData] = useState<any>([]);
    const [mentorNetworkSupportData, setMentorNetworkSupportData] = useState<any>([]);
    const [allCategory, setAllCategory] = useState<any>([]);
    const [mentorNetworkData, setMentorNetworkData] = useState<any>();
    const [allMentorLoader, setAllMentorLoader] = useState(false)
    const [height, setHeight] = useState(0)
    const ref = useRef<any>(null)
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [filter, setFilter] = useState<IFilters>(JSON.parse(JSON.stringify(initialFilters))) // deep clone
    const [totalCount, setTotalCount] = useState<number>(0)
    const [openScoreModal, setOpenScoreModal] = useState(false)
    const [mentorScore, setMentorScore] = useState({
        score: 0,
        mentorId: '',
    })

    useEffect(() => {
        if (mentorNetworkData && ref?.current?.clientHeight) {
            setHeight(ref?.current?.clientHeight)
        }
    }, [ref, mentorNetworkData])


    useEffect(() => {
        getCategoryAllData();
    }, [])

    useEffect(() => {
        getProjectsMentorNetworAllData();
        getAllMentorsCategoryData(initialFilters);
    }, [refreshData])

    const onChangePage = (page: number) => {
        if (filter.page === page) return
        let skip = (page - 1) * filter.limit
        let filters = { ...filter, page, skip }
        setFilter(filters)
        getAllMentorsCategoryData(filters)
    }

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
        }
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    // assign mentor api call
    const getProjectsMentorNetworAllData = async () => {
        await getProjectsMentorNetworData()
            .then((res) => {
                if (res?.status === 200) {
                    const totaleValue = res?.data?.values
                    let isLeadValues: any = [];
                    let isSupportValues: any = [];
                    let leadArray = totaleValue.filter((isLeadMore: any) =>
                        isLeadMore?.isLead === true
                    )
                    let _Lead = (leadArray.length > 0) ? isLeadValues.push(...leadArray) : ""

                    let supportArray = totaleValue.filter((isSupportMore: any) =>
                        isSupportMore?.isSupport === true
                    )
                    let _Support = (supportArray.length > 0) ? isSupportValues.push(...supportArray) : ""
                    setMentorNetworkLeadData(isLeadValues);
                    setMentorNetworkSupportData(isSupportValues);
                    return [isLeadValues, isSupportValues];
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Something went wrong')
            })
    }

    // category data
    const getCategoryAllData = async () => {
        await getAllCategoryData()
            .then(async (res) => {
                if (res?.status === 200) {
                    let responseData = res && res?.data?.values || []
                    const getAllToolsAPIResponseData = responseData && responseData?.map((item: any, index: number) => {
                        return {
                            "_id": item?._id || "",
                            "name": item?.name || "",
                            "slug": item?.slug || "",
                            "isSelected": false
                        };
                    }) || []
                    const fetchAllToolsDataArray = await Promise.all(getAllToolsAPIResponseData)
                    console.log(fetchAllToolsDataArray)
                    setAllCategory(fetchAllToolsDataArray)
                }
            })
            .catch((err) => {
                console.log(err, "Error")
            })
    }

    // category wise mentor data
    const getAllMentorsCategoryData = async (filters: IFilters) => {
        setAllMentorLoader(true)
        let areaNames = ""
        if (filters.categories) {
            const categoryValue = filters.categories.split(',')
            areaNames = allCategory.filter((cats: any) => {
                return !!categoryValue.find((item: string) => item === String(cats._id))
            }).map((item: any) => item.name).join(',')
        }
        await getAllCategoryWiseMentorsData(areaNames, { skip: filters.skip, limit: filters.limit, search: filters.search })
            .then((res) => {
                setAllMentorLoader(false)
                if (res?.status === 200) {
                    setMentorNetworkData(res?.data && res?.data?.values || []);
                    setTotalCount(res?.data && res?.data?.total || 0)
                }
            })
            .catch((err) => {
                setAllMentorLoader(false)
                console.log(err, "Error")
            })
    }

    const handleCategoryChange = (e: any, selectedItem: any) => {
        const updatedAllCategories = JSON.parse(JSON.stringify(allCategory))

        const itemIndex = updatedAllCategories.findIndex((item: any) => item._id === selectedItem._id)
        if (itemIndex === -1) return

        updatedAllCategories[itemIndex].isSelected = !selectedItem.isSelected
        setAllCategory(updatedAllCategories)

        let idsArr = updatedAllCategories.filter((item: any) => !!item?.isSelected).map((obj: any) => obj?._id).join(",")

        const filters = { ...initialFilters, search: filter.search, categories: idsArr }
        setFilter(filters)
        getAllMentorsCategoryData(filters)

    }

    const onClickOpenScoreModal = (mentorId: string, score: any) => {
        setMentorScore({ mentorId, score })
        setOpenScoreModal(true)
    }

    const onClickCloseScoreModal = () => {
        getProjectsMentorNetworAllData()
        setOpenScoreModal(false)
    }

    const onClickSearch = (value: string) => {
        const filters = { ...initialFilters, search: value }
        setFilter(filters)
        setAllCategory(allCategory.map((item: any) => ({ ...item, isSelected: false }))) // reset
        getAllMentorsCategoryData(filters)
    }

    const onClickDeselectAllFilter = () => {
        const filters = { ...initialFilters, search: filter.search }
        setFilter(filters)
        setAllCategory(allCategory.map((item: any) => ({ ...item, isSelected: false })))
        getAllMentorsCategoryData(filters)
    }

    const totalPages = useMemo(() => Math.ceil(totalCount / filter.limit), [totalCount, filter])

    return (
        <>
            <div className=''>
                <div className='lead-section'>
                    <div className='d-lg-flex d-md-flex d-block text-center'>
                        <h5 className='heading-new-5 color-primary me-lg-3 me-md-3 me-0 mb-lg-0 mb-md-0 mb-2'>Current Lead Mentors</h5>
                        <p className='paragraph-new mb-0' style={{ color: "#6D6D6D" }}>{mentorNetworkLeadData && mentorNetworkLeadData?.length <= 2 ? mentorNetworkLeadData?.length : 0}/2  Assigned</p>
                    </div>
                    <div className='connection-section mt-lg-5 mt-4'>
                        <div>
                            {mentorNetworkLeadData && mentorNetworkLeadData?.length ?
                                <div className="row">
                                    {mentorNetworkLeadData && mentorNetworkLeadData?.length < 2 && mentorNetworkLeadData?.map((item: any, i: number) => (
                                        <div className="col-lg-3 col-md-4 col-6 mb-lg-0 mb-md-0 mb-3" key={i}>
                                            <MentorCard
                                                name={item && item?.mentor?.name}
                                                title={item && item?.mentor && item?.mentor?.industriesOfExpertise && item?.mentor?.industriesOfExpertise.join(', ')}
                                                _id={item && item?.mentor && item?.mentor?._id}
                                                profilePic={item && item.mentor?.profilePic}
                                                requestStatus={item && item?.requestStatus}
                                                setRefreshData={setRefreshData}
                                                refreshData={refreshData}
                                                mentorScore={item?.mentor?.mentorScore}
                                                onUpdateScore={onClickOpenScoreModal}
                                                disableScore={item?.mentor?.isScoreAdded}
                                                showUpdate
                                            />
                                        </div>
                                    ))}
                                </div> : <p className='paragraph-new text-center' style={{ color: '#B7B7B7' }}>Nobody assigned yet.</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='Support-section mt-lg-5 mt-4'>
                    <div className='d-lg-flex d-md-flex d-block text-center'>
                        <h5 className='heading-new-5 color-primary me-lg-3 me-md-3 me-0 mb-lg-0 mb-md-0 mb-2'>Current Support Mentors</h5>
                        <p className='paragraph-new mb-0' style={{ color: "#6D6D6D" }}>{mentorNetworkSupportData?.length <= 3 ? mentorNetworkSupportData?.length : 0}/3  Assigned</p>
                    </div>
                    <div className='connection-section mt-lg-5 mt-md-3 mt-2'>
                        <div>
                            {mentorNetworkSupportData?.length > 0 ?
                                <>
                                    <div className="row">
                                        {mentorNetworkSupportData && mentorNetworkLeadData?.length <= 3 && mentorNetworkSupportData?.map((item: any, i: number) => (
                                            <div className="col-lg-3 col-md-4 col-6 mb-lg-0 mb-md-0 mb-3" key={i}>
                                                <MentorCard
                                                    name={item && item?.mentor?.name}
                                                    title={item && item?.mentor && item?.mentor?.industriesOfExpertise && item?.mentor?.industriesOfExpertise.join(', ')}
                                                    _id={item && item?.mentor && item?.mentor?._id}
                                                    profilePic={item && item?.mentor?.profilePic}
                                                    requestStatus={item && item?.requestStatus}
                                                    setRefreshData={setRefreshData}
                                                    refreshData={refreshData}
                                                    onUpdateScore={() => onClickOpenScoreModal(item && item?.mentor && item?.mentor?._id, item?.mentor?.mentorScore)}
                                                    disableScore={item?.mentor?.isScoreAdded}
                                                    showUpdate
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                                : <p className='paragraph-new text-center' style={{ color: '#B7B7B7' }}>Nobody assigned yet.</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='network-section mt-lg-5 mt-4'>
                    <div className='d-flex justify-content-between'>
                        <h4 className='heading-new-4 color-primary'>Mentor Network</h4>
                        {/* <p className='paragraph-new mb-0 request' style={{ color: "#6D6D6D" }}>Make a request</p> */}
                    </div>
                    <div className='network-catogory mt-2'>
                        <div className="network-category-container pb-2">
                            {allCategory && allCategory?.map((item: any, i: number) => (
                                <div className="my-2" key={i}>
                                    <div className='network-category-item'>
                                        <div className="form-check" onChange={(e: any) => { handleCategoryChange(e, item) }} >
                                            <label className="form-check-label paragraph-new-small ms-2 w-100" htmlFor={item && item?._id} >
                                                <input className="form-check-input" type="checkbox" name={item && item?.name} id={item && item?._id} value={item && item?._id} checked={item?.isSelected} />
                                                {item && item?.name}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {
                            allCategory && allCategory.filter((item: any) => item.isSelected).length > 0 &&
                            <div className="mt-2 text-center"><span className='paragraph-new-small cursor-pointer hover-primary' onClick={onClickDeselectAllFilter}>Deselect All</span></div>
                        }
                    </div>
                    <div className={height > 1000 ? 'network-catogory-data scrolled mt-2' : 'network-catogory-data mt-2'} ref={ref}>
                        <div className="mt-3 flex justify-end">
                            <SearchBox onSearch={onClickSearch} />
                        </div>
                        {
                            allMentorLoader && <div className="mentor-loader-container">
                                <CircularProgress size={32} sx={{ color: 'white' }} />
                            </div>
                        }
                        <div className="row">
                            {!allMentorLoader && mentorNetworkData && mentorNetworkData.length ? mentorNetworkData?.map((item: any, i: number) => (
                                <div className="col-lg-2 col-md-3 col-6 my-lg-3 my-2" key={i}>
                                    <MentorCard
                                        name={item.name}
                                        title={item && item?.industriesOfExpertise && item?.industriesOfExpertise.join(', ')}
                                        _id={item && item._id}
                                        profilePic={item && item.profilePic}
                                        requestStatus={item && item?.requestStatus}
                                        setRefreshData={setRefreshData}
                                        refreshData={refreshData}
                                    />
                                </div>
                            )) : !allMentorLoader && <p className='paragraph-new mb-0 text-center mt-6'>No data available</p>}

                        </div>
                        {totalPages > 1 ? <CustomPagination
                            total={totalPages}
                            page={filter.page}
                            onChange={onChangePage}
                        /> : null}
                    </div>
                </div>
                <MentorScoreModal
                    isOpen={openScoreModal}
                    toggle={onClickCloseScoreModal}
                    mentorScore={mentorScore.score}
                    mentorId={mentorScore.mentorId}
                />
            </div >
        </>
    )
}

export default Mentornetwork