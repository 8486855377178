import { useEffect, useState } from "react";

export default function useOutsideAlerter(ref: any) {

  const [isOutside, setIsOutside] = useState(true)

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  function handleClickOutside(event: MouseEvent) {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOutside(true)
    }
  }

  return { isOutside, setIsOutside }
}