import React from 'react'
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import "./style.scss"

interface ICustomModal {
  isOpen: boolean;
  toggle: () => void;
  hideClose?: boolean;
  children: React.ReactNode
  title?: string
  maxWidth?: 400 | 500 | 600 | 700 | 800
}

function CustomModal({ isOpen, toggle, children, title, hideClose, maxWidth = 500 }: ICustomModal) {

  let modalBodyStyles = { marginTop: '12px', paddingTop: '18px' } as any
  if (hideClose) {
    modalBodyStyles = {}
  }
  return (
    <Modal className='custom-modal-container' size="lg" style={{ maxWidth }} centered fade={false} isOpen={isOpen} toggle={toggle}>
      <ModalBody style={modalBodyStyles}>
        {!hideClose && <div className="close-btn" onClick={toggle}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
              fill="black"
            />
          </svg>
        </div>}
        <div className='min-h-[200px]'>
          {title && <h2 className='text-lg md:text-2xl text-center font-bold'>{title}</h2>}
          {children}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default CustomModal
