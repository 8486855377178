import React, { useEffect, useState } from 'react'
import "./index.scss"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import CircularProgress from "@mui/material/CircularProgress"
import Slider from '@mui/material/Slider';
import { addMentorScoreAPI } from '../API/APICall';
import { toast } from 'react-toastify';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import infoIcon from "../../../../../../assests/icons/info-icon.svg"

interface detailsProps {
  isOpen: boolean,
  toggle: () => void,
  mentorScore: number,
  mentorId: string
}

const MentorScoreModal = ({ isOpen, toggle, mentorScore, mentorId }: detailsProps) => {

  const [score, setScore] = useState({
    timeSpent: 0,
    connectionsFormed: 0,
    impactMade: 50,
  })
  const [loader, setLoader] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const onChangeSlider = (e: any, newValue: any) => {
    setScore(prev => ({ ...prev, impactMade: newValue }))
  }

  const onChangeHandler = (e: any) => {
    const { name, value } = e.target
    setScore(prev => ({ ...prev, [name]: value }))
  }

  const onSubmitScore = async () => {
    if (loader) return
    try {
      if (!score.timeSpent || !score.connectionsFormed || !score.impactMade) {
        return toast.error('Missing parameters')
      }
      setLoader(true)
      const response = await addMentorScoreAPI({ ...score, mentorId })
      toast.success(response.data && response.data.message || 'Success')
      setLoader(false)
      toggle()
    } catch (err: any) {
      toast.error(err && err.response && err.response.data && err.response.data.message || 'Something went wrong')
      setLoader(false)
    }
  }

  const onHoverInfo = () => {
    setShowInfo(prev => !prev)
  }

  return (
    <>
      <Modal className={`connection-request-model mentor-score-modal ${loader ? 'active-loader' : ''}`} centered fade={false} isOpen={isOpen} toggle={toggle}>
        <div className="modal-wrapper">
          <ModalHeader toggle={toggle} className='justify-content-center'>
            <div className="close-btn" onClick={toggle}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </ModalHeader>
        </div>
        <ModalBody>
          <div className="score-modal-container">
            {loader && <div className='modal-loader'>
              <CircularProgress size={40} sx={{ color: "white" }} />
            </div>}
            <h3 className="header">Update SymbioteScore</h3>
            <div className='reward-form'>
              <div className='reward-form-item'>
                <label>Total Time Spent with Mentor (in Min)</label>
                <input type="number" name="timeSpent" placeholder='0' value={score.timeSpent || ''} onChange={onChangeHandler} />
              </div>
              <div className='reward-form-item'>
                <label>Number of Partnerships achieved with help from Mentor</label>
                <input type="number" name="connectionsFormed" placeholder='0' value={score.connectionsFormed || ''} onChange={onChangeHandler} />
              </div>
              <div className='reward-form-item'>
                <label>Evaluate the impact of your mentor's guidance and mentorship on your project's progress this week.</label>
                <span>Scale: 1-100, 1 being minimal and 100 being highly impactful</span>
                <div className="score-slider">
                  <div className='score-digits'>
                    <h4>{score.impactMade}</h4>
                  </div>
                  <Slider
                    value={score.impactMade}
                    onChange={onChangeSlider}
                    color="primary"
                    sx={{
                      '& .MuiSlider-track': {
                        height: 2,
                        opacity: 1,
                        background: '#4398FF'
                      },
                      '& .MuiSlider-rail': {
                        background: '#4398FF',
                        opacity: 1,
                        height: '1px',
                      },
                      '& .MuiSlider-thumb': {
                        color: 'white !important',
                        height: '24px',
                        width: '24px',
                        border: '3px solid #4398FF'
                      }
                    }}
                  />
                </div>
              </div>
              <div className="progress-bar-container">
                <div className="progress-bar-item">
                  <CircularProgressbar value={mentorScore} text={`${Number(mentorScore).toFixed(0)}`} background
                    backgroundPadding={0}
                    styles={buildStyles({
                      backgroundColor: "transparent",
                      textColor: "#fff",
                      textSize: "32px",
                      pathColor: "#65DB6A",
                      trailColor: "#333333"
                    })} />
                </div>
                <div className='progress-text'>
                  <h3 className='text'>Aggregate <br />Mentor Score</h3>
                  <img className="calculation-info-icon" src={infoIcon} onMouseEnter={onHoverInfo} onMouseLeave={onHoverInfo} />
                </div>
                {showInfo && <div className='score-modal-calculation-info' onMouseEnter={onHoverInfo} onMouseLeave={onHoverInfo}>
                  <p>
                    Score of mentor is calculated on a Weighted Average basis using the below formula
                    <br />
                    <br />
                    (0.25 * Time spent) +
                    <br />
                    (0.25 * Connections formed) +
                    <br />
                    (0.5 * Impact)
                  </p>
                </div>}
              </div>
            </div>
            <div className="action-button">
              <button className='new-color-button' onClick={onSubmitScore}>Update</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default MentorScoreModal