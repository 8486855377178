import React, { useState, useEffect, useMemo, useCallback } from 'react'
import "./partner-network.sass"
import ConnectionStatusTable from './components/connection-status-table/connection-status-table';
import PartnerListTable from '../../../../home/components/partner-list-table/partner-list-table';
import { getCurrentPartnersData, getGrantPartners, getPartnerByIdAPI, getPartnerCategories, getPartnersData } from './API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import CustomPagination from '../../../../../shared/components/pagination/Pagination';
import DescriptionModal from './components/description-modal/description-modal';
import GrantPartnerList from './components/grant-partner-list/grant-partner-list';
import SearchBox from '../dashbord-top-section/search-box/search-box';

interface IColumn {
  id: string;
  label: string;
  minWidth: number;
}

const columns: IColumn[] = [
  { id: 'image', label: '', minWidth: 20 },
  { id: 'name', label: 'Name', minWidth: 120 },
  { id: 'area', label: 'Area', minWidth: 100 },
  { id: 'industriesOfExpertise', label: 'Industries Of Expertise', minWidth: 170 },
  { id: 'socials', label: 'Socials', minWidth: 120 },
  { id: 'status', label: 'Status', minWidth: 130 },
];

const grantPartnerColums: IColumn[] = [
  { id: 'image', label: '', minWidth: 20 },
  { id: 'name', label: 'Name', minWidth: 120 },
  { id: 'grantName', label: 'Grant Name', minWidth: 100 },
  { id: 'grantAmount', label: 'Grant Amount', minWidth: 100 },
  // { id: 'shortDescription', label: 'Description', minWidth: 160 },
  { id: 'socials', label: 'Socials', minWidth: 120 },
  { id: 'status', label: 'Status', minWidth: 130 },
];

const initialIndustriesOfExpertiseOptions = [
  {
    label: 'NFT',
    value: 'NFT',
    isSelected: false
  },
  {
    label: 'Blockchain Infrastructure',
    value: 'Blockchain Infrastructure',
    isSelected: false
  },
  {
    label: 'DeFi',
    value: 'DeFi',
    isSelected: false
  },
  {
    label: 'Metaverse',
    value: 'Metaverse',
    isSelected: false
  },
  {
    label: 'SocialFi',
    value: 'SocialFi',
    isSelected: false
  },
  {
    label: 'Middleware',
    value: 'Middleware',
    isSelected: false
  },
  {
    label: 'GameFi',
    value: 'GameFi',
    isSelected: false
  },
  {
    label: 'DAO',
    value: 'DAO',
    isSelected: false
  },
  {
    label: 'Smart Contract Platforms and Frameworks',
    value: 'Smart Contract Platforms and Frameworks',
    isSelected: false
  },
  {
    label: 'StableCoins',
    value: 'StableCoins',
    isSelected: false
  },
  {
    label: 'Exchanges',
    value: 'Exchanges',
    isSelected: false
  },
  {
    label: 'Finance/Banking',
    value: 'Finance/Banking',
    isSelected: false
  },
  {
    label: 'Privacy Coins',
    value: 'Privacy Coins',
    isSelected: false
  },
  {
    label: 'Wallets',
    value: 'Wallets',
    isSelected: false
  },
  {
    label: 'All',
    value: 'All',
    isSelected: false
  },
]

export interface TableFilterDAta {
  value: any,
}

const initialDescPartner = { partner: '', isGrant: false, showGrantDesc: false }

const PartnersNetwork = (props: TableFilterDAta) => {
  const [currentPartnerData, setCurrentPartnerData] = useState<any>([])
  const [partnerData, setPartnerData] = useState<any>([])
  const [cateriesData, setCateriesData] = useState<any>([])
  const [currentCateriesData, setCurrentCateriesData] = useState<any>([])
  const [selectedCategoryValues, setSelectedCategoryValues] = useState<any>();
  const [selectedCurrentCategoryValues, setSelectedCurrentCategoryValues] = useState<any>();
  const [partnerStatus, setPartnerStatus] = useState("")
  const [partnerLoader, setPartnerLoader] = useState(false)
  const [connectionLoader, setConnectionLoader] = useState(false)
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [allPartnerFilter, setAllPartnerFilter] = useState({
    page: 1,
    skip: 0,
    limit: 20,
  })

  const [currentPartnerFilter, setCurrentPartnerFilter] = useState({
    page: 1,
    skip: 0,
    limit: 5,
  })
  const [totalCount, setTotalCount] = useState<number>(0)
  const [currentTotalCount, setCurrentTotalCount] = useState<number>(0)
  const [industriesOptions, setIndustriesOptions] = useState([...initialIndustriesOfExpertiseOptions])
  const [descPartner, setDescPartner] = useState(JSON.parse(JSON.stringify(initialDescPartner)))
  const [grantPartners, setGrantPartners] = useState<any[]>([])
  const [totalGrantPartners, settotalGrantPartners] = useState(0)
  const [grantLoader, setGrantLoader] = useState(false)
  const [grantPartnerFilter, setGrantPartnerFilter] = useState({
    page: 1,
    skip: 0,
    limit: 5,
  })

  const { value } = props

  useEffect(() => {
    getAllPartnerCategories()
  }, [])

  useEffect(() => {
    getAllGrantPartners()
  }, [grantPartnerFilter])

  useEffect(() => {
    getAllCurrentPartnersNetworkData();
  }, [selectedCurrentCategoryValues, currentPartnerFilter])

  useEffect(() => {
    getAllPartnersData()
  }, [selectedCategoryValues, refreshData, allPartnerFilter, industriesOptions])

  const onChangePage = (page: number) => {
    let skip = (page - 1) * allPartnerFilter.limit
    setAllPartnerFilter(prev => ({ ...prev, page, skip }))
  }

  const onChangeCurrentPage = (page: number) => {
    let skip = (page - 1) * currentPartnerFilter.limit
    setCurrentPartnerFilter(prev => ({ ...prev, page, skip }))
  }

  const onChangeGrantPage = (page: number) => {
    let skip = (page - 1) * currentPartnerFilter.limit
    setGrantPartnerFilter(prev => ({ ...prev, page, skip }))
  }

  const getAllGrantPartners = async () => {
    try {
      setGrantLoader(true)
      const response = await getGrantPartners(grantPartnerFilter)
      setGrantLoader(false)
      setGrantPartners(response.values)
      settotalGrantPartners(response.total)
    } catch (ex) {
      setGrantLoader(false)
    }
  }

  // get current partner data api
  const getAllCurrentPartnersNetworkData = async () => {

    let operationNames = [] as string[]
    if (!!selectedCurrentCategoryValues) {
      const splitValues = String(selectedCurrentCategoryValues).split(',')
      currentCateriesData.filter((parentCat: any) => {
        const selectedSubCats = parentCat.subCategories.filter((childCat: any) => {
          return !!splitValues.find(item => item === childCat._id) as any
        }).map((item: any) => item.name)
        operationNames = [...operationNames, ...selectedSubCats]
      })
    }
    setConnectionLoader(true)
    await getCurrentPartnersData(operationNames.join(','), currentPartnerFilter)
      .then((res) => {
        setConnectionLoader(false)
        if (res?.status === 200) {
          setCurrentPartnerData(res?.data && res?.data?.values || []);
          setCurrentTotalCount(res?.data && res?.data?.total || 0);
        }
      })
      .catch((err) => {
        setConnectionLoader(false)
        console.log(err, "Error")
      })
  }

  // get partner data api
  const getAllPartnersData = async () => {
    const selectedIndustries = industriesOptions.filter(item => item.isSelected).map(item => item.value).join(',')

    let operationNames = [] as string[]
    if (!!selectedCategoryValues) {
      const splitValues = String(selectedCategoryValues).split(',')
      cateriesData.filter((parentCat: any) => {
        const selectedSubCats = parentCat.subCategories.filter((childCat: any) => {
          return !!splitValues.find(item => item === childCat._id) as any
        }).map((item: any) => item.name)
        operationNames = [...operationNames, ...selectedSubCats]
      })
    }

    setPartnerLoader(true)
    await getPartnersData(operationNames.join(','), allPartnerFilter, selectedIndustries)
      .then((res) => {
        setPartnerLoader(false)
        if (res?.status === 200) {
          setPartnerData(res?.data && res?.data?.values || []);
          setTotalCount(res?.data && res?.data?.total || 0)
        }
      })
      .catch((err) => {
        setPartnerLoader(false)
        console.log(err, "Error")
      })
  }

  // get partner categpries data api
  const getAllPartnerCategories = async () => {
    await getPartnerCategories()
      .then(async (res) => {
        if (res?.status === 200) {
          let responseData = res && res?.data?.values || []
          const getAllToolsAPIResponseData = responseData && responseData?.map((key: any, index: number) => {
            return {
              "_id": key?._id || "",
              "name": key?.name || "",
              "slug": key?.slug || "",
              "subCategories": key?.subCategories && key?.subCategories.map((item: any, index: any) => {
                return {
                  "_id": item?._id || "",
                  "name": item?.name || "",
                  "slug": item?.slug || "",
                  "isSelected": false
                };
              }) || [],
              "isSelectedCategory": false
            };

          }) || []
          const fetchAllToolsDataArray = await Promise.all(getAllToolsAPIResponseData)
          setCateriesData(JSON.parse(JSON.stringify(fetchAllToolsDataArray)))
          setCurrentCateriesData(JSON.parse(JSON.stringify(fetchAllToolsDataArray)))
        }
      })
      .catch((err) => {
        console.log(err, "Error")
      })
  }

  const handleOpen = (selectedItem: any) => {
    selectedItem.isSelectedCategory = !selectedItem.isSelectedCategory
    setCurrentCateriesData([...currentCateriesData])
  }

  const handleCategoryChange = (e: any, selectedItemVal: any, type: 'all' | 'current') => {
    selectedItemVal.isSelected = !selectedItemVal.isSelected
    if (type === "all") {
      let filterArray = cateriesData && cateriesData.map((categoryObj: any, i: any) => (
        categoryObj && categoryObj?.subCategories.filter((subCategoryItem: any) => subCategoryItem?.isSelected == true).map((subCategoryItemObj: any, key: any) => {
          return subCategoryItemObj?._id
        })))
      let categoryVal = filterArray.flat() ? filterArray?.count == 1 ? filterArray.flat()[0] : filterArray.flat().join(",") : "";
      setSelectedCategoryValues(categoryVal)
    } else {
      let filterArray = currentCateriesData && currentCateriesData.map((currentCategoryObj: any, i: any) => (
        currentCategoryObj && currentCategoryObj?.subCategories.filter((subCategoryItem: any) => subCategoryItem?.isSelected == true).map((subCategoryItemObj: any, key: any) => {
          return subCategoryItemObj?._id
        })))
      let categoryVal = filterArray.flat() ? filterArray?.count == 1 ? filterArray.flat()[0] : filterArray.flat().join(",") : "";
      setSelectedCurrentCategoryValues(categoryVal)
    }
  }

  const onChangeIndustriesOptions = useCallback((value: string, reset?: boolean) => {
    setIndustriesOptions(industriesOptions.map(item => {
      if (reset) return { ...item, isSelected: false }
      else if (item.value === value) {
        return { ...item, isSelected: !item.isSelected }
      } else return item
    }))
  }, [industriesOptions])

  const onClickReadMore = async (partnerId: string, isGrant?: boolean, showGrantDesc?: boolean) => {
    setDescPartner({ partner: partnerId, isGrant: !!isGrant, showGrantDesc: !!showGrantDesc })
  }

  const onClickSearch = async (value: string) => {
    setPartnerLoader(true)
    await getPartnersData('', { skip: 0, limit: 20, search: value })
      .then((res) => {
        setPartnerLoader(false)
        if (res?.status === 200) {
          setPartnerData(res?.data && res?.data?.values || []);
          setTotalCount(res?.data && res?.data?.total || 0)
        }
      })
      .catch((err) => {
        setPartnerLoader(false)
        console.log(err, "Error")
      })
  }

  const onClickDiselectAll = (type: 'all' | 'current') => {
    if (type === 'all') {
      setSelectedCategoryValues('')
      setCateriesData((prev: any) => prev.map((pItem: any) => ({ ...pItem, subCategories: pItem?.subCategories.map((item: any) => ({ ...item, isSelected: false })) })))
    } else {
      setSelectedCurrentCategoryValues('')
      setCurrentCateriesData((prev: any) => prev.map((pItem: any) => ({ ...pItem, subCategories: pItem?.subCategories.map((item: any) => ({ ...item, isSelected: false })) })))
    }
  }

  const showDiselectAll = (type: 'all' | 'current'): boolean => {
    if (type === 'all') {
      const selectedParent = cateriesData.filter((pItem: any) => !!pItem?.subCategories.find((item: any) => item.isSelected))
      return selectedParent.length > 0
    } else {
      const selectedParent = currentCateriesData.filter((pItem: any) => !!pItem?.subCategories.find((item: any) => item.isSelected))
      return selectedParent.length > 0
    }
  }

  const totalPages = useMemo(() => Math.ceil(totalCount / allPartnerFilter.limit), [totalCount, allPartnerFilter])
  const currentTotalPages = useMemo(() => Math.ceil(currentTotalCount / currentPartnerFilter.limit), [currentTotalCount, currentPartnerFilter])
  const grantTotalPages = useMemo(() => Math.ceil(currentTotalCount / grantPartnerFilter.limit), [totalGrantPartners, grantPartnerFilter])

  return (
    <>
      <div className="partner-network-page">

        <div className="current-partner-list-content">
          <div className="d-lg-flex d-md-flex d-block align-items-baseline mb-4 text-center">
            <h4 className='heading-new-4 mb-lg-0 mb-md-0 mb-2 color-primary'>Current Partners List</h4>
            <p className='paragraph-new-small fw-bold mb-0 ms-lg-3 ms-md-3 ms-0' style={{ color: "#555555" }}>Use the filters below for more focused access</p>
          </div>

          {/* {(!connectionLoader && currentPartnerData && currentPartnerData.length !== 0) && <div className="top-card-content">
            <div className="card-content-part">
              {currentCateriesData && currentCateriesData.map((currentObj: any, currentIndex: number) => {
                if (currentObj?.subCategories && currentObj?.subCategories.length === 0) {
                  return null
                }
                return <div className="same-content" key={currentIndex}>
                  <div className="list-card">
                    <p className='paragraph-new fw-bold text-center color-primary mb-2'>{currentObj?.name}</p>
                    <div className="center-dropdown position-relative">
                      <div className={"dropdown-option " + (currentObj?.isSelectedCategory ? 'active' : '')} >
                        {currentObj && currentObj?.subCategories.map((currentObjItem: any, index: any) => {
                          return <button className={currentObjItem?.isSelected ? "active" : ""} key={index} onClick={(e: any) => { handleCategoryChange(e, currentObjItem, "current") }}>{currentObjItem?.name}</button>
                        })}
                      </div>
                    </div>
                    {(currentObj?.subCategories && currentObj?.subCategories.length > 2) ?
                      <div
                        className="paragraph-new-small text-center mt-lg-3 mt-2"
                        role='button'
                        onClick={(e: any) => { handleOpen(currentObj) }} >
                        {currentObj?.isSelectedCategory ? 'Less options' : 'More options'}
                        {currentObj?.isSelectedCategory ? <i className="ri-arrow-up-s-line color-primary ml-1"></i> : <i className="ri-arrow-down-s-line color-primary"></i>}
                      </div> : null}
                  </div>
                </div>
              })}
            </div>
          </div>} */}

          {(currentPartnerData && currentPartnerData.length && currentCateriesData && currentCateriesData.length) ? <div className='network-catogory mt-2'>
            <div className="network-category-container pb-2">
              {currentCateriesData?.map((partnerObj: any) => {
                if (partnerObj?.subCategories && partnerObj?.subCategories.length === 0) {
                  return null
                }
                return partnerObj?.subCategories.map((item: any) => {
                  return <div className="my-2" key={item?.name}>
                    <div className='network-category-item'>
                      <div className="form-check" onChange={(e: any) => { handleCategoryChange(e, item, "current") }} >
                        <label className="form-check-label paragraph-new-small ms-2 w-100" htmlFor={item?._id + 'current'} >
                          <input className="form-check-input" type="checkbox" name={item && item?.name} id={item?._id + 'current'} value={item && item?._id} checked={item?.isSelected} />
                          {item && item?.name}
                        </label>
                      </div>
                    </div>
                  </div>
                })
              })}
            </div>
            {
              showDiselectAll('current') && <div className="mt-2 text-center"><span className='paragraph-new-small cursor-pointer hover-primary' onClick={() => onClickDiselectAll('current')}>Deselect All</span></div>
            }
          </div> : null}

          <div className="bottom-content">
            <div className="">
              <PartnerListTable
                setPartnerStatus={setPartnerStatus}
                currentPartnerData={currentPartnerData}
                tableColumns={columns}
                isLoading={connectionLoader}
              />

              {currentTotalPages > 1 ? <CustomPagination
                page={currentPartnerFilter.page}
                total={currentTotalPages}
                onChange={onChangeCurrentPage}
              /> : null}
            </div>
          </div>
        </div>

        <div className="current-partner-list-content mt-5">
          <div className="d-lg-flex d-md-flex d-block align-items-baseline mb-4 text-center">
            <h4 className='heading-new-4 mb-lg-0 mb-md-0 mb-2 color-primary'>Grant Partners List</h4>
          </div>
          <div className="bottom-content">
            <div className="">
              <GrantPartnerList
                setPartnerStatus={setPartnerStatus}
                currentPartnerData={grantPartners}
                tableColumns={grantPartnerColums}
                isLoading={grantLoader}
                onClickReadMore={onClickReadMore}
                onRefreshData={getAllGrantPartners}
              />

              {grantTotalPages > 1 ? <CustomPagination
                page={grantPartnerFilter.page}
                total={grantTotalPages}
                onChange={onChangeGrantPage}
              /> : null}
            </div>
          </div>
        </div>

        <div className="current-partner-list-content mt-5">
          <div className="d-lg-flex d-md-flex d-block align-items-baseline mb-4 text-center">
            <h4 className='heading-new-4 mb-lg-0 mb-md-0 mb-2 color-primary'>Partners List</h4>
            <p className='paragraph-new-small fw-bold mb-0 ms-lg-3 ms-md-3 ms-0' style={{ color: "#555555" }}>Use the filters below for more focused access</p>
          </div>
          {/* <div className="top-card-content">
            <div className="card-content-part">
              {cateriesData && cateriesData.map((partnerObj: any, partIndex: any) => {
                if (partnerObj?.subCategories && partnerObj?.subCategories.length === 0) {
                  return null
                }
                return <div className="same-content" key={partIndex}>
                  <div className="list-card">
                    <p className='paragraph-new fw-bold text-center color-primary mb-2'>{partnerObj?.name}</p>
                    <div className="center-dropdown position-relative">
                      <div className={"dropdown-option " + (partnerObj?.isSelectedCategory ? 'active' : '')} >
                        {partnerObj && partnerObj?.subCategories.map((partnerItem: any, partnerIndex: any) => {
                          return <button className={partnerItem?.isSelected ? "active" : ""} key={partnerIndex} onClick={(e: any) => { handleCategoryChange(e, partnerItem, "all") }}>{partnerItem?.name}</button>
                        })}
                      </div>
                    </div>
                    {(partnerObj?.subCategories && partnerObj?.subCategories.length > 2) ?
                      <div
                        className="paragraph-new-small text-center mt-lg-3 mt-2"
                        role='button'
                        onClick={(e: any) => { handleOpen(partnerObj) }} >
                        {partnerObj?.isSelectedCategory ? 'Less options' : 'More options'}
                        {partnerObj?.isSelectedCategory ? <i className="ri-arrow-up-s-line color-primary"></i> : <i className="ri-arrow-down-s-line color-primary"></i>}
                      </div> : null}
                  </div>
                </div>
              })}
            </div>
          </div> */}

          {(cateriesData && cateriesData.length) ? <div className='network-catogory mt-2'>
          <p className='paragraph-new fw-bold text-center color-primary'>Area</p>
            <div className="network-category-container pb-2">
              {cateriesData?.map((partnerObj: any) => {
                if (partnerObj?.subCategories && partnerObj?.subCategories.length === 0) {
                  return null
                }
                return partnerObj?.subCategories.map((item: any) => {
                  return <div className="my-2" key={item?.name}>
                    <div className='network-category-item'>
                      <div className="form-check" onChange={(e: any) => { handleCategoryChange(e, item, "all") }} >
                        <label className="form-check-label paragraph-new-small ms-2 w-100" htmlFor={item?._id + 'all'} >
                          <input className="form-check-input" type="checkbox" name={item && item?.name} id={item?._id + 'all'} value={item && item?._id} checked={item?.isSelected} />
                          {item && item?.name}
                        </label>
                      </div>
                    </div>
                  </div>
                })
              })}
            </div>
            {
              showDiselectAll('all') && <div className="mt-2 text-center"><span className='paragraph-new-small cursor-pointer hover-primary' onClick={() => onClickDiselectAll('all')}>Deselect All</span></div>
            }
          </div> : null}

          <div className="industries-filter-main mt-4">
            <p className='paragraph-new fw-bold text-center color-primary'>Industries</p>
            <div className='industries-filter-scrollable'>
              <div className='industries-filter-container'>
                {industriesOptions.map(option => {
                  return <div className="industries-filter-item" key={option.value}>
                    <span
                      className={`${option.isSelected ? 'selected' : 'not-selected'}`}
                      onClick={() => onChangeIndustriesOptions(option.value)}
                    >
                      {option.label}
                    </span>
                  </div>
                })}
              </div>
            </div>
            {industriesOptions.find(option => option.isSelected) && <p className='deselect-all-item' onClick={() => onChangeIndustriesOptions('', true)}>Deselect All</p> }
          </div>

          <div className="bottom-content">
            <div className="">
              <div className="mt-3 flex justify-end">
                <SearchBox onSearch={onClickSearch} />
              </div>
              <ConnectionStatusTable
                partnerData={partnerData}
                tabValue={value}
                tableColumns={columns}
                isLoading={partnerLoader}
                onClickReadMore={onClickReadMore}
                userType={"partner"}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
              />
              {totalPages > 1 ? <CustomPagination
                page={allPartnerFilter.page}
                total={totalPages}
                onChange={onChangePage}
              /> : null}
            </div>
          </div>
        </div>
        <DescriptionModal
          isOpen={!!descPartner.partner}
          toggle={() => setDescPartner({ ...initialDescPartner })}
          descPartner={descPartner}
        />
      </div>
    </>
  )
}

export default PartnersNetwork