import React, { useEffect, useRef, useState, useMemo } from 'react'
import "./launch-network.sass"
// import CarlPei from '../../../../../assests/images/accelerate/pages/vc-black-logo.svg';
import NoImage from '../../../../../assests/images/homepage/no-image-available.jpg';

import { getAllAndCategoryWiseAssignedLaunchnetworkData, getAllAndCategoryWiseLaunchnetworkData, getAllLauchnetworkCategoryData, postRequestLaunchNetworkToConnect } from './API/APICall';
import { ToastContainer, toast } from 'react-toastify';
import ConnectionRequestModel from '../partner-network/components/connection-request-model/connection-request-model';
import CustomPagination from '../../../../../shared/components/pagination/Pagination';
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import DescriptionModal from '../partner-network/components/description-modal/description-modal';
import SearchBox from '../dashbord-top-section/search-box/search-box';

const initialDescPartner = { partner: '', isGrant: false, showGrantDesc: false }

const Launchnetwork = () => {
    const [allCategory, setAllCategory] = useState<any>([]);
    const [launchpadNetworkData, setLaunchpadNetworkData] = useState<any>();
    const [assignedLaunchpadNetworkData, setAssignedLaunchpadNetworkData] = useState<any>();
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [refreshData, setRefreshData] = useState<boolean>(false)


    const [allDataheight, setAllDataHeight] = useState(0)
    const [currentDataheight, setCurrentDataHeight] = useState(0)
    const ref = useRef<any>(null)
    const currentref = useRef<any>(null)
    const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
    const [selectedLaunchNetworkData, setSelectedLaunchNetworkData] = useState<any>({})
    const connectionRequestModalToggle = () => {
        setConnectionRequestModal(!connectionRequestModal)
    }
    const [filters, setFilters] = useState({
        page: 1,
        skip: 0,
        limit: 12,
    })
    const [totalCount, setTotalCount] = useState(0)
    const [connectedLoader, setConnectedLoader] = useState(false)
    const [allLoader, setAllLoader] = useState(false)
    const [descPartner, setDescPartner] = useState(JSON.parse(JSON.stringify(initialDescPartner)))

    useEffect(() => {
        if (launchpadNetworkData && ref?.current?.clientHeight) {
            setAllDataHeight(ref?.current?.clientHeight)
        }
        if (assignedLaunchpadNetworkData && currentref?.current?.clientHeight) {
            setCurrentDataHeight(currentref?.current?.clientHeight)
        }
    }, [ref, launchpadNetworkData, assignedLaunchpadNetworkData, currentref])

    // category data
    const getCategoryAllData = async () => {
        await getAllLauchnetworkCategoryData()
            .then(async (res) => {
                if (res?.status === 200) {
                    let responseData = res && res?.data?.values || []
                    const getAllToolsAPIResponseData = responseData && responseData?.map((item: any, index: number) => {
                        return {
                            "_id": item?._id || "",
                            "name": item?.name || "",
                            "slug": item?.slug || "",
                            "isSelected": false
                        };
                    }) || []
                    const fetchAllToolsDataArray = await Promise.all(getAllToolsAPIResponseData)
                    setAllCategory(fetchAllToolsDataArray)
                    setAllCategory(fetchAllToolsDataArray)
                }
            })
            .catch((err) => {
                console.log(err, "Error")
            })
    }

    // category wise mentor data
    const getAllAndCategoryWiseAssignedLaunchnetwork = async () => {
        setConnectedLoader(true)
        await getAllAndCategoryWiseAssignedLaunchnetworkData()
            .then((res) => {
                setConnectedLoader(false)
                if (res?.status === 200) {
                    setAssignedLaunchpadNetworkData(res && res?.data?.values);
                }
            })
            .catch((err) => {
                setConnectedLoader(false)
                console.log(err, "Error")
            })
    }

    // category wise mentor data
    const getAllLunchNetworkCategoryData = async () => {
        setAllLoader(true)
        await getAllAndCategoryWiseLaunchnetworkData(selectedCategory && selectedCategory?.name, filters)
            .then((res) => {
                setAllLoader(false)
                if (res?.status === 200) {
                    setTotalCount(res?.data && res?.data?.total)
                    setLaunchpadNetworkData(res?.data && res?.data?.values);
                }
            })
            .catch((err) => {
                setAllLoader(false)
                console.log(err, "Error")
            })
    }

    const handleSetData = (selectedItem: any) => {
        setSelectedCategory(undefined)
        if (selectedItem.isSelected === false) {
            setSelectedCategory(selectedItem)
            selectedItem.isSelected = !selectedItem.isSelected
        } else {
            setSelectedCategory(undefined)
            selectedItem.isSelected = !selectedItem.isSelected
        }
    }


    const onChangePage = (page: number) => {
        const elm = document.getElementById('launch-network-view-container')
        if (elm) {
            elm.scrollIntoView(true)
        }
        let skip = (page - 1) * filters.limit
        setFilters(prev => ({ ...prev, page, skip }))
    }

    useEffect(() => {
        getCategoryAllData();
    }, [])

    useEffect(() => {
        getAllAndCategoryWiseAssignedLaunchnetwork();
    }, [refreshData])

    useEffect(() => {
        getAllLunchNetworkCategoryData();
    }, [selectedCategory, refreshData, filters])

    const onClickReadMore = async (partnerId: string, isGrant?: boolean, showGrantDesc?: boolean) => {
        setDescPartner({ partner: partnerId, isGrant: !!isGrant, showGrantDesc: !!showGrantDesc })
    }

    const onClickSearch = async (value: string) => {
        setAllLoader(true)
        await getAllAndCategoryWiseLaunchnetworkData('', { skip: 0, limit: 12, search: value })
            .then((res) => {
                setAllLoader(false)
                if (res?.status === 200) {
                    setTotalCount(res?.data && res?.data?.total)
                    setLaunchpadNetworkData(res?.data && res?.data?.values);
                }
            })
            .catch((err) => {
                setAllLoader(false)
                console.log(err, "Error")
            })
    }

    const totalPages = useMemo(() => Math.ceil(totalCount / filters.limit), [totalCount, filters])

    return (
        <>
            <div className='launch-network-section'>
                <div className='lead-section'>
                    <h5 className='heading-new-5 color-primary me-3'>Currently Connected to</h5>
                    {/* <div className='currently-filter text-center mt-lg-5 mt-md-3 mt-3 mb-lg-0 mb-md-4 mb-4'>
                        <p className='paragraph-new fw-bold mb-0'>Filter by</p>
                        <div className='currently-tab'>
                            {allCategory && allCategory?.map((item: any, i: any) => (
                                <button className='new-primary-button' style={{ border: assignedCategory === item ? "2px solid #4398FF" : "2px solid rgba(255, 255, 255, 0.25)" }} key={i} onClick={() => handleAssignesSetData(item)}>{item?.name}</button>
                            ))}
                        </div>
                    </div> */}
                    <div className={currentDataheight > 1000 ? 'connection-section scrolled mt-4' : 'connection-section mt-4'} ref={currentref}>
                        {connectedLoader && <div className='parent-full-height'>
                            <CircularProgress size={32} sx={{ color: "#ffffff" }} />
                        </div>}
                        {!connectedLoader && assignedLaunchpadNetworkData && assignedLaunchpadNetworkData?.length ?
                            <div className="row">
                                {assignedLaunchpadNetworkData?.map((item: any, i: any) => (
                                    <div className="col-lg-3 col-md-3 col-6 my-lg-3 my-2" key={i}>
                                        <div className='all-mentor-section text-center'>
                                            <div>
                                                <img className='img-fluid m-auto' src={item?.icon || NoImage} alt="" />
                                                <p className='paragraph-new mt-lg-3 mt-2 mb-0'>{item?.companyName || "No name available"}</p>
                                                <p className='paragraph-new-medium mt-lg-3 mt-2 mb-0'>{item?.title || "No title available"}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : !connectedLoader && <div className='parent-full-height'><p className='paragraph-new text-center' style={{ color: '#B7B7B7' }}>Nobody assigned yet.</p></div>
                        }
                    </div>
                </div>

                <div className='lead-section mt-lg-5 mt-3'>
                    <h4 className='heading-new-4 me-3 color-primary'>Our Launch Network</h4>
                    <div className='mt-lg-4 mt-md-3 mt-3 launch-filter' id="launch-network-view-container">
                        <div className='currently-filter text-center mb-lg-0 mb-md-4 mb-4'>
                            <p className='paragraph-new fw-bold mb-0 color-primary'>Filter by</p>
                            <div className='currently-tab'>
                                {allCategory && allCategory?.map((item: any, i: any) => (
                                    <span
                                        className={`${selectedCategory === item ? "selected" : ""}`}
                                        key={i}
                                        onClick={() => handleSetData(item)}
                                    >
                                        {item?.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                        {!allLoader && <div className="mt-3 flex justify-end">
                            <SearchBox onSearch={onClickSearch} />
                        </div>}
                        <div className={allDataheight > 1000 ? 'connection-section scrolled mt-4' : 'connection-section mt-4'} ref={ref}>
                            {allLoader && <div className='parent-full-height'>
                                <CircularProgress size={32} sx={{ color: "#ffffff" }} />
                            </div>}
                            {!allLoader && launchpadNetworkData && launchpadNetworkData?.length ?
                                <Grid container spacing={2}>
                                    {launchpadNetworkData && launchpadNetworkData?.map((item: any, i: any) => {
                                        const title = item?.companyOperations && item?.companyOperations.join(', ')
                                        return (
                                            <Grid item xs={6} md={3} key={i}>
                                                <div className='all-mentor-section text-center'>
                                                    <div>
                                                        <img
                                                            className='partner-logo-img m-auto'
                                                            src={item.icon || NoImage}
                                                            alt="logo"
                                                            onError={({ currentTarget }) => {
                                                                currentTarget.onerror = null;
                                                                currentTarget.src = NoImage;
                                                            }} />
                                                        <p className='paragraph-new mt-lg-3 mt-2 mb-0 cursor-pointer text-primary' style={{ fontWeight: 500 }} onClick={() => onClickReadMore(item._id)}>{item.companyName || '-'}</p>
                                                        <p className='limit-paragraph paragraph-new-medium mt-lg-3 mt-2 mb-0' title={title}>{title || '-'}</p>
                                                    </div>
                                                    {item?.requestStatus === "none" &&
                                                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' onClick={() => { setSelectedLaunchNetworkData(item); connectionRequestModalToggle() }}>Connect</button>
                                                    }
                                                    {item?.requestStatus === "requested" &&
                                                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "transparent", color: "#FFB800", border: "1px solid #FFB800" }}>Requested</button>
                                                    }
                                                    {item?.requestStatus === "rejected" &&
                                                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "transparent", color: "#FF0000", border: "1px solid #FF0000" }}>Not interested</button>
                                                    }
                                                    {item?.requestStatus === "connected" &&
                                                        <button className='new-primary-button mt-lg-3 mt-2 mb-0' style={{ background: "#4398FF", color: "#FFFFFF", border: "1px solid #4398FF" }}>Connected</button>
                                                    }
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid> : !allLoader && <div className='parent-full-height'><p className='paragraph-new text-center'>No data available</p></div>
                            }
                        </div>
                    </div>
                </div>
                {totalPages > 1 ? <CustomPagination
                    page={filters.page}
                    total={totalPages}
                    onChange={onChangePage}
                /> : null}
            </div>
            <ConnectionRequestModel
                isOpen={connectionRequestModal}
                toggle={connectionRequestModalToggle}
                name={selectedLaunchNetworkData.companyName || ''}
                id={selectedLaunchNetworkData._id || ''}
                icon={selectedLaunchNetworkData.icon || ''}
                userType={"launch-network"}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
            />
            <DescriptionModal
                isOpen={!!descPartner.partner}
                toggle={() => setDescPartner({ ...initialDescPartner })}
                descPartner={descPartner}
            />
        </>
    )
}

export default Launchnetwork