import React, { useState } from 'react';
import './dashbord-top-section.sass';
import Accelerate from '../../../../../assests/images/accelerate/accelerate.svg';
import BannerIMG from '../../../../../assests/images/accelerate/pages/dashboard-bg.svg';
import NoImage from '../../../../../assests/images/homepage/no-image-available.jpg';

import Timer from '../../../../../assests/images/accelerate/pages/timer.svg';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProvideFeedbackModel from '../../../vc-dashboard/components/vc-all-projects/components/provide-feedback-model/provide-feedback-model';
import UpdateSymbioteScoreModel from '../../../vc-dashboard/components/vc-all-projects/components/update-symbioteScore-model/update-symbioteScore-model';
import { getloggedInUserData, isLoggedIn, loggedInAs } from '../../../../../constants/utils';
import SearchBox from './search-box/search-box';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { toast } from 'react-toastify';
import { fileUploadAPI } from 'src/apis/common';
import { updateUserPhotoAPI } from 'src/pages/accelerate-pages/all-form/API/APICall';
import LoaderLayout from 'src/shared/layouts/loader-layout/LoaderLayout';

interface detailsProps {
    scoreFeedback?: any,
    setScoreFeedback?: any,
    userData: any
}

export const DashbordTopSection = ({ scoreFeedback, setScoreFeedback, userData }: detailsProps) => {

    const [bannerPreview, setBannerPreview] = useState<string | null>(null)
    const [iconPreview, setIconPreview] = useState<string | null>(null)
    const [profileLoader, setProfileLoader] = useState(false)
    const [loaderMsg, setLoaderMsg] = useState('')

    const [provideFeedbackModel, setProvideFeedbackModel] = useState<boolean>(false)
    const provideFeedbackModelToggle = () => setProvideFeedbackModel(!provideFeedbackModel)
    const [updateSymbioteScoreModel, setUpdateSymbioteScoreModel] = useState<boolean>(false)
    const updateSymbioteScoreModelToggle = () => setUpdateSymbioteScoreModel(!updateSymbioteScoreModel)

    const nav = useNavigate()
    const location = useLocation();
    const isUserLoggedIn = isLoggedIn()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const isProjectPublicProfile = pathname.includes("/public-profile-project")
    const isVCProfile = pathname.includes("/public-profile-vc")
    const isMentorProjects = pathname.includes("/public-profile-mentor")
    const isProjectDashboard = pathname.includes("/project/")
    const isProjectProfile = pathname.includes("/project-view/profile")

    const onChangeFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files![0] || null
        const key = e.target.name as 'icon' | 'banner'
        if (!file) return
        const objectUrl = URL.createObjectURL(file)

        if (key === 'icon') {
            setIconPreview(objectUrl)
        } else if (key === 'banner') {
            setBannerPreview(objectUrl)
        }
        onFileUpload(file, key)
    }


    const onFileUpload = async (file: any, imgType: 'icon' | 'banner') => {
        try {
            setProfileLoader(true)
            setLoaderMsg('Uploading image please wait...')
            const { link } = await fileUploadAPI(file)
            setLoaderMsg('Updating profile...')
            await updateUserPhotoAPI({ imageLocation: link, imgType })
            toast.success('Profile Updated Successfully')
            setProfileLoader(false)
            setLoaderMsg('')
        } catch (ex: any) {
            setProfileLoader(false)
            setLoaderMsg('')
            toast.error(ex.message)
        }
    }

    const onClickBackToDashboard = () => {
        const { mentor, vc, partner, project } = loggedInAs()
        if (mentor) return nav('/mentor-dashboard')
        else if (vc) return nav('/vc-dashboard')
        else if (partner) return nav('/partner-dashboard')
        else if (project) return nav('/project/dashboard')
    }

    const showBannerEdit = isUserLoggedIn && isProjectProfile
    const showProfileIconEdit = isUserLoggedIn && isProjectProfile

    return (
        <LoaderLayout isLoading={profileLoader} loaderMsg={loaderMsg}>
            <div>
                {isProjectPublicProfile &&
                    <div className='d-flex demo-title'>
                        <img src={Timer} alt="" />
                        <h5 className='heading-new-5 d-flex align-items-center justify-content-center'><span className='heading-new-5' style={{ color: "#65DB6A" }}>Get ready</span>for Demo Day</h5>
                    </div>
                }

                <div className='dashboard-profile-section mt-2'>
                    <div className="container container-maxwidth profil-text">
                        {(isVCProfile || isMentorProjects) &&
                            <div className='back-btn'>
                                <Link className='paragraph-new-medium mb-0' to={isVCProfile ? '/vc-dashboard' : '/mentor-dashboard'}>Back to Dashboard</Link>
                            </div>
                            //  :
                            // <img className='dashboard-title img-fluid' src={Accelerate} alt="" />
                        }
                    </div>
                    <div className='mt-lg-4 mt-2'>
                        <div className='profile-banner'>
                            <img className='img-fluid w-100' src={bannerPreview || userData?.banner} alt="" />
                            {showBannerEdit && <div className='absolute top-0 left-0 w-full h-full bg-[#00000094] flex justify-center items-center z-0'>
                                <input type="file" id="banner-file-upload" name="banner" onChange={onChangeFileHandler} className='hidden' />
                                <label htmlFor='banner-file-upload'>
                                    <div className='p-[14px] bg-[#00000091] rounded-full cursor-pointer'>
                                        <CameraAltIcon sx={{ color: 'white' }} />
                                    </div>
                                </label>
                            </div>}
                        </div>
                        <div className='container container-maxwidth profil-text'>
                            <div className='profil-text-section '>
                                <div className='d-flex align-items-end profile-btn'>
                                    <div className='user-profile relative'>
                                        <img src={iconPreview || userData?.icon} className='img-fluid' alt="" style={{ background: 'grey' }} />
                                        {showProfileIconEdit && <div className='absolute top-0 left-0 w-full h-full bg-[#00000094] z-0 flex justify-center items-center'>
                                            <input type="file" id="icon-file-upload" name="icon" onChange={onChangeFileHandler} className='hidden' />
                                            <label htmlFor='icon-file-upload'>
                                                <div className='p-[8px] md:p-[14px] bg-[#00000091] rounded-full cursor-pointer'>
                                                    <CameraAltIcon sx={{ color: 'white' }} className='w-[10px] md:w-[20px]' />
                                                </div>
                                            </label>
                                        </div>}
                                    </div>
                                    {isVCProfile &&
                                        <div className='d-lg-none d-md-none d-block'>
                                            <div className='mb-2 flex flex-col'>
                                                {userData?.isAccelerateProject &&
                                                    <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                        <span className='anim-span'></span>
                                                        <span className='anim-span anim-2'></span>
                                                        <span className='anim-span anim-3'></span>
                                                        <span className='anim-span anim-4'></span>
                                                        <span className='anim-span anim-5'></span>
                                                    </button>
                                                }
                                                {userData?.cohort?.name &&
                                                    <p className='new-primary-button-small mb-0'>{userData?.cohort?.name}</p>
                                                }
                                            </div>
                                            <div className='deshboard-social d-flex pt-1 mb-lg-4 mb-md-4 mb-0'>
                                                {userData?.linkedInProfileLink && userData?.linkedInProfileLink?.length > 0 &&
                                                    <a href={userData?.linkedInProfileLink} target='_blank'>
                                                        <i className="ri-linkedin-fill"></i>
                                                    </a>
                                                }
                                                {userData?.twitterLink && userData?.twitterLink?.length > 0 &&
                                                    <a href={userData?.twitterLink} target='_blank'>
                                                        <i className="ri-twitter-fill"></i>
                                                    </a>
                                                }
                                                {userData?.telegramLink && userData?.telegramLink?.length > 0 &&
                                                    <a href={userData?.telegramLink} target='_blank'>
                                                        <i className="ri-send-plane-fill"></i>
                                                    </a>
                                                }
                                                {userData?.websiteLink && userData?.websiteLink?.length > 0 &&
                                                    <a href={userData?.websiteLink} target='_blank'>
                                                        <i className="ri-link"></i>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {isMentorProjects &&
                                        <div className='d-lg-none d-md-none d-block'>
                                            <div className='mb-2'>
                                                {userData?.isAccelerateProject &&
                                                    <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                        <span className='anim-span'></span>
                                                        <span className='anim-span anim-2'></span>
                                                        <span className='anim-span anim-3'></span>
                                                        <span className='anim-span anim-4'></span>
                                                        <span className='anim-span anim-5'></span>
                                                    </button>
                                                }
                                                {userData?.cohort?.name &&
                                                    <p className='new-primary-button-small mb-0'>{userData?.cohort?.name}</p>
                                                }
                                            </div>
                                            <div className='deshboard-social d-flex pt-1 mb-lg-4 mb-md-4 mb-0'>
                                                {userData?.linkedInProfileLink && userData?.linkedInProfileLink?.length > 0 &&
                                                    <a href={userData?.linkedInProfileLink} target='_blank'>
                                                        <i className="ri-linkedin-fill"></i>
                                                    </a>
                                                }
                                                {userData?.twitterLink && userData?.twitterLink?.length > 0 &&
                                                    <a href={userData?.twitterLink} target='_blank'>
                                                        <i className="ri-twitter-fill"></i>
                                                    </a>
                                                }
                                                {userData?.telegramLink && userData?.telegramLink?.length > 0 &&
                                                    <a href={userData?.telegramLink} target='_blank'>
                                                        <i className="ri-send-plane-fill"></i>
                                                    </a>
                                                }
                                                {userData?.websiteLink && userData?.websiteLink?.length > 0 &&
                                                    <a href={userData?.websiteLink} target='_blank'>
                                                        <i className="ri-link"></i>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {!isProjectDashboard &&
                                        <div className='d-lg-block d-md-block d-none'>
                                            <h3 className='duelist-king mb-lg-4 mb-3 ms-3'>{userData?.companyName || "No name available"}</h3>
                                        </div>
                                    }
                                    {isProjectPublicProfile &&
                                        <div className='mb-lg-4 mb-3 ms-3 d-flex align-items-center gap-2'>
                                            {userData?.isAccelerateProject &&
                                                <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                    <span className='anim-span'></span>
                                                    <span className='anim-span anim-2'></span>
                                                    <span className='anim-span anim-3'></span>
                                                    <span className='anim-span anim-4'></span>
                                                    <span className='anim-span anim-5'></span>
                                                </button>
                                            }
                                            {userData?.cohort?.name &&
                                                <p className='new-primary-button-small mb-0'>{userData?.cohort?.name}</p>
                                            }
                                        </div>
                                    }
                                    {isProjectProfile &&
                                        <>
                                            <div className='mb-lg-4 mb-3 ms-3 d-flex align-items-center gap-2'>
                                                {userData?.isAccelerateProject &&
                                                    <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                        <span className='anim-span'></span>
                                                        <span className='anim-span anim-2'></span>
                                                        <span className='anim-span anim-3'></span>
                                                        <span className='anim-span anim-4'></span>
                                                        <span className='anim-span anim-5'></span>
                                                    </button>
                                                }
                                                {userData?.cohort?.name &&
                                                    <p className='new-primary-button-small mb-0'>{userData?.cohort?.name}</p>
                                                }
                                            </div>
                                        </>
                                    }
                                    {isProjectDashboard &&
                                        <div className='mb-lg-5 ms-lg-3 ms-md-3 ms-0 mb-4 d-flex align-items-center gap-2'>
                                            {userData?.isAccelerateProject &&
                                                <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                                                    <span className='anim-span'></span>
                                                    <span className='anim-span anim-2'></span>
                                                    <span className='anim-span anim-3'></span>
                                                    <span className='anim-span anim-4'></span>
                                                    <span className='anim-span anim-5'></span>
                                                </button>
                                            }
                                            {userData?.cohort?.name &&
                                                <p className='new-primary-button-small mb-0'>{userData?.cohort?.name}</p>
                                            }
                                        </div>
                                    }
                                </div>

                                {/* {isVCProfile &&
                                    <div className='mb-lg-4 mb-3 d-lg-block d-md-block d-none text-center'>
                                        <button className='new-color-button'>Connect to Project</button>
                                    </div>
                                } */}
                                {(isMentorProjects && userData && userData.isMentoring) &&
                                    <div className='mb-lg-4 mb-3 d-flex align-items-center justify-content-center mt-lg-0 mt-md-0 mt-3 gap-3'>
                                        {/* <button className='new-primary-button fw-bold' onClick={() => provideFeedbackModelToggle()}>Feedback</button> */}
                                        <button className='new-primary-button fw-bold' onClick={() => updateSymbioteScoreModelToggle()}>Update Score</button>
                                    </div>
                                }


                                <div className="flex items-start justify-center">
                                    <div className='deshboard-social mb-lg-5 mb-3 d-lg-flex d-md-flex d-none'>
                                        {userData?.linkedInProfileLink && userData?.linkedInProfileLink?.length > 0 &&
                                            <a href={userData?.linkedInProfileLink} target='_blank'>
                                                <i className="ri-linkedin-fill"></i>
                                            </a>
                                        }
                                        {userData?.twitterLink && userData?.twitterLink?.length > 0 &&
                                            <a href={userData?.twitterLink} target='_blank'>
                                                <i className="ri-twitter-fill"></i>
                                            </a>
                                        }
                                        {userData?.telegramLink && userData?.telegramLink?.length > 0 &&
                                            <a href={userData?.telegramLink} target='_blank'>
                                                <i className="ri-send-plane-fill"></i>
                                            </a>
                                        }
                                        {userData?.websiteLink && userData?.websiteLink?.length > 0 &&
                                            <a href={userData?.websiteLink} target='_blank'>
                                                <i className="ri-link"></i>
                                            </a>
                                        }
                                    </div>
                                </div>


                                {/* {isVCProfile &&
                                    <div className='mb-4 d-lg-none d-md-none d-block text-center'>
                                        <button className='new-color-button'>Connect to Project</button>
                                    </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
                {isProjectProfile &&
                    <div className='container container-maxwidth mt-lg-5 mt-md-3 mt-2'>
                        <div className='d-flex align-items-center gap-3 mt-3 d-lg-none d-md-none d-block'>
                            <div className='d-lg-none d-md-none d-block' >
                                <button className='new-color-button' onClick={() => nav("/forms/project-view/profile")}>Your Profile</button>
                            </div>
                            <div className='form-slider '>
                                <div className='percentage-slider text-center gap-2 align-items-center m-auto d-flex'>
                                    <CircularProgressbar value={userData?.profileCompleted || 0} text={`${Number(userData?.profileCompleted || 0).toFixed(0)}%`} background
                                        backgroundPadding={0}
                                        styles={buildStyles({
                                            backgroundColor: "transparent",
                                            textColor: "#fff",
                                            pathColor: "#FFB800",
                                            trailColor: "white"
                                        })} />
                                    <p className='paragraph-new-small mb-0 text-center' style={{ color: "#FFB800" }}>Completed</p>
                                </div>
                            </div>
                        </div>
                        <div className='profile-section d-flex align-items-center justify-content-between'>
                            <div className='back-btn d-lg-block d-md-block d-none'>
                                <p className='paragraph-new-medium mb-0' role='button' onClick={onClickBackToDashboard}>Back to Dashboard</p>
                            </div>
                            <div className='your-profile d-lg-block d-md-block d-none'>
                                <h2 className='heading-new-2'>Your Profile</h2>
                            </div>
                            <div className='public-btn m-lg-0 m-md-0 m-auto mt-lg-0 mt-md-0 mt-3'>
                                {isProjectProfile &&
                                    <button className='new-primary-button' onClick={() => nav(`/profile/public-profile-${isProjectProfile ? "project" : isMentorProjects ? "mentor" : "vc"}/${getloggedInUserData()?._id || ''}`)}><i className="ri-eye-fill me-2"></i>View as Public Profile</button>
                                }
                                {!isProjectProfile &&
                                    <button className='new-primary-button'><i className="ri-eye-fill me-2"></i>Viewing Public Profile</button>
                                }
                            </div>
                        </div>
                        <div className='form-slider mt-lg-4 mt-3 justify-content-center d-lg-flex d-md-flex d-none'>
                            <div className='percentage-slider gap-4 text-center m-auto align-items-center d-flex'>
                                <CircularProgressbar value={userData?.profileCompleted || 0} text={`${Number(userData?.profileCompleted || 0).toFixed(0)}%`} background
                                    backgroundPadding={0}
                                    styles={buildStyles({
                                        backgroundColor: "transparent",
                                        textColor: "#fff",
                                        pathColor: "#FFB800",
                                        trailColor: "white"
                                    })} />
                                <p className='paragraph-new-small mb-0 text-center' style={{ color: "#FFB800" }}>Completed</p>
                            </div>
                        </div>
                        {userData?.projectAvgScore < 100 ?
                            <div className='text-center mt-lg-4 mt-3'>
                                <h6 className='heading-new-6 fw-light'>Please enter any missing details in the form to complete your profile</h6>
                                <p className='paragraph-new-medium mb-0 mt-2 fw-medium' style={{ color: "#848484" }}>Fields and sections marked in Orange will guide you to the unfilled data sections</p>
                            </div>
                            : <p className='paragraph-new-medium text-decoration-underline text-center'>Edit the form</p>
                        }
                    </div>
                }
            </div>
            <ProvideFeedbackModel isOpen={provideFeedbackModel} toggle={provideFeedbackModelToggle} />
            <UpdateSymbioteScoreModel isOpen={updateSymbioteScoreModel} toggle={updateSymbioteScoreModelToggle} scoreFeedback={scoreFeedback} setScoreFeedback={setScoreFeedback} userData={userData} />
        </LoaderLayout>
    )
}
