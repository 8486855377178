import React, { useState } from 'react'
import "./connection-status-table.sass";
import partnerTableIcon from '../../../../../../../assests/images/homepage/partner-table-icon.svg'
import NoImage from '../../../../../../../../assests/images/homepage/no-image-available.jpg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import CircularProgress from '@mui/material/CircularProgress';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Avatar, Pagination } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ConnectionRequestModel from '../connection-request-model/connection-request-model';
import { RadioDropdown } from '../../../../../vc-dashboard/components/radio-dropdown/radio-dropdown';
import { ToastContainer, toast } from 'react-toastify';

interface Column {
    id: string;
    label: string;
    minWidth?: number | string;
    maxWidth?: number
}

export interface TableFilterDAta {
    tabValue: any,
    partnerData?: any,
    userType: string,
    bigData?: string,
    setRefreshData: any
    refreshData: boolean
    isLoading?: boolean
    onClickReadMore?: (id: string) => void
    tableColumns: { id: string, label: string, minWidth: number, maxWidth?: number }[]
}

const ConnectionStatusTable = (props: TableFilterDAta) => {

    const { tabValue, partnerData, userType, bigData, setRefreshData, refreshData, isLoading = false, onClickReadMore, tableColumns } = props
    const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
    const [selectedPartnerData, setSelectedPartnerData] = useState<any>({})

    const connectionRequestModalToggle = () => {
        setConnectionRequestModal(!connectionRequestModal)
    }
    const nav = useNavigate()
    const location = useLocation();
    const pathname = location.pathname;
    const isProfileView = pathname.includes("/project/dashboard")

    const isShowViewMore = (description: string) => {
        return description?.endsWith('...')
    }

    return (
        <>
            {/* {tabValue === 3 &&
                <div className="table-filter-content">
                    <div className="heading-table">
                        <p className='paragraph-new fw-bold mb-0'>Filter List by</p>
                        {dropdownData && dropdownData.map((item: any, index: number) => (
                            <RadioDropdown
                                key={index}
                                BtnName={item.btnName}
                                dropdownValues={item.dropdown}
                                name={undefined}
                            />
                        ))}
                    </div>
                </div>
            } */}

            <div className={bigData ? "partner-list-data big-data-value" : "partner-list-data"}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 550 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ minWidth: column.minWidth, paddingLeft: 0, maxWidth: column.maxWidth || 'auto' }}
                                        >
                                            <span className='fw-bold color-primary'>{column.label}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading && <TableRow>
                                    <TableCell sx={{ height: 200 }} colSpan={tableColumns.length} align='center'>
                                        <CircularProgress size={32} sx={{ color: "#ffffff" }} />
                                    </TableCell>
                                </TableRow>
                                }
                                {!isLoading && partnerData && partnerData.length ? partnerData?.map((row: any, i: number) => {

                                    const twitterLink = row?.twitterLink ? `https://twitter.com/${row?.twitterLink}` : ''
                                    const telegramLink = row?.telegramId ? `https://t.me/${row?.telegramId}` : ''
                                    const websiteLink = row?.companyWebsiteLink || ''
                                    const linkedinLink = row?.linkedInProfileLink || ''
                                    const name = userType === "vc" ? row?.name : row?.companyName
                                    const column2 = userType === "vc" ?
                                        row?.sectorsLikeToInvest && row?.sectorsLikeToInvest.join(', ') || '-'
                                        : row?.companyOperations && row?.companyOperations.join(', ') || '-'
                                    const column3 = userType === "vc" ? row?.stageOfInvests : row?.industriesOfExpertise!.join(', ') || "-"
                                    return (
                                        <TableRow style={{ fontSize: '4rem' }} key={i}>
                                            <TableCell onClick={() => userType === "vc" ? nav(`/public-view/vc/${row?._id}?user-type=project`) : nav(`/public-view/partner/${row?._id}`)} role={userType === "vc" ? "button" : ""}>
                                                <div className='d-flex align-items-center'>
                                                    <Avatar alt="Remy Sharp" className='img-fluid mt-0' src={row?.icon} />
                                                </div>
                                            </TableCell>
                                            <TableCell onClick={() => userType === "vc" ? nav(`/public-view/vc/${row?._id}?user-type=project`) : nav(`/public-view/partner/${row?._id}`)} role={userType === "vc" ? "button" : ""}>
                                                <div className='d-flex align-items-center'>
                                                    <p className={`paragraph-new-medium mb-0 mt-0 fw-bold hover-primary color-primary cursor-pointer`}>{name}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <p className='paragraph-new-medium table-description mb-0' title={column2}>
                                                    {column2}
                                                </p>
                                            </TableCell>
                                            <TableCell className=''>
                                                <p className='paragraph-new-medium table-description mb-0' title={column3}>
                                                    {column3}
                                                    {/* {userType !== "vc" && <span className="view-more-action" onClick={() => onClickReadMore!(row?._id)}>READ MORE</span>} */}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <ul className='social-link-table'>
                                                    {twitterLink && <li><a href={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                                                    {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                                                    {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                                                    {linkedinLink && <li><a href={linkedinLink} target='_blank'><i className="ri-linkedin-fill"></i></a></li>}
                                                </ul>
                                            </TableCell>
                                            <TableCell className=''>
                                                {row?.requestStatus === "none" &&
                                                    <button className='request-to-onnect-btn' onClick={() => { setSelectedPartnerData(row); connectionRequestModalToggle() }}>Request to Connect</button>
                                                }
                                                {row?.requestStatus === "requested" &&
                                                    <button className='request-to-onnect-btn yellow-border'>Requested</button>
                                                }
                                                {row?.requestStatus === "rejected" &&
                                                    <button className='request-to-onnect-btn red-border'>Not interested</button>
                                                }
                                                {row?.requestStatus === "connected" &&
                                                    <button className='request-to-onnect-btn green-border'>Connected</button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                ) : !isLoading && <TableRow>
                                    <TableCell sx={{ height: 200 }} colSpan={tableColumns.length} align='center'>
                                        <span>No data found</span>
                                    </TableCell>
                                </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <ConnectionRequestModel
                isOpen={connectionRequestModal}
                toggle={connectionRequestModalToggle}
                name={userType === "vc" ? selectedPartnerData?.name : selectedPartnerData?.companyName}
                id={selectedPartnerData?._id}
                icon={selectedPartnerData?.icon}
                userType={userType}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
            />
        </>
    );
};

export default ConnectionStatusTable;
