import axios from "axios";
import { GET_ALL_MENTORS_DATA, GET_ALL_MENTORS_PROJECT_DATA, GET_ALL_VC_PROJECT_DATA, GET_MENTORS_PROJECT_CONNECTION_REQUEST_DATA, GET_MENTOR_USER_DATA, GET_VC_PROJECT_CONNECTION_REQUEST_DATA, GET_VC_USER_DATA, POST_APPROVE_MENTOR_CONNECTION_REQUEST, POST_APPROVE_VC_CONNECTION_REQUEST, POST_PROJECT_SUBMIT_DATA, POST_REJECT_MENTOR_CONNECTION_REQUEST, POST_REJECT_VC_CONNECTION_REQUEST, POST_VC_SUBMIT_DATA, symbioteApiBaseUrl } from "../../../../constants";
import { log } from "console";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "multipart/form-data"
  };
}

const postBodyHeadersJson = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "application/json"
  };
}

const getvcUserURL = GET_VC_USER_DATA
const getvcUserByIdURL = POST_VC_SUBMIT_DATA
const getMentorUserURL = GET_MENTOR_USER_DATA
const getMentorUserByIdURL = GET_ALL_MENTORS_DATA
const getProjectMentorOrVCURL = POST_PROJECT_SUBMIT_DATA
const getMentorProjectURL = GET_ALL_MENTORS_PROJECT_DATA
const getVCProjectURL = GET_ALL_VC_PROJECT_DATA
const getMentorProjectConnectionRequestURL = GET_MENTORS_PROJECT_CONNECTION_REQUEST_DATA
const postRequestMentorApproveURL = POST_APPROVE_MENTOR_CONNECTION_REQUEST
const postRequestMentorRejectURL = POST_REJECT_MENTOR_CONNECTION_REQUEST
const getVCProjectConnectionRequestURL = GET_VC_PROJECT_CONNECTION_REQUEST_DATA
const postRequestVCApproveURL = POST_APPROVE_VC_CONNECTION_REQUEST
const postRequestVCRejectURL = POST_REJECT_VC_CONNECTION_REQUEST


const getMentorOrVCUserData = async (userType: string) => {
  let Url = "";
  if (userType === "vc-dashboard") {
    Url = getvcUserURL
  } else if (userType === "mentor-dashboard") {
    Url = getMentorUserURL
  } else if (userType === 'partner-dashboard') {
    Url = `${symbioteApiBaseUrl}/api/v1/partner/get/me`
  }
  const res: any = await axios.get(Url, { headers: postBodyHeaders() })
  return res;
};

const getProjectMentorOrVCData = async () => {
  const res: any = await axios.get(getProjectMentorOrVCURL, { headers: postBodyHeaders() })
  return res;
};

const getMentorOrVCProjectConnectionRequestData = async (userType: string) => {
  let Url = "";
  if (userType === "/vc-dashboard") {
    Url = getVCProjectConnectionRequestURL
  } else if (userType === "/mentor-dashboard") {
    Url = getMentorProjectConnectionRequestURL
  } else if (userType === "/partner-dashboard") {
    Url = `${symbioteApiBaseUrl}/api/v1/partner/request/pending`
  }
  const res: any = await axios.get(Url, { headers: postBodyHeaders() })
  return res;
};

const getMyProjectMentorOrVCData = async (projectType: boolean, sectionName: string) => {
  let apiUrl = "";
  if (sectionName === "mentor-dashboard") {
    apiUrl = getMentorProjectURL + "?support=" + projectType
  } else if (sectionName === "vc-dashboard") {
    apiUrl = getVCProjectURL
  }
  else if (sectionName === "partner-dashboard") {
    apiUrl = `${symbioteApiBaseUrl}/api/v1/partner/projects`
  }
  const res: any = await axios.get(apiUrl, { headers: postBodyHeaders() })
  return res;
};

const postRequestMentorApproveAndReject = async (requestId: any, oparationType: string, sectionName: string) => {
  let apiUrl = "";
  if (sectionName === "/mentor-dashboard") {
    if (oparationType === "approve") {
      apiUrl = postRequestMentorApproveURL
    } else {
      apiUrl = postRequestMentorRejectURL
    }
  } else if (sectionName === "/vc-dashboard") {
    if (oparationType === "approve") {
      apiUrl = postRequestVCApproveURL
    } else {
      apiUrl = postRequestVCRejectURL
    }
  } else if (sectionName === "/partner-dashboard") {
    if (oparationType === "approve") {
      apiUrl = `${symbioteApiBaseUrl}/api/v1/partner/request/approve/`
    } else {
      apiUrl = `${symbioteApiBaseUrl}/api/v1/partner/request/reject/`
    }
  }

  const res: any = await axios.post(apiUrl + requestId, {}, { headers: postBodyHeaders() })
  return res;
};

const getProjectVcTagWiseData = async (tag: string) => {
  const res: any = await axios.get(getProjectMentorOrVCURL + "?tag=" + tag, { headers: postBodyHeaders() })
  return res;
};

const getMentorOrVCUserByIdData = async (userId: string, userType: string) => {
  let Url = "";
  if (userType === "vc-dashboard") {
    Url = getvcUserByIdURL
  } else if (userType === "mentor-dashboard") {
    Url = getMentorUserByIdURL
  } else if (userType === "partner-dashboard") {
    Url = `${symbioteApiBaseUrl}/api/v1/partner`
  }
  const res: any = await axios.get(Url + '/' + userId, { headers: postBodyHeaders() })
  return res;
};

const setProjectInterestAPI = async (projectId: string, userType: 'mentor' | 'vc' | 'partner') => {
  let url = symbioteApiBaseUrl
  if (userType === 'mentor') {
    url = url.concat(`/api/v1/projects/mentor/interest/${projectId}`)
  } else if (userType === 'vc') {
    url = url.concat(`/api/v1/projects/vc/interest/${projectId}`)
  } else {
    url = url.concat(`/api/v1/projects/partner/interest/${projectId}`)
  }
  const res: any = await axios.post(url, {}, { headers: postBodyHeaders() })
  return res;
};


export {
  getMentorOrVCUserData,
  getProjectMentorOrVCData,
  getMentorOrVCProjectConnectionRequestData,
  getMyProjectMentorOrVCData,
  postRequestMentorApproveAndReject,
  getProjectVcTagWiseData,
  getMentorOrVCUserByIdData,
  setProjectInterestAPI
};