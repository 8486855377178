import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AddServiceRequest from '../new-request/AddServiceRequest';
import { getAllServiceRequestAPI } from '../api';
import moment from 'moment';

interface IServiceRequest {
  project: {
    name: string
  }
  title: string
  type: string
  status: 'ongoing' | 'pending' | 'completed' | 'unresolved'
  details: string
  attachments: string[]
  createdAt: number // unix timestamp
  requestStartDate: number // unix timestamp
  requestEndDate: number // unix timestamp
}

export default function ServiceRequests() {
  const nav = useNavigate()

  const [inProgressRequests, setInProgressRequests] = useState<IServiceRequest[]>([])
  const [completedRequests, setCompletedRequests] = useState<IServiceRequest[]>([])
  const [pendingRequests, setPendingRequests] = useState<IServiceRequest[]>([])
  const [unResolvedRequests, setUnResolvedRequests] = useState<IServiceRequest[]>([])

  useEffect(() => {
    getServiceRequests('ongoing')
    getServiceRequests('completed')
    getServiceRequests('pending')
    getServiceRequests('unresolved')
  }, [])

  const getServiceRequests = async (status?: 'ongoing' | 'pending' | 'completed' | 'unresolved') => {
    try {
      const { values, total } = await getAllServiceRequestAPI(status)
      if (status === 'ongoing') {
        setInProgressRequests(values)
      } else if (status === 'completed') {
        setCompletedRequests(values)
      } else if (status === 'pending') {
        setPendingRequests(values)
      } else if (status === "unresolved") {
        setUnResolvedRequests(values)
      }
    } catch (ex) { }
  }

  const totalRequests = inProgressRequests.length + pendingRequests.length + completedRequests.length + unResolvedRequests.length

  console.log(totalRequests, "totalRequests")

  return (
    <div className=''>
      <div className='flex justify-between items-center'>
        <h3 className='text-3xl font-semibold color-primary'>Service Requests</h3>
        <button
          className='text-white bg-primary px-4 py-1 rounded-xl font-semibold outline-none border-none'
          onClick={() => nav('/project/requests/new')}
        >Submit a new request</button>
      </div>

      {
        !totalRequests && <div className='w-full text-center my-8 text-white'>
          <span>You Don't have any request</span>
        </div>
      }

      {inProgressRequests && inProgressRequests.length ? <div className='grid gap-4 mt-3'>
        <div>
          <span className='bg-[#FFB800] text-black font-semibold px-2 py-1 rounded-lg '>In Progress</span>
        </div>
        {
          inProgressRequests?.map((item, index: number) => {
            return <InProgressRequestCard
              key={index}
              date={moment(item?.createdAt * 1000).format('DD MMM yyyy')}
              title={item.title}
              details={item.details}
            />
          })
        }
      </div> : null}

      {pendingRequests && pendingRequests.length ? <div className='grid gap-4 mt-12'>
        <div>
          <span className='bg-[gray] text-white font-semibold px-2 py-1 rounded-lg '>Pending</span>
        </div>
        {
          pendingRequests?.map((item, index: number) => {
            return <PendingRequestCard
              key={index}
              date={moment(item?.createdAt * 1000).format('DD MMM yyyy')}
              title={item.title}
              details={item.details}
            />
          })
        }
      </div> : null}

      {completedRequests && completedRequests.length ? <div className='grid gap-4 mt-12'>
        <div>
          <span className='bg-[#10AA3B] text-black font-semibold px-2 py-1 rounded-lg '>Completed</span>
        </div>
        {
          completedRequests?.map((item, index: number) => {
            return <CompletedRequestCard
              key={index}
              date={moment(item?.createdAt * 1000).format('DD MMM yyyy')}
              title={item.title}
              details={item.details}
              resolvedDate={moment(item?.requestEndDate * 1000).format('DD MMM yyyy')}
            />
          })
        }
      </div> : null}

      {unResolvedRequests && unResolvedRequests.length ? <div className='grid gap-4 mt-12'>
        <div>
          <span className='bg-[red] text-white font-semibold px-2 py-1 rounded-lg '>Un-Resolved</span>
        </div>
        {
          unResolvedRequests?.map((item, index: number) => {
            return <UnResolvedRequestCard
              key={index}
              date={moment(item?.createdAt * 1000).format('DD MMM yyyy')}
              title={item.title}
              details={item.details}
            />
          })
        }
      </div> : null}

    </div>
  )
}


const InProgressRequestCard = (props: { date: string, title: string, details: string }) => {
  return <div className='flex justify-between items-center rounded-xl text-white p-4' style={{ border: '1px solid #FFB800' }}>
    <table>
      <thead>
        <tr className=''>
          <th className='min-w-[120px] text-primary font-semibold text-sm p-1'>Date Raised</th>
          <th className='text-primary font-semibold text-sm p-1 w-[400px]'>Request</th>
          <th className='text-primary font-semibold text-sm p-1'>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='font-semibold text-sm align-top p-1'>{props.date}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.title}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.details}</td>
        </tr>
      </tbody>
    </table>
  </div>
}

const CompletedRequestCard = (props: { date: string, title: string, details: string, resolvedDate: string }) => {
  return <div className='flex justify-between items-center rounded-xl text-white p-4' style={{ border: '1px solid #10AA3B' }}>
    <table>
      <thead>
        <tr>
          <th className='min-w-[120px] text-primary font-semibold text-sm p-1'>Date Raised</th>
          <th className='text-primary font-semibold text-sm p-1 w-[400px]'>Request</th>
          <th className='text-primary font-semibold text-sm min-w-[120px] p-1'>Resolved on</th>
          <th className='text-primary font-semibold text-sm min-w-[140px] p-1'>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='font-semibold text-sm align-top p-1'>{props.date}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.title}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.resolvedDate}</td>
          <td className='font-semibold text-sm p-1'>
            <button className='bg-primary px-2 py-1 rounded-lg'>Re-Raise Issue</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
}

const PendingRequestCard = (props: { date: string, title: string, details: string }) => {
  return <div className='flex justify-between items-center rounded-xl text-white p-4' style={{ border: '1px solid gray' }}>
    <table>
      <thead>
        <tr className=''>
          <th className='min-w-[120px] text-primary font-semibold text-sm p-1'>Date Raised</th>
          <th className='text-primary font-semibold text-sm p-1 w-[400px]'>Request</th>
          <th className='text-primary font-semibold text-sm p-1'>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='font-semibold text-sm align-top p-1'>{props.date}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.title}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.details}</td>
        </tr>
      </tbody>
    </table>
  </div>
}

const UnResolvedRequestCard = (props: { date: string, title: string, details: string }) => {
  return <div className='flex justify-between items-center rounded-xl text-white p-4' style={{ border: '1px solid red' }}>
    <table>
      <thead>
        <tr className=''>
          <th className='min-w-[120px] text-primary font-semibold text-sm p-1'>Date Raised</th>
          <th className='text-primary font-semibold text-sm p-1 w-[400px]'>Request</th>
          <th className='text-primary font-semibold text-sm p-1'>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='font-semibold text-sm align-top p-1'>{props.date}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.title}</td>
          <td className='font-semibold text-sm align-top p-1'>{props.details}</td>
        </tr>
      </tbody>
    </table>
  </div>
}