import axios from "axios";
import { GET_PARTNER, GET_PARTNER_CATEGORIES_DATA, GET_PROJECT_PARTNER, POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION, POST_REQUEST_MENTOR_TO_CONNECTION, POST_REQUEST_PARTNER_TO_CONNECTION, POST_REQUEST_VC_TO_CONNECTION, symbioteApiBaseUrl } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeadersJson = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "application/json"
  };
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
    "Content-Type": "multipart/form-data"
  };
}

const getProjectPartnerURL = GET_PROJECT_PARTNER
const getPartnerURL = GET_PARTNER
const postRequestPartnerToConnectURL = POST_REQUEST_PARTNER_TO_CONNECTION
const postRequestVCToConnectURL = POST_REQUEST_VC_TO_CONNECTION
const postRequestMentorToConnectURL = POST_REQUEST_MENTOR_TO_CONNECTION
const postRequestLaunchNetworkToConnectURL = POST_REQUEST_LAUNCHNETWORK_TO_CONNECTION
const getPartnerCategoriesURL = GET_PARTNER_CATEGORIES_DATA

const getCurrentPartnersData = async (category?: any, filter?: { skip: number, limit: number }) => {
  let queryParams = {}
  if (category?.length > 0) {
    queryParams = { category }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }

  const res: any = await axios.get(getProjectPartnerURL, { headers: postBodyHeaders(), params: queryParams })
  return res;
};

const getPartnerCategories = async () => {
  const res: any = await axios.get(getPartnerCategoriesURL, { headers: postBodyHeaders() })
  return res;
};

const getPartnersData = async (category?: any, filter?: { skip: number, limit: number, search?: string, is_launch?: boolean }, selectedIndustries?: string) => {
  let queryParams = {}
  if (category?.length > 0) {
    queryParams = { operations: category }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }
  if (selectedIndustries) {
    queryParams = { ...queryParams, industries: selectedIndustries }
  }

  const res: any = await axios.get(getPartnerURL, { headers: postBodyHeaders(), params: queryParams })
  return res;
};

const getPartnerByIdAPI = (partnerId: string): Promise<any> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/partner/" + partnerId, { headers: postBodyHeadersJson() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({})
    })
  })
}

const getGrantPartnerByIdAPI = (partnerId: string): Promise<any> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/partner/grant/" + partnerId, { headers: postBodyHeadersJson() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({})
    })
  })
}

const postRequestPartnerToConnectData = async (userType: string, userId: any, message: string) => {
  let apiUrl = "";
  let requestbody = {}
  if (userType === "partner") {
    apiUrl = postRequestPartnerToConnectURL
    requestbody = {
      partnerId: userId,
      message: message
    }
  } else if (userType === "vc") {
    apiUrl = postRequestVCToConnectURL
    requestbody = {
      vcId: userId,
      message: message
    }
  } else if (userType === "mentor") {
    apiUrl = postRequestMentorToConnectURL
    requestbody = {
      mentorId: userId,
      message: message
    }
  } else if (userType === "launch-network") {
    apiUrl = postRequestLaunchNetworkToConnectURL
    requestbody = {
      networkId: userId,
      message: message
    }
  } else if (userType === 'grant-partner') {
    apiUrl = `${symbioteApiBaseUrl}/api/v1/partner/grant/request/connect`
    requestbody = {
      partnerId: userId,
      message: message
    }
  } 
  const res: any = await axios.post(apiUrl, requestbody, { headers: postBodyHeadersJson() })
  return res;
};

const getGrantPartners = (filters: { skip: number, limit: number }): Promise<{ values: any[], total: number }> => {
  return new Promise((resolve) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/partner/grant", { headers: postBodyHeadersJson(), params: { ...filters } }).then(response => {
      resolve(response.data)
    }).catch(err => {
      resolve({ values: [], total: 0 })
    })
  })
}


export {
  getPartnerByIdAPI,
  getCurrentPartnersData,
  getPartnersData,
  postRequestPartnerToConnectData,
  getPartnerCategories,
  getGrantPartners,
  getGrantPartnerByIdAPI,
};