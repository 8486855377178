import axios from "axios";
import { GET_CATEGORY_DATA, GET_PROJECT_MENTOR_NETWORK_DATA, POST_MENTOR_ADD_FEEDBACK, POST_MENTOR_SUBMIT_DATA, symbioteApiBaseUrl } from "../../../../../../constants";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`
  };
}

const getProjectsMentorNetworkURL = GET_PROJECT_MENTOR_NETWORK_DATA
const getCategoryData = GET_CATEGORY_DATA
const getMentorsDataURL = POST_MENTOR_SUBMIT_DATA

const getProjectsMentorNetworData = async () => {
  const res: any = await axios.get(getProjectsMentorNetworkURL, { headers: postBodyHeaders() })
  return res;
};

const getAllCategoryData = async () => {
  const res: any = await axios.get(getCategoryData, { headers: postBodyHeaders() })
  return res;
};


const getAllCategoryWiseMentorsData = async (category?: any, filter?: { skip: number, limit: number, search?: string }) => {

  let queryParams = {}
  if (category) {
    queryParams = { area: category }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }

  const res: any = await axios.get(getMentorsDataURL, { headers: postBodyHeaders(), params: queryParams })
  return res;
};

const addMentorFeedbackAPI = async (data: { score: number, feedback: string }, projectId: string) => {

  const res: any = await axios.post(
    POST_MENTOR_ADD_FEEDBACK.concat(projectId),
    data,
    { headers: postBodyHeaders() }
  )
  return res;
}

const addMentorScoreAPI = async (data: any) => {

  const res: any = await axios.post(
    `${symbioteApiBaseUrl}/api/v1/mentor/score`,
    data,
    { headers: postBodyHeaders() }
  )
  return res;
}

export {
  addMentorScoreAPI,
  getProjectsMentorNetworData,
  getAllCategoryData,
  getAllCategoryWiseMentorsData,
  addMentorFeedbackAPI
};