import { useNavigate } from 'react-router-dom'
import { IBlog } from '../Blogs'
import "./index.scss"
import moment from "moment"
import { toast } from 'react-toastify'

function BlogCardLg({ data }: { data: IBlog }) {
  const nav = useNavigate()
  const { slug, thumbnailUrl, title, subTitle, publishDate } = data

  const onClickCard = () => {
    nav(`/b/${slug}`)
  }

  const onClickCopy = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success('Link Copied', { autoClose: 1000, hideProgressBar: true })
  }

  return (
    <article className='blog-card-lg-container' onClick={onClickCard}>
      <div className="blog-image">
        <img src={thumbnailUrl} alt="" />
      </div>
      <h5 className="blog-title" title={title}>{title}</h5>
      <p className='blog-short-desc' title={subTitle}>{subTitle}</p>
      <div className="publication-share">
        {/* <button>Read</button> */}
        <div className="date-and-share">
          <span>Published on {publishDate && moment(publishDate).format('DD/MM/yyyy') || '-'}</span>
          <i className="ri-share-line" onClick={(e) => {
            e.stopPropagation()
            onClickCopy(`${window.location.origin}/b/${slug}`)
          }}></i>
        </div>
      </div>
    </article>
  )
}

export default BlogCardLg