import axios from "axios";
import { GET_LINKS_DATA, GET_PROJECTS_ASSIGNED_VC, GET_PROJECT_FEEDBACK_DATA, GET_PROJECT_GET_USER_DATA, GET_PROJECT_PROGRESS_DATA, GET_UPDATES_DATA, GET_VIDEO_CATEGORY_DATA, GET_VIDEO_DATA, POST_MENTOR_SUBMIT_DATA, POST_PROJECT_SUBMIT_DATA, POST_REQUEST_MENTOR_TO_CONNECTION, POST_VIDEO_AS_VIEWED, symbioteApiBaseUrl } from "../../../../constants";
import { IFeed } from "../components/dashboard/components/feeds/Feeds";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const authHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
  };
}

const getProjectsUserURL = GET_PROJECT_GET_USER_DATA
const getMentorsDataURL = POST_MENTOR_SUBMIT_DATA
const getVideoDataURL = GET_VIDEO_DATA
const getExternalVideoLinkDataURL = GET_LINKS_DATA
const getProgressDataURL = GET_PROJECT_PROGRESS_DATA
const getFeedbackDataURL = GET_PROJECT_FEEDBACK_DATA
const postRequestMentorToConnectURL = POST_REQUEST_MENTOR_TO_CONNECTION
const getProjectByIDURL = POST_PROJECT_SUBMIT_DATA
const getUpdatedURL = GET_UPDATES_DATA
const getAllVideopCategorysUrl = GET_VIDEO_CATEGORY_DATA
const putUpdateProjectSymboverseValue = POST_PROJECT_SUBMIT_DATA

const getProjectsUserData = async () => {
  const res: any = await axios.get(getProjectsUserURL, { headers: authHeaders() })
  return res;
};

const getAllMentorsData = async (recommended: boolean, oprationType: string, filter?: { skip: number, limit: number }) => {

  let queryParams = {}
  if (oprationType === "recomandedMentor") {
    queryParams = { recommended }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }

  const res: any = await axios.get(getMentorsDataURL, { headers: authHeaders(), params: queryParams })
  return res;
};

const getAllVideosData = async (recommended: boolean) => {
  const res: any = await axios.get(getVideoDataURL + "?recommended=" + recommended, { headers: authHeaders() })
  return res;
};

const getAllExternalVideosLinkData = async (data?: { limit: number }) => {
  let params = {}
  if (data) {
    params = { ...data }
  }
  const res: any = await axios.get(getExternalVideoLinkDataURL, { headers: authHeaders(), params: params })
  return res;
};

const getVideosDataByID = async (videoID: boolean) => {
  const res: any = await axios.get(getVideoDataURL + "/" + videoID, { headers: authHeaders() })
  return res;
};

const getAllVideoCategorys = async (): Promise<any> => {
  const res = await axios.get(getAllVideopCategorysUrl, { headers: authHeaders() });
  return res;
};

const setVideoAsViewedAPI = async (videoId: string): Promise<any> => {
  const url = POST_VIDEO_AS_VIEWED.concat("/" + videoId)
  const res = await axios.post(url, {}, { headers: authHeaders() });
  return res;
};

const getSectionVideoData = async (categoryId: string): Promise<any> => {
  const res: any = await axios.get(getVideoDataURL + "?categoryId=" + categoryId, { headers: authHeaders() })
  return res;
};

const getProjectProgressData = async (projectId?: string, filter?: { skip: number, limit: number }) => {
  let queryParams = {}
  if (projectId) {
    queryParams = { projectId }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }
  const res: any = await axios.get(getProgressDataURL, { headers: authHeaders(), params: queryParams })
  return res;
};

const getProjectFeedbackData = async (projectId?: string, filter?: { skip: number, limit: number }) => {
  let queryParams = {}
  if (projectId) {
    queryParams = { projectId }
  }
  if (filter) {
    queryParams = { ...queryParams, ...filter }
  }
  const res: any = await axios.get(getFeedbackDataURL, { headers: authHeaders(), params: queryParams })
  return res;
};

const postProjectProgressData = async (area: string, description: string, documentUrl: string) => {
  const bodyParams = {
    "area": area,
    "description": description,
    "documentUrl": documentUrl,
  }
  const res: any = await axios.post(getProgressDataURL, bodyParams, { headers: authHeaders() })
  return res;
};

const postRequestMentorToConnect = async (mentorId: any) => {
  const bodyParams = {
    "mentorId": mentorId
  }
  const res: any = await axios.post(postRequestMentorToConnectURL, bodyParams, { headers: authHeaders() })
  return res;
};

const getProjectsByIDData = async (projectId: string) => {
  const res: any = await axios.get(getProjectByIDURL + "/" + projectId, { headers: authHeaders() })
  return res;
};

const getUpdateData = async () => {
  const res: any = await axios.get(getUpdatedURL, { headers: authHeaders() })
  return res;
};

const putUpdateProjectData = async (projectId: any, scalePosition: string) => {
  const bodyParams = {
    "symbioteScalePostion": scalePosition,
  }
  const res: any = await axios.put(putUpdateProjectSymboverseValue + "/" + projectId, bodyParams, { headers: authHeaders() })
  return res;
};

export const generateGraduateNFTapi = (reqBody: { address: string, message: string, signature: string }): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.post(symbioteApiBaseUrl + "/api/v1/projects/graduate/nft", reqBody, { headers: authHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

export const getAllFeedsAPI = ({ filter }: { filter: { skip: number, limit: number } }): Promise<{ values: IFeed[], total: number }> => {
  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/feed", { headers: authHeaders(), params: { ...filter } }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

export const getFeedByIdAPI = (id: string): Promise<IFeed> => {
  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/feed/" + id, { headers: authHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};



export {
  getProjectsUserData,
  getAllMentorsData,
  getAllVideosData,
  getProjectProgressData,
  getProjectFeedbackData,
  postProjectProgressData,
  postRequestMentorToConnect,
  getProjectsByIDData,
  getVideosDataByID,
  getUpdateData,
  getAllVideoCategorys,
  getSectionVideoData,
  getAllExternalVideosLinkData,
  putUpdateProjectData,
  setVideoAsViewedAPI
};