import { useEffect } from 'react'
import { IJob } from './JobBoard'
import { Link } from 'react-router-dom'

interface IJobDetailCard {
  selectedItem: IJob
}

function JobDetailCard({ selectedItem }: IJobDetailCard) {
  const { _id, icon, companyName, isFullTime, applyLink, location, maxSalary, minSalary, role, shortDescription, telegramLink, websiteLink, twitterLink, currency, description } = selectedItem

  useEffect(() => {
    let parentHeight = "100%"
    const childElem = document.getElementById('job-detail-card-inner-id')
    const parentElm = document.getElementById('job-detail-card-container-id')
    if (parentElm) parentElm.style.height = parentHeight
    if (childElem) {
      parentHeight = childElem.clientHeight + "px"
      if (parentElm) parentElm.style.height = parentHeight
    }
  }, [selectedItem])

  const salary = currency + minSalary + "k" + " - " + currency + maxSalary + "k"

  return (
    <div className='job-detail-card-container' id='job-detail-card-container-id'>
      <div className='job-detail-card-inner' id='job-detail-card-inner-id'>

        <div className="section-1">
          <div className='icon-and-name'>
            <div className='image-section'>
              <img src={icon} />
            </div>
            <h5 className='project-name'>{companyName}</h5>
          </div>
          <p className='paragraph-new fw-bold' title={role}>{role}</p>
        </div>

        <div className='section-2'>
          <p className='paragraph-new fw-bold m-0'>{isFullTime ? salary : 'Contractual'}</p>
          <p className='paragraph-new fw-bold uppercase m-0'>{location}</p>
          <button className='new-color-button' onClick={() => window.open(applyLink)}>Apply</button>
        </div>

        <div className='section-3'>
          <p className='sup-new-small fw-lighter m-0 job-description'>{description || shortDescription}</p>
        </div>

        {(twitterLink || telegramLink || websiteLink) && <div className='section-4 mt-5'>
          <div className='jd-footer'>
            <div className='social-icons'>
              {twitterLink &&
                <Link to={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></Link>
              }
              {telegramLink &&
                <Link to={telegramLink} target='_blank'><i className="ri-send-plane-fill"></i></Link>
              }
              {websiteLink &&
                <Link to={websiteLink} target='_blank'><i className="ri-links-line"></i></Link>
              }
            </div>
          </div>
        </div>}

        <div className='apply-actions mt-5'>
          <button className='new-color-button' onClick={() => window.open(applyLink)}>Apply</button>
        </div>

      </div>
    </div>
  )
}

export default JobDetailCard