import React from 'react'
import "./progress-table.sass";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';

interface createModalProps {
    tableData: any,
    tableColumn: any,
}

const ProgressTable = ({ tableData, tableColumn }: createModalProps) => {

    return (
        <div className="progress-table-data position-relative">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {tableColumn.map((column: any) => (
                                    <TableCell
                                        key={column.id}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <span className='fw-bold color-primary'>{column.label}</span>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData?.length > 0 ?
                                <>
                                    {tableData?.map((row: any, i: number) => {
                                        return (
                                            <TableRow key={i}>
                                                {row?.createdAt && <TableCell sx={{ flex: '1 1 100%' }}>{moment(row?.createdAt).format("YYYY/MM/DD")}</TableCell>}
                                                {row.area && <TableCell sx={{ flex: '1 1 100%' }}>{row.area}</TableCell>}
                                                {row?.description && <TableCell sx={{ flex: '1 1 100%' }}>{row?.description}</TableCell>}
                                                {row?.documentUrl && <TableCell sx={{ flex: '1 1 100%' }}>{row?.documentUrl || "No data"}</TableCell>}
                                                {row?.mentor?.name && <TableCell sx={{ flex: '1 1 100%' }}>{row?.mentor?.name}</TableCell>}
                                                {row?.feedback && <TableCell sx={{ flex: '1 1 100%' }}><span>{row?.feedback}</span></TableCell>}
                                            </TableRow>
                                        )
                                    }
                                    )}
                                </>
                                : <p className='paragraph-new mb-0 text-center color-white-new no-data-p'>
                                    No data available
                                </p>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </div>
    );
};

export default ProgressTable;
