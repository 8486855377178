import React, { useLayoutEffect, useRef } from 'react'
import SplitType from 'split-type'
import gsap from "gsap"
import SectionHeading from '../common/SectionHeading'

export default function WhySymbiote() {

  const paragraphRef = useRef(null)

  useLayoutEffect(() => {

    const text = new SplitType(paragraphRef.current!, { types: 'words' })

    gsap.from(text.words, {
      scrollTrigger: {
        trigger: paragraphRef.current!,
        start: '-20px 80%',
        end: '-20px 80%',
        markers: false,
        scrub: false,
        toggleActions: "play none reverse none",
      },
      x: 80,
      opacity: 0,
      stagger: 0.01,
    })

  }, [])

  return (
    <div className='container flex flex-col-reverse md:flex-row pt-24 pb-14 md:pb-0'>
      <div className='w-full lg:w-[40%] h-full'>
        <div className='max-w-[380px] mx-auto grid gap-2 px-2 md:px-0'>
          {/* <h3 className='heading-section bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-3xl'>What is Symbiote</h3>
          <div className='heading-border bg-gradient-to-r from-[#000000] to-[#2479DF] h-[2px] w-[80%] mx-auto' /> */}

          <SectionHeading heading='Why Symbiote' />

          <p ref={paragraphRef} className='text-sm mt-3'>
            A fully remote accelerator program with best-in-class ecosystem networking. Support in Operational, legal, commercial, marketing, and tech support. Your journey is taken care from Token engineering support and business model testing to pinpoint product-market fit.
            <br />
            <br />
            An immersive experience with 50+ mentors and active VC network featuring known names in Web3.
          </p>
        </div>
      </div>
      <div className='w-full lg:w-[60%] text-center'>
        <div className='relative md:w-[500px] mx-auto'>
          <img src="/img/home/symbiote-logo.svg" alt="" className='move-up-down absolute z-10 w-[80px] md:w-[160px]' />
          <img src="/img/home/symbiote-shadow.svg" alt="" className='size-inc-desc absolute top-[0px] md:top-[50px] -left-10 md:left-[8px] z-20 md:scale-[0.8]' />
          <img src="/img/home/symbiote-layers.svg" alt="" className='w-fit relative z-0' />
        </div>
      </div>
    </div>
  )
}
