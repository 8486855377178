import React from 'react'
import './details-card.sass'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface ReminderCardData {
    id: string;
    startDate: any,
    endDate: string,
    author: string,
    title: string,
    guests: string,
    meetLink: string
    banner: string;
    isRemindered: boolean
    onClickReminder: (id: string) => void
    isPast?: boolean
}

export const ReminderDetailsCardData = (props: ReminderCardData) => {
    const { id, startDate, endDate, author, title, guests, meetLink, banner, onClickReminder, isRemindered, isPast } = props;
    var a = moment(new Date());
    var b = moment.unix(startDate);
    var diffrenceDays = b?.diff(a, 'days');

    return (
        <>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="details-card">
                    <div className='card-image-section'>
                        <img className='card-image' src={banner} />
                    </div>
                    <div className="event-content-container">
                        <div className="card-heading d-flex align-items-center justify-content-between" >
                            <h5 className='heading-new-5 mb-0'>{moment.unix(startDate).format('MMM DD YYYY h:mm a')} UTC</h5>
                            {!isPast && <p className='paragraph-new mb-0'>{diffrenceDays && diffrenceDays > 0 ? `${diffrenceDays} Days to go` : "Today"}</p>}
                        </div>
                        <h4 className='heading-new-4 fw-light mb-lg-3 mb-md-3 mb-2'>{title}</h4>
                        {/* <p className='paragraph-new fw-lighter'>with {author}</p> */}
                        <div className="guests d-flex gap-2">
                            <p className='paragraph-new mb-0 mt-0  fw-lighter'>Guests:</p>
                            <p className='paragraph-new mb-0 mt-0 fw-lighter'>{guests || "No data available"}</p>
                        </div>
                        {!isPast && <div className="bottom-link d-flex align-items-center justify-content-between">
                            {meetLink && <Link to={meetLink} target='_blank' className='paragraph-new fw-lighter'>
                                <i className="ri-link me-1"></i> Meeting Link
                            </Link>}
                            <div
                                className='paragraph-new fw-lighter cursor-pointer'
                                title="Add emails to Event"
                                onClick={() => isRemindered ? toast.success('Already added') : onClickReminder(id)}
                            >
                                {
                                    isRemindered ? <span className='font-semibold text-green-600'>Registered</span> :
                                        <span className='bg-green-600 px-2 py-1 rounded-lg font-semibold'>Register Now</span>

                                }
                            </div>
                        </div>}
                    </div>

                </div>
            </div>
        </>
    )
}
