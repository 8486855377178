import { useEffect, useState } from 'react'
import './index.scss'
import { Grid, CircularProgress } from '@mui/material'
import symbioteCard from "./symbiote-cards.png"
import { useWeb3React } from '@web3-react/core'
import { id } from "ethers"
import { toast } from 'react-toastify'
import { generateGraduateNFTapi, getProjectsUserData } from '../../API/APICall'
import axios from 'axios'
import { symbioteGraduateContractAddress } from '../../../../../constants'

interface INFT {
  name: string;
  image: string;
  description?: string;
}

export default function MintNFT() {

  const [user, setUser] = useState<any>({})
  const [loader, setLoader] = useState<boolean>(false)
  const [loaderMsg, setLoaderMsg] = useState<string>('')
  const [nft, setNft] = useState<INFT>()
  const { account, library } = useWeb3React()

  const { isProjectGraduated = false, walletAddress = '', projectNft = {} } = user || {}
  const { isMinted = false } = projectNft || {}

  useEffect(() => {
    getProjectDetails()
  }, [])

  const getProjectDetails = () => {
    setLoader(true)
    getProjectsUserData().then(response => {
      setLoader(false)
      const projectNft = response.data.projectNft
      if (projectNft && projectNft.isMinted) {
        axios.get(projectNft.metadataUri).then(metaRes => {
          setNft(metaRes.data)
        }).catch(err => {
          toast.error('Error while fetching your NFT')
        })
      }
      setUser(response.data)
    }).catch(ex => {
      setLoader(false)
    })
  }

  const onClickCreateNft = async () => {
    try {
      if (!account) return toast.error('Please connect wallet')
      if (projectNft && !Object.keys(projectNft).length) return toast.error('NFT is not yet processed by admin')
      const message = id(Date.now().toString());
      const signature = await library!.getSigner().signMessage(message)
      const sendObj = { message, signature, address: account } as any
      await generateGraduateNFTapi(sendObj)
      toast.success('NFT minted successfully')
      getProjectDetails()
    } catch (ex: any) {
      toast.error(ex.message || 'Something went wrong')
    }
  }

  const onClickViewNFT = () => {
    if (projectNft && projectNft.tokenId) {
      window.open(`https://testnets.opensea.io/assets/mumbai/${symbioteGraduateContractAddress}/${projectNft.tokenId}`)
    } else {
      toast.error('NFT Token id missing, please contact admin')
    }
  }

  return (
    <Grid container className="mint-nft-container">
      <Grid item xs={12}>
        <h1 className="container-heading">{isMinted ? 'Your NFT' : 'Mint Your NFT'}</h1>
      </Grid>

      {loader && <div className="mint-nft-loader">
        <CircularProgress style={{ color: 'white' }} size={32} />
      </div>}

      {
        (isMinted && nft && Object.keys(nft!).length) && <Grid item xs={12}>
          <div className='nft-card-item'>
            {
              nft?.image.includes('.mp4') ?
                <video autoPlay loop>
                  <source src={nft?.image} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> :
                <img src={nft?.image} alt='' />
            }
          </div>
        </Grid>
      }

      {!isMinted && <Grid item xs={12}>
        <div className="nft-card-image">
          <img className={!isProjectGraduated ? 'inactive' : ''} src={symbioteCard} alt="" />
        </div>
      </Grid>}

      <Grid item xs={12}>
        <div className='page-actions'>
          {
            isMinted ? <button className='new-color-button' onClick={onClickViewNFT}>View NFT on opensea</button> : isProjectGraduated ? <>
              {walletAddress ? <button className='new-color-button' onClick={onClickCreateNft}>Mint Your Graduation NFT</button> :
                <span className='non-eligible-text'>Please mint your NFT by adding your wallet address in the "Additional Information" section in <a href='/forms/project-view/profile'>your profile</a></span>
              }
            </> :
              <span className='non-eligible-text'>Eligible to mint after graduation</span>
          }
        </div>
      </Grid>

    </Grid>
  )
}