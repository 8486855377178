import { useEffect, useState } from 'react'
import { CircularProgress, Grid } from "@mui/material"
import blogElements from "./assets/blog-elements.png"
import blogBg from "./assets/blog-bg.png"
import arrowLeft from "./assets/arrow-left.svg"
import arrowRight from "./assets/arrow-right.svg"
import './blogs.scss'
import BlogCardLg from './Cards/BlogCardLg'
import BlogCardSm from './Cards/BlogCardSm'
import { sideScroll } from '../../constants/utils'
import { getAllBlogsAPI, getBlogTagsAPI } from './apis'
import { useNavigate } from 'react-router-dom'

export interface IBlog {
  title: string,
  subTitle: string,
  slug: string,
  author: string[],
  tags: string[],
  webUrl: string,
  thumbnailUrl: string,
  publishDate: string,
}

function Blogs() {
  const navigate = useNavigate()
  const [latestBlogs, setLatestBlogs] = useState<IBlog[]>([])
  const [trendingBlogs, setTrendingBlogs] = useState<IBlog[]>([])
  const [allBlogs, setAllBlogs] = useState<IBlog[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [selectedTag, setSelectedTag] = useState<string | null>(null)

  const [loaders, setLoader] = useState({
    latest: false,
    trending: false,
    all: false,
    collection: false
  })
  const [filter, setFilter] = useState({
    skip: 0,
    limit: 20,
  })

  useEffect(() => {
    onFetchTrendingBlogs()
    onFetchLatestBlogs()
    onFetchBlogTags()

    const urlQuery = Object.fromEntries(new URLSearchParams(window.location.search));
    if (urlQuery && urlQuery.tag) {
      setSelectedTag(urlQuery.tag)
    }

  }, [])

  useEffect(() => {
    onFetchAllBlogs({ tag: selectedTag || '' })
  }, [selectedTag])

  const onFetchBlogTags = async () => {
    setLoader(prev => ({ ...prev, collection: true }))
    const response = await getBlogTagsAPI()
    setLoader(prev => ({ ...prev, collection: false }))
    setTags(response)
  }

  const onFetchAllBlogs = async (filters: any) => {
    setLoader(prev => ({ ...prev, all: true }))
    const response = await getAllBlogsAPI({ ...filters, ...filter })
    setLoader(prev => ({ ...prev, all: false }))
    setAllBlogs(response.values)
  }

  const onFetchTrendingBlogs = async () => {
    setLoader(prev => ({ ...prev, trending: true }))
    const response = await getAllBlogsAPI({ skip: 0, limit: 6, isTrending: true })
    setLoader(prev => ({ ...prev, trending: false }))
    setTrendingBlogs(response.values)
  }

  const onFetchLatestBlogs = async () => {
    setLoader(prev => ({ ...prev, latest: true }))
    const response = await getAllBlogsAPI({ skip: 0, limit: 2 })
    setLoader(prev => ({ ...prev, latest: false }))
    setLatestBlogs(response.values)

  }

  const onScrollTrending = (position: 'left' | 'right') => {
    const element = document.getElementById("scrollable-trending-posts");
    if (element) {
      const firstItem = element.firstElementChild
      if (firstItem) {
        const scrollAmount = firstItem.clientWidth
        sideScroll(element, position, 10, scrollAmount, 30)
      }
    }
  }

  const onClickSelectTag = (tag: string) => {
    window.scrollTo(0, 0)
    setSelectedTag(tag)
    navigate(`/blogs?tag=${tag}`)
  }

  const onClickBackTag = () => {
    setSelectedTag(null)
    navigate(`/blogs`)
    setTimeout(() => {
      const element = document.getElementById("collections-tags-item");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)

  }

  return (
    <Grid container className='blogs-container'>

      {selectedTag ?
        <Grid item xs={12}>
          <div className='selected-tag-container'>
            <div className='tag-page-heading'>
              <span style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }} onClick={onClickBackTag}>{"< Back"}</span>
              <h1 className='heading-new-1 mb-4 mt-4' style={{ textTransform: 'capitalize' }}>{selectedTag}</h1>
            </div>
            <div className='blog-image-container'>
              <img className='blog-bg-img' src={blogBg} />
            </div>
          </div>

        </Grid>
        : <Grid item xs={12}>
          <div className='heading-and-logo'>
            <div className='info-section'>
              <h1 className='heading-new-1 mb-4'>SymBytes</h1>
              <span className='paragraph-new-small'>
                Your go-to guide on the latest Web3 narratives -  with a sprinkling of updates from the amazing projects in our ecosystem.
                <br />
                <br />
                Delivered to you every Tuesday.
              </span>
              <div className="mt-5">
                <iframe src="https://embeds.beehiiv.com/f7fa9f1c-cb34-4fc4-bcf4-92d0571864b4?slim=true" data-test-id="beehiiv-embed" height="52" style={{ margin: 0, borderRadius: '0px !important', backgroundColor: 'transparent', width: '100%', maxWidth: '420px' }}></iframe>
              </div>
            </div>
            <div className='blogs-icon-section'>
              <div className='image-container'>
                <img className='blog-bg-img' src={blogBg} />
                <img className='element-img' src={blogElements} />
              </div>
            </div>
          </div>
        </Grid>}

      <Grid item xs={12} className="posts-content-container">
        <Grid container maxWidth="1400px" margin="auto">
          {!selectedTag && <Grid item xs={12}>
            <div className='latest-posts-container'>
              <h3 className="heading-new-3 color-primary">Latest</h3>
              <Grid container spacing={2} className="mt-2">
                {loaders.latest && <Grid item xs={12} style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress style={{ color: '#4398FF' }} size={34} />
                </Grid>}
                {
                  !loaders.latest && latestBlogs.map((item, key) => {
                    return <Grid item xs={12} md={6} key={key}>
                      <BlogCardLg data={item} />
                    </Grid>
                  })
                }
              </Grid>
            </div>
          </Grid>}

          {!selectedTag && <Grid item xs={12}>
            <div className='trending-posts-container mt-5'>
              <h3 className="heading-new-3 color-primary">Trending</h3>
              {loaders.trending && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress style={{ color: '#4398FF' }} size={34} />
              </div>}
              <div className="trending-container mt-4" id="scrollable-trending-posts">
                {
                  !loaders.trending && trendingBlogs.map((item, key) => {
                    return <div key={key} className="trending-post-item">
                      <BlogCardSm data={item} />
                    </div>
                  })
                }
              </div>
              {trendingBlogs && trendingBlogs.length > 1 && <div className="actions-btn-container">
                <img src={arrowLeft} alt="" onClick={() => onScrollTrending('left')} />
                <img src={arrowRight} alt="" onClick={() => onScrollTrending('right')} />
              </div>}
            </div>
          </Grid>}

          {!selectedTag && <Grid item xs={12}>
            <div className='collections-container mt-5 mb-5' id="collections-tags-item">
              <h3 className="heading-new-3 color-primary">Categories</h3>
              <Grid container spacing={2} className="mt-2">
                {loaders.collection && <Grid item xs={12} style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress style={{ color: '#4398FF' }} size={34} />
                </Grid>}
                {
                  !loaders.collection && tags.map((item, key) => {
                    return <Grid item xs={6} sm={4} md={3} key={key}>
                      <div className="blog-collection-item" onClick={() => onClickSelectTag(item)}>
                        <span>{item}</span>
                      </div>
                    </Grid>
                  })
                }
              </Grid>
            </div>
          </Grid>}

          <Grid item xs={12}>
            <div className='all-posts-container'>
              {!selectedTag && <h3 className="heading-new-3 color-primary">All the posts</h3>}
              <Grid container spacing={4} className="mt-1">
                {loaders.all && <Grid item xs={12} style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress style={{ color: '#4398FF' }} size={34} />
                </Grid>}
                {
                  !loaders.all && allBlogs.map((item, key) => {
                    return <Grid item xs={12} sm={6} md={4} key={key}>
                      <BlogCardSm data={item} />
                    </Grid>
                  })
                }
              </Grid>
            </div>
          </Grid>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default Blogs