import React, { useMemo, useState } from 'react'
import './vc-card.sass';
import bannerPlaceholder from '../../../../../assests/images/banner-placeholder.png';
import imgPlaceholder from '../../../../../assests/images/image-placeholder.png';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

export interface VCCardData {
    _id: any,
    name: string,
    icon: string,
    banner: string,
    linkedInProfileLink: string
    twitterLink: string
    telegramLink: string
    indexValue: any
    companyName: string;
    companyDescription: string;
    websiteLink: string
    projectAvgScore: number
    isInterested: boolean
    disableInterested?: boolean
    isAccelerateProject: boolean
    onClickExpressInterest: (id: string) => void
}

const DESCRIPTION_MAX_LENGTH = 115

export const VCCard = (props: VCCardData) => {
    const nav = useNavigate()
    const { name, indexValue, banner, icon, companyName, projectAvgScore, companyDescription, isInterested,
        websiteLink, telegramLink, linkedInProfileLink, twitterLink, _id, onClickExpressInterest, disableInterested, isAccelerateProject = false } = props
    const location = useLocation();
    const pathname = location.pathname;
    const isMentorDashboard = pathname.includes("/mentor-dashboard")
    const isPartnerDashboard = pathname.includes("/partner-dashboard")
    const isVCDashboard = pathname.includes("/vc-dashboard")

    const shortCompanyDescription = useMemo(() => {
        if (companyDescription) {
            return companyDescription.length > DESCRIPTION_MAX_LENGTH ? companyDescription.substring(0, DESCRIPTION_MAX_LENGTH) + '...' : companyDescription
        } return "-"
    }, [companyDescription])

    return (
        <>
            <div className="col-lg-6 col-sm-6 col-12">
                <div className="vc-card">
                    <div className="top-button">
                        {isAccelerateProject && <button className='new-primary-button-small'>A project of Symbiote <span className='fw-bold'>Accelerate</span>
                            <span className='anim-span'></span>
                            <span className='anim-span anim-2'></span>
                            <span className='anim-span anim-3'></span>
                            <span className='anim-span anim-4'></span>
                            <span className='anim-span anim-5'></span>
                        </button>}
                        {/* <button className='new-color-button-small'>DeFi</button> */}
                    </div>
                    <div className="banner-image">
                        <img src={banner || bannerPlaceholder} className='img-fluid' alt="" />
                    </div>
                    <div className="text-content">
                        <div className="row ">
                            <div className="col-6">
                                <div className="left-part">
                                    <div className="user-image">
                                        <img src={icon || imgPlaceholder} className='img-fluid' alt="" />
                                        <h5 className='heading-new-5'>{companyName || "No name available"}</h5>
                                    </div>
                                    <div className="card-social">
                                        <ul className="justify-content-center">
                                            {linkedInProfileLink &&
                                                <li>
                                                    <Link to={linkedInProfileLink} target='_blank'>
                                                        <i className="ri-linkedin-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {twitterLink &&
                                                <li>
                                                    <Link to={twitterLink} target='_blank'>
                                                        <i className="ri-twitter-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {telegramLink &&
                                                <li>
                                                    <Link to={telegramLink} target='_blank'>
                                                        <i className="ri-send-plane-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {websiteLink &&
                                                <li>
                                                    <Link to={websiteLink} target='_blank'>
                                                        <i className="ri-link"></i>
                                                    </Link>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pe-lg-0">
                                <div className="right-part">
                                    {isMentorDashboard && indexValue ?
                                        <div className='d-flex cercle-slider align-items-center mb-3'>
                                            <div className='card-percentage-slider text-center d-flex'>
                                                <CircularProgressbar value={projectAvgScore || 0} text={`${Number(projectAvgScore || 0).toFixed(0)}`} background
                                                    backgroundPadding={0}
                                                    styles={buildStyles({
                                                        backgroundColor: "transparent",
                                                        textColor: "#fff",
                                                        pathColor: "#65DB6A",
                                                        trailColor: "white"
                                                    })} />
                                            </div>
                                            <div className='symbiotescore-text w-100'>
                                                <h3 className='color-white-new paragraph-new-medium fw-bold'>SymbioteScore</h3>
                                            </div>
                                        </div> : null
                                    }
                                    {/* {!indexValue &&
                                        <h6 className='heading-new-6 text-start mb-lg-3 mb-md-3 mb-2' >{companyName || "No title available"}</h6>
                                    } */}
                                    <p className='paragraph-new-small mb-0' style={{ marginTop: 20 }}>
                                        <span title={companyDescription}>
                                            {shortCompanyDescription || "No description available"}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center mt-lg-4 mt-md-3 mt-2">
                                {isMentorDashboard &&
                                    <>
                                        {indexValue ?
                                            <div className='project-card-actions'>
                                                <button className='card-button' onClick={() => nav(`/profile/public-profile-mentor/${_id}`)}>View Project Page</button>
                                                <button className='card-button' onClick={() => nav(`/profile/public-profile-mentor/${_id}`)}>Update Score</button>
                                            </div> :
                                            <div className='project-card-actions'>
                                                <button className='card-button' onClick={() => nav(`/profile/public-profile-mentor/${_id}`)}>View Project Page</button>
                                                {disableInterested ? null : <button className={`card-button ${isInterested ? 'interested' : ''}`} onClick={() => onClickExpressInterest!(_id)}>{isInterested ? 'Interested' : 'Express Interest'}</button>}
                                            </div>
                                        }
                                    </>
                                }
                                {(isVCDashboard || isPartnerDashboard) &&
                                    <div className='project-card-actions'>
                                        <button className='card-button' onClick={() => nav(`/profile/public-profile-vc/${_id}`)}>View Project Page</button>
                                        {disableInterested ? null : <button className={`card-button ${isInterested ? 'interested' : ''}`} onClick={() => onClickExpressInterest!(_id)}>{isInterested ? 'Interested' : 'Express Interest'}</button>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
