import React, { useState } from 'react'
import SectionHeading from '../common/SectionHeading'

const tabTitles = [
  'Tailored Growth Programs',
  'Access to Funding',
  'Long-Term Support',
  'Networking Opportunities'
]

const tabsData = [
  {
    heading: 'Tailored Growth Programs',
    content: 'Customized programs that focus on the unique needs for each project - helping you refine your product, expand your user base, and secure funding more efficiently.',
    img: '/img/home/join/growth-img.png'
  },
  {
    heading: 'Access to Funding',
    content: 'Leverage our network of top Web3 investors, providing you with the financial backing needed to grow in this competitive space.',
    img: '/img/home/join/funding-img.svg'
  },
  {
    heading: 'Long-Term Support',
    content: 'Even after the program ends, you’ll continue to receive support through our alumni network, giving you ongoing access to resources, mentorship, and collaboration opportunities.',
    img: '/img/home/join/support-img.svg'
  },
  {
    heading: 'Networking Opportunities',
    content: 'Gain direct connections to leading projects, VCs, and industry influencers, allowing you to build strategic partnerships.',
    img: '/img/home/join/network-img.png'
  },
]

export default function JoinSymbiote() {

  const [tabIndex, setTabIndex] = useState(0)
  const [tabContent, setTabContent] = useState(tabsData[0])

  const onChangeTab = (index: number) => {
    setTabIndex(index)
    setTabContent(tabsData[index])
  }

  const onClickPrev = () => {
    if (tabIndex === 0) return
    onChangeTab(tabIndex - 1)
  }

  const onClickNext = () => {
    if (tabIndex === tabTitles.length - 1) return
    onChangeTab(tabIndex + 1)
  }

  return (
    <div className='md:h-[600px] container md:pt-10'>
      <div className='flex flex-col lg:flex-row w-full h-full'>
        <div className='relative min-h-[200px] w-[100%] lg:w-[30%] flex justify-center items-center'>
          <h1 className='absolute top-10 bg-gradient-to-b from-[#FAFAFA] to-[#888888] inline-block text-transparent bg-clip-text text-2xl md:text-4xl whitespace-normal md:whitespace-pre'>Why Join {'\n'}Symbiote</h1>
          <div className='w-full text-center'>
            <ul className='list-none w-full md:w-[260px] grid gap-4 pl-0 md:pl-14 pt-24 md:pt-10 md:mx-auto text-start'>
              {
                tabTitles.map((title, key) => {
                  const isActive = tabIndex === key
                  return <li key={key} className='relative cursor-pointer w-fit md:w-auto' onClick={() => onChangeTab(key)}>
                    {isActive && <div className='absolute z-0 -bottom-3 md:bottom-0 md:-left-4 h-[4px] md:h-full bg-[#2479df] w-full md:w-[4px] rounded-lg' />}
                    <span className='font-light text-xs md:text-sm'>{title}</span>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
        <div className='relative min-h-[640px] md:min-h-full w-[100%] lg:w-[70%] bg-join-bg bg-no-repeat bg-cover flex pl-2 pr-2 md:pr-0 md:pl-20 items-center'>
          <h2 className='absolute z-0 top-10 text-2xl text-zinc-300'>{tabContent?.heading}</h2>
          <div className='h-fit w-full'>
            <div className='relative w-full'>
              <p className='text-sm md:w-[300px] pt-56 md:pt-0'>{tabContent?.content}</p>
              <img src={tabContent?.img} alt="" className='absolute z-0 right-[16%] -top-[20%] md:-top-[70%] h-[240px]' />
            </div>
          </div>
          <div className='absolute bottom-14 md:bottom-20'>
            <div className='flex gap-4'>
              <span className='border-2 font-bold px-2 py-1 cursor-pointer' onClick={onClickPrev}>{'<'}</span>
              <span className='border-2 font-bold px-2 py-1 cursor-pointer' onClick={onClickNext}>{'>'}</span>
            </div>
            <div className='flex gap-3 mt-10'>
              {tabTitles.map((item, index) => {
                const isActive = tabIndex === index
                return <div className='border w-2 h-2 rounded-full' style={{ background: isActive ? 'white' : 'transparent' }}>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
