import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Checkbox, Chip, FormControl, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import CancelIcon from "@mui/icons-material/Cancel";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import CountUp from 'react-countup';
import { getProjectsUserData } from '../project-dashboard/API/APICall';
import { postContactAssetsUpdatedData, postUpdateSubmitProjctData, putSubmitPartnerData, putUpdateMentorData, putUpdateVCData } from '../all-form/API/APICall';
import { getMentorOrVCUserData } from '../vc-dashboard/API/APICall';
import '../all-form/all-form.sass'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

var _ = require("lodash");

export const UserUpdateForm = () => {

    const [selectedNames, setSelectedNames] = useState<any>([]);
    const [inputValue, setInputValue] = useState<any>();
    const [selectedObjData, setSelectedObjData] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [jsonDict, setJsonDict] = useState<any>([]);
    const location = useLocation()
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const isProjectForm = pathname.includes("/forms/project-view/profile")
    const isProjectFirstTimeLoginForm = pathname.includes("forms/first-time-login")
    const isVCForm = pathname.includes("/vc-dashboard/profile-view")
    const isMentorForm = pathname.includes("/mentor-dashboard/profile-view")
    const isPartnerForm = pathname.includes("/partner-dashboard/profile-view")
    const nav = useNavigate()

    useEffect(() => {
        if (isProjectForm || isProjectFirstTimeLoginForm) {
            setSelectedObjData(projectFormInputData)
        } else if (isMentorForm) {
            setSelectedObjData(mentorFormInputData)
        } else if (isVCForm) {
            setSelectedObjData(VCFormInputData)
        } else if (isPartnerForm) {
            setSelectedObjData(partnerFormInputData)
        }
    }, [userData])

    useEffect(() => {
        if (isProjectForm || isProjectFirstTimeLoginForm) {
            getProjectsUserAllData()
        } else if (isMentorForm) {
            getMentorOrVCUserAllData("mentor-dashboard")
        } else if (isVCForm) {
            getMentorOrVCUserAllData("vc-dashboard")
        } else if (isPartnerForm) {
            getMentorOrVCUserAllData("partner-dashboard")
        }
    }, [])

    const projectFormInputData: any = {
        "Founder Information": [
            {
                questionid: 1,
                questionTitle: "Hello there! Can we get your full name",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.name,
                successMsg: "Pleasure to meet you!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 2,
                questionTitle: "Can you please give us your date of birth",
                questiontype: "datepicker",
                userAnswered: moment.unix(userData?.dob).format('YYYY-MM-DD'),
                successMsg: "Thanks!",
                errorMsg: "please enter your birth date",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "What is the best email address to contact you on?",
                questiontype: "textbox",
                inputType: "email",
                userAnswered: userData?.email,
                successMsg: "Thanks!",
                errorMsg: "please enter your email address",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Can we also have a phone number, please?",
                questiontype: "textbox",
                userAnswered: userData?.phone,
                inputType: "number",
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your number",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "Please paste the link to your LinkedIn profile",
                questiontype: "textbox",
                userAnswered: userData?.linkedInProfileLink,
                inputType: "text",
                successMsg: "We’d love to check out your profile!",
                errorMsg: "please enter your link",
                isRequired: true,
            },
            {
                questionid: 6,
                questionTitle: "Please upload your latest CV",
                questiontype: "fileupload",
                liveLink: userData?.cvUrl,
                userAnswered: "",
                successMsg: "Would be great to know you!",
                errorMsg: "please upload your CV",
                isRequired: false,
                localLink: {}
            },
        ],
        "Company information": [
            {
                questionid: 1,
                questionTitle: "Company name - What is the legal name of your venture?",
                questiontype: "textbox",
                userAnswered: userData?.companyName,
                inputType: "text",
                successMsg: "Pleasure to meet you!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 2,
                questionTitle: "Creation date - When did you begin building this?",
                questiontype: "datepicker",
                userAnswered: moment.unix(userData?.companyCreationDate).format('YYYY-MM-DD'),
                successMsg: "Thanks!",
                errorMsg: "please enter date",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Location - In what legal jurisdiction is it based? (City, Country)",
                questiontype: "textbox",
                userAnswered: userData?.location,
                inputType: "text",
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 4,
                questionTitle: "If your company has a website, please share the link",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.websiteLink,
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your website link",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "Company description - Briefly describe your company. What is your value proposition? What sector do you operate in? Etc.",
                questiontype: "textbox",
                userAnswered: userData?.companyDescription,
                inputType: "text",
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 6,
                questionTitle: "What is your role within the company? (Options - CEO, CTO, CMO, COO, Other)",
                questiontype: "textbox",
                userAnswered: userData?.companyRole,
                inputType: "text",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 7,
                questionTitle: "Employee count: How many employees do you have?",
                questiontype: "textbox",
                inputType: "number",
                userAnswered: userData?.employeesCount,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 8,
                questionTitle: "If you have a pitch deck that describes your company, please upload it",
                questiontype: "fileupload",
                localLink: {},
                liveLink: userData?.companyPitchDeckUrl,
                userAnswered: "",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 9,
                questionTitle: "If you have your company’s whitepaper, please upload it",
                questiontype: "fileupload",
                liveLink: userData?.companyWhitePaperUrl,
                userAnswered: "",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
                localLink: {}
            },
            {
                questionid: 10,
                questionTitle: "What stage is your company currently in",
                questiontype: "dropdown",
                userAnswered: userData?.companyCurrentStage,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "MVP",
                    "Early Traction",
                    "Growth Phase",
                    "Other"
                ]
            },
        ],
        "Company Metrics": [
            {
                questionid: 1,
                questionTitle: "How many users do you currently have?",
                questiontype: "dropdown",
                userAnswered: userData?.totalUsers,
                successMsg: "Thanks",
                errorMsg: "please enter users count",
                isRequired: false,
                dropdownOption: [
                    "Less than 10",
                    "10 - 100",
                    "100 - 1000",
                    "1000 - 10000",
                    "10000+"
                ]
            },
            {
                questionid: 2,
                questionTitle: "What is your average monthly spending? (Amount in $)",
                questiontype: "dropdown",
                userAnswered: userData?.avgMonthlySpending,
                successMsg: "Thanks!",
                errorMsg: "please enter your spanding",
                isRequired: false,
                dropdownOption: [
                    "Under 1k",
                    "1k - 5k",
                    "5k - 10k",
                    "10k - 50k",
                    "50k+"
                ]
            },
            {
                questionid: 3,
                questionTitle: "What is your average monthly revenue? (Amount in $)",
                questiontype: "dropdown",
                userAnswered: userData?.avgMonthlyRevenue,
                successMsg: "Thanks!",
                errorMsg: "please enter your revenue",
                isRequired: false,
                dropdownOption: [
                    "Under 1k",
                    "1k - 5k",
                    "5k - 10k",
                    "10k - 50k",
                    "50k+"
                ]
            },
            {
                questionid: 4,
                questionTitle: "Does your company currently have any debt?",
                questiontype: "dropdown",
                userAnswered: userData?.isCompanyInDebt === true ? "Yes" : "No",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Yes",
                    "No",
                ]
            },
            {
                questionid: 5,
                questionTitle: "Have you raised any capital for your business?",
                questiontype: "dropdown",
                userAnswered: userData?.isCapitalRaised === true ? "Yes" : "No",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Yes",
                    "No",
                ]
            },
            {
                questionid: 6,
                questionTitle: "Have you previously participated in an incubator, accelerator or pre-accelerator program?",
                questiontype: "dropdown",
                userAnswered: userData?.isAnyPreviousParticipation === true ? "Yes" : "No",
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
                dropdownOption: [
                    "Yes",
                    "No",
                ]
            },
        ],
        "Team Details": [
            {
                questionid: 1,
                questionTitle: "How many co-founders do you have?",
                questiontype: "dropdown",
                userAnswered: userData?.totalCofounders,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "1",
                    "2",
                    "3",
                    "More",
                ]
            },
            {
                questionid: 2,
                questionTitle: "Please give us your co-founder(s) LinkedIn profile(s)",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.cofounderLinkedInProfileLinks,
                successMsg: "Thanks!",
                errorMsg: "please enter link",
                isRequired: false,
            },
            {
                questionid: 3,
                questionTitle: "How long have you known each other?",
                questiontype: "dropdown",
                userAnswered: userData?.knownEachOtherInYrs,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
                dropdownOption: [
                    "Less than a year",
                    "1 - 2 years",
                    "2 - 5 years",
                    "5 - 10 years",
                    "10+ years"
                ]
            },
            {
                questionid: 4,
                questionTitle: "Is the team working full time on this project?",
                questiontype: "dropdown",
                userAnswered: userData?.isTeamWorkingFullTime === true ? "Yes" : "No",
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Yes",
                    "No",
                ],
                isdropdownNo: {
                    questionid: 5,
                    questionTitle: "What other professional activities are you involved in?",
                    questiontype: "textbox",
                    userAnswered: userData?.otherProActivities,
                    successMsg: "We promise, no spam!",
                    errorMsg: "please fillup the question",
                    isRequired: false,
                },
            },
            {
                questionid: 6,
                questionTitle: "Please outline the equity ownership of your company and any other relevant information regarding its structure.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.equityAndOwnershipInfo,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 7,
                questionTitle: "If you have anything else you would like to share regarding your venture, please elaborate.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.otherVentureInfo,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
        ],
        "Additional information": [
            {
                questionid: 1,
                questionTitle: "Why are you applying to our accelerator program? Choose as many as you like",
                questiontype: "dropdown",
                userAnswered: userData?.acceleratorProgramReasons,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: true,
                multiSelectDropdownOption: [
                    "Idea Clarity and MVP Build",
                    "Product Market Fit",
                    "Company Structuring and Legal",
                    "Team",
                    "Networks with Ecosystem Partners",
                    "Capital and VC Networks",
                    "Marketing and KOL Connections",
                    "Public Launch",
                    "Other"
                ]
            },
            {
                questionid: 2,
                questionTitle: " Are you committed to working exclusively on this project during the course of the accelerator program?",
                questiontype: "dropdown",
                userAnswered: userData?.isAvailableFullTime === true ? "Yes" : "No",
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Yes",
                    "No",
                ],
                // isdropdownNo: {
                //     questionid: 5,
                //     questionTitle: "please explain why you are unable to work full-time on this project.",
                //     questiontype: "textbox",
                //     userAnswered: "",
                //     successMsg: "We promise, no spam!",
                //     errorMsg: "please fillup the question",
                //     isRequired: false,
                // },
            },
            {
                questionid: 3,
                questionTitle: "Please enter your wallet address",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.walletAddress || '',
                successMsg: "",
                errorMsg: "",
                isRequired: false,
            },
        ],
    }

    const partnerFormInputData: any = {
        "PERSONAL DETAILS": [
            {
                questionid: 1,
                questionTitle: "Can we have your full name, please?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.name,
                successMsg: "Pleasure to meet you!",
                errorMsg: "please enter your full name",
                isRequired: true,
            },
            {
                questionid: 2,
                questionTitle: "Please share your email ID.",
                questiontype: "textbox",
                inputType: "email",
                userAnswered: userData?.email,
                successMsg: "Thanks!",
                errorMsg: "please enter your email id",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Please share your Telegram ID.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.telegramId,
                placeholder: "For example: johndoe",
                successMsg: "Thanks!",
                errorMsg: "please enter your telegram id",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Where are you from? (City, Country)",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.address,
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your address",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "Please share your Twitter ID.",
                questiontype: "textbox",
                inputType: "text",
                placeholder: "For example: johndoe",
                userAnswered: userData?.twitterLink,
                successMsg: "Thanks!",
                errorMsg: "please enter your telegram id",
                isRequired: true,
            },
        ],
        "COMPANY DETAILS": [
            {
                questionid: 1,
                questionTitle: "Please share your company name",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.companyName,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 2,
                questionTitle: "Please share the link to your company's website",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.companyWebsiteLink,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 3,
                questionTitle: "Which area do you operate in?",
                questiontype: "dropdown",
                userAnswered: userData?.companyOperations,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
                multiSelectDropdownOption: [
                    "Code and Dev Studio",
                    "Audit",
                    "Legal",
                    "Marketing",
                    "KOL",
                    "Community",
                    "Content",
                    "Design Studio",
                    "Product Integration",
                    "Business Partnership",
                    "Launchpad",
                    "Exchange",
                    "Market Maker",
                    "Liquidity Provider"
                ]
            },
            {
                questionid: 4,
                questionTitle: "What does your company do? Please share a short description.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.companyDescription,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "What kind of partner connections are you expecting from Symbiote?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.partnerConnectionType,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 7,
                questionTitle: "Choose one or more of the following industries of expertise",
                questiontype: "dropdown",
                userAnswered: userData?.industriesOfExpertise,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
                multiSelectDropdownOption: [
                    "NFT",
                    "Blockchain Infrastructure",
                    "DeFi",
                    "Metaverse",
                    "SocialFi",
                    "Middleware",
                    "GameFi",
                    "DAO",
                    "Smart Contract Platforms and Frameworks",
                    "StableCoins",
                    "Exchanges",
                    "Finance/Banking",
                    "Privacy Coins",
                    "Wallets",
                    "All",
                    "Other",
                ]
            },
            {
                questionid: 6,
                questionTitle: "Referrer",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.referrer,
                successMsg: "We promise, no spam!",
                errorMsg: "",
                isRequired: false,
            },
        ],
    }

    const VCFormInputData: any = {
        "PERSONAL INFORMATION": [
            {
                questionid: 1,
                questionTitle: "Please specify your name / the name of your fund",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.name,
                successMsg: "Pleasure to meet you!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 2,
                questionTitle: "Please share your email address",
                questiontype: "textbox",
                inputType: "email",
                userAnswered: userData?.email,
                successMsg: "Thanks!",
                errorMsg: "please enter your email address",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Can we also have your Telegram ID?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.telegramId,
                successMsg: "Thanks!",
                errorMsg: "please enter your telegram id",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Where are you from? (City, Country)",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.address,
                successMsg: "Thanks!",
                errorMsg: "please enter address",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "Please provide a link to your LinkedIn profile.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.linkedInProfileLink,
                successMsg: "Thanks!",
                errorMsg: "please enter your linkdin link",
                isRequired: true,
            },
        ],
        "VC INFORMATION": [
            {
                questionid: 1,
                questionTitle: "Are you an accredited investor?",
                questiontype: "dropdown",
                userAnswered: userData?.isAccreditedInvestor,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Yes",
                    "No"
                ]
            },
            {
                questionid: 2,
                questionTitle: "Please provide a link to your website.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.websiteLink,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Please share the number of portfolio companies",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.portfolioCompanies,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Please provide a link to your portfolio",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.portfolioLink,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: " Which stage do you invest in?",
                questiontype: "dropdown",
                userAnswered: userData?.stageOfInvests,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
                dropdownOption: [
                    "Early Stage",
                    "Private",
                    "Seed",
                    "Series A",
                    "Post Series A"
                ]
            },
            {
                questionid: 6,
                questionTitle: "What is your average investment ticket?  (Amount in $)",
                questiontype: "dropdown",
                userAnswered: userData?.averageInvestmentTicket,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
                dropdownOption: [
                    "Under 10k",
                    "10k - 50k",
                    "50k - 100k",
                    "100k - 200k",
                    "200k - 500k",
                    "500k+",
                ]
            },
            {
                questionid: 7,
                questionTitle: "Which assets do you invest in?",
                questiontype: "dropdown",
                userAnswered: userData?.investmentAssets,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
                dropdownOption: [
                    "Equity",
                    "Tokens",
                    "Both",
                ]
            },
            {
                questionid: 8,
                questionTitle: "Which sector(s) would you like to invest in?",
                questiontype: "dropdown",
                liveLink: "",
                userAnswered: userData?.sectorsLikeToInvest,
                successMsg: "We promise, no spam!",
                errorMsg: "please fillup the question",
                isRequired: false,
                multiSelectDropdownOption: [
                    "DeFi",
                    "DAOs",
                    "Web3 Education",
                    "Marketplaces",
                    "NFTs",
                    "Web3 Social Platforms",
                    "Other",
                ]
            }
        ],
        "ADDITIONAL INFORMATION": [
            {
                questionid: 1,
                questionTitle: "What are the top things you look for in a startup?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.startupTopThings,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 2,
                questionTitle: "What are your value adds to a startup?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.startupValueAdds,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Please share details of your partnerships with other web3 companies",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.detailsOfPartnershipWithOthers,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: false,
            },
            {
                questionid: 4,
                questionTitle: " Please specify your investment thesis & your industry expertise",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.investmentThesisOrExpertise,
                successMsg: "Thanks!",
                errorMsg: "please fillup the question",
                isRequired: true,
            }
        ],
    }

    const mentorFormInputData: any = {
        "PERSONAL INFORMATION": [
            {
                questionid: 1,
                questionTitle: "Please specify your full name.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.name,
                successMsg: "Pleasure to meet you!",
                errorMsg: "please enter your name",
                isRequired: true,
            },
            {
                questionid: 2,
                questionTitle: "Please share your email address",
                questiontype: "textbox",
                inputType: "email",
                userAnswered: userData?.email,
                successMsg: "Thanks!",
                errorMsg: "please enter your email address",
                isRequired: true,
            },
            {
                questionid: 3,
                questionTitle: "Can we also have your Telegram ID?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.telegramId,
                successMsg: "Thanks!",
                errorMsg: "please enter your telegram id",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Where are you from? (City, Country)",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.address,
                successMsg: "Thanks!",
                errorMsg: "please enter your address",
                isRequired: false,
            },
            {
                questionid: 5,
                questionTitle: "Please provide a link to your LinkedIn profile.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.linkedInProfileLink,
                successMsg: "Thanks!",
                errorMsg: "please enter your linkdin link",
                isRequired: true,
            },
        ],
        "MENTORSHIP DETAILS": [
            {
                questionid: 1,
                questionTitle: "What unique contribution can you bring to the startups that you mentor?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.uniqueContributionToStartups,
                successMsg: "Thanks",
                errorMsg: "please fillup the question",
                isRequired: true
            },
            {
                questionid: 2,
                questionTitle: "How many years of experience do you have mentoring startups?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.yearsOfExp,
                successMsg: "Thanks!",
                errorMsg: "please enter your birth date",
                isRequired: false,
            },
            {
                questionid: 3,
                questionTitle: "Please share your past work records (link(s)) that show the credibility of your experience.",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.pastWorkRecords,
                successMsg: "Thanks!",
                errorMsg: "please enter your birth date",
                isRequired: true,
            },
            {
                questionid: 4,
                questionTitle: "Why are you interested in becoming a Symbiote mentor?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.whyInterestedInMentor,
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your number",
                isRequired: true,
            },
            {
                questionid: 5,
                questionTitle: "Choose at least one of the following areas of expertise",
                questiontype: "dropdown",
                userAnswered: userData?.areasOfExpertise || [],
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your number",
                isRequired: true,
                multiSelectDropdownOption: [
                    "Tokenomics",
                    "Tech and Architecture",
                    "Community",
                    "Business fundamentals",
                    "Regulatory",
                    "Development/Code",
                    "Launch/(Post)Sale",
                    "Investors",
                    "Networker",
                ]
            },
            {
                questionid: 7,
                questionTitle: "Choose at least one of the following industries of expertise",
                questiontype: "dropdown",
                userAnswered: userData?.industriesOfExpertise,
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your number",
                isRequired: false,
                multiSelectDropdownOption: [
                    "NFT",
                    "Blockchain Infrastructure",
                    "DeFi",
                    "Metaverse",
                    "SocialFi",
                    "Middleware",
                    "GameFi",
                    "DAO",
                    "Smart Contract Platforms and Frameworks",
                    "StableCoins",
                    "Exchanges",
                    "Finance/Banking",
                    "Privacy Coins",
                    "Wallets",
                    "Other,",
                ]
            },
            {
                questionid: 6,
                questionTitle: "What else should we know about you?",
                questiontype: "textbox",
                inputType: "text",
                userAnswered: userData?.about,
                successMsg: "We promise, no spam!",
                errorMsg: "please enter your number",
                isRequired: false,
            }
        ]
    }

    const handleSubmitData = async () => {
        let errorFilterResultsDataArray: any = [];
        selectedObjData && Object.keys(selectedObjData).map((keyname: any, i: any) => {
            let errorTempArray = selectedObjData[keyname] && selectedObjData[keyname].filter((errorDataItemObj: any) =>
                errorDataItemObj?.isRequired === true && ((errorDataItemObj?.questiontype === "fileupload" && errorDataItemObj?.localLink === "") || (errorDataItemObj?.questiontype !== "fileupload" && errorDataItemObj?.userAnswered === ""))
            )
            let _ = (errorTempArray?.length > 0) ? errorFilterResultsDataArray.push(...errorTempArray) : ""
            return errorTempArray;
        })

        if (errorFilterResultsDataArray?.length > 0) {
            toast.error("Please fill up all the required questions before processing this form.")
        } else {
            let filterResultsDataArray: any = [];
            selectedObjData && Object.keys(selectedObjData).map((keyname: any, i: any) => {
                let tempArray = selectedObjData[keyname] && selectedObjData[keyname].filter((dataItemObj: any) =>
                    dataItemObj.questiontype === "fileupload" && (Object.keys(dataItemObj?.localLink)?.length > 0 && dataItemObj?.localLink.constructor === Object)
                ).map((obj: any, key: any) => {
                    return { "keyName": keyname, "dataObject": obj }
                }, {});
                let _ = (tempArray?.length > 0) ? filterResultsDataArray.push(...tempArray) : ""
                return tempArray;
            })

            if (filterResultsDataArray?.length > 0) {
                filterResultsDataArray && filterResultsDataArray.map(async (item: any, i: number) => {
                    let response = await postImageUploade(item?.dataObject?.localLink)
                    selectedObjData[item?.keyName] && selectedObjData[item?.keyName].filter((dataItemObj: any) => dataItemObj.questionid === item?.dataObject?.questionid).forEach((someobject: any) => someobject.liveLink = response?.data?.link)
                    if (i === (Number(filterResultsDataArray?.length) - 1)) {
                        submitDataForProfileCreation(userData._id)
                    }
                })
            } else {
                submitDataForProfileCreation(userData._id)
            }
        }
    }

    const submitDataForProfileCreation = async (userId: any) => {

        if (isProjectForm || isProjectFirstTimeLoginForm) {
            let name = selectedObjData["Founder Information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let dob = moment(selectedObjData["Founder Information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered).unix()
            let phone = selectedObjData["Founder Information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let linkedInProfileLink = selectedObjData["Founder Information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let cvUrl = selectedObjData["Founder Information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.liveLink || ""

            let companyName = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let companyCreationDate = moment(selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered).unix()
            let location = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let websiteLink = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let companyDescription = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let companyRole = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered
            let employeesCount = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 7)?.userAnswered
            let companyPitchDeckUrl = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 8)?.liveLink || ""
            let companyWhitePaperUrl = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 9)?.liveLink || ""
            let companyCurrentStage = selectedObjData["Company information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 10)?.userAnswered

            let totalUsers = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let avgMonthlySpending = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let avgMonthlyRevenue = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let isCompanyInDebt = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let isCapitalRaised = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let isAnyPreviousParticipation = selectedObjData["Company Metrics"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered

            let totalCofounders = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let cofounderLinkedInProfileLinks = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let knownEachOtherInYrs = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let isTeamWorkingFullTime = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            // let otherProActivities = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered -- if user select other option
            let equityAndOwnershipInfo = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered
            let otherVentureInfo = selectedObjData["Team Details"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 7)?.userAnswered

            let acceleratorProgramReasons = selectedObjData["Additional information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let isAvailableFullTime = selectedObjData["Additional information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let walletAddress = selectedObjData["Additional information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            // let explainIfNotFullTime = selectedObjData["Additional information"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered - if user select other option
            try {
                await postUpdateSubmitProjctData(
                    userId,
                    name,
                    dob,
                    phone,
                    linkedInProfileLink,
                    cvUrl,
                    companyName,
                    companyCreationDate,
                    location,
                    websiteLink,
                    companyDescription,
                    companyRole,
                    Number(employeesCount),
                    companyPitchDeckUrl,
                    companyWhitePaperUrl,
                    companyCurrentStage,
                    totalUsers,
                    avgMonthlySpending,
                    avgMonthlyRevenue,
                    isCompanyInDebt.toLowerCase() === "yes" ? true : isCompanyInDebt.toLowerCase() === "no" ? false : false,
                    isCapitalRaised.toLowerCase() === "yes" ? true : isCapitalRaised.toLowerCase() === "no" ? false : false,
                    isAnyPreviousParticipation.toLowerCase() === "yes" ? true : isAnyPreviousParticipation.toLowerCase() === "no" ? false : false,
                    totalCofounders,
                    cofounderLinkedInProfileLinks,
                    knownEachOtherInYrs,
                    isTeamWorkingFullTime.toLowerCase() === "yes" ? true : isTeamWorkingFullTime.toLowerCase() === "no" ? false : false,
                    "",
                    equityAndOwnershipInfo,
                    otherVentureInfo,
                    acceleratorProgramReasons,
                    isAvailableFullTime.toLowerCase() === "yes" ? true : isAvailableFullTime.toLowerCase() === "no" ? false : false,
                    "",
                    walletAddress,
                )
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.success(res?.data?.message)
                            if (isProjectFirstTimeLoginForm) {
                                nav("/project-thanks")
                            }
                            return res
                        }
                        return {};
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }

        if (isMentorForm) {
            let name = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let telegramId = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let address = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let linkedInProfileLink = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered

            let uniqueContributionToStartups = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let yearsOfExp = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let pastWorkRecords = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let whyInterestedInMentor = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let areasOfExpertise = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let industriesOfExpertise = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 7)?.userAnswered
            let about = selectedObjData["MENTORSHIP DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered

            try {
                await putUpdateMentorData(
                    userId,
                    name,
                    telegramId,
                    address,
                    linkedInProfileLink,
                    uniqueContributionToStartups,
                    yearsOfExp,
                    pastWorkRecords,
                    whyInterestedInMentor,
                    areasOfExpertise,
                    industriesOfExpertise,
                    about
                )
                    .then((res) => {
                        if (res?.status === 200) {
                            console.log("res of save api ", res)
                            toast.success(res?.data?.message)
                            return res
                        }
                        return {};
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }

        if (isVCForm) {
            let name = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let telegramId = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let address = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let linkedInProfileLink = selectedObjData["PERSONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered

            let isAccreditedInvestor = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let websiteLink = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let portfolioCompanies = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let portfolioLink = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let stageOfInvests = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let averageInvestmentTicket = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered
            let investmentAssets = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 7)?.userAnswered
            let sectorsLikeToInvest = selectedObjData["VC INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 8)?.userAnswered

            let startupTopThings = selectedObjData["ADDITIONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let startupValueAdds = selectedObjData["ADDITIONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let detailsOfPartnershipWithOthers = selectedObjData["ADDITIONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let investmentThesisOrExpertise = selectedObjData["ADDITIONAL INFORMATION"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered

            try {
                await putUpdateVCData(
                    userId,
                    name,
                    telegramId,
                    address,
                    linkedInProfileLink,
                    isAccreditedInvestor.toLowerCase() === "yes" ? true : isAccreditedInvestor.toLowerCase() === "no" ? false : false,
                    websiteLink,
                    portfolioCompanies,
                    portfolioLink,
                    stageOfInvests,
                    averageInvestmentTicket,
                    investmentAssets,
                    sectorsLikeToInvest,
                    startupTopThings,
                    startupValueAdds,
                    detailsOfPartnershipWithOthers,
                    investmentThesisOrExpertise,
                )
                    .then((res) => {
                        if (res?.status === 200) {
                            console.log("res of save api ", res)
                            toast.success(res?.data?.message)
                            return res
                        }
                        return {};
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }
        if (isPartnerForm) {
            let name = selectedObjData["PERSONAL DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let email = selectedObjData["PERSONAL DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let telegramId = selectedObjData["PERSONAL DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let address = selectedObjData["PERSONAL DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let twitterLink = selectedObjData["PERSONAL DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered

            let companyName = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 1)?.userAnswered
            let companyWebsiteLink = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 2)?.userAnswered
            let companyOperations = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 3)?.userAnswered
            let companyDescription = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 4)?.userAnswered
            let partnerConnectionType = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 5)?.userAnswered
            let referrer = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 6)?.userAnswered
            let industriesOfExpertise = selectedObjData["COMPANY DETAILS"].find((dataItemObj: any) => Number(dataItemObj.questionid) === 7)?.userAnswered

            try {
                await putSubmitPartnerData(userId, {
                    name,
                    email,
                    telegramId,
                    address,
                    companyName,
                    companyWebsiteLink,
                    companyOperations,
                    companyDescription,
                    partnerConnectionType,
                    referrer,
                    industriesOfExpertise,
                    twitterLink
                })
                    .then((res) => {
                        if (res?.status === 200) {
                            toast.success(res?.data?.message)
                            return res
                        }
                        return {};
                    })
                    .catch((err: any) => {
                        toast.error(err?.response?.data?.message)
                        return err
                    })
            }
            catch (err: any) {
                toast.error(err?.response?.data?.message)
                return err;
            }
        }
    }

    const postImageUploade = async (fileObj: any) => {
        try {
            return await postContactAssetsUpdatedData(fileObj, isProjectForm ? "project-ahead" : isMentorForm ? "mentor-projects" : isVCForm ? "invest" : "become-partner")
                .then((res) => {
                    if (res?.status === 200) {
                        return res
                    } else {
                        toast.success(res?.data?.message)
                    }
                    return {};
                })
                .catch((err: any) => {
                    toast.error(err?.response?.data?.message)
                    return err
                })
        }
        catch (err: any) {
            toast.error(err?.response?.data?.message)
            return err;
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setJsonDict([])
        for (let index = 0; index < Number(newValue); index++) {
            let isHavingError = true
            let keyElement = Object.keys(selectedObjData)[index];
            selectedObjData[keyElement].map((dataItemObj: any, p: any) => {
                if (dataItemObj?.isRequired == true && dataItemObj?.userAnswered?.length > 0) {
                    isHavingError = false
                }
            })
            let errorObj = { "key": keyElement, "isError": isHavingError }
            setJsonDict((prev: any) => [...prev, errorObj])
        }
        setTabValue(newValue);
    };

    const handleNextTab = (event: React.SyntheticEvent, newValue: number, selectedOBJ: any) => {
        setJsonDict([])
        for (let index = 0; index < Number(newValue); index++) {
            let isHavingError = true
            let keyElement = Object.keys(selectedObjData)[index];
            selectedObjData[keyElement].map((dataItemObj: any, p: any) => {
                if (dataItemObj?.isRequired == true && dataItemObj?.userAnswered?.length > 0) {
                    isHavingError = false
                }
            })
            let errorObj = { "key": keyElement, "isError": isHavingError }
            setJsonDict((prev: any) => [...prev, errorObj])
        }
        setTabValue(newValue);
        checkIsSectionHavingError(selectedOBJ)
    };

    const checkIsSectionHavingError = (sectionkeyName: any) => {
        const filtered = jsonDict.filter((obj: any) => {
            return obj.key === sectionkeyName;
        });
        return filtered
    }

    const getProjectsUserAllData = async () => {
        await getProjectsUserData()
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message)
            })
    }

    const getMentorOrVCUserAllData = async (userType: string) => {
        await getMentorOrVCUserData(userType)
            .then((res) => {
                if (res?.status === 200) {
                    setUserData(res.data && res.data)
                }
            })
            .catch((err) => {
                toast.error(err?.response?.data?.message || 'Error while getting vc user data')
            })
    }

    const handelValueChange = (e: any, itemOBJ: any, position: number, itemKey: any) => {
        setInputValue(e || "")
        itemOBJ.userAnswered = e || ""
    };

    const handelFileUpload = (e: any, itemOBJ: any, position: number, itemKey: any) => {
        itemOBJ.localLink = e || {}
    };

    const handleChangeMultiSelect = (event: any, itemOBJ: any) => {
        const value: any = event;
        itemOBJ.userAnswered = value || []
        setSelectedNames(itemOBJ?.userAnswered);
    };

    const handleRemoveOrUpdateMultiSelect = (value: any, itemOBJ: any) => {
        const finalResults = itemOBJ?.userAnswered && itemOBJ?.userAnswered.filter((item: any) => item !== value)
        setSelectedNames(
            itemOBJ?.userAnswered && itemOBJ?.userAnswered.filter((item: any) => item !== value)
        )
        itemOBJ.userAnswered = finalResults || []
    };

    return (
        <div className="projectDetails">
            <Box sx={{ flexGrow: 1, display: 'flex' }} component='div'>
                <Tabs
                    component='div'
                    orientation="vertical"
                    variant="scrollable"
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    {selectedObjData && Object.keys(selectedObjData).map((key: any, i: any) => (
                        <Tab label={key} {...a11yProps(key)} key={i} className={checkIsSectionHavingError(key).length == 0 ? 'defaultTab' : checkIsSectionHavingError(key).isError === true ? "warningTab" : "successTab"} />
                    ))}
                </Tabs>
                {selectedObjData && Object.keys(selectedObjData).map((key: any, i: any) => (
                    <TabPanel value={tabValue} index={i} key={i} >
                        <div className='founders-form'>
                            <form>
                                {selectedObjData[key].map((item: any, index: any) => {
                                    return <div key={index}>
                                        {/* inpute text box */}
                                        {item && item?.questiontype === "textbox" &&
                                            <div className="form-group mb-lg-4 mb-md-3 mb-2 position-relative">
                                                <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                <input type={item && item?.inputType} placeholder={item?.placeholder} className="form-control" value={item && item?.userAnswered} id={item.questionid} onChange={(e: any) => { handelValueChange(e?.target?.value, item, index, key) }} />
                                                {/* {item && item?.isRequired ? item?.userAnswered?.length > 0 ?
                                                            <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.successMsg}</div>
                                                            :
                                                            <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.errorMsg}</div>
                                                            : null
                                                        } */}
                                            </div>
                                        }
                                        {/* date inpute */}
                                        {item && item?.questiontype === "datepicker" &&
                                            <div className="form-group mb-lg-4 mb-md-3 mb-2">
                                                <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                <input type="date" className="form-control" value={item && item?.userAnswered} name="dateofbirth" id={item.questionid} onChange={(e: any) => { handelValueChange(e?.target?.value, item, index, key) }} />
                                                {/* {item && item?.isRequired ? item?.userAnswered?.length > 0 ?
                                                            <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.successMsg}</div>
                                                            :
                                                            <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.errorMsg}</div>
                                                            : null
                                                        } */}
                                            </div>
                                        }
                                        {/* file uploader */}
                                        {item && item?.questiontype === "fileupload" &&
                                            <div className="form-group mb-lg-4 mb-md-3 mb-2 position-relative">
                                                <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                <div className="file-uploader position-relative">
                                                    <input type="file" className="form-control" name="file" id={item.questionid} onChange={(e: any) => { handelFileUpload(e?.target?.files[0], item, index, key) }} />
                                                    <i className="ri-upload-2-line"></i>
                                                </div>
                                                {/* {item && item?.isRequired ? item?.userAnswered?.length > 0 ?
                                                            <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.successMsg}</div>
                                                            :
                                                            <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.errorMsg}</div>
                                                            : null
                                                        } */}
                                            </div>
                                        }

                                        {/* malti select dropdown */}
                                        {item && item?.questiontype === "dropdown" && item?.multiSelectDropdownOption &&
                                            <div className="form-group mb-lg-4 mb-md-3 mb-2 position-relative">
                                                <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                <div className="malti-select">
                                                    <FormControl sx={{ width: "100%" }}>
                                                        <Select
                                                            multiple
                                                            value={item && item?.userAnswered || ""}
                                                            onChange={(e: any) => handleChangeMultiSelect(e.target.value, item)}
                                                            input={<OutlinedInput label="Multiple Select" />}
                                                            renderValue={(selected: any) => (
                                                                <Stack key={selected} gap={1} direction="row" flexWrap="wrap">
                                                                    {selected && selected.map((value: any) => (
                                                                        <Chip
                                                                            key={value}
                                                                            label={value}
                                                                            sx={{ background: '#353535' }}
                                                                            onDelete={() =>
                                                                                handleRemoveOrUpdateMultiSelect(value, item)
                                                                            }
                                                                            deleteIcon={
                                                                                <CancelIcon
                                                                                    sx={{ color: '#dbdbdb' }}
                                                                                    onMouseDown={(event) => event.stopPropagation()}
                                                                                />
                                                                            }
                                                                        />
                                                                    ))}
                                                                </Stack>
                                                            )}
                                                        >
                                                            {item && item?.multiSelectDropdownOption.map((name: any, i: number) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={name}
                                                                    sx={{ justifyContent: "space-between" }}
                                                                >
                                                                    {name}
                                                                    {selectedNames && selectedNames.includes(name) ? <Checkbox checked={selectedNames.indexOf(name) > -1} /> : null}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {/* {item && item?.isRequired ? item?.userAnswered?.length > 0 ?
                                                                <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.successMsg}</div>
                                                                :
                                                                <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.errorMsg}</div>
                                                                : null
                                                            } */}
                                                </div>
                                            </div>
                                        }
                                        {/* single select dropdown */}
                                        {item && item?.questiontype === "dropdown" && item?.dropdownOption &&
                                            <>
                                                <div className="form-group mb-lg-4 mb-md-3 mb-2" >
                                                    <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                    <select className="form-select select-option" aria-label="Default select example" value={item?.userAnswered} onChange={(e: any) => { handelValueChange(e?.target?.value, item, index, key) }}>
                                                        <option selected>Open this select menu</option>
                                                        {item?.dropdownOption.map((name: any, i: any) => (
                                                            <option key={i} value={name} >{name}</option>
                                                        ))}
                                                    </select>
                                                    {/* {item && item?.isRequired ? item?.userAnswered?.length > 0 ?
                                                                <>
                                                                    <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.successMsg}</div>
                                                                </> :
                                                                <>
                                                                    <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.errorMsg}</div>
                                                                </> : null
                                                            } */}
                                                </div>
                                                {/* {item && item.isdropdownNo && item?.userAnswered === "NO" &&
                                                            <div className="form-group mb-lg-4 mb-md-3 mb-2 position-relative">
                                                                <label className='paragraph-new fw-lighter mb-lg-3 mb-md-3 mb-2'>{item?.isdropdownNo?.questionTitle} {item && item?.isRequired && <span style={{ color: "red" }}>*</span>}</label>
                                                                <input type="text" className="form-control" value={item && item?.isdropdownNo?.userAnswered || ""} id={item?.isdropdownNo?.questionid} onChange={(e: any) => { handelValueChange(e?.target?.value, item, index, key) }} />
                                                                {item && item?.isdropdownNo?.isRequired ? item?.isdropdownNo?.userAnswered?.length > 0 ?
                                                                    <>
                                                                        <div className='paragraph-new-medium success mb-0 mt-2 text-end'>{item?.isdropdownNo?.successMsg}</div>
                                                                    </> :
                                                                    <>
                                                                        <div className='paragraph-new-medium error mb-0 mt-2 text-end'>{item?.isdropdownNo?.errorMsg}</div>
                                                                    </> : null
                                                                }
                                                            </div>
                                                        } */}
                                            </>
                                        }
                                    </div>
                                })}
                            </form>
                            {Number(tabValue) < Object.keys(selectedObjData).length - 1 ?
                                <div className='mt-4 text-center'>
                                    <button className='new-primary-button' onClick={(e: any) => handleNextTab(e, tabValue + 1, key)}>Next</button>
                                </div>
                                : null
                            }
                            <div className="text-center">
                                {Number(tabValue) === Object.keys(selectedObjData).length - 1 ?
                                    <button className='new-primary-button' onClick={handleSubmitData}>Submit</button> : ""
                                }
                            </div>
                        </div>
                    </TabPanel>
                ))}
            </Box>
        </div>
    )
}
