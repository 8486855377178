import React, { useState } from 'react'
import "./update-symbioteScore-model.sass";
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import DecrementIcon from '../../../../../../../assests/images/homepage/decrement-icon.svg'
import IncrementIcon from '../../../../../../../assests/images/homepage/increment-icon.svg'
import { addMentorFeedbackAPI } from '../../../../../project-dashboard/components/mentor-network/API/APICall';
import { toast } from 'react-toastify';

interface detailsProps {
    isOpen: boolean,
    toggle: () => void,
    scoreFeedback: { score: number, feedback: string },
    setScoreFeedback: (value: any) => void
    userData: any
}

const UpdateSymbioteScoreModel = ({ isOpen, toggle, scoreFeedback, setScoreFeedback, userData }: detailsProps) => {

    const onChangeScore = (value: number) => {
        if (value > 100) value = 100
        if (value < 0) value = 0
        setScoreFeedback((prev: any) => ({ ...prev, score: value }))
    }

    const onChangeFeedback = (value: string) => {
        setScoreFeedback((prev: any) => ({ ...prev, feedback: value }))
    }

    const onSubmitScoreFeedback = async () => {
        const response = await addMentorFeedbackAPI(scoreFeedback, userData._id)
        if (response && response.data) {
            toast.success('Project feedback added')
            toggle();
        }
    }

    return (
        <>
            <Modal className='connection-request-model' centered fade={false} isOpen={isOpen} toggle={toggle}>
                <div className="modal-wrapper">
                    <ModalHeader toggle={toggle} className='justify-content-center'>
                        <div className="close-btn" onClick={toggle}>
                            <svg
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
                                    fill="black"
                                />
                            </svg>
                        </div>
                    </ModalHeader>
                </div>
                <ModalBody>
                    <div className='connection-request-details'>
                        <h3 className='heading-new-3 text-center color-white-new'>Update SymbioteScore</h3>
                        <div className='form-slider my-4'>
                            <div className='percentage-slider text-center m-auto d-flex align-items-center justify-content-center gap-lg-3 gap-2'>
                                <CircularProgressbar value={Number(userData && userData.projectAvgScore || 0)} text={`${Number(userData && userData.projectAvgScore || 0).toFixed(0)}`} background
                                    backgroundPadding={0}
                                    styles={buildStyles({
                                        backgroundColor: "transparent",
                                        textColor: "#fff",
                                        pathColor: "#65DB6A",
                                        trailColor: "white"
                                    })} />
                                <p className='paragraph-new-small text-center mb-0'>Current Score</p>
                            </div>
                        </div>
                        <div className='form-slider integreted-slider d-flex align-items-center '>
                            <div className='icrement-icon' role='button' onClick={() => onChangeScore(scoreFeedback && scoreFeedback.score - 5)}>
                                <img src={DecrementIcon} className='img-fluid' alt="" />
                            </div>
                            <div className='percentage-slider text-center m-auto d-flex'>
                                <CircularProgressbar
                                    value={Number(scoreFeedback && scoreFeedback.score)}
                                    text={`${Number(scoreFeedback && scoreFeedback.score).toFixed(0)}`}
                                    background
                                    backgroundPadding={0}
                                    styles={buildStyles({
                                        backgroundColor: "transparent",
                                        textColor: "#fff",
                                        pathColor: "#65DB6A",
                                        trailColor: "white"
                                    })} />
                            </div>
                            <div className='icrement-icon' role='button' onClick={() => onChangeScore(scoreFeedback.score + 5)}>
                                <img src={IncrementIcon} className='img-fluid' alt="" />
                            </div>
                        </div>

                        <div className="paragraph-new mt-4 text-center fw-lighter">Write Message</div>
                        <div className="mt-lg-4 mt-md-4 mt-2 connection-request-textarea">
                            <textarea
                                className="form-control"
                                rows={8}
                                placeholder='Add Feedback (Optional)'
                                value={scoreFeedback && scoreFeedback.feedback}
                                onChange={e => onChangeFeedback(e.target.value)}
                            />
                        </div>

                        <div className="text-center mt-4">
                            <button className='new-primary-button' onClick={onSubmitScoreFeedback}>Update</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default UpdateSymbioteScoreModel