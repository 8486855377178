import React, { useEffect, useState } from 'react'
import "./vc-projects.sass"
import { VCCard } from '../vc-card/vc-card'
import Filterlist from '../filter-list/filter-list'
import { useLocation } from 'react-router-dom'
import { getMyProjectMentorOrVCData, setProjectInterestAPI } from '../../API/APICall'
import { toast } from 'react-toastify'
import { CircularProgress } from '@mui/material'

export interface TabIndexData {
    value: any,
}

const VcProjects = (props: TabIndexData) => {
    const { value } = props
    const location = useLocation();
    const pathname = location.pathname;
    const isMentorDashboard = pathname.includes("/mentor-dashboard")
    const isVcDashboard = pathname.includes("/vc-dashboard")
    const [selectedMenu, setselectedMenu] = useState("Mentoring");
    const [projectMenu, setProjectMenu] = useState(["Mentoring"]);
    const [mentorProjectData, setMentorProjectData] = useState<any>();
    const [projectLoader, setProjectLoader] = useState(false)

    const getMyProjectMentorAllData = async (disableLoader?: boolean) => {
        if (!disableLoader) setProjectLoader(true)
        await getMyProjectMentorOrVCData(selectedMenu === "Mentoring" ? false : true, isMentorDashboard ? 'mentor-dashboard' : isVcDashboard ? 'vc-dashboard' : 'partner-dashboard')
            .then((res) => {
                setProjectLoader(false)
                if (res?.status === 200) {
                    setMentorProjectData(res?.data && res?.data?.values)
                }
            })
            .catch((err) => {
                setProjectLoader(false)
                toast.error(err?.response?.data?.message)
            })
    }

    useEffect(() => {
        if (projectMenu.length < 2) {
            setProjectMenu([...projectMenu, "Supporting"])
        }
        if (selectedMenu) {
            getMyProjectMentorAllData()
        }
    }, [selectedMenu])

    const onClickExpressInterest = async (projectId: string) => {
        await setProjectInterestAPI(projectId, isMentorDashboard ? 'mentor' : isVcDashboard ? 'vc' : 'partner')
        getMyProjectMentorAllData(true)
    }

    return (
        <>
            {isMentorDashboard && <div className="event-button text-center mb-5">
                {projectMenu.map((menu: any, id: number) => (
                    <button key={id}
                        className={selectedMenu === menu ? "selectedMenu menuItem " : "menuItem "}
                        onClick={() => setselectedMenu(menu)}
                    >
                        {menu}
                    </button>
                ))}
            </div>}

            {projectLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress style={{ color: 'white' }} size={32} />
            </div>}

            {!projectLoader && <div className="row mb-lg-5 mb-md-4">
                {mentorProjectData?.length > 0 ?
                    <>
                        {mentorProjectData && mentorProjectData.map((item: any, index: number) => (
                            <VCCard
                                indexValue={value}
                                key={index}
                                name={item?.name}
                                icon={item?.icon}
                                banner={item?.banner}
                                _id={item?._id}
                                isInterested={item?.isInterested}
                                projectAvgScore={item?.projectAvgScore}
                                companyName={item?.companyName}
                                companyDescription={item?.companyDescription}
                                linkedInProfileLink={item?.linkedInProfileLink}
                                twitterLink={item?.twitterLink}
                                telegramLink={item?.telegramLink}
                                websiteLink={item?.websiteLink}
                                disableInterested={!isMentorDashboard}
                                onClickExpressInterest={onClickExpressInterest}
                                isAccelerateProject={item?.isAccelerateProject}
                            />
                        ))}
                    </>
                    : <p className='paragraph-new text-center mb-0'>No data available</p>
                }
            </div>}

        </>
    )
}

export default VcProjects