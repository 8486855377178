import axios from "axios";
import { GET_ALL_ASSIGNED_VC, GET_ALL_MENTORS_DATA, POST_CONTACT_ASSETS_UPDATE_DATA, POST_MENTOR_SUBMIT_DATA, POST_PARTNER_SUBMIT_DATA, POST_PROJECT_SUBMIT_DATA, POST_VC_SUBMIT_DATA, symbioteApiBaseUrl } from "../../../../constants";
import { getloggedInUserData, loggedInAs } from "src/constants/utils";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const postBodyHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`
  };
}

const postProjectProfileAssetsURL = POST_CONTACT_ASSETS_UPDATE_DATA
const postVCProfileAssetsURL = POST_CONTACT_ASSETS_UPDATE_DATA
const postMentorProfileAssetsURL = POST_CONTACT_ASSETS_UPDATE_DATA
const postPartnerProfileAssetsURL = POST_CONTACT_ASSETS_UPDATE_DATA
const postProjectSubmitDataURL = POST_PROJECT_SUBMIT_DATA
const postMentorSubmitDataURL = POST_MENTOR_SUBMIT_DATA
const postVCSubmitDataURL = POST_VC_SUBMIT_DATA
const postPartnerSubmitDataURL = POST_PARTNER_SUBMIT_DATA
const postUpdateProjectSubmitDataURL = POST_PROJECT_SUBMIT_DATA
const postUpdateMentorDataURL = GET_ALL_MENTORS_DATA
const postUpdateVCDataURL = GET_ALL_ASSIGNED_VC

const postContactAssetsUpdatedData = async (fileObj: any, userType: string) => {
  let bodyFormData = new FormData();

  if (fileObj != undefined) {
    bodyFormData.append("file", fileObj || '');
  }

  let apiURL = "";

  if (userType === "project-ahead") {
    apiURL = postProjectProfileAssetsURL;
  } else if (userType === "become-partner") {
    apiURL = postPartnerProfileAssetsURL;
  } else if (userType === "invest") {
    apiURL = postVCProfileAssetsURL;
  } else if (userType === "mentor-projects") {
    apiURL = postMentorProfileAssetsURL;
  }

  const res: any = await axios({
    method: "post",
    url: apiURL,
    data: bodyFormData,
    headers: {},
  })

  return res;
};

const postSubmitProjctData = async (name: string, dob: number, email: string, phone: string, linkedInProfileLink: string, cvUrl: string, companyName: string, companyCreationDate: number, location: string, websiteLink: string,
  companyDescription: string, companyRole: string, employeesCount: number, companyPitchDeckUrl: string, companyWhitePaperUrl: string, companyCurrentStage: string,
  totalUsers: string, avgMonthlySpending: string, avgMonthlyRevenue: string, isCompanyInDebt: boolean, isCapitalRaised: boolean, isAnyPreviousParticipation: boolean,
  totalCofounders: number, cofounderLinkedInProfileLinks: any, knownEachOtherInYrs: string, isTeamWorkingFullTime: boolean, otherProActivities: string, equityAndOwnershipInfo: string, otherVentureInfo: string,
  acceleratorProgramReasons: any, isAvailableFullTime: boolean, explainIfNotFullTime: string, referrer: string, telegramLink: string, twitterLink: string
) => {
  const bodyParams = {
    "name": name,
    "dob": dob,
    "email": email,
    "phone": phone,
    "linkedInProfileLink": linkedInProfileLink,
    "cvUrl": cvUrl,
    "companyName": companyName,
    "companyCreationDate": companyCreationDate,
    "location": location,
    "websiteLink": websiteLink,
    "companyDescription": companyDescription,
    "companyRole": companyRole,
    "employeesCount": employeesCount,
    "companyPitchDeckUrl": companyPitchDeckUrl,
    "companyWhitePaperUrl": companyWhitePaperUrl,
    "companyCurrentStage": companyCurrentStage,
    "totalUsers": totalUsers,
    "avgMonthlySpending": avgMonthlySpending,
    "avgMonthlyRevenue": avgMonthlyRevenue,
    "isCompanyInDebt": isCompanyInDebt,
    "isCapitalRaised": isCapitalRaised,
    "isAnyPreviousParticipation": isAnyPreviousParticipation,
    "totalCofounders": totalCofounders,
    "cofounderLinkedInProfileLinks": cofounderLinkedInProfileLinks,
    "knownEachOtherInYrs": knownEachOtherInYrs,
    "isTeamWorkingFullTime": isTeamWorkingFullTime,
    "otherProActivities": otherProActivities,
    "equityAndOwnershipInfo": equityAndOwnershipInfo,
    "otherVentureInfo": otherVentureInfo,
    "acceleratorProgramReasons": acceleratorProgramReasons,
    "isAvailableFullTime": isAvailableFullTime,
    "explainIfNotFullTime": explainIfNotFullTime,
    "referrer": referrer,
    telegramLink,
    twitterLink
  }
  const res: any = await axios.post(postProjectSubmitDataURL, bodyParams, { headers: {}, })
  return res;
};

const postSubmitMentorData = async (name: string, email: string, telegramId: string, address: string, linkedInProfileLink: string, uniqueContributionToStartups: string, yearsOfExp: string, pastWorkRecords: string, whyInterestedInMentor: string, areasOfExpertise: string, industriesOfExpertise: string, about: string, referrer: string) => {
  const bodyParams = {
    "name": name,
    "email": email,
    "telegramId": telegramId,
    "address": address,
    "linkedInProfileLink": linkedInProfileLink,
    "uniqueContributionToStartups": uniqueContributionToStartups,
    "yearsOfExp": yearsOfExp,
    "pastWorkRecords": pastWorkRecords,
    "whyInterestedInMentor": whyInterestedInMentor,
    "areasOfExpertise": areasOfExpertise,
    "industriesOfExpertise": industriesOfExpertise,
    "about": about,
    "referrer": referrer
  }
  const res: any = await axios.post(postMentorSubmitDataURL, bodyParams, { headers: {}, })
  return res;
};

const postSubmitVCData = async (name: string, email: string, telegramId: string, address: string, linkedInProfileLink: string,
  isAccreditedInvestor: boolean, websiteLink: string, portfolioCompanies: string, portfolioLink: string, stageOfInvests: string, averageInvestmentTicket: string,
  investmentAssets: string, sectorsLikeToInvest: string, startupTopThings: string, startupValueAdds: string, detailsOfPartnershipWithOthers: string, investmentThesisOrExpertise: string,
  referrer: string
) => {
  const bodyParams = {
    "name": name,
    "email": email,
    "telegramId": telegramId,
    "address": address,
    "linkedInProfileLink": linkedInProfileLink,
    "isAccreditedInvestor": isAccreditedInvestor,
    "websiteLink": websiteLink,
    "portfolioCompanies": portfolioCompanies,
    "portfolioLink": portfolioLink,
    "stageOfInvests": stageOfInvests,
    "averageInvestmentTicket": averageInvestmentTicket,
    "investmentAssets": investmentAssets,
    "sectorsLikeToInvest": sectorsLikeToInvest,
    "startupTopThings": startupTopThings,
    "startupValueAdds": startupValueAdds,
    "detailsOfPartnershipWithOthers": detailsOfPartnershipWithOthers,
    "investmentThesisOrExpertise": investmentThesisOrExpertise,
    "referrer": referrer
  }
  const res: any = await axios.post(postVCSubmitDataURL, bodyParams, { headers: {}, })
  return res;
};

const postSubmitPartnerData = async (name: string, email: string, telegramId: string, address: string, companyName: string,
  companyWebsiteLink: string, companyOperations: string, companyDescription: string, partnerConnectionType: string,
  referrer: string, industriesOfExpertise: string, twitterLink: string
) => {
  const bodyParams = {
    "name": name,
    "email": email,
    "telegramId": telegramId,
    "address": address,
    "companyName": companyName,
    "companyWebsiteLink": companyWebsiteLink,
    "companyOperations": companyOperations,
    "companyDescription": companyDescription,
    "partnerConnectionType": partnerConnectionType,
    "referrer": referrer,
    "industriesOfExpertise": industriesOfExpertise,
    "twitterLink": twitterLink
  }
  const res: any = await axios.post(postPartnerSubmitDataURL, bodyParams, { headers: {}, })
  return res;
};

const postUpdateSubmitProjctData = async (projectId: any, name: string, dob: number, phone: string, linkedInProfileLink: string, cvUrl: string, companyName: string, companyCreationDate: number, location: string, websiteLink: string,
  companyDescription: string, companyRole: string, employeesCount: number, companyPitchDeckUrl: string, companyWhitePaperUrl: string, companyCurrentStage: string,
  totalUsers: string, avgMonthlySpending: string, avgMonthlyRevenue: string, isCompanyInDebt: boolean, isCapitalRaised: boolean, isAnyPreviousParticipation: boolean,
  totalCofounders: number, cofounderLinkedInProfileLinks: any, knownEachOtherInYrs: string, isTeamWorkingFullTime: boolean, otherProActivities: string, equityAndOwnershipInfo: string, otherVentureInfo: string,
  acceleratorProgramReasons: any, isAvailableFullTime: boolean, explainIfNotFullTime: string, walletAddress: string
) => {
  const bodyParams = {
    "name": name,
    "dob": dob,
    "phone": phone,
    "linkedInProfileLink": linkedInProfileLink,
    "cvUrl": cvUrl,
    "companyName": companyName,
    "companyCreationDate": companyCreationDate,
    "location": location,
    "websiteLink": websiteLink,
    "companyDescription": companyDescription,
    "companyRole": companyRole,
    "employeesCount": employeesCount,
    "companyPitchDeckUrl": companyPitchDeckUrl,
    "companyWhitePaperUrl": companyWhitePaperUrl,
    "companyCurrentStage": companyCurrentStage,
    "totalUsers": totalUsers,
    "avgMonthlySpending": avgMonthlySpending,
    "avgMonthlyRevenue": avgMonthlyRevenue,
    "isCompanyInDebt": isCompanyInDebt,
    "isCapitalRaised": isCapitalRaised,
    "isAnyPreviousParticipation": isAnyPreviousParticipation,
    "totalCofounders": totalCofounders,
    "cofounderLinkedInProfileLinks": cofounderLinkedInProfileLinks,
    "knownEachOtherInYrs": knownEachOtherInYrs,
    "isTeamWorkingFullTime": isTeamWorkingFullTime,
    "otherProActivities": otherProActivities,
    "equityAndOwnershipInfo": equityAndOwnershipInfo,
    "otherVentureInfo": otherVentureInfo,
    "acceleratorProgramReasons": acceleratorProgramReasons,
    "isAvailableFullTime": isAvailableFullTime,
    "explainIfNotFullTime": explainIfNotFullTime,
  } as any

  // if (walletAddress) {
  bodyParams.walletAddress = walletAddress
  // }
  const res: any = await axios.put(postUpdateProjectSubmitDataURL + "/" + projectId, bodyParams, { headers: postBodyHeaders() })
  return res;
};

const putUpdateVCData = async (vcId: string, name: string, telegramId: string, address: string, linkedInProfileLink: string,
  isAccreditedInvestor: boolean, websiteLink: string, portfolioCompanies: string, portfolioLink: string, stageOfInvests: string, averageInvestmentTicket: string,
  investmentAssets: string, sectorsLikeToInvest: string, startupTopThings: string, startupValueAdds: string, detailsOfPartnershipWithOthers: string, investmentThesisOrExpertise: string,

) => {
  const bodyParams = {
    "name": name,
    "telegramId": telegramId,
    "address": address,
    "linkedInProfileLink": linkedInProfileLink,
    "isAccreditedInvestor": isAccreditedInvestor,
    "websiteLink": websiteLink,
    "portfolioCompanies": portfolioCompanies,
    "portfolioLink": portfolioLink,
    "stageOfInvests": stageOfInvests,
    "averageInvestmentTicket": averageInvestmentTicket,
    "investmentAssets": investmentAssets,
    "sectorsLikeToInvest": sectorsLikeToInvest,
    "startupTopThings": startupTopThings,
    "startupValueAdds": startupValueAdds,
    "detailsOfPartnershipWithOthers": detailsOfPartnershipWithOthers,
    "investmentThesisOrExpertise": investmentThesisOrExpertise,
  }
  const res: any = await axios.put(postUpdateVCDataURL + "/" + vcId, bodyParams, { headers: postBodyHeaders() })
  return res;
};

const putUpdateMentorData = async (mentorId: string, name: string, telegramId: string, address: string, linkedInProfileLink: string, uniqueContributionToStartups: string, yearsOfExp: string, pastWorkRecords: string, whyInterestedInMentor: string, areasOfExpertise: string, industriesOfExpertise: string, about: string) => {
  const bodyParams = {
    "name": name,
    "telegramId": telegramId,
    "address": address,
    "linkedInProfileLink": linkedInProfileLink,
    "uniqueContributionToStartups": uniqueContributionToStartups,
    "yearsOfExp": yearsOfExp,
    "pastWorkRecords": pastWorkRecords,
    "whyInterestedInMentor": whyInterestedInMentor,
    "areasOfExpertise": areasOfExpertise,
    "industriesOfExpertise": industriesOfExpertise,
    "about": about,
  }
  const res: any = await axios.put(postUpdateMentorDataURL + "/" + mentorId, bodyParams, { headers: postBodyHeaders() })
  return res;
};

const putSubmitPartnerData = async (partnerId: string, data: {
  name: string, email: string, telegramId: string, address: string, companyName: string,
  companyWebsiteLink: string, companyOperations: string, companyDescription: string, partnerConnectionType: string,
  referrer: string, industriesOfExpertise: string, twitterLink: string
}) => {

  const bodyParams = {
    "name": data.name,
    "email": data.email,
    "telegramId": data.telegramId,
    "address": data.address,
    "companyName": data.companyName,
    "companyWebsiteLink": data.companyWebsiteLink,
    "companyOperations": data.companyOperations,
    "companyDescription": data.companyDescription,
    "partnerConnectionType": data.partnerConnectionType,
    "referrer": data.referrer,
    "industriesOfExpertise": data.industriesOfExpertise,
    "twitterLink": data.twitterLink
  }
  const res: any = await axios.put(postPartnerSubmitDataURL + "/" + partnerId, bodyParams, { headers: postBodyHeaders() })
  return res;
};

type UpdateUserPhotoType = {
  imageLocation: string
  imgType?: 'banner' | 'icon',
}

const updateUserPhotoAPI = async (reqObj: UpdateUserPhotoType) => {
  const { imgType, imageLocation } = reqObj
  const { project, partner, mentor, vc } = loggedInAs()
  const { _id } = getloggedInUserData()
  let reqBody = {}
  let apiUrl = symbioteApiBaseUrl

  if (project) {
    if (!imgType) return // imgType required if user logged in as project
    reqBody = { [imgType]: imageLocation }
    apiUrl = apiUrl.concat(`/api/v1/projects/${_id}`)
  }
  else if (partner) {
    reqBody = { icon: imageLocation }
    apiUrl = apiUrl.concat(`/api/v1/partner/${_id}`)
  } else if (mentor) {
    reqBody = { profilePic: imageLocation }
    apiUrl = apiUrl.concat(`/api/v1/mentor/${_id}`)
  } else if (vc) {
    reqBody = { icon: imageLocation }
    apiUrl = apiUrl.concat(`/api/v1/vc/${_id}`)
  } else return

  return await axios.put(apiUrl, reqBody, { headers: postBodyHeaders() })
}

export {
  postContactAssetsUpdatedData,
  postSubmitProjctData,
  postSubmitMentorData,
  postSubmitVCData,
  postSubmitPartnerData,
  postUpdateSubmitProjctData,
  putUpdateVCData,
  putUpdateMentorData,
  putSubmitPartnerData,
  updateUserPhotoAPI,
};