import React, { useLayoutEffect } from 'react'
import './style.scss'
import TopSection from './components/TopSection'
import WhySymbiote from './components/WhySymbiote'
import OurNetwork from './components/OurNetwork'
import Stories from './components/Stories'
import Footer from './components/Footer'
import JoinSymbiote from './components/JoinSymbiote'
import NewsSection from './components/NewsSection'
import StayConnected from './components/StayConnected'
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function NewHome() {

  return (
    <div className='text-white new-homepage-container'>
      <TopSection />
      <WhySymbiote />
      <OurNetwork />
      <Stories />
      <JoinSymbiote />
      <NewsSection />
      <StayConnected />
      <Footer />
    </div>
  )
}