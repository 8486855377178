import React from 'react'
import "./partner-list-table.sass";
import NoImage from '../../../../assests/images/homepage/no-image-available.jpg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export interface statusData {
    setPartnerStatus?: any
    currentPartnerData?: any
    tableColumns?: any
    tabIndex?: any,
    userType?: any
    isLoading?: boolean
}

const PartnerListTable = (props: statusData) => {
    const { setPartnerStatus, currentPartnerData, tableColumns, tabIndex, userType, isLoading = false } = props
    const location = useLocation();
    const pathname = location.pathname;
    const isProjectDashboard = pathname.includes("/project/dashboard")
    const nav = useNavigate()

    if (isLoading) {
        return <div className='min-h-[100px] flex justify-center items-center'>
            <CircularProgress size={32} sx={{ color: "#ffffff" }} />
        </div>
    }

    if (!isLoading && currentPartnerData && currentPartnerData.length === 0) {
        return <div className='min-h-[100px] flex justify-center items-center'>
            <p className='paragraph-new text-center' style={{ color: '#B7B7B7' }}>Nobody assigned yet.</p>
        </div>

    }

    return (
        <>
            <div className="partner-list-data">
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {tableColumns.map((column: any) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ minWidth: column.minWidth, paddingLeft: 0, maxWidth: column.maxWidth || 'auto' }}
                                        >
                                            <span className='color-primary fw-bold'>
                                                {column.label}</span>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {currentPartnerData && currentPartnerData?.length ?
                                    <>
                                        {currentPartnerData && currentPartnerData?.map((row: any, i: number) => {

                                            const twitterLink = row?.twitterLink ? `https://twitter.com/${row?.twitterLink}` : ''
                                            const telegramLink = row?.telegramId ? `https://t.me/${row?.telegramId}` : ''
                                            const websiteLink = row?.companyWebsiteLink || ''
                                            const linkedinLink = row?.linkedInProfileLink || ''
                                            const name = userType === "vc" ? row?.name : row?.companyName
                                            const column2 = userType === "vc" ?
                                                row?.sectorsLikeToInvest && row?.sectorsLikeToInvest.join(', ') || '-'
                                                : row?.companyOperations && row?.companyOperations.join(', ') || '-'

                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        <Avatar alt="Remy Sharp" className='img-fluid mt-0' src={row?.icon || NoImage} />
                                                    </TableCell>
                                                    <TableCell onClick={() => userType === "vc" ? nav(`/public-view/vc/${row?._id}?user-type=project`) : ""} role={userType === "vc" ? "button" : ""}>
                                                        <div className='d-flex align-items-center'>
                                                            {/* <Avatar alt="Remy Sharp" className='img-fluid mt-0' src={row?.icon || NoImage} /> */}
                                                            <p className='paragraph-new-medium mb-0 mt-0 fw-bold'>{name}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell><p className='paragraph-new-medium mb-0 mt-0'>{column2}</p></TableCell>
                                                    <TableCell className=''>
                                                        <p className='paragraph-new-medium table-description mb-0'>{userType === "vc" ? row?.description : row?.shortDescription || '-'}</p>
                                                    </TableCell>
                                                    <TableCell >
                                                        <ul className='social-link-table'>
                                                            {twitterLink && <li><a href={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                                                            {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                                                            {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                                                            {linkedinLink && <li><a href={linkedinLink} target='_blank'><i className="ri-linkedin-fill"></i></a></li>}
                                                        </ul>
                                                    </TableCell>
                                                    <TableCell><p className="paragraph-new-medium mb-0 mt-0">Connected</p></TableCell>
                                                </TableRow>
                                            )
                                        }
                                        )}
                                    </>
                                    : null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Paper>
            </div>
        </>
    );
};

export default PartnerListTable;
