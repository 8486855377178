import React, { useEffect, useState } from 'react'
import "./dashboard.sass"
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import Slider from "react-slick";
import { ProgressRangeSlider } from './components/progress-slider/progress-slider'
import Updatessymbioversetable from './components/updates-symbioverse-table/updates-symbioverse-table';
import MentorCard from './components/mentor-card/mentor-card';
import { getAllExternalVideosLinkData, getAllMentorsData, getAllVideosData } from '../../API/APICall';
import YouTube from 'react-youtube';
import { ToastContainer, toast } from 'react-toastify';
import { addRemoveEventReminderAPI, getEventsData } from '../calendar-events/API/APICall';
import moment from 'moment';
import MediaSlider from '../../../../../shared/components/media-slider-comp/mediaSlider';
import CircularProgress from "@mui/material/CircularProgress"
import Feeds from './components/feeds/Feeds';
import { useLocation } from 'react-router-dom';

export interface MentorCardData {
  value: any,
  setValue: any,
  projectUserData: any
}

let Mentorsettings = {
  dots: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  infinite: false,
  rows: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 2,
      }
    }

  ]
};

const Dashboard = (props: MentorCardData) => {
  const { value, setValue, projectUserData } = props
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [recomandedMentorsData, setRecomandedMentorsData] = useState<any>();
  const [recomandedCourcesData, setRecomandedCourcesData] = useState<any>();
  const [recomandedMentorsLoader, setRecomandedMentorsLoader] = useState(false)
  const [recomandedCourcesLoader, setRecomandedCourcesLoader] = useState(false)
  const [eventsData, setEventsData] = useState<any>();
  const [refreshData, setRefreshData] = useState<boolean>(false)
  const [eventLoader, setEventLoader] = useState<boolean>(false)

  const location = useLocation()

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    return (() => {
      window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  // const getProjectsUserAllData = async () => {
  //   await getProjectsUserData()
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         setProjectUserData(res.data && res.data)
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(
  //         err.message || 'Error while getting project User data'
  //       )
  //     })
  // }

  const getRecomandedMentorData = async () => {
    setRecomandedMentorsLoader(true)
    await getAllMentorsData(true, "recomandedMentor", { skip: 0, limit: 6 })
      .then((res) => {
        setRecomandedMentorsLoader(false)
        if (res?.status === 200) {
          setRecomandedMentorsData(res?.data?.values && res?.data?.values)
        }
      })
      .catch((err) => {
        setRecomandedMentorsLoader(false)
        toast.error(err?.response?.data?.message)
      })
  }

  const getRecomandedCoursesData = async () => {
    setRecomandedCourcesLoader(true)
    await getAllExternalVideosLinkData({ limit: 6 })
      .then((res) => {
        setRecomandedCourcesLoader(false)
        if (res?.status === 200) {
          setRecomandedCourcesData(res?.data?.values && res?.data?.values)
        }
      })
      .catch((err) => {
        setRecomandedCourcesLoader(false)
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    getRecomandedCoursesData()
  }, [])

  useEffect(() => {
    getRecomandedMentorData()
  }, [refreshData])

  // get events data data
  const getAllEventsData = async () => {
    setEventLoader(true)
    await getEventsData()
      .then((res) => {
        setEventLoader(false)
        if (res?.status === 200) {
          setEventsData(res && res?.data?.values);
        }
      })
      .catch((err) => {
        setEventLoader(false)
        console.log(err, "Error")
      })
  }

  useEffect(() => {
    getAllEventsData()
  }, [])

  if (location.pathname.includes('/project/feeds')) {

    (() => {
      const elm = document.getElementById('scroll-start-element')
      if (elm) {
        elm.scrollIntoView()
      }
    })()

    return <Feeds hideMore />
  }

  return (
    <>
      <div className='dashboard-section'>

        <div className='symbiotescore-section position-relative'>
          <div className='d-flex gap-3 align-items-center '>
            <div className='percentage-slider text-center d-flex '>
              <CircularProgressbar value={projectUserData && projectUserData?.projectAvgScore || 0} text={`${Number(projectUserData && projectUserData?.projectAvgScore || 0).toFixed(0)}`} background
                backgroundPadding={0}
                styles={buildStyles({
                  backgroundColor: "transparent",
                  textColor: "#fff",
                  pathColor: "#FFB800",
                  trailColor: "white"
                })} />
            </div>
            <div className='symbiotescore-text w-100'>
              <div className='position-absolute end-0 top-0 m-2'>
                <i className="ri-information-line " style={{ color: "#4398FF" }}></i>
              </div>
              <h3 className='color-white-new'>SymbioteScore</h3>
              <p className='paragraph-new-medium mb-0'>Aggregate score based on lead mentors' and Symbiote team's feedback.</p>
            </div>
          </div>
        </div>

        {projectUserData && projectUserData?.projectAvgScore > 80 &&
          <div className='d-flex align-items-center justify-content-center mt-4 gap-3'>
            <p className='paragraph-new-medium mb-0 partner-score-80 fw-bolder'>Your aggregate score is 80+. You’re eligible for Exclusive Partners now.</p>
            <button className='new-primary-button' onClick={() => setValue(3)}>Visit Partner Network</button>
          </div>
        }

        <div className='counter-section mt-lg-5 mt-4 mb-lg-0 mb-md-0 mb-4'>
          <div className="row">
            <div className='col-lg-4 col-md-4 col-4'>
              <div className='counters-card'>
                <h5 className='heading-new-5 color-primary'>Partner Connects</h5>
                <h3 className='heading-new-3 mt-3'>{projectUserData && projectUserData?.partnerConnects || 0}</h3>
              </div>
            </div>
            <div className='col-lg-4 col-md-4 col-4'>
              <div className='counters-card'>
                <h5 className='heading-new-5 color-primary'>Mentor Connects</h5>
                <h3 className='heading-new-3 mt-3'>{projectUserData && projectUserData?.mentorConnects || 0}</h3>
              </div>
            </div>
            {/* <div className='col-lg-4 col-md-4 col-4'>
              <div className='counters-card'>
                <h5 className='heading-new-5 color-primary'>Courses Completed</h5>
                <h3 className='heading-new-3 mt-3'>{projectUserData && projectUserData?.coursesCompleted || 0} </h3>
              </div>
            </div> */}
          </div>
        </div>

        <div className='mt-lg-5 mt-md-3 mt-2'>
          {projectUserData && <ProgressRangeSlider symbioteScalePostion={projectUserData?.symbioteScalePostion || 0} tabValue={value} setValue={setValue} />}
        </div>

        <div>
          <Feeds />
        </div>

        <div className=''>
          <Updatessymbioversetable />
        </div>

        <div className='mentors-section mt-lg-5 mt-4'>
          <div className="d-lg-flex d-md-flex d-block align-items-center gap-3 text-center">
            <h4 className='heading-new-4 mb-lg-0 mb-md-0 mb-2 color-primary'>Recommended Mentors</h4>
            {projectUserData && !projectUserData?.isFormCompleted &&
              <p className='paragraph-new-small mb-0' style={{ color: "#AAA" }}>Complete the profile form entirely for more accurate suggestions</p>
            }
          </div>
          <div className='connection-section mt-lg-4 mt-md-3 mt-3'>
            {screenSize && screenSize.width > 767 ?
              <div className="row">
                {recomandedMentorsLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress style={{ color: 'white' }} size={32} />
                </div>}
                {!recomandedMentorsLoader && recomandedMentorsData && recomandedMentorsData?.map((item: any, i: any) => (
                  <div className="col-lg-2 col-md-3 my-lg-3 my-2" key={i}>
                    <MentorCard
                      key={i}
                      name={item && item.name || "No name available"}
                      title={item && item?.industriesOfExpertise && item?.industriesOfExpertise.join(', ')}
                      _id={item && item._id}
                      profilePic={item && item.profilePic}
                      requestStatus={item && item?.requestStatus}
                      setRefreshData={setRefreshData}
                      refreshData={refreshData}
                    />
                  </div>
                ))}
              </div> :
              <div className='accelerate-list-slider'>
                {recomandedMentorsLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <CircularProgress style={{ color: 'white' }} size={32} />
                </div>}
                <Slider {...Mentorsettings}>
                  {!recomandedMentorsLoader && recomandedMentorsData && recomandedMentorsData?.map((item: any, i: number) => (
                    <MentorCard
                      key={i}
                      name={item && item.name || "No name available"}
                      title={item && item?.industriesOfExpertise && item?.industriesOfExpertise.join(', ')}
                      _id={item && item._id}
                      profilePic={item && item.profilePic}
                      requestStatus={item && item?.requestStatus}
                      setRefreshData={setRefreshData}
                      refreshData={refreshData}
                    />
                  ))}
                </Slider>
              </div>
            }
          </div>
        </div>

        <div className='courses-section mt-lg-5 mt-4'>
          <div className="d-lg-flex d-md-flex d-block align-items-center gap-3 text-center mb-lg-5 mb-4">
            <h3 className='heading-new-4 mb-lg-0 mb-md-0 mb-2 color-primary'>Recommended Courses</h3>
            {projectUserData && !projectUserData?.isFormCompleted &&
              <p className='paragraph-new-small mb-0' style={{ color: "#AAA" }}>Complete the profile form entirely for more accurate suggestions</p>
            }
          </div>
          {/* <div className='media-slider'>
            <Slider {...settings}>
              {recomandedCourcesData && recomandedCourcesData?.map((values: any, i: number) => (
                <div className="slide-content" key={i}>
                  <div className="content"> */}
          {/* <ReactPlayer className="card-player" width={'100%'} height={'100%'} controls={true} playing={playing} loop={false} muted={true} url={"https://media.istockphoto.com/id/1425072693/video/portrait-of-lgbtqia-fashion-designer.mp4?s=mp4-640x640-is&k=20&c=zqhqs66duEibazqUiXk-TR6b5bm-894X4KgcsFcNS0Y="} /> */}
          {/* <YouTube videoId={values && values?.videoId} */}
          {/* // opts={opts} onReady={this._onReady}
                    /> */}
          {/* <div className='mt-lg-3 mt-md-3 mt-2 slider-card-content'>
                      <h4 className='heading-new-4 color-white-new text-center'>{values && values?.title}</h4>
                      <p className='paragraph-new text-center mt-lg-3 mt-md-3 mt-2'>{values && values?.shortDescription}</p>
                    </div>
                  </div>
                </div>
              ))}<media
            </Slider>
          </div> */}
          {recomandedCourcesLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: 'white' }} size={32} />
          </div>}
          {!recomandedCourcesLoader && <MediaSlider videoData={recomandedCourcesData} isexternalContent />}
        </div>

        <div className='event-section'>
          <div className="row">
            {/* <div className="col-lg-6">
              <div className="h-100">
                <h4 className='heading-new-4 text-lg-start text-md-start text-center'>Your Upcoming Events</h4>
                <div className='event-bg mt-3'>
                  <div className='event-card'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='heading-new-4'>15 May 2023</h4>
                      <p className='paragraph-new fw-light mb-0'>3 Days to go</p>
                    </div>
                    <h4 className='heading-new-4 fw-light mt-3'>Understanding Tokenomics</h4>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                      <h4 className='paragraph-new fw-light'>with Gregory Peck</h4>
                      <i className="ri-notification-3-line" style={{ color: "#fff" }}></i>
                    </div>
                  </div>
                  <div className='event-card mt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='heading-new-4'>15 May 2023</h4>
                      <p className='paragraph-new fw-light mb-0'>3 Days to go</p>
                    </div>
                    <h4 className='heading-new-4 fw-light mt-3'>Understanding Tokenomics</h4>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                      <h4 className='paragraph-new fw-light'>with Gregory Peck</h4>
                      <i className="ri-notification-3-line" style={{ color: "#fff" }}></i>
                    </div>
                  </div>

                </div>
              </div>
            </div> */}
            <div className="col-lg-12 mt-lg-0 mt-4">
              <div className="">
                <h4 className='heading-new-4 text-lg-start text-md-start text-center color-primary'>Events on Symbiote</h4>
                <div className='event-bg mt-3'>
                  {eventLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress style={{ color: 'white' }} size={32} />
                  </div>}
                  {!eventLoader &&
                    <>
                      {eventsData && eventsData?.length > 0 ?
                        eventsData.map((item: any, i: number) => {
                          var a = moment(new Date());
                          var b = moment.unix(item.startDate);
                          var diffrenceDays = b?.diff(a, 'days');
                          return (
                            <div className='event-card2' key={i}>
                              <div className='d-flex justify-content-between align-items-center'>
                                <h4 className='heading-new-4'>{moment.unix(item?.startDate).format('MMM DD YYYY')}</h4>
                                <p className='paragraph-new fw-light mb-0'>{diffrenceDays && diffrenceDays > 0 ? `${diffrenceDays} Days to go` : "Today"}</p>
                              </div>
                              <h4 className='heading-new-4 fw-light mt-3'>{item?.title}</h4>
                              <div className='d-flex justify-content-between align-items-center mt-3'>
                                <div className="guests d-flex gap-2">
                                  <p className='paragraph-new mb-0 mt-0  fw-lighter'>Guests:</p>
                                  <p className='paragraph-new mb-0 mt-0 fw-lighter'>{item?.guests || "No data available"}</p>
                                </div>
                                {/* {item.isReminder ?
                                  <span title="reminder added" onClick={() => addRemoveEventReminder(item._id)}><i className="ri-notification-3-fill" style={{ color: "#fff", cursor: 'pointer' }}></i></span>
                                  :
                                  <span title="add reminder" onClick={() => addRemoveEventReminder(item._id)}><i className="ri-notification-3-line" style={{ color: "#fff", cursor: 'pointer' }}></i></span>
                                } */}
                              </div>
                            </div>
                          )
                        })
                        : <p className='paragraph-new text-center'>No Events</p>
                      }
                    </>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard