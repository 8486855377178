import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const getLibrary = (provider: any) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

root.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <ToastContainer
      autoClose={3000}
      style={{ fontSize: 14, fontWeight: '700', width: 'fit-content', minWidth: '300px' }}
      closeOnClick
      newestOnTop
    />
    <App />
  </Web3ReactProvider>
);
