import React, { useEffect, useRef, useState } from 'react'
import "./offers-card.sass"
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';
import viewDiscountIcon from "../../../../../../../assests/icons/view-discount-icon.svg"
import { Link } from 'react-router-dom'
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model';

export interface MentorCardData {
    projectPartnerDiscountData: any,
    setRefreshData: any,
    refreshData: boolean,
    onClickViewDiscount: (discountId: string) => void
}

const Offerscard = (props: MentorCardData) => {
    const { projectPartnerDiscountData, setRefreshData, refreshData, onClickViewDiscount } = props
    const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
    const [selectedPartnerData, setSelectedPartnerData] = useState<any>()

    const connectionRequestModalToggle = () => {
        setConnectionRequestModal(!connectionRequestModal)
    }
    const [height, setHeight] = useState(0)
    const ref = useRef<any>(null)

    useEffect(() => {
        if (projectPartnerDiscountData && ref?.current?.clientHeight) {
            setHeight(ref?.current?.clientHeight)
        }
    }, [ref, projectPartnerDiscountData])

    return (
        <>
            <div className={height > 1000 ? 'offer-table-section mt-lg-5 scrolled mt-md-3 mt-2' : 'offer-table-section mt-lg-5 mt-md-3 mt-2'}>
                <div className="row">
                    {projectPartnerDiscountData && projectPartnerDiscountData?.length > 0 ?
                        projectPartnerDiscountData?.map((item: any, i: any) => (
                            <div className="col-lg-4 col-md-6 col-12 my-lg-3 my-2" key={i}>
                                <div className='vc-list-card text-center'>
                                    <div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <img className="m-auto discount-partner-icon" src={item?.partner?.icon || NoImage} alt="" />
                                            <h5 className='heading-new-5 text-start mt-4 company-name-text'>{item?.partner?.companyName || "No name available"}</h5>
                                        </div>
                                        <ul className='social-link-table mt-lg-3 mt-md-3 mt-2'>
                                            {item?.partner?.twitterLink && item?.partner?.twitterLink?.length > 0 &&
                                                <li>
                                                    <Link to={item?.partner?.twitterLink} target='_blank'>
                                                        <i className="ri-twitter-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {item?.partner?.linkedInLink && item?.partner?.linkedInLink?.length > 0 &&
                                                <li>
                                                    <Link to={item?.partner?.linkedInLink} target='_blank'>
                                                        <i className="ri-linkedin-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {item?.partner?.telegramId && item?.partner?.telegramId?.length > 0 &&
                                                <li>
                                                    <Link to={item?.partner?.telegramId} target='_blank'>
                                                        <i className="ri-send-plane-fill"></i>
                                                    </Link>
                                                </li>
                                            }
                                            {item?.partner?.companyWebsiteLink && item?.partner?.companyWebsiteLink?.length > 0 &&
                                                <li>
                                                    <Link to={item?.partner?.companyWebsiteLink} target='_blank'>
                                                        <i className="ri-links-line"></i>
                                                    </Link>
                                                </li>
                                            }
                                        </ul>
                                        <p className='paragraph-new fw-lighter mt-lg-3 mt-md-3 mt-2 mb-lg-3 mb-md-3 mb-2 company-operations-text'>{item?.partner?.companyOperations && item?.partner?.companyOperations.join(', ') || "-"}</p>
                                        {/* <h5 className='avail'>{item?.title || "No title available"}</h5> */}
                                        <div className="view-discount-item">
                                            <button className='new-color-button view-discount-btn mt-2' onClick={() => onClickViewDiscount!(item?._id)}>
                                                <img src={viewDiscountIcon} />
                                                View Discounts
                                            </button>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        {item?.requestStatus === "none" &&
                                            <button className='request-to-onnect-btn mt-lg-3 mt-md-3 mt-2' onClick={() => { setSelectedPartnerData(item?.partner); connectionRequestModalToggle() }}>Connect to Partner</button>
                                        }
                                        {item?.requestStatus === "requested" &&
                                            <button className='request-to-onnect-btn mt-lg-3 mt-md-3 mt-2' style={{ background: "transparent", color: "#FFB800", border: "1px solid #FFB800" }}>Requested</button>
                                        }
                                        {item?.requestStatus === "rejected" &&
                                            <button className='request-to-onnect-btn mt-lg-3 mt-md-3 mt-2' style={{ background: "transparent", color: "#FF0000", border: "1px solid #FF0000" }}>Not interested</button>
                                        }
                                        {item?.requestStatus === "connected" &&
                                            <button className='request-to-onnect-btn mt-lg-3 mt-md-3 mt-2' style={{ background: "#4398FF", color: "#FFFFFF", border: "1px solid #4398FF" }}>Connected</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                        : <p className='paragraph-new text-center'>No data available</p>
                    }
                </div>
            </div>
            <ConnectionRequestModel
                isOpen={connectionRequestModal}
                toggle={connectionRequestModalToggle}
                name={selectedPartnerData?.companyName}
                id={selectedPartnerData?._id}
                icon={selectedPartnerData?.icon}
                userType={"partner"}
                setRefreshData={setRefreshData}
                refreshData={refreshData}
            />
        </>
    )
}

export default Offerscard