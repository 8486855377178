import axios from "axios";
import { symbioteApiBaseUrl } from "../../../../../constants";
import { ICombineFilters, IJob } from "./JobBoard";

const getAuthorizationAccessToken = () => {
  const authorization = JSON.parse(localStorage.getItem("authorization") as any)
  if (authorization && authorization) {
    return authorization
  }
  return ""
}

const authHeaders = () => {
  return {
    Authorization: `Bearer ${getAuthorizationAccessToken()}`,
  };
}

export const getAllJobsAPI = (filters: ICombineFilters): Promise<{ values: IJob[], total: number }> => {
  const [minSalary, maxSalary] = filters.salaryRange

  let searchQuery = { minSalary, maxSalary } as any
  if (filters) {
    searchQuery = { ...searchQuery, ...filters }
  }
  delete searchQuery.salaryRange
  delete searchQuery.page
  delete searchQuery.minSalary
  delete searchQuery.maxSalary


  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/job", { headers: authHeaders(), params: searchQuery }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

export const getJobByIdAPI = (id: string): Promise<IJob> => {
  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/job/" + id, { headers: authHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

export const getJobRolesAPI = (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/job/roles", { headers: authHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};

export const getJobIndustriesAPI = (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    axios.get(symbioteApiBaseUrl + "/api/v1/job/industries", { headers: authHeaders() }).then(response => {
      resolve(response.data)
    }).catch(err => {
      reject(err.response ? err.response.data : { message: 'Something went wrong' })
    })
  })
};