import React, { useEffect, useState } from 'react'
import "./partner-discounts.sass"
import DiscountsCard from './components/discounts-card/discounts-card'
import Offerscard from './components/offers-card/offers-card'
import Slider from 'react-slick'
import Featurecard from '../vc-network/components/feature-card/feature-card'
import { getProjectPartnerDiscountData } from './API/APICall'
import { ToastContainer, toast } from 'react-toastify'
import DiscountDescriptionModal from './components/discount-description-modal/discount-description-modal'
import CircularProgress from "@mui/material/CircularProgress"

export interface MentorCardData {
    value: any,
}

let settings = {
    dots: false,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    responsive: [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 2.1,
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 1.9,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1.8,
            }
        },
        {
            breakpoint: 600,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 300,
            settings: {
                rows: 3,
                slidesToShow: 1,
            }
        }

    ]
};

const Partnerdiscounts = (props: MentorCardData) => {
    const { value } = props
    const [projectPartnerDiscountData, setProjectPartnerDiscountData] = useState<any>([])
    const [discountsLoader, setDiscountLoader] = useState(false)
    const [projectPartnerDiscountFeaturedData, setProjectPartnerDiscountFeaturedData] = useState<any>([])
    const [featuredDiscountLoader, setFeaturedDiscountLoader] = useState(false)
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [viewDiscountId, setViewDiscountId] = useState('')

    useEffect(() => {
        getAllProjectPartnerDiscountData(true)
        getAllProjectPartnerDiscountData(false)
    }, [refreshData])

    // get project partner discount data
    const getAllProjectPartnerDiscountData = async (isFeatured: boolean) => {
        isFeatured ? setFeaturedDiscountLoader(true) : setDiscountLoader(true)
        await getProjectPartnerDiscountData(isFeatured)
            .then((res) => {
                isFeatured ? setFeaturedDiscountLoader(false) : setDiscountLoader(false)
                if (res?.status === 200) {
                    if (isFeatured) {
                        setProjectPartnerDiscountFeaturedData(res && res?.data?.values);
                    } else {
                        setProjectPartnerDiscountData(res && res?.data?.values);
                    }
                }
            })
            .catch((err) => {
                isFeatured ? setFeaturedDiscountLoader(false) : setDiscountLoader(false)
            })
    }

    const onClickViewDiscount = (discountId: string) => {
        setViewDiscountId(discountId)
    }

    return (
        <>
            <div>
                <div className='feature-vc-section'>
                    <h4 className='heading-new-4 mb-lg-5 mb-4 color-primary'>Featured Discounts - JUST IN</h4>

                    {featuredDiscountLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress style={{ color: 'white' }} size={32} />
                    </div>}

                    {!featuredDiscountLoader && <>
                        {projectPartnerDiscountFeaturedData?.length > 0 ?
                            <Slider {...settings}>
                                {projectPartnerDiscountFeaturedData && projectPartnerDiscountFeaturedData?.map((item: any, i: number) => (
                                    <Featurecard
                                        key={i}
                                        name={item?.partner?.companyName}
                                        _id={item?._id}
                                        area={item?.partner?.companyOperations && item?.partner?.companyOperations.join(', ')}
                                        description={item?.partner?.industriesOfExpertise && item?.partner?.industriesOfExpertise.join(', ')}
                                        icon={item?.partner?.icon}
                                        linkedInLink={item?.partner?.linkedInProfileLink}
                                        twitterLink={item?.partner?.twitterLink}
                                        telegramId={item?.partner?.telegramId}
                                        companyWebsiteLink={item?.partner?.companyWebsiteLink}
                                        title={item?.title}
                                        requestStatus={item?.requestStatus}
                                        setRefreshData={setRefreshData}
                                        refreshData={refreshData}
                                        onClickViewDiscount={onClickViewDiscount}
                                    />
                                ))}
                            </Slider> : <p className='paragraph-new text-center'>No data available</p>
                        }
                    </>}
                </div>
                <div className='all-offers-section mt-lg-5 mt-md-3 mt-2'>
                    <h4 className='heading-new-4 color-primary'>All offers</h4>
                    <div>
                        {discountsLoader && <div style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress style={{ color: 'white' }} size={32} />
                        </div>}
                        {!discountsLoader && <Offerscard
                            projectPartnerDiscountData={projectPartnerDiscountData && projectPartnerDiscountData}
                            setRefreshData={setRefreshData}
                            refreshData={refreshData}
                            onClickViewDiscount={onClickViewDiscount}
                        />}
                    </div>
                </div>
                <DiscountDescriptionModal
                    isOpen={!!viewDiscountId}
                    toggle={() => setViewDiscountId('')}
                    discountId={viewDiscountId}
                    setRefreshData={setRefreshData}
                    refreshData={refreshData}
                />
            </div>
        </>
    )
}

export default Partnerdiscounts