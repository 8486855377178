import { useState, useEffect, useRef } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './header.sass'
import { isLoggedIn, loggedInAs } from '../../../constants/utils';
import accelerate from '../../../assests/images/accelerate/accelerate-name-image.svg';
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { injected } from "../wallet/connectors";

const Header = () => {
    const nav = useNavigate()
    const location = useLocation()
    const isUserLoggedIn = isLoggedIn()

    const [connectLoader, setConnectLoader] = useState(false)
    const [isConnected, setIsConnected] = useState("initial")
    const { chainId = 0, activate, account, active, deactivate } = useWeb3React()

    const walletConnecting = useRef(false)

    const isHomepage = location.pathname === '/' ||
        location.pathname === '/blogs' ||
        location.pathname.includes('/b/') ||
        location.pathname.includes('/jobs') ||
        location.pathname.includes('/events')
    const showConnectWallet = location.pathname.includes('/project/')

    const clearDataInLocalStorage = () => {
        localStorage.clear();
        nav('/')
    }

    const onClickDashboard = () => {
        const { mentor, project, vc, partner } = loggedInAs()
        if (mentor) nav('/mentor-dashboard')
        else if (vc) nav('/vc-dashboard')
        else if (project) nav('/project/dashboard')
        else nav('/partner-dashboard')
    }

    useEffect(() => {
        (async () => {
            // const { error } = await checkIfTokenIsValid() as any
            // if (error) {
            //     toast.error("Session expired, please login")
            //     return clearDataInLocalStorage()
            // }
            let wasConnected = sessionStorage.getItem("connected") as any
            wasConnected = wasConnected ? JSON.parse(wasConnected) : {}
            if (wasConnected?.connection) {
                connectToWallet()
            }

        })()
    }, [])

    useEffect(() => {
        if (!active && isConnected === "success" && !chainId) {
            toast.error("Chain not supported")
        }
    }, [active, isConnected, chainId])

    async function connectToWallet() {
        try {
            walletConnecting.current = true
            setConnectLoader(true)
            setIsConnected("initial")
            await activate(injected)
            sessionStorage.setItem("connected", JSON.stringify({ connection: true }))
            setIsConnected("success")
            setConnectLoader(false)
        } catch (ex) {
            setConnectLoader(false)
            console.log(ex)
        }
    }

    async function disconnectToWallet() {
        setIsConnected("disconnect")
        deactivate()
        sessionStorage.removeItem("connected")
        toast.success("Wallet disconnected")
    }

    const isWalletConnected = isConnected === "success"

    if (location.pathname === "/") return null

    return (
        <div className="updated-header">
            <header>
                <div className="container">
                    <nav className="navbar">
                        <div className='d-flex align-items-center'>
                            <Link to="/" className="navbar-logo heading-new-3 text-decoration-none">Symbiote</Link>
                            {!isHomepage && <div className="accelerate-icon-item">
                                <img src={accelerate} className='img-fluid accelerate-main-img' alt="" width="100%" height="100%" />
                            </div>}
                        </div>
                        {/* <Link to="/" className="navbar-logo heading-new-3 text-decoration-none">Symbiote</Link> */}

                        <div>
                            <a href="/blogs" className="header-link-navigation">Blog</a>
                            {/* <a href="/jobs" className="header-link-navigation">Jobs</a> */}
                            {!isUserLoggedIn && <a href="/events" className="header-link-navigation">Events</a>}
                            {(isUserLoggedIn && isHomepage) ?
                                <button className='new-primary-button me-2' onClick={onClickDashboard}>Dashboard</button> : null
                            }
                            {(isHomepage && !isUserLoggedIn) && <button className='new-primary-button me-2' onClick={() => { window.open("mailto:contact@symbiote.gg") }}>Contact Us</button>}
                            {isUserLoggedIn ?
                                <>
                                    {(!isHomepage && showConnectWallet) && <button className='new-primary-button me-2' onClick={isWalletConnected ? disconnectToWallet : connectToWallet}>{isWalletConnected ? 'Disconnect Wallet' : 'Connect Wallet'}</button>}
                                    <button className='new-primary-button' onClick={clearDataInLocalStorage}>Logout</button>
                                </>
                                :
                                <button className='new-primary-button' onClick={() => { nav("/login") }}>Login</button>
                            }
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    )
}

export default Header;