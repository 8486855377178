import React, { useState, useEffect } from 'react'
import "../../../../../../home/components/partner-list-table/partner-list-table.sass";
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ConnectionRequestModel from '../connection-request-model/connection-request-model';

export interface statusData {
  setPartnerStatus?: any
  currentPartnerData?: any
  tableColumns?: any
  tabIndex?: any,
  userType?: any
  isLoading?: boolean
  onClickReadMore?: (id: string, isGrant: boolean, isGrantDesc: boolean) => void
  onRefreshData: () => void
}

const GrantPartnerList = (props: statusData) => {
  const { setPartnerStatus, currentPartnerData, tableColumns, userType, isLoading = false, onRefreshData, onClickReadMore } = props
  const location = useLocation();
  const pathname = location.pathname;
  const isProjectDashboard = pathname.includes("/project/dashboard")
  const nav = useNavigate()

  const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
  const [selectedPartnerData, setSelectedPartnerData] = useState<any>({})

  const connectionRequestModalToggle = () => {
    setConnectionRequestModal(!connectionRequestModal)
  }

  return (
    <>
      <div className="partner-list-data">
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {tableColumns.map((column: any) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth, paddingLeft: 0, maxWidth: column.maxWidth || 'auto' }}
                    >
                      <span className='color-primary fw-bold'>
                        {column.label}</span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && <TableRow>
                  <TableCell sx={{ height: 200 }} colSpan={tableColumns.length} align='center'>
                    <CircularProgress size={32} sx={{ color: "#ffffff" }} />
                  </TableCell>
                </TableRow>
                }
                {!isLoading && currentPartnerData && currentPartnerData?.length ?
                  <>
                    {currentPartnerData && currentPartnerData?.map((row: any, i: number) => {

                      const twitterLink = row?.twitterLink ? `https://twitter.com/${row?.twitterLink}` : ''
                      const telegramLink = row?.telegramLink ? `https://t.me/${row?.telegramLink}` : ''
                      const websiteLink = row?.websiteLink || ''
                      const name = row?.name
                      const column2 = String(row?.grantCurrency || '') + String(row?.grantAmount || '')
                      return (
                        <TableRow key={i}>
                          <TableCell>
                            <Avatar alt="Remy Sharp" className='img-fluid mt-0' src={row?.icon || NoImage} />
                          </TableCell>
                          <TableCell onClick={() => userType === "vc" ? nav(`/public-view/vc/${row?._id}?user-type=project`) : ""} role={userType === "vc" ? "button" : ""}>
                            <div className='d-flex align-items-center'>
                              {/* <Avatar alt="Remy Sharp" className='img-fluid mt-0' src={row?.icon || NoImage} /> */}
                              <p className='paragraph-new-medium mb-0 mt-0 fw-bold color-primary cursor-pointer' onClick={() => onClickReadMore!(row?._id, true, false)}>{name}</p>
                            </div>
                          </TableCell>
                          <TableCell><p className='paragraph-new-medium mb-0 mt-0 fw-bold color-primary cursor-pointer' onClick={() => onClickReadMore!(row?._id, true, true)}>{row?.grantName}</p></TableCell>
                          <TableCell><p className='paragraph-new-medium mb-0 mt-0'>{column2}</p></TableCell>
                          {/* <TableCell className=''>
                            <p className='paragraph-new-medium table-description mb-0'>{userType === "vc" ? row?.description : row?.shortDescription || '-'}</p>
                          </TableCell> */}
                          <TableCell >
                            <ul className='social-link-table'>
                              {twitterLink && <li><a href={twitterLink} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                              {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                              {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                            </ul>
                          </TableCell>
                          <TableCell>
                            {/* <p className="paragraph-new-medium mb-0 mt-0">Connected</p> */}
                            <TableCell className=''>
                              {row?.requestStatus === "none" &&
                                <button className='request-to-onnect-btn' onClick={() => { setSelectedPartnerData(row); connectionRequestModalToggle() }}>Request to Connect</button>
                              }
                              {row?.requestStatus === "requested" &&
                                <button className='request-to-onnect-btn yellow-border'>Requested</button>
                              }
                              {row?.requestStatus === "rejected" &&
                                <button className='request-to-onnect-btn red-border'>Not interested</button>
                              }
                              {row?.requestStatus === "connected" &&
                                <button className='request-to-onnect-btn green-border'>Connected</button>
                              }
                            </TableCell>
                          </TableCell>
                        </TableRow>
                      )
                    }
                    )}
                  </>
                  : !isLoading && <p className='paragraph-new mb-0 text-center color-white-new no-data-p'>
                    No data available
                  </p>
                }
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        <ConnectionRequestModel
          isOpen={connectionRequestModal}
          toggle={connectionRequestModalToggle}
          name={selectedPartnerData?.name}
          id={selectedPartnerData?._id}
          icon={selectedPartnerData?.icon}
          userType={'grant-partner'}
          setRefreshData={onRefreshData}
          refreshData={false}
        />
      </div>
    </>
  );
};

export default GrantPartnerList;
