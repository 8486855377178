import { Switch as MuiSwitch, styled, FormGroup } from '@mui/material'

const AntSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 46,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(26px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: '1px solid white',
        backgroundColor: '#303030',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: 14,
    color: '#4398FF',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: '#161616',
    border: '1px solid grey',
    boxSizing: 'border-box',
  },
}));

export default function Switch(props: {value: boolean, onChange: any}) {
  const { value, onChange } = props
  return (
    <FormGroup>
      <AntSwitch value={value} onChange={onChange}/>
    </FormGroup>
  );
}