import { useLocation, useNavigate } from "react-router-dom";
// import Slider from "react-slick";
import YouTube from 'react-youtube';

// let settings = {
//   dots: true,
//   slidesToShow: 2.5,
//   slidesToScroll: 1,
//   arrows: false,
//   infinite: false,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2.1,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 1,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1.1,
//         slidesToScroll: 1
//       }
//     }
//   ]
// };

export interface videoData {
  videoData: any,
  isexternalContent?: boolean
}

const MediaSlider = (props: videoData) => {
  const { videoData, isexternalContent } = props
  const nav = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  // const onPlayerReady: YouTubeProps['onReady'] = (event: any) => {
  //   event.target.pauseVideo();
  // }

  const onClickMediaCard = (item: any) => {
    if (isexternalContent) {
      return window.open(item.link)
    } else {
      nav(`/project/resources/video-details/${item._id}`)
    }
  }

  return <div className="media-slider-main">
    <div className="media-slider-container">
      {
        videoData && videoData.length ? videoData.map((item: any, index: number) => {
          return <div key={index} className="slider-item" onClick={() => onClickMediaCard(item)}>
            <div className="youtube-video-item">
              <div className="disable-video-playback"></div>
              {isexternalContent ? <img src={item?.image} className="img-fluid" alt="" /> :
                <YouTube videoId={item && item?.videoId} className="youtube-video" />}
            </div>
            <div className="title-item">
              <h4 className='heading-new-4 color-white-new text-center' title={item && item?.title || ""}>{item && item?.title || "No title available"}</h4>
            </div>
            <div className="description-item">
              <p className='paragraph-new text-center mt-lg-3 mt-md-3 mt-2 mb-3' title={item && isexternalContent ? item?.description : item?.shortDescription || ""}>{item && isexternalContent ? item?.description : item?.shortDescription || "No short description available"}</p>
            </div>
          </div>
        }) : null
      }
    </div>
  </div>
};

export default MediaSlider;

