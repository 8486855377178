import React, { useEffect, useState } from 'react'
import "./index.scss"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import CircularProgress from "@mui/material/CircularProgress"
import NoImage from '../../../../../../../assests/images/homepage/no-image-available.jpg';
import { getPartnerDiscountByIdAPI } from '../../API/APICall';
import ConnectionRequestModel from '../../../partner-network/components/connection-request-model/connection-request-model';

interface detailsProps {
  isOpen: boolean,
  toggle: () => void,
  discountId: string,
  setRefreshData: (value: boolean) => void
  refreshData: boolean
}

const DiscountDescriptionModal = ({ isOpen, toggle, discountId, setRefreshData, refreshData }: detailsProps) => {

  const [descLoader, setDescLoader] = useState(true)
  const [discount, setDiscount] = useState<any>({})
  const [connectionRequestModal, setConnectionRequestModal] = useState<boolean>(false)
  const [selectedPartnerData, setSelectedPartnerData] = useState<any>()

  const connectionRequestModalToggle = () => {
    setConnectionRequestModal(!connectionRequestModal)
  }

  useEffect(() => {
    if (discountId) {
      onFetchDiscountById(discountId)
    }
    return () => {
      setDiscount({})
    }
  }, [discountId, refreshData])

  const onFetchDiscountById = async (discountId: string) => {
    setDescLoader(true)
    const response = await getPartnerDiscountByIdAPI(discountId)
    setDiscount(response)
    setDescLoader(false)
  }

  const partner = discount?.partner || {}
  const { twitterLink = '', telegramId = '', companyWebsiteLink = '', linkedInLink = '' } = partner || {}

  const twitterLinkid = twitterLink ? `https://twitter.com/${twitterLink}` : ''
  const telegramLink = telegramId ? `https://t.me/${telegramId}` : ''
  const websiteLink = companyWebsiteLink || ''
  const linkedinLinkId = linkedInLink || ''

  return (
    <>
      <Modal className='connection-request-model description-modal' centered fade={false} isOpen={isOpen} toggle={toggle}>
        <div className="modal-wrapper">
          <ModalHeader toggle={toggle} className='justify-content-center'>
            <div className="close-btn" onClick={toggle}>
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 3C9.705 3 3 9.705 3 18C3 26.295 9.705 33 18 33C26.295 33 33 26.295 33 18C33 9.705 26.295 3 18 3ZM18 30C11.385 30 6 24.615 6 18C6 11.385 11.385 6 18 6C24.615 6 30 11.385 30 18C30 24.615 24.615 30 18 30ZM23.385 10.5L18 15.885L12.615 10.5L10.5 12.615L15.885 18L10.5 23.385L12.615 25.5L18 20.115L23.385 25.5L25.5 23.385L20.115 18L25.5 12.615L23.385 10.5Z"
                  fill="black"
                />
              </svg>
            </div>
          </ModalHeader>
        </div>
        <ModalBody>
          <div className="description-modal-container">
            {descLoader ? <div className='modal-loader'>
              <CircularProgress size={32} sx={{ color: 'white' }} />
            </div>
              :
              <div className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center gap-3">
                    <img
                      className="rounded-circle"
                      src={discount?.partner?.icon || ''}
                      width={100}
                      alt="icon"
                    />
                    <div className="d-flex flex-column align-items-start">
                      <h4>{discount?.partner?.companyName || ''}</h4>
                      <span>{discount?.partner?.companyOperations && discount?.partner?.companyOperations.join(', ') || ''}</span>
                    </div>
                  </div>

                  <div className="d-none d-md-block">
                    <div className='deshboard-social d-flex gap-3'>
                      <ul className='social-link-table'>
                        {twitterLinkid && <li><a href={twitterLinkid} target='_blank'><i className="ri-twitter-fill"></i></a></li>}
                        {websiteLink && <li><a href={websiteLink} target="_blank"><i className="ri-links-line"></i></a></li>}
                        {telegramLink && <li><a href={telegramLink} target="_blank"><i className="ri-send-plane-fill"></i></a></li>}
                        {linkedinLinkId && <li><a href={linkedinLinkId} target='_blank'><i className="ri-linkedin-fill"></i></a></li>}
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="mt-4">
                  <p style={{ whiteSpace: 'pre-wrap' }}>{discount?.description || 'No Description Available'}</p>
                </div>
                <div className="mt-4 text-center">
                  <button className="new-primary-button" onClick={() => { setSelectedPartnerData(discount?.partner); connectionRequestModalToggle(); }}>Connect to Partner</button>
                </div>
              </div>
            }
          </div>
        </ModalBody>
        <ConnectionRequestModel
          isOpen={connectionRequestModal}
          toggle={connectionRequestModalToggle}
          name={selectedPartnerData?.companyName}
          id={selectedPartnerData?._id}
          icon={selectedPartnerData?.icon}
          userType={"partner"}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
        />
      </Modal>
    </>
  );
}

export default DiscountDescriptionModal